/** */
import React, { Component } from "react";
import { Button } from "@mui/material";
import panUploadIcon from "../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../assets/images/picEditIcon.png";
import TickIcon from "../../assets/images/fileUploadedTick.png";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      imagePreview_Url: "",
      fileUpload: "",
      selectedFileName: this.props.pancard_file,
      imagePreview: "d-none",
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
    };
    this.handleselectedFile = this.handleselectedFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
  }
  handleselectedFile = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
      uploadFileName: "d-block",
      uploadFileBlock: "d-none",
    });
    this.props.handleUploadFile(event);
  };
  handleDeleteFile = () => {
    this.setState({
      selectedFile: "",
      selectedFileName: "",
      uploadFileName: "d-none",
      uploadFileBlock: "d-block",
    });
  };

  componentDidMount() {
    if(this.props.pancard_file){
      this.setState({
        selectedFileName: this.props.pancard_file,
        uploadFileName: "d-block",
        uploadFileBlock: "d-none",
      });
    }else{
      this.setState({
        selectedFile: "",
        selectedFileName: "",
        uploadFileName: "d-none",
        uploadFileBlock: "d-block",
      });
    }
  }
  componentWillReceiveProps(props) {
    if(props.pancard_file){
      this.setState({
        selectedFileName: this.props.pancard_file,
        uploadFileName: "d-block",
        uploadFileBlock: "d-none",
      });
    }else{
      this.setState({
        selectedFile: "",
        selectedFileName: "",
        uploadFileName: "d-none",
        uploadFileBlock: "d-block",
      });
    }
  }

  render() {
    return (
      <div>
        <label>&nbsp;</label>
        <div className="ducumentUploadBlock">
          <div
            className={"documentUploadContent " + this.state.uploadFileBlock}
          >
            <input
              type="file"
              name="file"
              id="file-upload"
              onChange={this.handleselectedFile}
              className="hiddenInput"
            />
            <label htmlFor="file-upload" style={{ display: "flex" }}>
              <Button
                variant="contained"
                component="span"
                //endIcon={<GetAppIcon />}
                className="documentUpload"
              >
                <img src={panUploadIcon} alt="" /> Upload Pan Card
              </Button>
            </label>
          </div>
          <div className={this.state.uploadFileName }>
            <div className="uploadedInfo">
              <img src={TickIcon} alt="" className="tickIcon" />
              <div className="selectedFileName">
                {this.state.selectedFileName}
              </div>
              <button onClick={this.handleDeleteFile} className="deleteButton">
                <img src={DeleteIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ImageUpload;
