import React, { Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  { serverApi } from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons"
import Link from "@mui/material/Link";
import { Grid, Box } from "@mui/material";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldNormal from '../../../components/TextField/TextFieldNormal'
import {createTaxMaster} from "../../../store/Master/TaxMaster/actions"
import swal from "sweetalert";


class TaxMaster extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      companyId: 0, 
      userDit: JSON.parse(localStorage.getItem("authUser")),
      taxName: "",
      taxPercentage:"",
      createdBy:"",
      createdOn:"" 
    };
  }
  componentDidMount() {
   console.log("getting the response here to check", this.props.location.rowdata)
   if(this.props.location){
     if(this.props.location.rowdata){
       this.setState({
         taxName: this.props.location.rowdata.taxName,
         taxPercentage: this.props.location.rowdata.taxPercentage,
         createdBy: this.props.location.rowdata.createdBy,
         createdOn: this.props.location.rowdata.createdOn
       })
     }
   }
  }

  onhandlechangeValue = (key) => (event) => {
    console.log("getting the entered value", key, event);
    if(key === "taxName"){
      // const tax = event.replace(/[^a-zA-Z\s]+/g, '');
      this.setState({
        taxName: event,
      })
    }
    if(key === "taxPercentage"){
      if ((/^[0-9]+(\.[0-9]{0,2})?$/.test(event) || event==="")&& event <= 100) {
      this.setState({
        taxPercentage: event
      })
    }
  }
  }

  onClickCreateUpdate =() => {
    console.log("getting the data after selection", this.state.taxName, this.state.taxPercentage);
    if(this.props.location.rowdata === undefined){
      // if(this.state.taxName === "" || this.state.taxPercentage === ""){
      //   swal("Please Enter the mandatory field", {icon:"error"});
      //   console.log("mandatory fields", this.props.location.rowdata)
      // }

      if(this.state.taxName === "" ){
        swal("Please Enter the Tax Name !!!");
        return false;
      }
      if(this.state.taxPercentage === "" ){
        swal("Please Enter the Tax Percentage !!!");
        return false;
      }
      
      
      else{
      
      const data = {
        tax:{
          taxName: this.state.taxName,
          taxPercentage: this.state.taxPercentage,
          createdBy: this.state.userDit.userId
        },
        postAuthenticationDto:{
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyIdconfig")
        }
      }
      console.log("created successfully", data)
      this.props.createTaxMaster(serverApi.CREATE_TAX_MASTER_API, data, this.props.history);
      }
    }else{
      // if(this.state.taxName === "" || this.state.taxPercentage === ""){
      //   swal("Please Enter the mandatory field", {icon:"error"});
      //   console.log("mandatory fields", this.props.location.rowdata)
      // }
      if(this.state.taxName === "" ){
        swal("Please Enter the Tax Name !!!");
        return false;
      }
      if(this.state.taxPercentage === "" ){
        swal("Please Enter the Tax Percentage !!!");
        return false;
      }
      
      else{
      
      const data = {
        tax:{
          taxId: this.props.location.rowdata.taxId,
          taxName: this.state.taxName,
          taxPercentage: this.state.taxPercentage,
          createdBy: this.state.userDit.userId
        },
        postAuthenticationDto:{
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyIdconfig")
        }
      }
      console.log("Updated S successfully", data)
      this.props.createTaxMaster(serverApi.UPDATE_TAX_MASTER_API, data, this.props.history);
      }
    }
    
  }

  onClickCancel =() => {
    this.props.history.push("/tax_master")
  }

  render() {
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <>
              <div className="departmentMasterContainer">
                <div className="consoleFormContainer">
                  <h5>Fill Details</h5>
                  <div className="consoleFormBlock">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                        >

                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldReq
                                label="Tax Name"
                                value={this.state.taxName}
                                caps="OFF"
                                onChange={this.onhandlechangeValue("taxName")}
                                disabled={true}
                              />
                            </div>
                          </Box>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldReq
                                label="Tax Percentage"
                                value={this.state.taxPercentage}
                                caps="OFF"
                                onChange={this.onhandlechangeValue("taxPercentage")}
                                disabled={true}
                              />
                            </div>
                          </Box>
                          
                        </Box>
                        {this.props.location.rowdata === undefined ? (
                            ""
                          ) : (
                            <>
                              <Box gridColumn="span 6" style={{marginTop:"20px"}}>
                                <div className="consoleUpdateTextBlock">
                                  <div>
                                    <span className="taxLabel">Created By : <b>{this.state.createdBy}</b></span>
                                  </div>
                                  <div>
                                    <span className="taxLabel">Created On :<b> {this.state.createdOn}</b></span>
                                  </div>
                                </div>
                              </Box>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className="consoleFormButtonBlock">
                
                {/* <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={this.onClickCancel}
                  />
                </Link> */}
                {/* {this.props.location.rowdata !== undefined ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                ) : (
                  <CustomButton
                    label={"Create"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                )} */}

                {/* Popup For Validation */}
                {this.state.confirm && (
                  ""
                )}
              </div>
            </>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  
};

export default withRouter(
  connect(mapStatetoProps, {
    // departmentMasterList,
    createTaxMaster
  })(TaxMaster)
);
