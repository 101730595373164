import React, { Component } from "react";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';




class Alerts extends Component {
    constructor(props) {
      super(props);
      this.state = { };  
    }  
    
    render() {     
  
      return (
        <>
          <Stack sx={{ width: '100%', marginBottom:'10px', display:this.props.alert }} spacing={2}>
            <Alert severity={this.props.severity}>{this.props.notmsg}</Alert>
          </Stack>
        </>
      );
    }
  }
  
  
  export default Alerts;