import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Grid, Box } from '@mui/material'
import TextFieldNormal from '../../../components/TextField/TextFieldNormalNew';
import TextFieldReq from "../../../components/TextField/TextFieldReq";



class LeavePolicyFillDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            leavePolicyDesc: this.props.header.leavePolicyDesc
        }
    }


    handleChange = (key) => (event) => {
        this.setState({ [key]: event })
        // this.props.onhandlechangeValue(event, key);
        if (key === 'leavePolicyDesc') {
            this.props.header.leavePolicyDesc = event
            this.setState({
                leavePolicyDesc: event
            })
        }

    }

    componentWillReceiveProps(props) {

        if (props.location.rowdata) {
            this.props.header.leavePolicyDesc = props.location.rowdata.leavePolicyDesc;
            this.setState({
                leavePolicyDesc: props.location.rowdata.leavePolicyDesc
            })
        }

    }

    render() {
        return (
            <div>
                <div className="consoleFormContainer">
                    <h5>Fill Details</h5>
                    <div className="consoleFormBlock">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={2}
                                >

                                    <Box gridColumn="span 3">
                                        <div className="consoleTextFieldBlock">
                                            <TextFieldReq label="Leave Policies Description"
                                                value={this.state.leavePolicyDesc}
                                                caps="OFF"
                                                minValue="0"
                                                onChange={this.handleChange("leavePolicyDesc")}
                                                required
                                            />
                                        </div>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStatetoProps = (state) => { }
export default connect(
    mapStatetoProps
)(withRouter(LeavePolicyFillDetails))
