import { PAYSCHEME_PARAMETERS_LIST, CREATE_PAYSCHEME_PARAMETERS, UPDATE_PAYSCHEME_PARAMETERS } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd,getListurl} from '../../../helpers/Server_Helper';
import { payschemeParametersListSuccessfull,createPayschemeParametersSuccessfull, updatePayschemeParametersSuccessfull } from './actions';
import swal from "sweetalert";

function* payschemeParametersList({ payload: { url,data, history } }) {
    try {
        const response = yield call(getListurl, url,data);
        
        if(response){
            if(response.data.length > 0){
                console.log("response---0---00",response)
                response.data.sort((a, b) => (a.suppId < b.suppId) ? 1 : -1)
                yield put(payschemeParametersListSuccessfull({ response }))
            }else{
                const response = null;
                yield put(payschemeParametersListSuccessfull({ response }))
            }
            
        }else{
            const response = null;
            yield put(payschemeParametersListSuccessfull({ response }))
        }
        
    } catch (error) {
        console.log(error)
    }
}
function* createPayschemeParameters({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(createPayschemeParametersSuccessfull({ response }));
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/payscheme_parameters")
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        swal("error:" + error, { icon: "danger" });
        console.log(error)
    }
}
function* updatePayschemeParameters({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(updatePayschemeParametersSuccessfull({ response }))
        if (response.data.status === true) {
            swal(response.data.message, { icon: "success" })
            history.push("/payscheme_parameters")
        } else {
            swal(response.data.message, { icon: "error" })
        }
    } catch (error) {
        console.log(error)
        swal("error:" + error, { icon: "danger" });
    }
}

export function* watchPayschemeParametersList() {
    yield takeEvery(PAYSCHEME_PARAMETERS_LIST, payschemeParametersList)
}

export function* watchCreatePayschemeParameters() {
    yield takeEvery(CREATE_PAYSCHEME_PARAMETERS, createPayschemeParameters)
}
export function* watchUpdatePayschemeParameters() {
    yield takeEvery(UPDATE_PAYSCHEME_PARAMETERS, updatePayschemeParameters)
}

function* PayschemeParaSaga() {
    yield all([
        fork(watchPayschemeParametersList),
        fork(watchCreatePayschemeParameters),
        fork(watchUpdatePayschemeParameters)
    ])
}
export default PayschemeParaSaga;