// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  SHOW_RIGHT_SIDEBAR,
  HIDE_RIGHT_SIDEBAR,
  HEADER_DATA,
} from "./actionTypes";

import {
  changeSidebarType as changeSidebarTypeAction,
  changeTopbarTheme as changeTopbarThemeAction,
  headerDataSuccess,
} from "./actions";

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 * @param {*} param0
 */
function* changeLayout({ payload: layout }) {
  try {
    if (layout === "horizontal") {
      yield put(changeTopbarThemeAction("dark"));
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      yield put(changeTopbarThemeAction("light"));
    }
    yield call(changeBodyAttribute, "data-layout", layout);
  } catch (error) {}
}

/**
 * Changes the layout width
 * @param {*} param0
 */
function* changeLayoutWidth({ payload: { width, layoutType } }) {
  try {
    if (layoutType === "vertical") {
      if (width === "boxed") {
        yield put(changeSidebarTypeAction("icon"));
      } else {
        yield put(changeSidebarTypeAction("default"));
      }
    }
    yield call(changeBodyAttribute, "data-layout-size", width);
  } catch (error) {}
}

/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
function* changeLeftSidebarTheme({ payload: { theme, layoutType } }) {
  try {
    yield call(changeBodyAttribute, "data-sidebar", theme);
    if (layoutType === "vertical") {
      if (theme === "light")
        //Fire action for changing dark theme of topbar
        yield put(changeTopbarThemeAction("dark"));
      if (theme === "dark")
        //Fire action for changing light theme of topbar
        yield put(changeTopbarThemeAction("light"));
    }
  } catch (error) {}
}

/**
 * Changes the topbar theme
 * @param {*} param0
 */
function* changeTopbarTheme({ payload: theme }) {
  try {
    yield call(changeBodyAttribute, "data-topbar", theme);
  } catch (error) {}
}

/**
 * Changes the left sidebar type
 * @param {*} param0
 */
function* changeLeftSidebarType({ payload: { sidebarType, isMobile } }) {
  try {
    switch (sidebarType) {
      case "compact":
        console.log("--------------------Saga compact--------------");
        yield call(changeBodyAttribute, "data-sidebar-size", "small");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
      case "icon":
        console.log("--------------------Saga Icon--------------");
        yield call(changeBodyAttribute, "data-keep-enlarged", "true");
        yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      case "condensed":
        console.log("--------------------Saga condensed--------------");
        yield call(manageBodyClass, "sidebar-enable", "add");
        if (!isMobile) yield call(manageBodyClass, "vertical-collpsed", "add");
        break;
      default:
        console.log("--------------------Saga default--------------");
        yield call(changeBodyAttribute, "data-sidebar-size", "");
        yield call(manageBodyClass, "sidebar-enable", "remove");
        if (!isMobile)
          yield call(manageBodyClass, "vertical-collpsed", "remove");
        break;
    }
  } catch (error) {}
}

function* getHeaderData({ payload: layout }) {
  try {
    if (layout.pathname === "/CompanyList") {
      let response = {
        parentName: "Company's List",
        createbranchbtn: 4,
        isInnerNavigation: false,
        rowdata: layout.rowdata,
        headerName: "Company Details",
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/basicdetails") {
      let response = {
        parentName: "Basic Details",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Company Details",
        menulist: [
          {
            name: "Basic Details",
            link: "/basicdetails",
            active: "active",
          },
          {
            name: "Official Details",
            link: "/officialDetails",
            active: "",
          },
          {
            name: "Branch Creation",
            link: "/branchlist",
            active: "",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/officialDetails") {
      let response = {
        parentName: "Official Details",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Company Details",
        menulist: [
          {
            name: "Basic Details",
            link: "/basicdetails",
            active: "",
          },
          {
            name: "Official Details",
            link: "/officialDetails",
            active: "active",
          },
          {
            name: "Branch Creation",
            link: "/branchlist",
            active: "",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/branchlist") {
      let response = {
        parentName: "Branch's List",
        createbranchbtn: 1,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Company Details",
        menulist: [
          {
            name: "Basic Details",
            link: "/basicdetails",
            active: "",
          },
          {
            name: "Official Details",
            link: "/officialDetails",
            active: "",
          },
          {
            name: "Branch Creation",
            link: "/branchlist",
            active: "active",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/createbranch") {
      let response = {
        parentName: "Create Branch",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Company Details",
        menulist: [
          {
            name: "Basic Details",
            link: "/basicdetails",
            active: "",
          },
          {
            name: "Official Details",
            link: "/officialDetails",
            active: "",
          },
          {
            name: "Branch Creation",
            link: "/branchlist",
            active: "active",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/invoice") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "active",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "active",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/mrprint") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "active",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/date_allowable") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "active",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/configure") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "active",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "active",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/purchase_settings") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "active",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/sales_settings") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "active",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/gate_entry") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "active",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/customer_internal_note") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "active",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_leave_types") {
      let response = {
        parentName: "Create Leave Type",
        // createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Leave Type",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "active",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }



    if (layout.pathname === "/leaveTypes") {
      let response = {
        parentName: "Leave Type",
        createbranchbtn: 5,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "active",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    

    if (layout.pathname === "/department_master") {
      let response = {
        parentName: "Department Master",
        createbranchbtn: 6,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "active",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_department_master") {
      let response = {
        parentName: "Create Department Master",

        // createbranchbtn: 0,

        isInnerNavigation: true,

        rowdata: layout.rowdata,

        headerName: "Department Master",

        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "active",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };

      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/payscheme") {
      let response = {
        parentName: "Pay Scheme",
        createbranchbtn: 7,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "active",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_payScheme_creation") {
      let response = {
        parentName: "Create Pay Scheme",
        // createbranchbtn: 7,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "PayScheme Creation",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "active",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/leavePolicies") {
      let response = {
        parentName: "Leave Policies",
        createbranchbtn: 8,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "active",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_leave_policy") {
      let response = {
        parentName: "Create Leave Policies",
        // createbranchbtn: 8,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "active",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/payscheme_parameters") {
      let response = {
        parentName: "Pay Scheme Parameters",
        createbranchbtn: 9,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "active",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };

      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_payscheme_parameters") {
      let response = {
        parentName: "Create Pay Scheme Parameters",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Create Pay Scheme Parameters",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "active",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/tax_master") {
      let response = {
        parentName: "Tax Master",
        createbranchbtn: 10,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:"active"
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_tax_master") {
      let response = {
        parentName: "Tax Master",
        // createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:"active"
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/bank_account_master") {
      let response = {
        parentName: "Bank Account Master",
        createbranchbtn: 11,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:"active"
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_bank_account_master") {
      let response = {
        parentName: "Create Bank Account Master",
        // createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Create Bank Account",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:"active"
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:""
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/supplier_types") {
      let response = {
        parentName: "Supplier Types",
        createbranchbtn: 12,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Master Data",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:"active"
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_supplier_types") {
      let response = {
        parentName: "Create Supplier Types",
        // createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Create Supplier Types",
        menulist: [
          {
            name: "Leave Type",
            link: "/leaveTypes",
            active: "",
          },

          {
            name: "Department Master",
            link: "/department_master",
            active: "",
          },
          {
            name: "Pay Scheme Creation",
            link: "/payscheme",
            active: "",
          },
          {
            name: "Leave Policies",
            link: "/leavePolicies",
            active: "",
          },
          {
            name: "Pay Scheme Parameter",
            link: "/payscheme_parameters",
            active: "",
          },
          {
            name: "Tax Master",
            link:"/tax_master",
            active:""
          },
          {
            name: "Bank Account Master",
            link:"/bank_account_master",
            active:""
          },
          {
            name: "Supplier Types",
            link:"/supplier_types",
            active:"active"
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }


    if (layout.pathname === "/structure") {
      let response = {
        parentName: "Configuration",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [
          // {
          // 	name: "Invoice",
          // 	link: "/invoice",
          // 	active: "",
          // },
          // {
          // 	name: "MR Print",
          // 	link: "/mrprint",
          // 	active: "",
          // },
          {
            name: "Date Allowable",
            link: "/date_allowable",
            active: "",
          },
          // {
          // 	name: "Configure",
          // 	link: "/configure",
          // 	active: "",
          // },
          {
          	name: "Purchase Setting",
          	link: "/purchase_settings",
          	active: "",
          },
          {
          	name: "Sales Setting",
          	link: "/sales_settings",
          	active: "",
          },
          // {
          // 	name: "Gate Entry / Out",
          // 	link: "/gate_entry",
          // 	active: "",
          // },
          // {
          // 	name: "Customer Internal Map",
          // 	link: "/customer_internal_note",
          // 	active: "",
          // },
          {
          	name: "Structure",
          	link: "/structure",
          	active: "active",
          }
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/Billing") {
      let response = {
        parentName: "Billing Details",
        createbranchbtn: 0,
        isInnerNavigation: false,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/ModuleSelection") {
      let response = {
        parentName: "Module Details",
        createbranchbtn: 0,
        isInnerNavigation: false,
        rowdata: layout.rowdata,
        headerName: "Configuration Details",
        menulist: [],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/role_management") {
      let response = {
        parentName: "Role Management",
        createbranchbtn: 3,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "User Management",
        menulist: [
          {
            name: "Role Management",
            link: "/role_management",
            active: "active",
          },
          {
            name: "User Management",
            link: "/UserManagement",
            active: "",
          },
          {
            name: "Approval Hierarchy",
            link: "/approve_hierachy",
            active: "",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/UserManagement") {
      let response = {
        parentName: "User Management",
        createbranchbtn: 2,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "User Management",
        menulist: [
          {
            name: "Role Management",
            link: "/role_management",
            active: "",
          },
          {
            name: "User Management",
            link: "/UserManagement",
            active: "active",
          },
          {
            name: "Approval Hierarchy",
            link: "/approve_hierachy",
            active: "",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/approve_hierachy") {
      let response = {
        parentName: "Approval Hierarchy",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Approval Hierarchy",
        menulist: [
          {
            name: "Role Management",
            link: "/role_management",
            active: "",
          },
          {
            name: "User Management",
            link: "/UserManagement",
            active: "",
          },
          {
            name: "Approval Hierarchy",
            link: "/approve_hierachy",
            active: "active",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
    if (layout.pathname === "/create_role") {
      let response = {
        parentName: "Role Creation",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "Role Management",

        menulist: [
          {
            name: "Role Management",
            link: "/role_management",
            active: "active",
          },
          {
            name: "User Management",
            link: "/UserManagement",
            active: "",
          },
          {
            name: "Approval Hierarchy",
            link: "/approve_hierachy",
            active: "",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }

    if (layout.pathname === "/create_user") {
      let response = {
        parentName: "User Creation",
        createbranchbtn: 0,
        isInnerNavigation: true,
        rowdata: layout.rowdata,
        headerName: "User Management",
        menulist: [
          {
            name: "Role Management",
            link: "/role_management",
            active: "",
          },
          {
            name: "User Management",
            link: "/UserManagement",
            active: "active",
          },
          {
            name: "Approval Hierarchy",
            link: "/approve_hierachy",
            active: "",
          },
        ],
      };
      yield put(headerDataSuccess(response));
    }
  } catch (error) {}
}

/**
 * Toggles the rightsidebar
 */
function* toggleRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled");
  } catch (error) {}
}

/**
 * Show the rightsidebar
 */
function* showRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "add");
  } catch (error) {}
}

/**
 * Hides the rightsidebar
 */
function* hideRightSidebar() {
  try {
    yield call(manageBodyClass, "right-bar-enabled", "remove");
  } catch (error) {}
}

/**
 * Watchers
 */
export function* watchChangeLayoutType() {
  yield takeEvery(CHANGE_LAYOUT, changeLayout);
}

export function* watchHeaderData() {
  yield takeEvery(HEADER_DATA, getHeaderData);
}

export function* watchChangeLayoutWidth() {
  yield takeEvery(CHANGE_LAYOUT_WIDTH, changeLayoutWidth);
}

export function* watchChangeLeftSidebarTheme() {
  yield takeEvery(CHANGE_SIDEBAR_THEME, changeLeftSidebarTheme);
}

export function* watchChangeLeftSidebarType() {
  yield takeEvery(CHANGE_SIDEBAR_TYPE, changeLeftSidebarType);
}

export function* watchChangeTopbarTheme() {
  yield takeEvery(CHANGE_TOPBAR_THEME, changeTopbarTheme);
}

export function* watchToggleRightSidebar() {
  yield takeEvery(TOGGLE_RIGHT_SIDEBAR, toggleRightSidebar);
}

export function* watchShowRightSidebar() {
  yield takeEvery(SHOW_RIGHT_SIDEBAR, showRightSidebar);
}

export function* watchHideRightSidebar() {
  yield takeEvery(HIDE_RIGHT_SIDEBAR, hideRightSidebar);
}

function* LayoutSaga() {
  yield all([
    fork(watchChangeLayoutType),
    fork(watchChangeLayoutWidth),
    fork(watchChangeLeftSidebarTheme),
    fork(watchChangeLeftSidebarType),
    fork(watchToggleRightSidebar),
    fork(watchShowRightSidebar),
    fork(watchHideRightSidebar),
    fork(watchChangeTopbarTheme),
    fork(watchHeaderData),
  ]);
}

export default LayoutSaga;
