import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import BootstrapTable from "react-bootstrap-table-next";
import { withRouter } from "react-router-dom";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import ToolkitProvider,{Search} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import EditIcon from "@mui/icons-material/Edit";
import cons from "../../../helpers/Consts";
import CustomButton from "../../../components/Buttons/Buttons";
import ExportIcon from "../../../assets/images/exportIcon.png";


class Gridwithcustomview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: "none",
      productsData: this.props.mdata,
      menu1: "none",
      filterList: [],
      searchLists: [],
      checkLists: [],
      activeFilter: [],
      pathName: this.props.url,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      years: 0,
      year_data: "",
      name: "update the indent",
      ShowApp_rej: false,
      showCustonView: false,
      showFilter: false,
      testState: false,
      filter_form: this.props.filter_form,
      columns: [
        {
          text: "",
          dataField: "",
          headerAlign: "",
          align: "",
          hidden: false,
        },
      ],
    };
  }
  editRow = (row) => {
    cons.COMPANY_ID = row.companyId;
    this.props.history.push({ pathname: this.props.pathname, rowdata: row });
    localStorage.setItem("companyupdateId", row.companyId);
  };
  componentDidMount() {
    var mcolumns = this.props.mcolumns;

    console.log("Table Columns", this.props.mcolumns);
    if (mcolumns) {
      this.setState({
        columns: [],
      });
      const columnsnew = [];
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          text: mcolumns[i].Header,
          headerAlign: "left",
          align: "left",
          hidden: mcolumns[i].hidden,
        };
        columnsnew.push(coldata);
      }
      if (this.props.actions) {
        var actions = {
          dataField: "actions",
          text: "Actions",
          headerAlign: "left",
          align: "left",
        };
        columnsnew.push(actions);
      }   
      this.setState({
        columns: columnsnew,
      });
    }
  }

  componentWillReceiveProps = (props) => {
    var mcolumns = props.mcolumns;

    console.log("Table Columns from will receive props", props.mcolumns);
    if (mcolumns) {
      this.setState({
        columns: [],
      });
      const columnsnew = [];
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          text: mcolumns[i].Header,
          headerAlign: "left",
          align: "left",
          hidden: mcolumns[i].hidden,
        };
        columnsnew.push(coldata);
      }
      if (props.actions) {
        var actions = {
          dataField: "actions",
          text: "Actions",
          headerAlign: "left",
          align: "left",
        };
        columnsnew.push(actions);
      }


      this.setState({
        columns: columnsnew,
      });
    }
  };
 

  state = { showing: false };
  priceFormatter = (column, colIndex) => {
    // const { showing } = this.state;
    // const products = this.state.productsData;
  
  };
  render() {
    console.log("getting the data from normal grid");

    const mdata = this.props.mdata;
    const mcolumns = this.props.mcolumns;
    if (mdata) {
      var products = mdata.map((row, order) => ({
        ...row,
        chk: (
          <div className="checkbox form-check">
            <div className="custom-checkbox custom-control custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={this.handleChange}
                value={row.id}
                id={row.id}
              />
              <label className="custom-control-label" for={row.id}></label>
            </div>{" "}
          </div>
        ),
        actions: (
          <Fragment>
            {console.log("getting row data", row)}
            <EditIcon onClick={() => this.editRow(row)} />
          </Fragment>
        ),

      }));
    } else {
      var products = [];
    }
    const { SearchBar } = Search;
 
    const defaultSorted = [
        {
          dataField: "companyId",
          order: "asc",
        },
      ];
    const MyExportCSV = (props) => {
      const handleExportClick = () => {
        props.onExport();
      };
      return (
        // <div onClick={handleExportClick} className="more_orderList">
        //   <FileUploadOutlinedIcon />
        //   <span>Export list</span>
        // </div>
            <CustomButton
            label="Export"
            className="lightBlueButton"
            type="button"
            icon={ExportIcon}
            handleClick={handleExportClick}
          />
      );
    };

    return (
      <div className="gridMonthSelection">
        <div className="gridContainer">
          <div className="gridComponent">
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                totalSize: products.length,
                page: 1,
                sizePerPage: 5,
                nextPageText: "Next >",
                prePageText: "< Prev",
                withFirstAndLast: false,
                showTotal: true,

                sizePerPageList: [
                    {
                        text: "10",
                        value: 10,
                      },
                      {
                        text: "30",
                        value: 30,
                      },
                      {
                        text: "50",
                        value: 50,
                      },
                      {
                        text: "All",
                        value: products.length,
                      },
                ],
                hideSizePerPage: products.length === 0,
              })}
              keyField="companyId"
              columns={this.state.columns}
              data={products}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="companyId"
                  columns={this.state.columns}
                  data={products}
                  columnToggle
                  search
                  exportCSV={{
                    fileName: this.state.filter_form,
                    exportAll: true,
                    noAutoBOM: false,
                    blobType: "text/csv;charset=ansi",
                  }}
                >
                  {(toolkitprops) => (
                    <div>
                      <div className="buttonBlock">
                        <Grid item xs={12} md={3}>
                          {/* {this.props.monthPicker && <MonthPicker />} */}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <div
                            style={{ display: "flex", position: "relative" }}
                          >
                               <div className="gridButtonBlock">
                                  <SearchBar {...toolkitprops.searchProps} />
                                {/* </Grid>
                                <Grid item xs={12} md={2}> */}
                                  <MyExportCSV {...toolkitprops.csvProps} />
                                </div>
                          </div>
                        </Grid>
                      </div>
 
                     <div
                        className="customizeViewContainer"
                        style={{ display: this.state.menu }}
                      >
                    
                      </div>
                   
                      <BootstrapTable
                        {...toolkitprops.baseProps}
                        {...paginationTableProps}
                        defaultSorted={defaultSorted}
                        defaultSortDirection="asc"
                        striped
                        noDataIndication="No Data Is Available"
                        keyField="id"
                       
                      
                      />
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
      
          </div>
        </div>
      </div>
    );
  }
}
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {
   
  })(Gridwithcustomview)
);

