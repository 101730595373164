import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";
import {Link} from "react-router-dom"
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";
import RadioButton from "../../components/RadioButton/RadioButton";

class MrinInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      mryes: false,
      mrno: false,
      mrinv: 0,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
    };
  }

  onClickRadioButton = (selectedValue) => {
    var mrinv = 0;
    var mryes = false,
      mrno = false;
    if (selectedValue === "Yes") {
      mrinv = 1;
      mryes = true;
    } else if (selectedValue === "No") {
      mrinv = 2;
      mrno = true;
    }

    this.setState({
      selectedValue: selectedValue,
      mrinv: mrinv,
      mryes: mryes,
      mrno: mrno,
    });
  };

  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      });
    }
    return id;
  };

  handleConfig = () => {
    var printstyle = this.state.selectedValue;
    if (!printstyle) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select MR In Invoice",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (this.state.companyId == 0) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: this.state.companyId,
        configList: [
          {
            autoId: this.getAutoIdFromList(configNames.MR_INV_PRINT_MODE),
            configParameter: configNames.MR_INV_PRINT_MODE,
            isActive: 1,
            configValue: this.state.mrinv,
          },
        ],
      };
      if (this.state.companyId) {
        var msg = "MR In Invoice Updated Successfully.";

        this.props.setConfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };

  componentDidMount=()=> {
    var mrinv = 0;
    var mryes = false,
      mrno = false;
    //var configdata = cons.CONFIG_DATA;//JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.MR_INV_PRINT_MODE) {
          if (prop.configValue === "1") {
            mrinv = 1;
            mryes = true;
          } else if (prop.configValue === "2") {
            mrinv = 2;
            mrno = true;
          }
          this.setState({
            mrinv: mrinv,
            mryes: mryes,
            mrno: mrno,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }

  }

  componentWillReceiveProps=(props)=> {
    var mrinv = 0;
    var mryes = false,
      mrno = false;
    //var configdata = cons.CONFIG_DATA;//JSON.parse(localStorage.getItem("configdata"));
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    var configdata = props.configdata
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.MR_INV_PRINT_MODE) {
          if (prop.configValue === "1") {
            mrinv = 1;
            mryes = true;
          } else if (prop.configValue === "2") {
            mrinv = 2;
            mrno = true;
          }
          this.setState({
            mrinv: mrinv,
            mryes: mryes,
            mrno: mrno,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }

  }


  // componentWillMount = () => {
  //   var mrinv = 0;
  //   var mryes = false,
  //     mrno = false;
  //   var configdata = JSON.parse(localStorage.getItem("configdata"));
  //   if (configdata) {
  //     configdata.forEach((prop) => {
  //       if (prop.configParameter === configNames.MR_PRINT_MODE) {
  //         console.log("Magesj rpw Mr in Print Invoice", prop);
  //         if (prop.configValue === "1") {
  //           mrinv = 1;
  //           mryes = true;
  //         } else if (prop.configValue === "2") {
  //           mrinv = 2;
  //           mrno = true;
  //         }
  //         this.setState({
  //           mrinv: mrinv,
  //           mryes: mryes,
  //           mrno: mrno,
  //           configdata: configdata,
  //           companyId: localStorage.getItem("companyIdconfig"),
  //         });
  //       }
  //     });
  //   }
  // };

  render() {
    
    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <div className="consoleFormContainer">
          <h5>MR in Invoice</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={6}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="mrinvoice"
                        checkednew={this.state.mryes}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="mrinvoice"
                        checkednew={this.state.mrno}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/invoice">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default connect(mapStatetoProps, {
  setConfiguration,
})(MrinInvoice);
