import React, { useState } from 'react'

export const ToggleButton = ({ label, selected, styleClass, onChange, name, image, iconSizeClass, disabled }) => {
  const [show, setShow] = useState(selected)
  const handleToggleElement = (e) => {
    const checked = e.target.checked
    if (checked) {
      onChange(name, checked, disabled);
      setShow(true)
    } else {
      onChange(name, checked, disabled);
      setShow(false)

    }
  }
  return (
    <div className="toggleButton">
      <label className="switch">
        <input
         
          onClick={
            handleToggleElement
          }
          type="checkbox"
          value={selected}
          defaultChecked={selected}
          // className="regular-checkbox"
        />
        <span className="slider round"></span>
        {/* {selected} */}
      </label>
      {show && show ? (
        <div className="buttonActive">Active</div>
      ) : (
        <div className="buttonInactive">Inactive</div>
      )}
    </div>
  )
}

export default ToggleButton
