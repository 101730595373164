import { all, call, fork, takeEvery, put} from "redux-saga/effects";
import swal from "sweetalert";

import {DELETEFILE, UPLOADFILE} from './actionTypes';
import {deleteFileSuccessfull, UploadFileListSuccessfull} from './actions';
import {getListurl, postAdd} from '../../helpers/Server_Helper';
import {getProfilePicFileSetNull} from '../Master/PaySchemecreation/actions'



function* getUploadFileList({payload:{url,data,history}}) {
    try{
        const response = yield call(postAdd,url,data);
        yield put(UploadFileListSuccessfull({response}))
        swal("Uploaded Successfully", { icon: "success" })
        console.log('PrResponse.....uploadFile',response)
    }catch(error){
        console.log(error)
    }
}
function* deleteFile({payload:{url,history,pageName}}) {
    try{
        const response = yield call(getListurl,url);
        yield put(deleteFileSuccessfull({response}))
        console.log('response',response,pageName)
        if(response.data.message =='Success.'){
            if(pageName ==="companyCreation"){ //doing this here is to set the props of profile props
          yield put(getProfilePicFileSetNull(null))

            }
            swal('Successfully Deleted', { icon: "success" })
        }
        
    }catch(error){
        console.log(error)
    }
}




export function* watchUploadFileList () {
    yield takeEvery(UPLOADFILE, getUploadFileList)
    yield takeEvery(DELETEFILE,deleteFile)
}




function* UploadFileListSaga() {
    yield all([
        fork(watchUploadFileList),
    ])
}


export default UploadFileListSaga;