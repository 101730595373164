import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cons, { serverApi } from "../../../helpers/Consts";
import { getbranchlist } from "../../../store/UserManagement/actions";
import { getLeaveTypeList } from "../../../store/Master/LeaveTypeMaster/actions";
import { getCompanys } from "../../../store/Company/BasicDetails/actions";
import GridViewSearch from "../../../components/Grid/GridViewSerch";
import GridView from "../../../components/Grid/GridView";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      listColumn: [],
      listData: [],
    };
  }
  componentDidMount() {
 const data={
  "cipher": JSON.parse(localStorage.getItem("authUser")).cipher,
  "companyId": localStorage.getItem("companyIdconfig"),
  "leaveTypeCode": null,
  "leaveDesc": null,
  "payable": null
 }
      this.props.getLeaveTypeList(
        serverApi.GET_ALL_LEAVE_TYPE_LIST ,
        data,
        this.props.history
      );
  }
  componentWillReceiveProps(props) {
    if (props.LeaveType_List) {
      if (props.LeaveType_List.column) {
        this.setState({
          listColumn: props.LeaveType_List.column,
        });
      }
      if (props.LeaveType_List.data) {
        this.setState({
          listData: props.LeaveType_List.data,
        });
      }
    }
  }
  render() {
    console.log("getting the data man", this.props.LeaveType_List);
    const mdata = this.props.LeaveType_List
      ? this.props.LeaveType_List.data
      : "";
    const mcolumns = this.props.LeaveType_List
      ? this.props.LeaveType_List.column
      : "";

    return (
      <GridViewSearch
        mdata={this.state.listData}
        mcolumns={listColumn}
        pathname="/create_leave_types"
      />
    );
  }
}
const listColumn = [
  // {
  //   Header: "Leave Type Id",
  //   accessor: "leaveTypeId",
  //   headerAlign: "left",
  //   align: "left",
  //   hidden: true,
  // },
  {
    Header: "Leave Type Code",
    dataField: "leaveTypeCode",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Leave Type Description",
    dataField: "leaveTypeDescription",
    headerAlign: "left",
    align: "left",
    hidden: false,
  },
  {
    Header: "Payable",
    dataField: "payable",
    headerAlign: "left",
    align: "left",
    hidden: false,
  }
];

const mapStatetoProps = (state) => {
  const { LeaveType_List } = state.LeaveTypeListReducer;
  return { LeaveType_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLeaveTypeList,
  })(Index)
);
