import {
  API_ERRORS,
  BASICDETAILS,
  BASICDETAILS_ADD,
  BASICDETAILS_ADD_SUCCESSFUL,
  COMPANYS,
  COMPANYS_LIST_SUCCESSFUL,
  IMAGE_CMP_UPLOAD,
  COMPANY_DIT,
  COMPANY_DIT_SUCCESS,
  CLIENT_LIST,
  CLIENT_LIST_SUCCESS,
  COMPANY_PROPS,
  COMPANY_TABLE,
  COMPANY_TABLE_SUCCESS,
  CLEAR_CLIENT_LIST
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  companyslist: [],
  companyscoulmns: [],
  clientList: [],
  clientColumn: [],
  companyresponse:null, 
  model: false,
  error_msg: null,
  rowdata: null,
  headerData:[],
  CompanyTableList:[]
};

const basicdetails = (state = initialState, action) => {
  switch (action.type) {    
    case COMPANYS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case COMPANYS_LIST_SUCCESSFUL:
      state = {
        ...state,
        companyslist: action.payload.response.data,
        companyscoulmns: action.payload.response.column,
        loading: false,
      };
      break;
      case CLIENT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CLIENT_LIST_SUCCESS:
      state = {
        ...state,
        clientList: action.payload.response,
        loading: false,
      };
      break;
      case CLEAR_CLIENT_LIST:
        state = {
          ...state,
          clientList: action.payload,
          loading: false,
        };
        break;
    case BASICDETAILS_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case IMAGE_CMP_UPLOAD:
        state = {
          ...state,
          companyresponse: action.payload,
          loading: false,
        };
        break;
    case BASICDETAILS_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        companyresponse: action.payload,
        loading: false,
        model: false,
      };
      break;
      case COMPANY_DIT:
        state = {
          ...state,
          loading: true
        }
        break;
      case COMPANY_DIT_SUCCESS:
        state = {
          ...state,
          rowdata: action.payload.response.data,
          loading: false
        }
        break;
        case COMPANY_TABLE:
          state = {
            ...state, 
            loading: true,
          };
          break;
        case COMPANY_TABLE_SUCCESS:
          state = {
            ...state,
            CompanyTableList: action.payload.response,
            loading: false,
          };
          break;
    
    case API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
      case COMPANY_PROPS:
        state = {
          ...state,
          headerData: action.payload,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default basicdetails;
