import React from "react";
import SuccessTick from "../../assets/images/successTick.png";

export default function paymentConfirmation() {
  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <div className="successContainer">
      <div className="successCard">
        <div className="successCardHeader">
          <img src={SuccessTick} alt="" />
          <div>
            <h2>Payment Successful!</h2>
            <p>
              Payment successfully made on 12th May'22 for Monthly Subscripton
            </p>
          </div>
        </div>
        <div className="successCardContainer">
          <h3>Order confirmation details:</h3>
          <ul>
            <li>
              Order Number: <strong>ABC13456</strong>
            </li>
            <li>
              Amount Paid: <strong>2356</strong>
            </li>
            {/* <li>
              Confirmation mail sent to: <strong>emailid@gmail.com</strong>
            </li> */}
          </ul>
          {/* <a
            href="https://console.cloud.vowerp.com/"
            target="_blank"
            class="successButton"
          >
            Get Started
          </a> */}
        </div>
      </div>
    </div>
  );
}
