import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";

import RadioButton from "../../components/RadioButton/RadioButton";
import BlankVideoImage from "../../assets/images/videoGif/BlankVideoImage.png";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";
import GateEntryInWithPO from "../../assets/images/videoGif/Gate-entry-in-with-PO.gif";
import GateEntryInWithoutPO from "../../assets/images/videoGif/Gate-entry-in-without-PO.gif";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "../../assets/images/CloseIcon.png";
import {Link} from "react-router-dom"

class GateEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      gateentry: 0,
      gateyes: false,
      gateno: false,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
      gateentrygif: 1,
    };
  }

  onClickRadioButton = (selectedValue) => {
    var gateentry = 0;
    var gateyes = false,
      gateno = false;
    var gateentrygif = 1;
    if (selectedValue === "Yes") {
      gateentry = 1;
      gateyes = true;
      gateentrygif = 1;
    } else if (selectedValue === "No") {
      gateentry = 2;
      gateno = true;
      gateentrygif = 2;
    }
    this.setState({
      selectedValue: selectedValue,
      gateentry: gateentry,
      gateyes: gateyes,
      gateno: gateno,
      gateentrygif: gateentrygif,
    });
  };
  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      });
    }
    return id;
  };
  handleConfig = () => {
    var printstyle = this.state.selectedValue;
    if (!printstyle) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Gate Entry / Out",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (!this.state.companyId) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: this.state.companyId,
        configList: [
          {
            autoId: this.getAutoIdFromList(configNames.GATE_ENTRY_WITHOUT_PO),
            configParameter: configNames.GATE_ENTRY_WITHOUT_PO,
            isActive: 1,
            configValue: this.state.gateentry,
          },
        ],
      };
      if (this.state.companyId) {
        var msg = "PO Print Updated Successfully.";
        this.props.setConfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry!!! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };

  componentDidMount = () => {
    var gateentry = 0;
    var gateyes = false,
      gateno = false;
    //var configdata = JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.GATE_ENTRY_WITHOUT_PO) {
          if (prop.configValue === "1") {
            gateentry = 1;
            gateyes = true;
          } else if (prop.configValue === "2") {
            gateentry = 2;
            gateno = true;
          }
          this.setState({
            gateentry: gateentry,
            gateyes: gateyes,
            gateno: gateno,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  };

  componentWillReceiveProps(props) {
    var gateentry = 0;
    var gateyes = false,
      gateno = false;
    //var configdata = JSON.parse(localStorage.getItem("configdata"));
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    var configdata = props.configdata
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.GATE_ENTRY_WITHOUT_PO) {
          if (prop.configValue === "1") {
            gateentry = 1;
            gateyes = true;
          } else if (prop.configValue === "2") {
            gateentry = 2;
            gateno = true;
          }
          this.setState({
            gateentry: gateentry,
            gateyes: gateyes,
            gateno: gateno,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  }

  render() {
    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        <div className="consoleFormContainer">
          <h5>Gate Entry / Out</h5>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="consoleFormBlock">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="gateEntry"
                        checkednew={this.state.gateyes}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="gateEntry"
                        checkednew={this.state.gateno}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                className="VideoGifContainer"
                style={{
                  display: this.state.gateentrygif == 1 ? "block" : "none",
                }}
              >
                <img
                  src={GateEntryInWithPO}
                  alt=""
                  onClick={() => this.setState({ open: true })}
                />
              </div>
              <Dialog open={this.state.open} maxWidth="md">
                <img
                  src={CloseIcon}
                  className="closeButton"
                  alt=""
                  onClick={() => this.setState({ open: false })}
                />
                <img src={GateEntryInWithPO} alt="indent" />
              </Dialog>

              <div
                className="VideoGifContainer"
                style={{
                  display: this.state.gateentrygif == 2 ? "block" : "none",
                }}
              >
                <img
                  src={GateEntryInWithoutPO}
                  alt=""
                  onClick={() => this.setState({ open: true })}
                />
              </div>
              <Dialog open={this.state.open} maxWidth="md">
                <img
                  src={CloseIcon}
                  className="closeButton"
                  alt=""
                  onClick={() => this.setState({ open: false })}
                />
                <img src={GateEntryInWithoutPO} alt="indent" />
              </Dialog>
            </Grid>
          </Grid>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/invoice">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default connect(mapStatetoProps, {
  setConfiguration,
})(GateEntry);
