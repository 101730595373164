import React, { Component } from "react";
import { connect } from "react-redux";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import InnerNavigation from "../../components/InnerNavigation/InnerNavigation";
import { Grid } from "@mui/material";
import Header from "../../components/Header/Header";
import BasicDetails from "../../Pages/CompanyCreation/BasicDetails";
import { withRouter } from "react-router-dom";
import OfficialDetails from "./OfficialDetails";
import {
  companyprops,
} from "../../store/Company/BasicDetails/actions";

class CompanyCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Basic Details",
      companypage: <BasicDetails />,
      createbranchbtn: 0,
      module: 2,
      companyId: 0,
      headerData: {
        alertMailHost: null,
        alertMailId: null,
        alertMailName: null,
        alertMailPassword: null,
        bucketName: "",
        cinNo: null,
        companyAddress1: null,
        companyAddress2: null,
        companyCode: "",
        companyId: 0,
        companyName: "",
        contactNo: "",
        contactPerson: "",
        createdBy: null,
        emailId: "",
        financialDate: "",
        folderName: "",
        gstNo: "",
        gstNo2: null,
        juteMrBccMail: null,
        jutePoBccMail: null,
        orgId: localStorage.getItem("companyupdateId"),
        panNO: "",
        portNo: null,
        stateCode1: null,
        stateCode2: null,
        stateName1: null,
        stateName2: null,
        storeMrBccMail: null,
        storePoBccMail: null,
        tallySync: null,
        yourUrl: ""
      }
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (name, page, btn) => {
    this.setState({
      parentName: name,
      companypage: page,
      createbranchbtn: btn
    });
    // console.log("Company Creation", this.state);

  }

  componentDidMount = () => {
    // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%", this.props);
    if (this.props.location.rowdata) {
      var rowd = this.props.location.rowdata;
      this.setState({
        companyId: rowd.companyId,
        headerData: rowd,
      });
      
    }
  }

  render() {



    return (
      <>
        <Grid container className="mainContainer">
          <LeftSidebar />
          <Grid className="mainContent">
            <InnerNavigation childName={this.state.parentName} buttonbranch={this.state.createbranchbtn} module={this.state.module} onNameChange={this.onChange} myCompanyId={this.state.companyId} headerData={this.state.headerData} />
            <div className="consoleContainer">
              <Header childName={this.state.parentName} buttonbranch={this.state.createbranchbtn} onNameChange={this.onChange} myCompanyId={this.state.companyId} headerData={this.state.headerData} />
              {this.state.companypage}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }


}
const mapStatetoProps = (state) => {
  const { companyresponse, headerData } = state.BasicDetails;
  return { companyresponse, headerData };
};
// export default CompanyCreation;
export default withRouter(connect(mapStatetoProps, { companyprops
})(CompanyCreation));
