import React, { useState } from "react";
import PropTypes from "prop-types";

import { validateInput, validatorRequired } from "../../Utilities/Validator";

const TextFieldAlfaNum = ({
  value,
  label,
  placeholder,
  star,
  caps,
  validators,  
  validatorsrequired,
  type,
  onChange,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;


    if(validatorsrequired){
      if(value===''){
        setError(validatorRequired(validatorsrequired, value));
      }else{
        setError(validateInput(validators, value));
      }
    }else{
      setError(validateInput(validators, value));
    }
    if(caps === 'YES'){
      onChange(value.toUpperCase());   
    }else{
      onChange(value);   
    }
    
  };

  return (
    <div className="TextFieldBlock">
      {label && <label htmlFor="app-input-field">{label}<label className="starcolor">{star}</label></label>}
      <br />

      {type === "textarea" ? (
        <textarea
          className="TextField"
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          onChange={handleChange}
        />
      ) : (
        <input
          type={type}
          value={value}
          className="TextField"
          placeholder={placeholder}
          onChange={handleChange}
        />
      )}
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

TextFieldAlfaNum.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextFieldAlfaNum.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
};

export default TextFieldAlfaNum;
