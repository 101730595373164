import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cons, { serverApi } from "../../../helpers/Consts";
import { getbranchlist } from "../../../store/UserManagement/actions";
// import {getLeaveTypeList} from "../../../store/Master/LeaveTypeMaster/actions"
import {getLeavePolicyListAll} from "../../../store/Master/LeavePolicy/actions"
import { getCompanys } from "../../../store/Company/BasicDetails/actions";
import GridViewSearch from "../../../components/Grid/GridViewSerch";
import GridView from "../../../components/Grid/GridView";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: 0, userDit: JSON.parse(localStorage.getItem("authUser")) };
  }
  componentDidMount() {
    // this.props.getLeaveTypeList(serverApi.GET_ALL_LEAVE_TYPE_LIST + this.state.userDit.orgId, this.props.history); // calling api for Leave type list
    this.props.getLeavePolicyListAll(serverApi.GET_LEAVE_POLICY + localStorage.getItem("companyIdconfig"), this.props.history)
  }

  render() {
    const mdata = this.props.leavePolicyList ? this.props.leavePolicyList.data : "";
    const mcolumns = this.props.leavePolicyList ? this.props.leavePolicyList.column : "";

    return (
      <GridViewSearch mdata={mdata} mcolumns={LeavePolicy} pathname="/create_leave_policy"/>
    )
  }
}

const LeavePolicy=[
  {
    Header:"Leave Policy Description",
    // accessor: "leavePolicyDesc",
    dataField:"leavePolicyDesc",
    hidden:false,
  }
]

const mapStatetoProps = (state) => {
  const { leavePolicyList } = state.LeavePolicyReducer
  return { leavePolicyList };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLeavePolicyListAll
  })(Index)
);
