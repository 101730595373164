import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cons, { serverApi } from "../../helpers/Consts";
import PlaceHolderRole from "../../components/PlaceHolders/PlaceHolderComponentRole";
import {
  getUserGroups,
  getUserGroupRow,
} from "../../store/UserManagement/actions";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExportIcon from "../../assets/images/exportIcon.png";
import CustomButton from "../../components/Buttons/Buttons";
import BootstrapTable from "react-bootstrap-table-next";

import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

class RoleManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyIdconfig"),
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentDidMount() {
  let dataPayload = {
    comId: JSON.parse(localStorage.getItem("authUser")).orgId,
    cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
  };
    this.props.getUserGroups(
      serverApi.GET_ALL_USER_GROUP +
      localStorage.getItem("companyIdconfig") +
        "/"+this.state.userDit.cipher,
        dataPayload,
      this.props.history
    );
  }
  editRow = (row) => {
    // this.props.history.push({pathname:'/CompanyCreation', rowdata: row});
    // this.props.getUserGroupRow(
    //   serverApi.GET_USER_GROUP_BY_ID +
    //     row.roleId +
    //     "/f5b3a62a162d8ae1e7e1f2cd34defd1a",
    //   this.props.history
    // );
    // this.props.onNameChange('Role Creation', <CreateRole />, 1);
  this.props.history.push({ pathname: "/create_role", rowId: row.roleId });

  };
  deleteRow(row) {
  }
  handleValidSubmit(event, values) {
    // this.props.basicdetailsAdd(values, this.props.history);
  }

  render() {
    const mdata = this.props.usergrouplist;
    // const mcolumns = this.props.usergroupcolumns;
    const mcolumns = [];

    if (mdata) {
      var products = this.props.usergrouplist.map((row, order) => ({
        ...row,
        chk: (
          <div className="checkbox form-check">
            <div className="custom-checkbox custom-control custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={this.handleChange}
                value={row.companyId}
                id={row.companyId}
              />
              <label className="custom-control-label" for={row.companyId}></label>
            </div>
            {" "}
          </div>
        ),
        actions: (
          <Fragment>
            <EditIcon onClick={() => this.editRow(row)} />
            {/* <DeleteIcon onClick={() => this.deleteRow(row)} /> */}
          </Fragment>
        ),
      }));
    } else {
      var products = [];
    }
    const { SearchBar } = Search;
    const headerSortingStyle = { backgroundColor: "#e3edf8" };
    const columns = [
      // {
      //   dataField: "companyId",
      //   text: "ID",
      //   //sort: true,
      //   //headerSortingStyle,
      //   headerAlign: "left",
      //   align: "left",
      // },
    ];
    if (mcolumns) {
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          // text: mcolumns[i].Header,
          text:"Role Id",
          hidden:true,
          //sort: true,
          //headerSortingStyle,
          headerAlign: "left",
          align: "left",
        };
        columns.push(coldata);
      }
      var roleName={
        dataField: "roleName",
        text: "Role Name",
        //sort: true,
        //headerSortingStyle,
        headerAlign: "left",
      };
      columns.push(roleName);

      var roleType={
        dataField: "roleType",
        text: "Role Type",
        //sort: true,
        //headerSortingStyle,
        headerAlign: "left",
      };
      columns.push(roleType);

      
      var actions = {
        dataField: "actions",
        text: "Actions",
        //headerSortingStyle,
        headerAlign: "left",
        align: "left",
      };
      columns.push(actions);
    } else {
      const columns = [];
    }
    const defaultSorted = [
      {
        dataField: "companyId",
        order: "asc",
      },
    ];

    const MyExportCSV = (props) => {
      const handleExportClick = () => {
        props.onExport();
      };
      return (
        <CustomButton
          label="Export"
          className="lightBlueButton"
          type="button"
          icon={ExportIcon}
          handleClick={handleExportClick}
        />
      );
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };
    return (
      <div className="consoleFormContainer">
        {!this.props.usergrouplist ? (
          <PlaceHolderRole />
        ) : (
          <div className="">
            <div className="gridContainer">
              <div className="gridComponent">
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: products.length,
                    page: 1,
                    sizePerPage: 5,
                    nextPageText: "Next >",
                    prePageText: "< Prev",
                    withFirstAndLast: false,
                    showTotal: true,
                    sizePerPageList: [
                      { text: "10", value: 10 },
                      { text: "30", value: 30 },
                      { text: "50", value: 50 },
                      { text: "All", value: products.length },
                    ],
                    hideSizePerPage: products.length === 0,
                  })}
                  keyField="companyId"
                  columns={columns}
                  data={products}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="companyId"
                      columns={columns}
                      data={products}
                      search
                      // exportCSV={{
                      //   fileName: "productList.csv",
                      //   onlyExportSelection: true,
                      //   exportAll: true,
                      // }}
                    >
                      {(toolkitprops) => (
                        <div>
                          <Grid container spacing={2} className="buttonBlock">
                            <div className="gridButtonBlock">
                              <SearchBar {...toolkitprops.searchProps} />
                              {/* </Grid>
                                <Grid item xs={12} md={2}> */}
                              <MyExportCSV {...toolkitprops.csvProps} />
                            </div>
                          </Grid>
                          <BootstrapTable
                            {...toolkitprops.baseProps}
                            {...paginationTableProps}
                            // selectRow={selectRow}
                            defaultSorted={defaultSorted}
                            defaultSortDirection="asc"
                            striped
                            // hover
                            // condensed
                            // pagination={ paginationFactory()}
                            noDataIndication="No Data Is Available"
                          />
                          <SizePerPageDropdownStandalone {...paginationProps} />

                          <PaginationListStandalone {...paginationProps} />
                        </div>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { usergrouplist, usergroupcolumns, rowdata } = state.UserManagement;
  return { usergrouplist, usergroupcolumns, rowdata };
};

export default withRouter(
  connect(mapStatetoProps, {
    getUserGroups,
    getUserGroupRow,
  })(RoleManagement)
);
