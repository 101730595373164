// /* Author : Niranjan
// Created on : 03-02-2022
//*/

import React, { Component } from "react";
import { Box, Grid, Button } from "@mui/material";
import EditLogoIcon from "../../assets/images/picEditIcon.png";

class ImageUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // file: "",
      // imagePreview_Url: "",
      // fileUpload: "",
      // imagePreview: "none",
    };

    // this.handleImageChange = this.handleImageChange.bind(this);
    // this.SaveSubmit = this.SaveSubmit.bind(this);
    // this.handleImageRemove = this.handleImageRemove.bind(this);
  }

  SaveSubmit(e) {
    e.preventDefault();
    // console.log(this.state.imagePreview_Url);
    //TODO - save > this.state.imagePreview_Url in you DB using your API logic.
  }

  
  render() {
    return (
      <div className="uploadBlock">
        <div style={{ display: this.props.fileUpload }}>
          <Button component="label" className="uploadBtn">
            <div className="uploadInfo">
              <h1>+</h1>
              <h3>Upload Logo</h3>
              <p>(Format: jpeg, png</p>
              <p>size: 200px by 200px)</p>
            </div>
            <input
              type="file"
              hidden
              onChange={this.props.handleImageChange}
              accept="image/*"
            />
          </Button>
        </div>
        <div
          className="imagePreview"
          style={{
            display: this.props.imagePreview,
          }}
        >
          <img src={this.props.imagePreview_Url} />
          {/* <input type="button" value="close" /> */}
          <button onClick={this.props.handleImageRemove} className="editIconStyle">
            <img src={EditLogoIcon} alt="" />
          </button>
        </div>
      </div>
    );
  }
}
export default ImageUpload;
