import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CustomButton from "../../../components/Buttons/Buttons";
import DynamicSelect from "./DynamicSelect";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { UpdatePayScheme } from "../../../store/Master/PaySchemecreation/actions";
import { useDispatch } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import { useHistory } from "react-router-dom";

const ItemType = {
  ITEM: "item",
};

const DraggableItem = ({ item, index, moveItem, removeItem, isDisabled }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.ITEM,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !isDisabled,
  });

  const [, drop] = useDrop({
    accept: ItemType.ITEM,
    hover: (draggedItem) => {
      if (draggedItem.index !== index && !isDisabled) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={!isDisabled ? (node) => drag(drop(node)) : null}
      className="draggable-item"
      style={{ opacity: isDragging ? 0.5 : 1, display: 'flex', alignItems: 'center', cursor: isDisabled ? 'default' : 'move',}}
    >
      <span>{item.label}</span>
      {!isDisabled && (
        <IconButton
          size="small"
          onClick={() => removeItem(index)}
          style={{ marginLeft: 'auto' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

const Sidebar = ({ initialItems, open, onClose, isDisabled }) => {
  const [items, setItems] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // Filter and sort items for the initial load, excluding those with componentOrderPayScheme null or 0
    const sortedItems = initialItems?.paySchemeDetailsDTO
      ?.filter(item => item.componentOrderPayScheme && item.componentOrderPayScheme > 0)
      ?.sort((a, b) => a.componentOrderPayScheme - b.componentOrderPayScheme)
      ?.map(item => ({
        value: item.componentId,
        label: item.componentName,
      }));

    setItems(sortedItems);

    // Prepare dropdown items
    const dropdownItems = initialItems?.paySchemeDetailsDTO?.map(item => ({
      value: item.componentId,
      label: item.componentName,
    }));

    setDropdownItems(dropdownItems);
  }, [initialItems]);

  const handleMenuOpen = () => {
    setDropdownOpen(true);
  };

  const moveItem = (fromIndex, toIndex) => {
    if (isDisabled) return;
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
  };

  const handleSelectChange = (selectedValue, selectedLabel) => {
    if (isDisabled) return;

    setSelectedValue({ value: selectedValue, label: selectedLabel });
    if (selectedValue && !items.some(item => item.value === selectedValue)) {
      const newItem = initialItems?.paySchemeDetailsDTO?.find(
        item => item.componentId === selectedValue
      );
      if (newItem) {
        setItems(prevItems => [
          ...prevItems,
          { value: newItem.componentId, label: newItem.componentName }
        ]);
      }
    }
  };

  const removeItem = (index) => {
    if (isDisabled) return;

    setItems(items.filter((_, i) => i !== index));
  };

  const getPayload = () => {
    const payloadDetails = items.map((item, index) => ({
      id: initialItems.paySchemeDetailsDTO.find(dto => dto.componentId === item.value).id,
      componentId: item.value,
      formula: initialItems.paySchemeDetailsDTO.find(dto => dto.componentId === item.value).formula,
      paySchemeId: initialItems.paySchemeDetailsDTO.find(dto => dto.componentId === item.value).paySchemeId,
      componentOrderPayScheme: index + 1,
      componentOrderPaySlip: index + 1,
      type: initialItems.paySchemeDetailsDTO.find(dto => dto.componentId === item.value).type,
      status: initialItems.paySchemeDetailsDTO.find(dto => dto.componentId === item.value).status,
    }));

    const allComponentIds = initialItems.paySchemeDetailsDTO.map(dto => dto.componentId);
    const selectedComponentIds = items.map(item => item.value);
    const notSelectedItems = allComponentIds
      .filter(id => !selectedComponentIds.includes(id))
      .map(id => {
        const item = initialItems.paySchemeDetailsDTO.find(dto => dto.componentId === id);
        return {
          id: item.id,
          componentId: item.componentId,
          formula: item.formula,
          paySchemeId: item.paySchemeId,
          componentOrderPayScheme: 0,
          componentOrderPaySlip: 0,
          type: item.type,
          status: item.status
        };
      });

    const paySchemeDTO = {
      id: initialItems.paySchemeDTO.id,
      code: initialItems.paySchemeDTO.code,
      businessunitId: initialItems.paySchemeDTO.businessunitId,
      designation: initialItems.paySchemeDTO.designation,
      branchid: initialItems.paySchemeDTO.branchid,
      description: initialItems.paySchemeDTO.description,
      name: initialItems.paySchemeDTO.name,
      wageId: initialItems.paySchemeDTO.wageId,
      status: initialItems.paySchemeDTO.status,
      activeStatus: initialItems.paySchemeDTO.activeStatus,
    };

    return {
      paySchemeDTO: paySchemeDTO,
      paySchemeDetailsDTO: [...payloadDetails, ...notSelectedItems],
    };
  };

  const handleSubmit = () => {
    if (isDisabled) return;

    const payload = getPayload();
    console.log("Submitting payload:", payload);
    dispatch(UpdatePayScheme(serverApi.UPDATE_PAY_SCHEME, payload, history));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            position: "fixed",
            top: 0,
            right: 0,
            margin: 0,
            bottom: 0,
            width: "30%",
            maxHeight: "100vh",
          },
        }}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={0}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div className="consoleTextFieldBlock">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <div className="filterBlockHeader">
                  <span>Pay Parameter Ordering</span>
                </div>
                <IconButton onClick={onClose} style={{ marginLeft: 'auto' }}>
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <div className="prResponseFilter">
              <Grid item xs={12} className="filterBlockContainer">
                <DynamicSelect
                  arrayOfData={dropdownItems}
                  className="dropdownBlockContainer"
                  menuPosition="fixed"
                  menuPlacement="auto"
                  onSelectChange={handleSelectChange}
                  isDropdownOpen={isDropdownOpen}
                  handleMenuOpen={handleMenuOpen}
                  selected={selectedValue}
                  isDisabled={isDisabled}
                />
                <Box className="sidebar-body">
                  {items?.map((item, index) => (
                    <DraggableItem
                      key={index}
                      index={index}
                      item={item}
                      moveItem={moveItem}
                      removeItem={removeItem}
                      isDisabled={isDisabled}
                    />
                  ))}
                </Box>
                <div className="consoleFormButtonBlock">
                  <CustomButton
                    label={"Cancel"}
                    className="greenButton"
                    handleClick={onClose}
                    type="button"
                  />
                  <CustomButton
                    label={"Submit"}
                    className="greenButton"
                    handleClick={handleSubmit}
                    type="button"
                    disabled={isDisabled}
                  />
                </div>
              </Grid>
            </div>
          </div>
        </Grid>
      </Dialog>
    </DndProvider>
  );
};

export default Sidebar;
