import React, { Component } from "react";
import { Grid, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { getCurrencyConfiguration } from "../../store/Configuration/actions";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

class InwardPoConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      poCurrency: 0,
      PurchaseCurrencyINR: false,
      PurchaseCurrencyUSD: false,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
      autoId: null,
      poCurrencyINR: 0,
      poCurrencyUSD: 0,
    };
  }

  handleConfig = () => {
    if (
      this.state.PurchaseCurrencyINR == false &&
      this.state.PurchaseCurrencyUSD == false
    ) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select the Currency type",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var inrVal=0
      var usdVal=0
      if (this.state.PurchaseCurrencyINR) {
        var inrVal = 1;
      } 
       if (this.state.PurchaseCurrencyUSD) {
        var usdVal = 1;
      }
      var values = {
        inr: inrVal,
        usd: usdVal,
      };
      var msg = "Currency Configuration Updated Successfully.";
      var data = {
        companyId: localStorage.getItem("companyIdconfig"),
        configList: [
          {
            autoId: this.state.autoId,
            configParameter: configNames.CURRENCY_TYPES,
            isActive: 1,
            configValue: JSON.stringify(values),
          },
        ],
      };
      this.props.getCurrencyConfiguration(
        serverApi.UPDATE_CONFIGURATION_NEW,
        data,
        msg,
        this.props.history
      );
    }
  };
  onClickRadioButton = (name, checked) => {
    if (name === "inr") {
      this.setState({
        PurchaseCurrencyINR: !this.state.PurchaseCurrencyINR,
      });
    } else if (name == "usd") {
      this.setState({
        PurchaseCurrencyUSD: !this.state.PurchaseCurrencyUSD,
      });
    }
  };

  componentDidMount = () => {
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      if (configdata.length !== 0) {
        configdata.forEach((prop) => {
          if (prop.configParameter === configNames.CURRENCY_TYPES) {
            var Values = JSON.parse(prop.configValue);
            if (Values.inr == 1) {
              this.setState({
                PurchaseCurrencyINR: true,
                // PurchaseCurrencyUSD:false,
                poCurrencyINR: 1,
                // poCurrencyUSD: 0,
                configdata: configdata,
                autoId: prop.autoId,
              });
            } 
             if (Values.usd == 1) {
              this.setState({
                // PurchaseCurrencyINR:false,
                PurchaseCurrencyUSD: true,
                // poCurrencyINR: 0,
                poCurrencyUSD: 1,
                configdata: configdata,
                autoId: prop.autoId,
              });
            }
          } else {
            this.setState({
              PurchaseCurrencyINR: false,
              PurchaseCurrencyUSD: false,
              poCurrencyINR: 0,
              poCurrencyUSD: 0,
              configdata: [],
              autoId: null,
            });
          }
        });
      } else {
        this.setState({
          PurchaseCurrencyINR: false,
          PurchaseCurrencyUSD: false,
          poCurrencyINR: 0,
          poCurrencyUSD: 0,
          configdata: [],
          autoId: null,
        });
      }
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.configdata) {
      if (props.configdata.length !== 0) {
        props.configdata.forEach((prop) => {
          if (prop.configParameter === configNames.CURRENCY_TYPES) {
            var Values = JSON.parse(prop.configValue);
            if (Values.inr == 1) {
              this.setState({
                PurchaseCurrencyINR: true,
                // PurchaseCurrencyUSD:false,
                poCurrencyINR: 1,
                // poCurrencyUSD: 0,
                configdata: prop.configdata,
                autoId: prop.autoId,
              });
            }
            if (Values.usd == 1) {
              this.setState({
                // PurchaseCurrencyINR:false,
                PurchaseCurrencyUSD: true,
                // poCurrencyINR: 0,
                poCurrencyUSD: 1,
                configdata: prop.configdata,
                autoId: prop.autoId,
              });
            }
          } else {
            this.setState({
              PurchaseCurrencyINR: false,
              PurchaseCurrencyUSD: false,
              poCurrencyINR: 0,
              poCurrencyUSD: 0,
              configdata: [],
              autoId: null,
            });
          }
        });
      } else {
        this.setState({
          PurchaseCurrencyINR: false,
          PurchaseCurrencyUSD: false,
          poCurrencyINR: 0,
          poCurrencyUSD: 0,
          configdata: [],
          autoId: null,
        });
      }
    }
  };
  render() {
    return (
      <>
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        <div className="consoleFormContainer">
          <h5>Purchase Currency Configuration</h5>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="consoleFormBlock">
                <Box
                  gridColumn="span 3"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid item xs={3}>
                    {/* <div className=""> */}
                    <span className="TextFieldBlock">
                      <label>INR</label>
                    </span>
                    <Checkbox
                      onChange={() => this.onClickRadioButton("inr")}
                      checked={this.state.PurchaseCurrencyINR}
                      name="inr"
                    />
                    {/* <RadioButton
                      labelText="INR"
                      value="inr"
                      groupname="currency"
                      checkednew={this.state.PurchaseCurrencyINR}
                      onChange={this.onClickRadioButton}
                    /> */}
                    {/* </div> */}
                  </Grid>
                  <Grid item xs={3}>
                    <span className="TextFieldBlock">
                      <label>USD</label>
                    </span>
                    <Checkbox
                      onChange={() => this.onClickRadioButton("usd")}
                      checked={this.state.PurchaseCurrencyUSD}
                      name="usd"
                    />
                    {/* <div className="">
                      <RadioButton
                        labelText="USD"
                        value="usd"
                        groupname="currency"
                        checkednew={this.state.PurchaseCurrencyUSD}
                        onChange={this.onClickRadioButton}
                      />
                    </div> */}
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="consoleFormButtonBlock">
          <Link to="/invoice">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companysList, companyscoulmns } = state.BasicDetails;
  const { configdatalist, configdata } = state.Configuration;
  return { companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
  getCurrencyConfiguration,
})(InwardPoConfig);
