/** Created On Nagesh Medisetty On 02-02-2022 */
import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCompanys } from "../../store/Company/BasicDetails/actions";
import CustomButton from "../../components/Buttons/Buttons";
import ExportIcon from "../../assets/images/exportIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import cons, { serverApi } from "../../helpers/Consts";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { GridRow } from "@mui/x-data-grid";
import GridView from "../../components/Grid/GridView";
import { getProfilePicFileSetNull } from "../../store/Master/PaySchemecreation/actions"
import Header from "../../components/Header/Header";
class CompanyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Company's List",
      createbranchbtn: 4,
      module: 2,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyslist: [],
      companyscoulmns: [],
    };
  }

  componentDidMount() {
    this.props.getProfilePicFileSetNull(null)
    const data = {
      cipher: this.state.userDit.cipher,
      orgId: this.state.userDit.orgId,
    };

    this.props.getCompanys(
      serverApi.COMPANYMASTER_LIST_BY_ORG_ID,
      data,
      this.props.history
    );

    localStorage.removeItem("companyId");
    localStorage.removeItem("companyupdateId");
  }

  componentWillReceiveProps(props) {
    if (props.companyslist) {
      this.setState({
        companyslist: props.companyslist,
      });
    }
    if (props.companyscoulmns) {
      this.setState({
        companyscoulmns: props.companyscoulmns,
      });
    }
  }

  editRow = (row) => {
    cons.COMPANY_ID = row.companyId;
    this.props.history.push({ pathname: "/basicdetails", rowdata: row });
    // console.log("Nagesh Row Edit", row.companyId);
    localStorage.setItem("companyupdateId", row.companyId);
    localStorage.setItem("branchcompanyId", row.companyId);
  };
  deleteRow(row) {
    cons.COMPANY_ID = row.companyId;
    // console.log("Nagesh Row Delete", row);
  }

  render() {
    const mdata = this.state.companyslist;
    const mcolumns = this.state.companyscoulmns;
    return(
      <>
      <GridView mdata={this.state.companyslist} mcolumns={companyscoulmns} pathname="/basicdetails"/>

      </>
    )
  }
}

const companyscoulmns=[
  {
    Header:"Company Code",
    accessor: "companyCode",
    hidden:false,
  },
  {
  Header:"Company Name",
  accessor:"companyName",
  hidden:false,
  },

]

const mapStatetoProps = (state) => {
  const { companyslist, companyscoulmns } = state.BasicDetails;
  return { companyslist, companyscoulmns };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCompanys,
    getProfilePicFileSetNull
  })(CompanyList)
);
