import {  
  CONFIGURATION,
  CONFIGURATION_DATA,
  CONFIGURATION_SUCCESSFUL,
  CONFIGURATION_UPDATE,
  CONFIGURATION_DATA_SUCCESSFUL,
  CURRENCY_CONFIGURATION,
  CURRENCY_CONFIGURATION_SUCCESSFULL,
  INWARD_PO_CONFIGURATION,
  INWARD_PO_CONFIGURATION_SUCCESSFULL
} from "./actionTypes";

export const getConfiguration = (url, history, companyId) => {
  return {
    type: CONFIGURATION,
    payload: { url, history, companyId },
  };
};

export const setConfiguration = (url, data, msg, history) => {
  return {
    type: CONFIGURATION_UPDATE,
    payload: { url, data, msg, history },
  };
};

export const ConfigurationSuccessful = (data) => {
  return {
    type: CONFIGURATION_SUCCESSFUL,
    payload: data,
  };
};

// New Actions for Date allowable
export const getConfigurationData = (url, data, msg, history) => {
  return {
    type: CONFIGURATION_DATA,
    payload: { url, data, msg, history},
  };
};
export const getConfigurationDataSuccessful = (data) => {
  return {
    type: CONFIGURATION_DATA_SUCCESSFUL,
    payload: data,
  };
};
//  Currency configuration 
export const getCurrencyConfiguration = (url, data, msg, history) => {
  return {
    type: CURRENCY_CONFIGURATION,
    payload: { url, data, msg, history},
  };
};
export const getCurrencyConfigurationSuccessful = (data) => {
  return {
    type: CURRENCY_CONFIGURATION_SUCCESSFULL,
    payload: data,
  };
};

//Po configuration 
export const getInwardPOconfiguration = (url, data, msg, history) => {
  return {
    type: INWARD_PO_CONFIGURATION,
    payload: { url, data, msg, history},
  };
};
export const getInwardPOconfigurationSuccessful = (data) => {
  return {
    type: INWARD_PO_CONFIGURATION_SUCCESSFULL,
    payload: data,
  };
};