import React, { Component } from "react";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import InnerNavigation from "../../components/InnerNavigation/InnerNavigation";
import { Grid } from "@mui/material";
import Header from "../../components/Header/Header";
import Invoice from "../../Pages/Configuration/Invoice";
import cons, { serverApi } from "../../helpers/Consts";

class Configuration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Invoice",
      companypage: <Invoice />,
      createbranchbtn: 0,
      module: 4,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (name, page, btn) => {
    this.setState({
      parentName: name,
      companypage: page,
      createbranchbtn: btn,
    });
  };
  render() {
    return (
      <>
        <Grid container className="mainContainer">
          <LeftSidebar />
          <Grid className="mainContent">
            <InnerNavigation
              childName={this.state.parentName}
              buttonbranch={this.state.createbranchbtn}
              module={this.state.module}
              onNameChange={this.onChange}
            />
            <div className="consoleContainer">
              <Header
                childName={this.state.parentName}
                buttonbranch={this.state.createbranchbtn}
                onNameChange={this.onChange}
              />
              {this.state.companypage}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Configuration;
