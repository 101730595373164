import { getList, postAdd } from "../../../helpers/Server_Helper";
import { getSupplierTypeMasterListSuccessfull,createSupplierTypesSuccessfull,updateSupplierTypesSuccessfull } from "./actions";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { CREATE_SUPPLIER_TYPES, GET_SUPPLIER_TYPE_MASTER_LIST ,UPDATE_SUPPLIER_TYPES} from "./actionsTypes";
import swal from "sweetalert";


function* SupplierTypesListSaga({payload:{url,data,history}}){
    try{
        const response = yield call(getList,url,data);
        yield put(getSupplierTypeMasterListSuccessfull({response}));
    }catch(error){
        console.log(error);
    }
}

function* Create_Supplier_Types({payload:{url,data,history}}){
    try{
        const response=yield call(postAdd,url,data);
        yield put(createSupplierTypesSuccessfull({response}));
        if(response.data.status===true){
            swal(response.data.message,{icon:"success"})
            history.push("/supplier_types")//url

        }else{
            swal(response.data.message,{icon:"error"})
        }
    }catch(error){
        swal("error:"+error,{icon:"danger"});
        console.log(error);
    }
}

function* Update_Supplier_Types({payload:{url,data,history}}){
    try{
        const response=yield call(postAdd,url,data);
        yield put(updateSupplierTypesSuccessfull({response}));
        if(response.data.status===true){
        swal(response.data.message,{icon:"success"})
        history.push("/supplier_types")//path
    }else {
        swal(response.data.message,{icon:"error"})
    }
}catch(error){
    console.log(error);
    swal("error:"+error,{icon:"danger"});
}
}

export function* watchSupplierTypesList(){
    yield takeEvery(GET_SUPPLIER_TYPE_MASTER_LIST,SupplierTypesListSaga);
}

export function* watchCreateSupplierTypes(){
    yield takeEvery(CREATE_SUPPLIER_TYPES,Create_Supplier_Types)
}

export function* watchUpdateSupplierTypes(){
    yield takeEvery(UPDATE_SUPPLIER_TYPES,Update_Supplier_Types)
}

function* SupplierTypesSaga(){
    yield all([
        fork(watchSupplierTypesList),
        fork(watchCreateSupplierTypes),
        fork(watchUpdateSupplierTypes),
    ])

}
export default SupplierTypesSaga;