import { all } from 'redux-saga/effects'


import LayoutSaga from './layout/saga';
import loginSaga from './auth/login/saga';
import basicDetails from './Company/BasicDetails/saga';
import officialDetails from './Company/OfficialDetails/saga';
import branchCreation from './Company/BranchCreation/saga';
import form from './Form/saga';
import Configuration from './Configuration/saga';
import UserManagement from './UserManagement/saga';
import ModuleSelection from './ModuleSelection/saga';
import LeaveTypeListSaga from './Master/LeaveTypeMaster/saga';
import DepartmentListSaga from './Master/DepartmentMaster/saga';
import PaySchemeSaga from "./Master/PaySchemecreation/saga";
import LeavePolicySaga from './Master/LeavePolicy/saga';
import PayschemeParaSaga from "./Master/PayschemeParameters/saga";
import UploadFileListSaga from "./UploadFile/saga"; 
import TaxMasterSaga from './Master/TaxMaster/saga';
import BankAccount from "./Master/BankAccountMaster/saga";
import BankAccountSaga from './Master/BankAccountMaster/saga';
import SupplierTypesSaga from './Master/SupplierTypeMaster/saga';

export default function* rootSaga() {
    yield all([
        LayoutSaga(),
        loginSaga(),
        basicDetails(),
        officialDetails(),
        branchCreation(),
        form(),
        Configuration(),
        UserManagement(),
        ModuleSelection(),
        LeaveTypeListSaga(),
        DepartmentListSaga(),
        PaySchemeSaga(),
        LeavePolicySaga(),
        PayschemeParaSaga(),
        UploadFileListSaga(),
        TaxMasterSaga(),
        BankAccountSaga(),
        SupplierTypesSaga(),
    ])
}