import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button'


export default function InputWithIcon(props) {
  const handleChange = (event) => {
    const { value } = event.target
    props.handleClick(value)
 }

 const onclick=()=>{
  props.onClick()
 }
 
  return (
    <div className="consoleAdormentBlock">
      <TextField
        onChange={handleChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {props.label&& <span>{props.label}</span>}
              <Button onClick={onclick}>{props.btnLable}</Button>
              <img src={props.imgUrl} alt="" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
