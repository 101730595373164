import {PAYSCHEME_PARAMETERS_LIST,PAYSCHEME_PARAMETERS_LIST_SUCCESSFULL,API_ERRORS,
  CREATE_PAYSCHEME_PARAMETERS, CREATE_PAYSCHEME_PARAMETERS_SUCCESSFULL, UPDATE_PAYSCHEME_PARAMETERS_SUCCESSFULL, UPDATE_PAYSCHEME_PARAMETERS,
} from './actionTypes';

const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    PayschemeParametersList: [],
    Create_Payscheme:[],
    Update_Payscheme:[],
  };

  const PayschemeParaReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYSCHEME_PARAMETERS_LIST:
            state = {
              ...state,
              loading: true,
            };
            break;
          case PAYSCHEME_PARAMETERS_LIST_SUCCESSFULL:
            state = {
              ...state,
              PayschemeParametersList: action.payload.response,
              loading: true,
            };
            break;
            case CREATE_PAYSCHEME_PARAMETERS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case CREATE_PAYSCHEME_PARAMETERS_SUCCESSFULL:
              state = {
                ...state,
                Create_Payscheme: action.payload.response,
                loading: true,
              };
              break;
              case UPDATE_PAYSCHEME_PARAMETERS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case UPDATE_PAYSCHEME_PARAMETERS_SUCCESSFULL:
              state = {
                ...state,
                Update_Payscheme: action.payload.response,
                loading: true,
              };
              break;
              case API_ERRORS:
          state = {
            ...state,
            loading: false,
            error_msg: action.payload,
          };
          break;
          default:
            state = { ...state };
            break;
    }
    return state;
  }

  export default PayschemeParaReducer;