import React, { Component, Suspense } from "react";
import { Grid, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  branchCreationAdd,
  getMasterStates,
} from "../../store/Company/BranchCreation/actions";
import { getUsersList } from "../../store/UserManagement/actions";
import cons, { serverApi, ApprovalTasks } from "../../helpers/Consts";
import DynamicSelect from "../../components/Dropdown/DynamicSelect";
import { GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import GridPlusIcon from "../../assets/images/gridPlusIcon.png";
import { DataGrid } from "@mui/x-data-grid";
import {
  getbranchlist,
  getportallist,
  addRoletypes,
  addApproval,
} from "../../store/UserManagement/actions";
import {
  getCompanys,
  getClient,
} from "../../store/Company/BasicDetails/actions";
import AutoCompleteSelect from "../../components/Dropdown/AutoCompleteSelect";
import AutoMultiselect from "../../components/Dropdown/AutoMultiselect";
import ApprovalLazyLoading from "./ApprovalLazyLoading";
export const kuttiMaccha = React.lazy(() => import("./ApprovalLazyLoading"));
class ApprovelHierarcy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      branchId: 0,
      selectedValue: "Nothing selected",
      selectedId: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      newActions: [],
      BranchList: [],
      user1: [],
      portalList: [],
      userslist: [],
      arrayOfData: [],
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }

  componentDidMount() {

  window.addEventListener('scroll', this.handleScroll);

    this.props.getportallist(serverApi.GET_ALL_USER);
    var data = {
      comId: JSON.parse(localStorage.getItem("authUser")).orgId,
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    };
    this.props.getUsersList(
      serverApi.GET_ALL_USERS_LIST,
      data,
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    console.log("jkjjlsjklsjs", props.clientList, ApprovalTasks);
    let list = [];
    let options = [];
    var newActions1 = ApprovalTasks.map((prop, inx) => {
      var data = props.clientList.filter(function (item) {
        return item.taskId == prop.id;
      });
      var obj = {
        id: prop.id,
        taskName: prop.taskName,
        action: data,
      };
      return obj;
    });
    let newActions = [];
    props.clientList.map((prop, inx) => {
      newActions1.filter(function (item) {
        if (item.id == prop.taskId) {
          var actiondata = item.action[0];
          var payload = {
            id: actiondata.id,
            taskId: actiondata.taskId,
            l1User1: actiondata.l1User1 ? actiondata.l1User1.id : "",
            l1User2: actiondata.l1User2 ? actiondata.l1User2.id : "",
            l1User3: actiondata.l1User3 ? actiondata.l1User3.id : "",
            l2User1: actiondata.l2User1 ? actiondata.l2User1.id : "",
            l2User2: actiondata.l2User2 ? actiondata.l2User2.id : "",
            l2User3: actiondata.l2User3 ? actiondata.l2User3.id : "",
            l3User1: actiondata.l3User1 ? actiondata.l3User1.id : "",
            l3User2: actiondata.l3User2 ? actiondata.l3User2.id : "",
            l3User3: actiondata.l3User3 ? actiondata.l3User3.id : "",
            l4User1: actiondata.l4User1 ? actiondata.l4User1.id : "",
            l4User2: actiondata.l4User2 ? actiondata.l4User2.id : "",
            l4User3: actiondata.l4User3 ? actiondata.l4User3.id : "",
            l5User1: actiondata.l5User1 ? actiondata.l5User1.id : "",
            l5User2: actiondata.l5User2 ? actiondata.l5User2.id : "",
            l5User3: actiondata.l5User3 ? actiondata.l5User3.id : "",
            modifiedBy: "",
            companyId: actiondata.companyId,
            branchId: actiondata.branchId,
          };
          var obj = {
            id: item.id,
            taskName: item.taskName,
            action: item.action,
            payload: payload,
          };
          newActions.push(obj);
        }
      });
    });
    this.setState({
      newActions: newActions,
    });

    console.log("Nagesh NewActions", newActions);

    if (props.branchList) {
      const Branchlist = [];

      let obj2 = { label: "Select....", value: "", name: "Select Branch" };
      Branchlist.push(obj2);
      props.branchList.forEach((prop) => {
        let obj = {
          label: prop.brname,
          value: prop.id,
          name: prop.brname,
        };
        Branchlist.push(obj);
      });
      this.setState({
        BranchList: Branchlist,
      });
    }

    if (props.portalList) {
      console.log("portalList", props.portalList);
      props.portalList.forEach((prop) => {
        list.push({
          title: prop.name,
          id: prop.value,
          label: prop.label,
        });
      });
      // for(let i = 0; i<10000; i = i+1) {
      //   options.push({value: i, label: `Option ${i}`})
      // }
      // console.log("getting the data after")
      this.setState({
        portalList: list,
      });
    }
    let usersList = [];
    if (props.userslist) {
      console.log("userslist", props.userslist);
      props.userslist.forEach((prop) => {
        usersList.push({
          title: prop.userName,
          id: prop.userId,
          label: prop.userName,
        });
      });
      console.log("userslist", usersList);
      this.setState({
        userslist: usersList,
      });
    }
    // company List
    var arrayOfdata = [];
    if (props.companyslist) {
      var obj1 = { label: "Select....", value: "", name: "Select Company" };
      arrayOfdata.push(obj1);
      props.companyslist.forEach((prop) => {
        var obj = {
          label: prop.companyCode,
          value: prop.companyId,
          name: prop.companyCode,
        };
        arrayOfdata.push(obj);
      });
      this.setState({
        arrayOfData: arrayOfdata,
      });
    }
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  handleSelectChange = (selectedValue, selectedId) => {
    this.setState({
      selectedValue: selectedValue,
      selectedId: selectedId,
      branchId: selectedValue,
      loadHierarchyTable: true,
    });

    var data = {
      branchId: selectedValue,
      companyId: this.state.companyId,
    };
    this.props.getClient(serverApi.GET_TASKS_LIST, data, this.props.history);
  };

  handleSelectChangeCom = (selectedValue, selectedId) => {
    this.setState({
      selectedValue: selectedValue,
      selectedId: selectedId,
      loadHierarchyTable: false,
      companyId: selectedValue,
    });

    var data = {
      companyId: selectedValue,
      cipher: this.state.userDit.cipher,
    };

    this.props.getbranchlist(
      serverApi.BRANCHMASTER_DATATABLE,
      data,
      this.props.history
    );
  };

  handleValidSubmit = (event) => {
    event.preventDefault();
    var payload = [];

    this.state.newActions.map((p) => {
      console.log("getting the data after update", p.payload);
      p.payload.modifiedBy = this.state.userDit.userId;
      payload.push(p.payload);
    });

    var data = { approveList: payload };
    console.log("data....", data);
    // this.props.getCompanys(
    //   serverUPDATE_APPOVE_HIERACHY,data, this.props.history
    // )
    this.props.addApproval(
      serverApi.UPDATE_APPOVE_HIERACHY,
      data,
      this.props.history,
      this.state.companyId,
      this.state.branchId
    );

    // var data1 = {
    //   branchId: this.state.branchId,
    //   companyId: this.state.companyId,
    // };

    // this.props.getClient(serverApi.GET_TASKS_LIST, data1, this.props.history);

    // console.log("Nagesh handleValidSubmit", data);
  };

  getMopt = (selectedValue, name, values) => {
    console.log("bhavya value", values);
    console.log("bhavya select", selectedValue);
    console.log("bhavya select", this.state.newActions);
    ///mytaskId is used to check the Level
    /// taskId is used to check the (taskdesc)taskName

    let List = [];
    List = this.state.newActions.map((data, index) => {
      // console.log("bhavya",data)
      if (data.action) {
        data.action.map((data2, index) => {
          if (data2.taskId === values.myTask.taskId) {
            console.log("bhavya", data2, selectedValue.length);
            //For level one........
            if (selectedValue.length === 0) {
              console.log("bhavya", data2, selectedValue.length);
              data.payload.l1User1 = "";
              data.payload.l1User2 = "";
              data.payload.l1User3 = "";
              console.log("bhavya", data.payload.l2User1, selectedValue);
            }
            if (values.myTaskType === 1) {
              console.log("bhavya", data2, selectedValue.length);
              if (selectedValue.length === 1) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l1User1 = selectedValue[0];
                data.payload.l1User2 = "";
                data.payload.l1User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 2) {
                data.payload.l1User1 = selectedValue[0];
                data.payload.l1User2 = selectedValue[1];
                data.payload.l1User3 = "";
              }
              if (selectedValue.length === 3) {
                data.payload.l1User1 = selectedValue[0];
                data.payload.l1User2 = selectedValue[1];
                data.payload.l1User3 = selectedValue[2];
              }
            }
            // For level Two......

            if (values.myTaskType === 2) {
              console.log("bhavya", data2, selectedValue.length);
              if (selectedValue.length === 0) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l2User1 = "";
                data.payload.l2User2 = "";
                data.payload.l2User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 1) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l2User1 = selectedValue[0];
                data.payload.l2User2 = "";
                data.payload.l2User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 2) {
                data.payload.l2User1 = selectedValue[0];
                data.payload.l2User2 = selectedValue[1];
                data.payload.l2User3 = "";
              }
              if (selectedValue.length === 3) {
                data.payload.l2User1 = selectedValue[0];
                data.payload.l2User2 = selectedValue[1];
                data.payload.l2User3 = selectedValue[2];
              }
            }
            // For level Three......
            if (values.myTaskType === 3) {
              console.log("bhavya", data2, selectedValue.length);
              if (selectedValue.length === 0) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l3User1 = "";
                data.payload.l3User2 = "";
                data.payload.l3User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 1) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l3User1 = selectedValue[0];
                data.payload.l3User2 = "";
                data.payload.l3User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 2) {
                data.payload.l3User1 = selectedValue[0];
                data.payload.l3User2 = selectedValue[1];
                data.payload.l3User3 = "";
              }
              if (selectedValue.length === 3) {
                data.payload.l3User1 = selectedValue[0];
                data.payload.l3User2 = selectedValue[1];
                data.payload.l3User3 = selectedValue[2];
              }
            }
            // For level Four......
            if (values.myTaskType === 4) {
              console.log("bhavya", data2, selectedValue.length);
              if (selectedValue.length === 0) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l4User1 = "";
                data.payload.l4User2 = "";
                data.payload.l4User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 1) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l4User1 = selectedValue[0];
                data.payload.l4User2 = "";
                data.payload.l4User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 2) {
                data.payload.l4User1 = selectedValue[0];
                data.payload.l4User2 = selectedValue[1];
                data.payload.l4User3 = "";
              }
              if (selectedValue.length === 3) {
                data.payload.l4User1 = selectedValue[0];
                data.payload.l4User2 = selectedValue[1];
                data.payload.l4User3 = selectedValue[2];
              }
            }
            // For level Five......
            if (values.myTaskType === 5) {
              console.log("bhavya", data2, selectedValue.length);
              if (selectedValue.length === 0) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l5User1 = "";
                data.payload.l5User2 = "";
                data.payload.l5User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 1) {
                console.log("bhavya", data2, selectedValue.length);
                data.payload.l5User1 = selectedValue[0];
                data.payload.l5User2 = "";
                data.payload.l5User3 = "";
                console.log("bhavya", data.payload.l2User1, selectedValue);
              }
              if (selectedValue.length === 2) {
                data.payload.l5User1 = selectedValue[0];
                data.payload.l5User2 = selectedValue[1];
                data.payload.l5User3 = "";
              }
              if (selectedValue.length === 3) {
                data.payload.l5User1 = selectedValue[0];
                data.payload.l5User2 = selectedValue[1];
                data.payload.l5User3 = selectedValue[2];
              }
            }
          }
          return data2;
        });
      }
      return data;
    });
    console.log("bhavya List", List);
    this.setState({
      newActions: List,
    });
  };

  getDefaultValue = (row, type, data) => {
    var item = [];
    var item1 = [];
    var item2 = [];
    console.log("getting the data from approval hierarchy", data);
    data.map((p, inx) => {
      if (row) {
        if (type == 1) {
          if (row.l1User1) {
            if (row.l1User1.id == p.id) {
              item.push(inx);
            }
          }
          if (row.l1User2) {
            if (row.l1User2.id == p.id) {
              item1.push(inx);
            }
          }
          if (row.l1User3) {
            if (row.l1User3.id == p.id) {
              item2.push(inx);
            }
          }
        }
        if (type == 2) {
          if (row.l2User1) {
            if (row.l2User1.id == p.id) {
              item.push(inx);
            }
          }
          if (row.l2User2) {
            if (row.l2User2.id == p.id) {
              item1.push(inx);
            }
          }
          if (row.l2User3) {
            if (row.l2User3.id == p.id) {
              item2.push(inx);
            }
          }
        }
        if (type == 3) {
          if (row.l3User1) {
            if (row.l3User1.id == p.id) {
              item.push(inx);
            }
          }
          if (row.l3User2) {
            if (row.l3User2.id == p.id) {
              item1.push(inx);
            }
          }
          if (row.l3User3) {
            if (row.l3User3.id == p.id) {
              item2.push(inx);
            }
          }
        }
        if (type == 4) {
          if (row.l4User1) {
            if (row.l4User1.id == p.id) {
              item.push(inx);
            }
          }
          if (row.l4User2) {
            if (row.l4User2.id == p.id) {
              item1.push(inx);
            }
          }
          if (row.l4User3) {
            if (row.l4User3.id == p.id) {
              item2.push(inx);
            }
          }
        }
        if (type == 5) {
          if (row.l5User1) {
            if (row.l5User1.id == p.id) {
              item.push(inx);
            }
          }
          if (row.l5User2) {
            if (row.l5User2.id == p.id) {
              item1.push(inx);
            }
          }
          if (row.l5User3) {
            if (row.l5User3.id == p.id) {
              item2.push(inx);
            }
          }
        }
      }
    });

    if (item.length > 0 && item1.length > 0 && item2.length > 0) {
      console.log("Nagesh1", [data[item], data[item1], data[item2]]);
      return [data[item], data[item1], data[item2]];
    } else if (item.length > 0 && item1.length > 0 && item2.length == 0) {
      console.log("Nagesh2", [data[item], data[item1]]);
      return [data[item], data[item1]];
    } else if (item.length > 0 && item1.length == 0 && item2.length == 0) {
      console.log("Nagesh3", [data[item]]);
      return [data[item]];
    } else {
      return [];
    }
  };

  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    console.log('Scrolling...');
    this.setState({
      isDropdownOpen:true
    })
  };

  render() {
    console.log("kutittiiiiii", this.state.newActions, this.state.portalList);
    // const portalList = [];
    // if (this.props.portalList) {
    //   this.props.portalList.forEach((prop) => {
    //     var obj = {
    //       title: prop.name,
    //       id: prop.value,
    //       label: prop.label,
    //     };
    //     portalList.push(obj);
    //   });
    // }

    // const BranchList = [];
    // if (this.props.branchList) {
    //   var obj2 = { label: "Select....", value: "", name: "Select Branch" };
    //   BranchList.push(obj2);
    //   this.props.branchList.forEach((prop) => {
    //     var obj = {
    //       label: prop.brname,
    //       value: prop.id,
    //       name: prop.brname,
    //     };
    //     BranchList.push(obj);
    //   });
    // }

    const columns = [
      { field: "taskName", headerName: "TASK", width: 150 },
      {
        field: "level1",
        headerName: (
          <div className="levelContainer">
            <div className="orangeStatus"></div>
            <span>LEVEL 1</span>
          </div>
        ),
        width: 250,
        sortable: false,
        renderCell: (params) => {
          console.log("Rajesh Params", params.row.action[0]);
          var actiondata = params.row.action[0] ? params.row.action[0] : null;
          return (
            <>
              {console.log(
                "getting the action data",
                actiondata,
                this.state.userslist
              )}
              {console.log(
                "getDefaultValue",
                this.getDefaultValue(actiondata, 1, this.state.portalList)
              )}
              <AutoCompleteSelect
                style={{ width: "100%" }}
                placeholder="Select..."
                options={this.state.portalList}
                defaultValue={this.getDefaultValue(
                  actiondata,
                  1,
                  this.state.portalList
                )}
                checkbox={true}
                myTaskType={1}
                myTask={actiondata}
                getOpt={this.getMopt}
                name="indent"
              />
            </>
          );
        },
      },
      {
        field: "level2",
        headerName: (
          <div className="levelContainer">
            <div className="redStatus"></div>
            <span>LEVEL 2</span>
          </div>
        ),
        width: 250,
        sortable: false,
        renderCell: (params) => {
          var actiondata = params.row.action[0] ? params.row.action[0] : null;
          return (
            <AutoCompleteSelect
              style={{ width: "100%" }}
              placeholder="Select..."
              options={this.state.portalList}
              defaultValue={this.getDefaultValue(
                actiondata,
                2,
                this.state.portalList
              )}
              checkbox={true}
              myTaskType={2}
              myTask={actiondata}
              getOpt={this.getMopt}
              name="indent"
            />
          );
        },
      },

      {
        field: "level3",
        headerName: (
          <div className="levelContainer">
            <div className="greenStatus"></div>
            <span>LEVEL 3</span>
          </div>
        ),
        width: 250,
        sortable: false,
        renderCell: (params) => {
          var actiondata = params.row.action[0] ? params.row.action[0] : null;
          return (
            <AutoCompleteSelect
              style={{ width: "100%" }}
              placeholder="Select..."
              options={this.state.portalList}
              defaultValue={this.getDefaultValue(
                actiondata,
                3,
                this.state.portalList
              )}
              checkbox={true}
              myTaskType={3}
              myTask={actiondata}
              getOpt={this.getMopt}
              name="indent"
            />
            // <AutoCompleteSelect style={{width:"100%"}}
            //   placeholder="Select..."
            //   options={this.state.portalList}
            //   defaultValue={this.getDefaultValue(actiondata, 3, this.state.portalList)}
            //   checkbox={true}
            //   myTaskType={3}
            //   myTask={actiondata}
            //   getOpt={this.getMopt}
            //   name="indent"
            // />
          );
        },
      },

      {
        field: "level4",
        headerName: (
          <div className="levelContainer">
            <div className="blueStatus"></div>
            <span>LEVEL 4</span>
          </div>
        ),
        width: 250,
        sortable: false,
        renderCell: (params) => {
          var actiondata = params.row.action[0] ? params.row.action[0] : null;
          return (
            <AutoCompleteSelect
              style={{ width: "100%" }}
              placeholder="Select..."
              options={this.state.portalList}
              defaultValue={this.getDefaultValue(
                actiondata,
                4,
                this.state.portalList
              )}
              checkbox={true}
              myTaskType={4}
              myTask={actiondata}
              getOpt={this.getMopt}
              name="indent"
            />
            // <AutoCompleteSelect style={{width:"100%"}}
            //   placeholder="Select..."
            //   options={this.state.portalList}
            //   defaultValue={this.getDefaultValue(actiondata, 4,this.state.portalList)}
            //   checkbox={true}
            //   myTaskType={4}
            //   myTask={actiondata}
            //   getOpt={this.getMopt}
            //   name="indent"
            // />
          );
        },
      },
      {
        field: "level5",
        headerName: (
          <div className="levelContainer">
            <div className="lightBlueStatus"></div>
            <span>LEVEL 5</span>
          </div>
        ),
        width: 250,
        sortable: false,
        renderCell: (params) => {
          var actiondata = params.row.action[0] ? params.row.action[0] : null;
          return (
            <AutoCompleteSelect
              style={{ width: "100%" }}
              placeholder="Select..."
              options={this.state.portalList}
              defaultValue={this.getDefaultValue(
                actiondata,
                5,
                this.state.portalList
              )}
              checkbox={true}
              myTaskType={5}
              myTask={actiondata}
              getOpt={this.getMopt}
              name="indent"
            />
            // <AutoCompleteSelect style={{width:"100%"}}
            //   placeholder="Select..."
            //   options={this.state.portalListt}
            //   defaultValue={this.getDefaultValue(actiondata, 5, this.state.portalList)}
            //   checkbox={true}
            //   myTaskType={5}
            //   myTask={actiondata}
            //   getOpt={this.getMopt}
            //   name="indent"
            // />
          );
        },
      },
    ];

    return (
      <>
        <form onSubmit={this.handleValidSubmit}>
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.arrayOfData}
                          onSelectChange={this.handleSelectChangeCom}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          lable="Company"
                          selected={this.state.companyId}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          // arrayOfData={this.state.BranchList}
                          arrayOfData={this.state.BranchList}
                          onSelectChange={this.handleSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          lable="Branch"
                          selected={this.state.branchId}
                        />
                      </div>
                    </Box>
                    {this.state.loadHierarchyTable ? (
                      <Box gridColumn="span 4">
                        <div
                          className="consoleFormButtonBlock"
                          style={{
                            padding: "32px 0 0",
                            display: "flex",
                            justifyContent:"center"
                          }}
                        >
                          <Button variant="contained" className="cancelButton">
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            className="saveButton"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
          {this.state.loadHierarchyTable ? (
            <div>
              <div
                className="consoleFormBlock"
                style={{ height: 3715, marginTop: "10px" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      //    gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 12">
                        <div className="approvalHierarcyBlock">
                          {/* <div style={{ height:"100%", width: "100%" }}> */}
                          <DataGrid
                            rows={this.state.newActions}
                            columns={columns}
                            // pageSize={5}
                            // rowsPerPageOptions={[]}
                            style={{ overflow: "hidden", height: 3770 }}
                          />
                          {/* </div> */}
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          ) : null}
        </form>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { stateslist } = state.BranchCreation;
  const { branchList, portalList } = state.UserManagement;
  const { companyslist, clientList } = state.BasicDetails;
  const { userslist } = state.UserManagement;
  return {
    stateslist,
    branchList,
    companyslist,
    portalList,
    clientList,
    userslist,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCompanys,
    getMasterStates,
    branchCreationAdd,
    getbranchlist,
    getportallist,
    getClient,
    addRoletypes,
    addApproval,
    getUsersList,
  })(ApprovelHierarcy)
);
