import {
  LOCATIONS,
  LOCATION_LIST_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  locationlist: [],
};

const location = (state = initialState, action) => {
  switch (action.type) {
    case LOCATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOCATION_LIST_SUCCESSFUL:
      state = {
        ...state,
        locationlist: action.payload.response,
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default location;
