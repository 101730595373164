import React, { Component } from "react";
import { Grid, Box, Paper, Button} from "@mui/material";
import { connect } from "react-redux";
import Tabs from "../../components/TabsNavigation/TabsNavigationPurchaseSettings";
import IndentwithMutipleDepts from "../../Pages/Configuration/IndentwithMutipleDepts";
import PoPrint from "../../Pages/Configuration/PoPrint";
import InwardPoConfig from "./InwardPoConfig";
import PurchaseCurrencyConfig from "./PurchaseCurrencyConfig";
import OpenIndentConfig from "./OpenIndentConfig";

class PurchaseSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHedder: "Basic Details",
      companyname: "",
      setdate:"",
      dtvalue:"",
      companyId:0,
      errors:false,
    };
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  render() {
    return (
      <>           
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tabs 
              printstyles={<IndentwithMutipleDepts configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId} />} 
              mrininvoice={<PoPrint configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId} />} 
              inward={<InwardPoConfig configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId} />}  
              purchseCurrencyConfig={<PurchaseCurrencyConfig configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId}/>}
              openIndent={<OpenIndentConfig configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId}/>}

              />                  
            </Grid>                
          </Grid>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  // const { locationlist, model, rowdata } = state.Location;
  // return { locationlist, model, rowdata };
};

export default connect(mapStatetoProps, {
})(PurchaseSettings);
