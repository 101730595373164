//import { Switch } from "react-router-dom";

import {
 GET_LEAVE_POLICY_LIST, GET_LEAVE_POLICY_LIST_SUCCESSFULL, 
 GET_LEAVE_SELECTION_LIST, GET_LEAVE_SELECTION_LIST_SUCCESSFULL,
 CREATE_LEAVE_POLICY, CREATE_LEAVE_POLICY_SUCCESSFULL, LEAVE_POLICY_VIEW_BY_ID_LIST, 
 LEAVE_POLICY_VIEW_BY_ID_LIST_SUCCESSFULL
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    leavePolicyList: [],
    loading: false,
    leaveSelectionList: [],
    error_msg: null,
    createList: [],
    viewByIdList: []
  };
  
  const LeavePolicyReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_LEAVE_POLICY_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case GET_LEAVE_POLICY_LIST_SUCCESSFULL:
        // console.log("getting the response from action", action.payload.response.data)
        state = {
          ...state,
          leavePolicyList: action.payload.response.data,
          loading: false,
        };
        break;

        case GET_LEAVE_SELECTION_LIST:
          state = {
            ...state,
            loading: true,
          };
          break;
    
        case GET_LEAVE_SELECTION_LIST_SUCCESSFULL:
          // console.log("getting the response from action", action.payload.response.data)
          state = {
            ...state,
            leaveSelectionList: action.payload.response.data,
            loading: false,
          };
          break;   
          
          case CREATE_LEAVE_POLICY:
            state = {
              ...state,
              loading: true,
            };
            break;
      
          case CREATE_LEAVE_POLICY_SUCCESSFULL:
            // console.log("getting the response from action", action.payload.response.data)
            state = {
              ...state,
              createList: action.payload.response.data,
              loading: false,
            };
            break;
          
            case LEAVE_POLICY_VIEW_BY_ID_LIST:
              state = {
                ...state,
                loading: true,
              };
              break;
        
            case LEAVE_POLICY_VIEW_BY_ID_LIST_SUCCESSFULL:
              console.log("getting the response from actionsssss", action)
              state = {
                ...state,
                viewByIdList: action.payload.response.data,
                loading: false,
              };
              break;
      
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default LeavePolicyReducer;
  