import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER, CHECK_TOKEN, RESET_PASSWORD, FIRST_COMPANY } from "./actionTypes";
import { apiError, loginUserSuccessful, logoutUserSuccess, checkTokenUserSuccessful } from "./actions";
import { getCompanys } from "../../../store/Company/BasicDetails/actions";
import {getFistCompanys, firstcompanyListSuccessful} from "../../../store/auth/login/actions"

// AUTH related methods
import { postLogin, getToken, postResetPwd } from "../../../helpers/fackBackend_Helper";
import swal from "sweetalert";
import { hideSpinner } from "../../../helpers/Consts";
// import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import cons, { serverApi } from "../../../helpers/Consts";
import {getList} from "../../../helpers/Server_Helper"

//Initilize firebase
// const fireBaseBackend = getFirebaseBackend();

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: {url, user, history } }) {
  try {
    const response = yield call(postLogin, url, {
      userName: user.username,
      password: user.password,
      reqSource: user.reqSource,
    });
    // console.log(response);
    
    if (response.loginStatus) {
        localStorage.setItem("authUser", JSON.stringify(response));
        localStorage.setItem("dashboard",1);
        // console.log(response.userId);
        hideSpinner();
        const cmpdata = {
          cipher: "",
          orgId: response.orgId,
        };
        var comUrl = serverApi.COMPANYMASTER_LIST_BY_ORG_ID;
        yield put(getFistCompanys(comUrl,cmpdata,history));
        
    }
    yield put(loginUserSuccessful(response));

    if(user.password == "welcome"){
        history.push("/CreatePassword");
    }else{
        history.push("/dashboard");
    }    
  } catch (error) {
    yield put(apiError(error));
  }
}

function* checkToken({ payload: {url, user, history } }) {
  try {
    const response = yield call(getToken, url, {
      username: user.username,
      password: user.password,
      grant_type: "password",
    });

    localStorage.setItem("myToken", JSON.stringify(response));
    localStorage.setItem("access_token", response.access_token);
    localStorage.setItem("token_type", response.token_type);
    
    yield put(checkTokenUserSuccessful(response));
    
    // history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
    
  }
}



function* logoutUser({ payload: { history } }) {
  try {
    
    localStorage.removeItem("authUser");
    localStorage.removeItem("myToken");
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_type");
    localStorage.removeItem("dashboard",0);
    localStorage.removeItem("company",0);
    localStorage.removeItem("config",0);
    localStorage.removeItem("module",0);
    localStorage.removeItem("usermange",0);
    localStorage.removeItem("billing",0);
    // yield put(logoutUserSuccess(response));
    // console.log("Logout",logoutUserSuccess(response))

    history.push("/login");
  } catch (error) {
    // yield put(apiError(error));
  }
}

//{"id":"2","oldPassword":"11","newPassword":"1","confirmPassword":"1","modifiedBy":"support","modifiedOn":1648751400000}

function* resetPassword({ payload: {url, user, history } }) {
  try {
    const response = yield call(postResetPwd, url, {
      userId: user.userId,
      forgotPassword: false,
      newPassword: user.password,
      // confirmPassword: user.password,
      // modifiedBy: user.reqSource,
      // modifiedOn: "",
    });
    // console.log(response);      
    // history.push("/dashboard");     
    history.push("/logout");     
  } catch (error) {
    yield put(apiError(error));
  }
}

function* firstCompanySaga({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if(response){
      if(response.data){
        localStorage.setItem("financialDate",response.data[0].financialDate)
        localStorage.setItem("yourUrl",response.data[0].yourUrl)
        localStorage.setItem("companyIdconfig", response.data[0].companyId);
        localStorage.setItem("companyupdateId", response.data[0].companyId);
        localStorage.setItem("companyName",response.data[0].companyCode)
      }
    }
    // yield put(firstcompanyListSuccessful({ response }));
  } catch (error) {
    // console.log(error);
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}
export function* watchUserToken() {
  yield takeEvery(CHECK_TOKEN, checkToken);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* watchFirstCompany() {
  yield takeEvery(FIRST_COMPANY, firstCompanySaga)
}

function* loginSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout), fork(watchUserToken), fork(watchResetPassword), fork(watchFirstCompany)]);
}

export default loginSaga;
