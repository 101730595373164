import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import {
  CONFIGURATION, CONFIGURATION_DATA, CONFIGURATION_UPDATE, CURRENCY_CONFIGURATION, INWARD_PO_CONFIGURATION,
} from "./actionTypes";
import {
  ConfigurationSuccessful,
  getConfigurationDataSuccessful,
  getConfiguration,
  getCurrencyConfigurationSuccessful,
  getInwardPOconfigurationSuccessful
} from "./actions";
import {
  postAdd,getListurl,
  
} from "../../helpers/Server_Helper";
import  { serverApi } from "../../helpers/Consts";
import swal from "sweetalert";

function* getConfig({ payload: {url, history, companyId} }) {
  try {
    const response = yield call(getListurl, url,{}); 
      yield put(ConfigurationSuccessful({ response }));
      // localStorage.setItem("companyIdconfig",companyId);
      // localStorage.setItem("configdata", JSON.stringify(response.data.configList));
      // history.push('/Configuration');  
  } catch (error) {
    console.log(error);
  }
}
function* UpdateConfig({ payload: { url, data, msg, history  } }) {  
  try { 
      const response = yield call(postAdd, url, data);      
      console.log("nagesh resp Date", response);
      if (response.data.status == true) {       
          // yield put(ConfigurationSuccessful(response.data));
          swal(msg, {
            icon: "success",
          });          
          // if(response.status){
          //   history.push('/Configuration');   
          // }else{
          //   history.push('/Configuration');   
          // }     
      } else {
        swal("Sorry! Unable to ad Basic Details. Please try again!", {
          icon: "error",
        });
      }      
    
  } catch (error) {
    console.log(error);
    swal("Sorry! Permission Denied!", {
      icon: "error",
    });
    //yield put(apiError(error));
  }
}
// New saga for Date allowable
function* getNewConfigData({ payload: { url, data, msg, history } }) {
  try {
    const response = yield call(postAdd, url,data);
    yield put(getConfigurationDataSuccessful({ response }));
    swal(msg,{ icon: "success"})
    if(response){
      let url = serverApi.GET_CONFIGURATION_BY_COMPANY +localStorage.getItem('companyIdconfig')
      yield put(getConfiguration( url))
   
    }
  } catch (error) {
    console.log(error);
  }
}
// currency configuration 
function* getCurrencyConfigurationData({ payload: { url, data, msg, history } }) {
  try {
    const response = yield call(postAdd, url,data);
    yield put(getCurrencyConfigurationSuccessful({ response }));
    swal(msg,{ icon: "success"})
    if(response){
      let url = serverApi.GET_CONFIGURATION_BY_COMPANY +localStorage.getItem('companyIdconfig')
      yield put(getConfiguration( url))
    }
  } catch (error) {
    console.log(error);
  }
}
// Inward Po config
function* getInwardPOConfigurationData({ payload: { url, data, msg, history } }) {
  try {
    const response = yield call(postAdd, url,data);
    yield put(getInwardPOconfigurationSuccessful({ response }));
    swal(msg,{ icon: "success"})
    if(response){
      let url = serverApi.GET_CONFIGURATION_BY_COMPANY +localStorage.getItem('companyIdconfig')
      yield put(getConfiguration( url))
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchgetConfiguration() {
  yield takeEvery(CONFIGURATION, getConfig);
}

export function* watchUpdateConfig() {
  yield takeEvery(CONFIGURATION_UPDATE, UpdateConfig);
}
export function* watchgetNewConfigData() {
  yield takeEvery(CONFIGURATION_DATA, getNewConfigData);
}
export function* watchsetCurrencyNewConfig(){
  yield takeEvery(CURRENCY_CONFIGURATION,getCurrencyConfigurationData)
}
export function* watchsetInwardPoConfig(){
  yield takeEvery(INWARD_PO_CONFIGURATION,getInwardPOConfigurationData)
}
function* ConfigurationSaga() {
  yield all([
    fork(watchgetConfiguration),
    fork(watchUpdateConfig),
    fork(watchgetNewConfigData),
    fork(watchsetCurrencyNewConfig),
    fork(watchsetInwardPoConfig)
  ]);
}

export default ConfigurationSaga;
