import { API_ERRORS, CREATE_SUPPLIER_TYPES, CREATE_SUPPLIER_TYPES_SUCCESSFULL, GET_SUPPLIER_TYPE_MASTER_LIST, GET_SUPPLIER_TYPE_MASTER_LIST_SUCCESSFULL, UPDATE_SUPPLIER_TYPES, UPDATE_SUPPLIER_TYPES_SUCCESSFULL } from "./actionsTypes";
const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    SupplierTypesList: [],
    CreateSupplierTypes: [],
    UpdateSupplierTypes: [],
};

const SupplierTypesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUPPLIER_TYPE_MASTER_LIST:
            state = {
                ...state,
                loading: true,
            };
            break;
        case GET_SUPPLIER_TYPE_MASTER_LIST_SUCCESSFULL:
            state = {
                ...state,
                SupplierTypesList: action.payload.response,
                loading: true,
            };
            break;

        case CREATE_SUPPLIER_TYPES:
            state = {
                ...state,
                loading: true,
            };
            break;

        case CREATE_SUPPLIER_TYPES_SUCCESSFULL:
            state = {
                ...state,
                CreateSupplierTypes: action.payload.response,
                loading: true,
            };
            break;

        case UPDATE_SUPPLIER_TYPES:
            state = {
                ...state,
                loading: true,
            };
            break;

        case UPDATE_SUPPLIER_TYPES_SUCCESSFULL:
            state = {
                ...state,
                UpdateSupplierTypes: action.payload.response,
                loading: true,
            };
            break;

        case API_ERRORS:
            state = {
                ...state,
                loading: false,
                error_msg: action.payload,
            };
            break;

        default:
            state = {
                ...state
            };
            break;


    }
    return state;
}
export default SupplierTypesReducer;