import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";
import Alerts from "../../components/notificationAlerts/Alerts"
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";
import RadioButton from "../../components/RadioButton/RadioButton";
import {Link} from "react-router-dom"

class CustomerInternalMap extends Component {
  constructor(props) {
    super(props);
    this.state = {selectedValue: "",
    notmsg: "",
    severity: "error",
    alert:'none',
    custmap:0,
    custyes:false,
    custno:false,
    configdata: [],
    companyId:localStorage.getItem("companyIdconfig")
  };  


}

onClickRadioButton = (selectedValue) =>{
  var custmap = 0;
    var custyes=false, custno=false;
    if(selectedValue==='Yes'){
      custmap = 1;
      custyes=true;      
    }else if(selectedValue==='No'){
      custmap = 2;
      custno=true;     
    }
    this.setState({
      selectedValue: selectedValue,
      custmap:custmap,
      custyes:custyes,
      custno:custno
    })
  
}
getAutoIdFromList=(configParameter)=>{
  var list=this.state.configdata;
  var id=undefined;
  if(list.length>0){
      list.forEach(prop=>{
          if(prop.configParameter===configParameter){
              id= prop.autoId;

          }
      })
  }
  return id;
};
handleConfig=() => {
  var printstyle = this.state.selectedValue;
  if(!printstyle){
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry! Please Select Customer Internal Mapping",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }else if(!this.state.companyId){
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry! Please Select Company",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }else{
    var data ={
      "companyId": this.state.companyId,
      "configList": [
          {
              "autoId": this.getAutoIdFromList(configNames.CUSTOMER_INTERNAL_MAPPING),
              "configParameter": configNames.CUSTOMER_INTERNAL_MAPPING,
              "isActive": 1,
              "configValue":this.state.custmap
          },
          
      ]
  };
  if(this.state.companyId){
    var msg = "PO Print Updated Successfully.";      
      this.props.setConfiguration(serverApi.UPDATE_CONFIGURATION_NEW, data, msg, this.props.history)
  } else {
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry!!! Please Select Company",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }
  }
  
  

}

componentDidMount=()=> {
  var custmap = 0;
  var custyes=false, custno=false;
  //var configdata = JSON.parse(localStorage.getItem("configdata"));
  var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
  if(configdata){
    configdata.forEach(prop => {
        if(prop.configParameter===configNames.CUSTOMER_INTERNAL_MAPPING){
          if(prop.configValue==='1'){
            custmap = 1;
            custyes=true;      
          }else if(prop.configValue==='2'){
            custmap = 2;
            custno=true;     
          }
          this.setState({
            custmap:custmap,
            custyes:custyes,
            custno:custno,
            configdata: configdata,
            companyId:localStorage.getItem("companyIdconfig")
          })
        }
    })      
  }
}

componentWillReceiveProps(props) {
  var custmap = 0;
  var custyes=false, custno=false;
  //var configdata = JSON.parse(localStorage.getItem("configdata"));
  //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
  var configdata = props.configdata
  if(configdata){
    configdata.forEach(prop => {
        if(prop.configParameter===configNames.CUSTOMER_INTERNAL_MAPPING){
          if(prop.configValue==='1'){
            custmap = 1;
            custyes=true;      
          }else if(prop.configValue==='2'){
            custmap = 2;
            custno=true;     
          }
          this.setState({
            custmap:custmap,
            custyes:custyes,
            custno:custno,
            configdata: configdata,
            companyId:localStorage.getItem("companyIdconfig")
          })
        }
    })      
  }
}

  render() {

    return (
      <>
        <Alerts alert = {this.state.alert} severity={this.state.severity} notmsg={this.state.notmsg}/>
          <div className="consoleFormContainer">
            <h5>Customer Internal Mapping</h5>
            <div className="consoleFormBlock">
              <Grid container spacing={2}>

                <Grid item xs={10}>
                  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                    <Grid item xs={3}>
                      <div className="">
                        <RadioButton labelText="Yes" value="yes" groupname="internelMap"  checkednew={this.state.custyes} onChange={this.onClickRadioButton}/>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="">
                        <RadioButton labelText="No" value="no" groupname="internelMap" checkednew={this.state.custno} onChange={this.onClickRadioButton}/>
                      </div>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>


          <div className="consoleFormButtonBlock">
            <Link to="/invoice">
              <Button variant="contained" className="cancelButton">
                Cancel
              </Button>
            </Link>
            <Button variant="contained" type="button" className="saveButton" onClick={this.handleConfig}>
              Save
            </Button>
          </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default connect(mapStatetoProps, {
  setConfiguration
})(CustomerInternalMap);
