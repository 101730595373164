import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../Pages/Authentication/Login";
import Logout from "../Pages/Authentication/Logout";

// Dashboard
import Dashboard from "../Pages/Dashboard/Dashboard";
import Test from "../Pages/Test/Test";
import ResetPassword from "../Pages/Authentication/ResetPassword";
import CreatePassword from "../Pages/Authentication/CreatePassword";
import CompanyList from "../Pages/CompanyCreation/CompanyList";
import CompanyCreation from "../Pages/CompanyCreation/CompanyCreation";
import BasicDetails from "../Pages/CompanyCreation/BasicDetails";
import UserManagement from "../Pages/UserManagement/UserManagement";
import Configuration from "../Pages/Configuration/Configuration";
import Billing from "../Pages/Billing/Billing";
import ModuleSelection from "../Pages/ModuleSelection/ModuleSelection";
import UpdateRole from "../Pages/UserManagement/UpdateRole";
import UsersList from "../Pages/UserManagement/UsersList";
import Testnew from "../Pages/Test/Testnew";
import OfficialDetails from "../Pages/CompanyCreation/OfficialDetails";
import BranchList from "../Pages/CompanyCreation/BranchList";
import CreateBranch from "../Pages/CompanyCreation/BranchCreation";
import Invoice from "../Pages/Configuration/Invoice";
import MRPrint from "../Pages/Configuration/MRPrint";
import DateAllowable from "../Pages/Configuration/DateAllowable";
import SalesSettings from "../Pages/Configuration/SalesSettings";
import configure from "../Pages/Configuration/Configur";
import PurchaseSettings from "../Pages/Configuration/PurchaseSettings";
import InwardPoConfig from "../Pages/Configuration/InwardPoConfig";
import OpenIndentConfig from "../Pages/Configuration/OpenIndentConfig"
import GateEntry from "../Pages/Configuration/GateEntry";
import CustomerInternalMap from "../Pages/Configuration/CustomerInternalMap";
import Structure from "../Pages/Configuration/Structure";
import BillingDetails from "../Pages/Billing/BillingDetails";
import ModuleSelect from "../Pages/ModuleSelection/ModuleSelect";
import RoleManagement from "../Pages/UserManagement/RoleManagement";
import ApprovalHierarcy from "../Pages/UserManagement/ApprovelHierarcy";
import CreateRole from "../Pages/UserManagement/CreateRole";
import UserCreate from "../Pages/UserManagement/UserCreate";
import TEST2 from "../Pages/UserManagement/test";
import Google from "../Pages/CompanyCreation/Test";
import CreateLeaveType from "../Pages/MasterData/LeaveTypes/CreateLeaveTypes";
import LeaveTypes from "../Pages/MasterData/LeaveTypes/index";
import PayschemeCreation from "../Pages/MasterData/PayschemeCreation/index";
import DepartmentMaster from "../Pages/MasterData/DepartmentMaster/index";
import CreateDepartmentMaster from "../Pages/MasterData/DepartmentMaster/CreateDepartmentMaster";
import CreatePayCreation from "../Pages/MasterData/PayschemeCreation/CreatePayCreation";
import LeavePolicyList from "../Pages/MasterData/LeavePolicy";
import CreateLeavePolicy from "../Pages/MasterData/LeavePolicy/CreateLeavePolicies";
import payschemeParameters from "../Pages/MasterData/PayschemeParameters/index";
import CreatePayschemeParameters from "../Pages/MasterData/PayschemeParameters/CreatePayschemePara";
import PayschemeParameters from "../Pages/MasterData/PayschemeParameters/index";
import PaymentConfirmation from "../Pages/PaymentConfirmation/PaymentConfirmation";
import ApprovalLazyLoading from "../Pages/UserManagement/ApprovalLazyLoading";
import TaxMaster from "../Pages/MasterData/TaxMaster/index";
import CreateTaxMaster from "../Pages/MasterData/TaxMaster/TaxMaster";
import CreateBankAccounts from"../Pages/MasterData/BankAccount/CreateBankAccount";
import BankAccount from "../Pages/MasterData/BankAccount";
import CreateSupplierTypes from "../Pages/MasterData/SupplierTypes/CreateSupplierTypes";
import SupplierTypes from "../Pages/MasterData/SupplierTypes";
const authProtectedRoutes = [
  // {path: "/dashboard", component: Dashboard },


  { path: "/basicdetails", component: BasicDetails },
  { path: "/officialDetails", component: OfficialDetails },
  { path: "/branchlist", component: BranchList },
  { path: "/createbranch", component: CreateBranch },
  { path: "/invoice", component: Invoice },
  { path: "/mrprint", component: MRPrint },
  { path: "/date_allowable", component: DateAllowable },
  { path: "/sales_settings", component: SalesSettings },
  { path: "/configure", component: configure },
  { path: "/purchase_settings", component: PurchaseSettings },
  {path: "/inward_Poconfig", component:InwardPoConfig},
  {path:"/openIndentConfig",component:OpenIndentConfig},
  { path: "/gate_entry", component: GateEntry },
  { path: "/customer_internal_note", component: CustomerInternalMap },
  { path: "/structure", component: Structure },
  { path: "/role_management", component: RoleManagement },
  // { path: "/approve_hierachy", component: ApprovalHierarcy },
  { path: "/approve_hierachy", component: ApprovalLazyLoading },
  { path: "/create_role", component: CreateRole },
  {path: "/create_leave_types", component: CreateLeaveType},
  { path: "/create_user", component: UserCreate },
  {path: "/googleChart", component: Google},
  { path: "/testing", component: TEST2 },
  { path: "/resetpassword", component: ResetPassword },
  { path: "/CompanyList", component: CompanyList },
  { path: "/CompanyCreation", component: CompanyCreation },
  { path: "/UserManagement", component: UsersList },
  { path: "/Configuration", component: Configuration },
  { path: "/Billing", component: BillingDetails },
  { path: "/ModuleSelection", component: ModuleSelect },
  { path: "/UpdateRole", component: UpdateRole },
  { path: "/test", component: Test },
  { path: "/testnew", component: Testnew },
  {path: "/leaveTypes", component: LeaveTypes },
  { path: "/department_master", component: DepartmentMaster },
  { path: "/create_department_master", component: CreateDepartmentMaster },
  {path: "/payscheme", component: PayschemeCreation},
  {path: "/create_payScheme_creation", component: CreatePayCreation},
  {path: "/leavePolicies", component: LeavePolicyList},
  {path: "/create_leave_policy", component: CreateLeavePolicy},
  { path: "/payscheme_parameters", component: PayschemeParameters },
  { path: "/create_payscheme_parameters", component: CreatePayschemeParameters},
  { path: "/payment_confirmation", component: PaymentConfirmation},
  { path: "/tax_master", component: TaxMaster},
  { path: "/create_tax_master", component: CreateTaxMaster},
  {path:"/bank_account_master",component: BankAccount},
  {path:"/create_bank_account_master",component:CreateBankAccounts},
  {path:"/supplier_types",component:SupplierTypes},
  {path:"/create_supplier_types",component:CreateSupplierTypes}
  
  
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const authProtectedRoutesWithOutInnerNavigation = [
  { path: "/dashboard", component: Dashboard },
  { path: "/createpassword", component: CreatePassword },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/", component: Login },
  { path: "/login", component: Login },
];

export {
  authProtectedRoutes,
  publicRoutes,
  authProtectedRoutesWithOutInnerNavigation,
};
