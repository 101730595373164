import React from "react";
import PropTypes from "prop-types";

import location_img from "../../assets/images/location.png";
import facial_img from "../../assets/images/facial.png";
import whatsaap_img from "../../assets/images/whatsaap.png";
import tally_img from "../../assets/images/tally.png";
import biometric_img from "../../assets/images/biometric.png";
import payment_img from "../../assets/images/payment.png";



const Checkbox = ({ label, selected, styleClass, onChange, name, image, iconSizeClass, disabled }) => {
  const handleChange = (event) => {
    const { name, checked, disabled } = event.target;
    onChange(name, checked, disabled);
  };
  if(image==='location_img'){
    image = location_img
  }
  if(image==='facial_img'){
    image = facial_img
  }
  if(image==='whatsaap_img'){
    image = whatsaap_img
  }
  if(image==='tally_img'){
    image = tally_img
  }
  if(image==='biometric_img'){
    image = biometric_img
  }
  if(image==='payment_img'){
    image = payment_img
  }
  return (
    <div className="checkboxBlock">
      
        <input
          type="checkbox"
          className="checkbox"
          value={selected}
          checked={selected}
          defaultChecked={selected}
          onChange={handleChange}
          name={name}
          disabled={disabled ? true : false}
        />
        {
          image ? <div className="iconBg">
          <img src={image} alt="" className={iconSizeClass} />
        </div> : ""
        }
        
        {label}
        
    </div>
  );
};

Checkbox.propTypes = {
  styleClass: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  styleClass: "regular-checkbox",
};

export default Checkbox;
