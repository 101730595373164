import React from "react";

function Button(props) {
  const { className, handleClick, type, label, icon, disabled } = props;
  const customClass = `button ${className} ${disabled ? "disabled" : ""}`;

  return (
    <button
      className={customClass}
      onClick={!disabled ? handleClick : null}
      type={type}
      disabled={disabled} // Native disabled prop to prevent clicking
    >
      {icon && <img src={icon} alt="icon" />}
      {label}
    </button>
  );
}

export default Button;
