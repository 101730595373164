import {
  BRANCH_API_ERRORS,
  BRANCHDETAILS_ADD,
  BRANCHDETAILS_ADD_SUCCESSFUL,
  GET_STATES_LIST,
  STATES_LIST_SUCCESS,
  CLEAR_FORM_BRANCH,
  GST_VERIFICATION,GST_VERIFICATION_SUCCESSFULL
} from "./actionTypes"; 

export const branchCreationAdd = (url, data, updateView, history) => {
  console.log(url);
  return {
    type: BRANCHDETAILS_ADD,
    payload: { url, data, updateView, history },
  };
};

export const branchCreationAddSuccessful = (response) => {
  console.log(response);
  return {
    type: BRANCHDETAILS_ADD_SUCCESSFUL,
    payload: response,
  };
};

export const branchapiErrors = (error) => {
  return {
    type: BRANCH_API_ERRORS, 
    payload: error,
  };
};

export const getMasterStates = (url) => {
  return {
    type: GET_STATES_LIST,
    payload: { url },
  };
}; 

export const stateslist = (states) => {
  return {
    type: STATES_LIST_SUCCESS,
    payload: states,
  };
};

export const clearFormBranch = () => {
  return {
    type: CLEAR_FORM_BRANCH,
  };
};

export const getGstVerification = (url, history) => {
  return {
    type: GST_VERIFICATION,
    payload: { url, history },
  };
};

export const gstVerificationSuccessful = (data) => {
  return {
    type: GST_VERIFICATION_SUCCESSFULL,
    payload: data,
  };
};
