import React, { Component } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import swal from "sweetalert";
import 'font-awesome/css/font-awesome.min.css';
// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// actions
import { checkLogin, apiError, checkToken } from '../../store/actions';


import { serverApi, showSpinner, hideSpinner } from "../../helpers/Consts";

// availity-reactstrap-validation
// import {AvForm, AvField} from 'availity-reactstrap-validation';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: 'su', password: '123456',loading: false };
    this.onHandleLogin = this.onHandleLogin.bind(this);
  }
  // http://devapi.vowerp.com/security-api/oauth/token

  onHandleLogin = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    showSpinner();
    this.props.apiError('');
    let username = event.target.email.value;
    let password = event.target.password.value;
    let reqSource = 0;

    const data = {
      username, password, reqSource
    };

    

    this.setState({
      username: username,
      password: password
    })
    /* Authentication Token generations */
    this.props.checkToken(serverApi.OAUTH_LOGIN, data, this.props.history);
    
    
    /* Login  */
    
   
  }

  componentWillReceiveProps(nextProps){
    // console.log("getting the data after login in again", nextProps)
    if(nextProps.loginError){
      this.setState({ loading: false });
      hideSpinner();
      swal("Enter correct username or password", { icon: "error" });
    }else{
      
      let username = this.state.username;
      let password = this.state.password;
      let reqSource = 0;
      const data = {
        username, password, reqSource
      };     
  
      this.props.checkLogin(serverApi.LOGIN, data, nextProps.history);
     }
    
  }

  componentDidMount() {
    this.props.apiError('');
  }





  render() {
    const { loading } = this.state; 
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="loginPatternOne"
        >
          <CssBaseline />
          <Grid item xs={false} sm={4} md={6} className="loginBackground">
            <Box className="loginLeftContent">
              <h2>Nice to meet you</h2>
              <h1>WELCOME</h1>
              <p>
                We have customized this product according to your needs and can't
                wait to see you using this product. This will simplify working of
                your business
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
            className="loginContainer"
          >
            {/* <form onValidSubmit={this.handleSubmit}> */}
              <Box
                className="loginBlock"
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={VowLogo} alt="" />
                <Typography component="h1">Login</Typography>
                <Typography component="p">
                  Welcome to VOW ERP Solutions. Start your journey with simple user
                  ID login.
                </Typography>
                <form onSubmit={this.onHandleLogin}>
                <Box
                  // component="form"
                  noValidate
                  // onSubmit={this.handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    // label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    className="loginInput"
                    placeholder="User ID"                    
                    validate={{required: true}}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    // label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className="loginInput"
                    placeholder="Password"
                  />
                  <Grid container className="keepSignedAndChechbox">
                    <Grid item>
                      <FormControlLabel
                        control={<Checkbox value="remember" />}
                        label="Keep me signed in"
                        className="keepSigned"
                      />
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2" className="alreadyMember">
                        {"Forgot Password?"}
                      </Link>
                    </Grid>
                  </Grid>
                  {/* <Grid style={{textAlign:"center", color:"red"}}>{this.props.loginError ? this.props.loginError : ""}</Grid> */}
                  {/* <Button
                    color="primary"
                    className="loginButton"       
                    type="sumbit"            
                  >
                    Log In
                  </Button> */}
                  <button className="loginButton" color="primary" type='submit' disabled={loading} style={{cursor:"pointer"}}>
                    {loading && (
                      <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
                      
                    )}
                    {loading && <span>Loading Data from Server...</span>}
                    {!loading && <span>Log In </span>}
                  </button>
                </Box>
                </form>
              </Box>
            {/* </form> */}


          </Grid>

        </Grid>
      </ThemeProvider>
    );
  }
}
const theme = createTheme();
const mapStatetoProps = (state) => {
  const { loginError, resp } = state.Login;
  return { loginError, resp };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError, checkToken })(Login)
);
