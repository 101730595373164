import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from "@mui/material/TextField";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import Checkbox from "../../components/CheckBox/Checkbox";
import Checkbox from "@mui/material/Checkbox";
import { options } from "../chart/googleChart";
import swal from "sweetalert";

export default class AutoCompleteSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      mdef: []
      // mdef: []
    };
    this.onTagsChange = this.onTagsChange.bind(this);
  }

  onTagsChange = (event, values) => {
    var tagsIds = null;
    var mtags = [];
    let i = 0;
    if (values.length <= 3) {
      values.map((prop, key) => {
        if (i == 0) {
          tagsIds = prop.id;
        } else {
          tagsIds = tagsIds + "," + prop.id;
        }
        if (mtags.length <= 2) {
          mtags.push(prop.id);
          i++;
        } 

      });
    }else{
      swal("dont add more than 3")
      values.pop()
    }

    this.setState(
      {
        tags: values,
      },
      () => {
        // This will output an array of objects
        // given by Autocompelte options property.
      }
    );
    var mr = {
      myTaskType: this.props.myTaskType,
      myTask: this.props.myTask,
    }
    this.props.getOpt(mtags, this.props.name, mr);
    // this.props.onSelectChange(tagsIds, values[0].title, this.props.name);
  };




  componentWillReceiveProps(props) {
    if (props) {
      if (props.defaultValue) {
        var list = []
        props.defaultValue.map((arr) => {
          props.options.map((p, i) => {
            if (arr == p.id)
              list.push({ title: p.title, id: p.id, label: p.label });
          });
        });
        this.setState({
          mdef: list
        });
      }
    }
  }



  render() {
    var list = [];
    if (this.props.options) {
      if (this.props.defaultValue) {
        this.props.defaultValue.map((arr) => {
          this.props.options.map((p, i) => {
            if (arr == p.id)
            list.push({ title: p.title, id: p.id, label: p.label });
          });
        });
        // this.setState({\\
        //   mdef: list
        // });
      }
    }

    // this is used for limiting the incoming record for fast loading the data
    // of the dropdown

    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      // stringify: (option) => console.log("getting the option data of 200 records", option),
      limit: 100
    });
    // console.log("getting default value filterOptions", filterOptions)

    return (
      <div className="autoCompleteDropdownContainer" style={{ width: "100%" }}>
        <label>{this.props.label}</label>
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={this.props.options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.title}
          filterOptions={filterOptions}
          defaultValue={this.props.defaultValue}
          onChange={this.onTagsChange}
          limitTags={2}
          popupIcon={
            <KeyboardArrowDownIcon sx={{ color: "hsl(0, 0%, 80%)" }} />
          }
          renderOption={(props, option, { selected }) => (
            ///className="isDisabled" 
            <li {...props} >
              {this.props.checkbox ? <Checkbox checked={selected} /> : ""}
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select..." />
          )}
          disableClearable
        />
        {/* <Autocomplete
          multiple
          limitTags={1}
          id="multiple-limit-tags"
          options={top100Films}
          getOptionLabel={(option) => option.title}
          defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
          renderInput={(params) => (
            <TextField {...params} placeholder="Favorites" />
          )}
          sx={{ width: "500px" }}
        /> */}
      </div>
    );
  }
}

const top100Films = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
  { title: "12 Angry Men", year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: "Pulp Fiction", year: 1994 },
  { title: "The Lord of the Rings", year: 2003 },
  { title: "The Good, the Bad and the Ugly", year: 1966 },
  { title: "Fight Club", year: 1999 },
  { title: "The Lord of the Rings", year: 2001 },
  { title: "Star Wars: Episode V", year: 1980 },
  { title: "Forrest Gump", year: 1994 },
  { title: "Inception", year: 2010 },
];
