import validatorvals from 'validator';

export function required(value, message) {
    if (!value || !value.toString().trim().length) {
        return { error: true, message };
    }
    return false;
}
export function special(value, message) {
    const length = value ? value.toString().length : 0;
    if (length > 0) {
       if(!validatorvals.isAlphanumeric(value)){
        return { error: true, message };    
       }       
    }
    return false;
}
export function email(value, message) {
    const length = value ? value.toString().length : 0;
    if (length > 0) {
       if(!validatorvals.isEmail(value)){
        return { error: true, message };    
       }       
    }
    return false;
}
export function number(value, message) {
    const length = value ? value.toString().length : 0;
    if (length > 0) {        
        if(length > 10){
            var message = "Invalid Phone Number";
            return { error: true, message };  
        }
        const result = validatorvals.isNumeric(value);
        if (!result) {                
            return { error: true, message };  
        }            
    }
    if(length==0){
        return true;
    }else{
        return false;
    }
    
}