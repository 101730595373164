import React, { useState } from "react";
import PropTypes from "prop-types";

import { validateInput, validatorLenght, validatorRequired } from "../../Utilities/Validator";

const TextFieldPanCard = ({
  value,
  label,
  placeholder,
  star,
  length,
  validators,  
  validatorlenght,
  validatorsrequired,
  type,
  onChange,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    let errorlen = false;
    let error = false;

    if (value === '') {
      setError(validatorRequired(validatorsrequired, value));
      onChange(value.toUpperCase());
    } else {

      if ((validatorlenght === 'undefined') && (validators === 'undefined')) {
        onChange(value.toUpperCase());
      } else if ((validatorlenght) && (validators)) {
        error = validateInput(validators, value, event);
        errorlen = validatorLenght(validatorlenght, value, length);
        if ((error === false) && (errorlen === false)) {
          onChange(value.toUpperCase());
        } else {
          setError(error + errorlen);
        }

      } else if ((validators) || (validatorlenght === 'undefined')) {
        
        error = validateInput(validators, value, event);
        if (error === false) {
          onChange(value.toUpperCase());
        } else {
          setError(error);
        }
      } else if ((validators === 'undefined') && (validatorlenght)) {
        errorlen = validatorLenght(validatorlenght, value, length);
        if (errorlen === false) {
          onChange(value.toUpperCase());
        } else {
          setError(errorlen);
        }
      }
    }


  };

  return (
    <div className="TextFieldBlock">
      {label && <label htmlFor="app-input-field">{label}<label className="starcolor">{star}</label></label>}
      <br />

      {type === "textarea" ? (
        <textarea
          className="TextField"
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          onChange={handleChange}
        />
      ) : (
        <input
          type={type}
          value={value}
          className="TextField"
          placeholder={placeholder}
          onChange={handleChange}
        />
      )}
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

TextFieldPanCard.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextFieldPanCard.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
};

export default TextFieldPanCard;
