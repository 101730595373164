import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import Alerts from "../../components/notificationAlerts/Alerts";
import RadioButton from "../../components/RadioButton/RadioButton";
import Checkbox from "../../components/CheckBox/Checkbox";
import { setConfiguration } from "../../store/Configuration/actions";
import { Link } from "react-router-dom"

class Structure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "", 
      selectedInvValue: "",
      notmsg: "",
      severity: "error",
      alert: 'none',
      jute: false,
      store: false,
      agent: false,
      vendor: false,
      sogeneral: false,
      rowmaterials: false,
      jutesalegoods: false,
      brosales: false,
      brojute: false,
      //brojute:false,
      txnotax: false,
      txgst: false,
      txvat: false,
      sendingMR: 0,
      legacy: false,
      corparate: false,
      ksk: false,
      invModels: 0,
      ingeneral: false,
      inrawgoods: false,
      fingoods: false,
      configdata: [],
      HSN_Yes: true,
      HSN_No: false
    };

  }
  onClickRadioButton = (selectedValue) => {
    var sendingMR = 0;
    var legacy = false, corparate = false, ksk = false;
    if (selectedValue === 'Legacy') {
      sendingMR = 1;
      legacy = true;
    } else if (selectedValue === 'Corparate') {
      sendingMR = 2;
      corparate = true;
    } else if (selectedValue === 'KSK') {
      sendingMR = 3;
      ksk = true;
    }
    this.setState({
      selectedValue: selectedValue,
      sendingMR: sendingMR,
      legacy: legacy,
      corparate: corparate,
      ksk: ksk,
    })
  }
  onClickHSNRadioButton = (selectedValue) => {
    var HSNType = false, Yes = false, No = false;
    if (selectedValue === "Yes") {
      Yes = true
    }
    if (selectedValue === "No") {
      No = true
    }
    this.setState({
      HSNType: HSNType,
      HSN_Yes: Yes,
      HSN_No: No,
    })
  }

  onClickInvRadioButton = (selectedInvValue) => {
    var invModels = 0;
    var ingeneral = false, inrawgoods = false, fingoods = false;
    if (selectedInvValue === 'General') {
      invModels = 1;
      ingeneral = true;
    } else if (selectedInvValue === 'Raw Goods') {
      invModels = 2;
      inrawgoods = true;
    } else if (selectedInvValue === 'Finished Goods') {
      invModels = 3;
      fingoods = true;
    }
    this.setState({
      selectedInvValue: selectedInvValue,
      invModels: invModels,
      ingeneral: ingeneral,
      inrawgoods: inrawgoods,
      fingoods: fingoods,
    })

  }

  handleCheckBox = (name, value) => {
    // this.setState({
    //   [name]: value,
    // });
    this.setState(prevState => {
      return {
        ...prevState,
        [name]: value
      };
    });
  }

  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach(prop => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;

        }
      })
    }
    return id;
  };

  handleConfig = () => {
    var printstyle = this.state.selectedValue;
    if (!printstyle) {
      this.setState({
        alert: 'block',
        severity: 'error',
        notmsg: "Sorrry! Please Select Label Type",
      });
      setTimeout(() => {
        this.setState({ alert: 'none' });
      }, 3000);
    } else if (!this.state.companyId) {
      this.setState({
        alert: 'block',
        severity: 'error',
        notmsg: "Sorrry! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: 'none' });
      }, 3000);
    } else if (!this.state.selectedInvValue) {
      this.setState({
        alert: 'block',
        severity: 'error',
        notmsg: "Sorrry! Please Select Invoicing Models ",
      });
      setTimeout(() => {
        this.setState({ alert: 'none' });
      }, 3000);
    } else {
      //var brojute=false,brosales=false;
      var suppliertype = { jute: this.state.jute ? 1 : 0, store: this.state.store ? 1 : 0, agent: this.state.agent ? 1 : 0, vendor: this.state.vendor ? 1 : 0 };
      var salesordertype = { general: this.state.general ? 1 : 0, rowmaterial: this.state.rowmaterial ? 1 : 0, jutesalegoods: this.state.jutesalegoods ? 1 : 0 };
      var brokertype = { brojute: this.state.brojute ? 1 : 0, brosales: this.state.brosales ? 1 : 0 };
      var taxType = { txgst: this.state.txgst ? 1 : 0, txvat: this.state.txvat ? 1 : 0, txnotax: this.state.txnotax ? 1 : 0 }

      var data = {
        "companyId": this.state.companyId,
        "configList": [
          {
            "autoId": this.getAutoIdFromList(configNames.SUPPLIER_TYPES),
            "configParameter": configNames.SUPPLIER_TYPES,
            "isActive": 1,
            "configValue": JSON.stringify(suppliertype),
          },
          {
            "autoId": this.getAutoIdFromList(configNames.SALE_ORDER_TYPES),
            "configParameter": configNames.SALE_ORDER_TYPES,
            "isActive": 1,
            "configValue": JSON.stringify(salesordertype),
          },
          {
            "autoId": this.getAutoIdFromList(configNames.BROKER_TYPES),
            "configParameter": configNames.BROKER_TYPES,
            "isActive": 1,
            "configValue": JSON.stringify(brokertype),
          },
          {
            "autoId": this.getAutoIdFromList(configNames.TAX_TYPES),
            "configParameter": configNames.TAX_TYPES,
            "isActive": 1,
            "configValue": JSON.stringify(taxType),
          },
          {
            "autoId": this.getAutoIdFromList(configNames.LABELS_TYPE),
            "configParameter": configNames.LABELS_TYPE,
            "isActive": 1,
            "configValue": this.state.sendingMR,
          },
          {
            "autoId": this.getAutoIdFromList(configNames.HSN_CODE),
            "configParameter": configNames.HSN_CODE,
            "isActive": 1,
            "configValue": this.state.HSN_Yes ? 1 : 0
          },
          {
            "autoId": this.getAutoIdFromList(configNames.INVOICING_PRINT_MODEL),
            "configParameter": configNames.INVOICING_PRINT_MODEL,
            "isActive": 1,
            "configValue": this.state.invModels,
          },
        ]
      };

      if (this.state.companyId) {
        var msg = "Stucture Updated Successfully.";
        this.props.setConfiguration(serverApi.UPDATE_CONFIGURATION_NEW, data, msg, this.props.history)
      } else {
        this.setState({
          alert: 'block',
          severity: 'error',
          notmsg: "Sorrry! Please Select Companynnnnn",
        });
        setTimeout(() => {
          this.setState({ alert: 'none' });
        }, 3000);
      }
    }

  }

  componentWillMount = () => {
    var jute = false, store = false, agent = false, vendor = false;
    var general = false, rowmaterial = false, jutesalegoods = false;
    var brojute = false, brosales = false;
    var txgst = false, txvat = false, txnotax = false;
    var sendingMR = 0;
    var legacy = false, corparate = false, ksk = false;
    var invModels = 0;
    var ingeneral = false, inrawgoods = false, fingoods = false;

    //var configdata = JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach(prop => {
        if (prop.configParameter === configNames.SUPPLIER_TYPES) {
          if (prop.configValue) {
            var supplierTypes = JSON.parse(prop.configValue);
            if (supplierTypes) {
              if (supplierTypes.jute == 1) {
                jute = true;
              }
              if (supplierTypes.store == 1) {
                store = true;
              }
              if (supplierTypes.agent == 1) {
                agent = true;
              }
              if (supplierTypes.vendor == 1) {
                vendor = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.SALE_ORDER_TYPES) {
          if (prop.configValue) {
            var saleOrderTypes = JSON.parse(prop.configValue);
            if (saleOrderTypes) {
              if (saleOrderTypes.general == 1) {
                general = true;
              }
              if (saleOrderTypes.rowmaterial == 1) {
                rowmaterial = true;
              }
              if (saleOrderTypes.juteSale == 1) {
                jutesalegoods = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.BROKER_TYPES) {
          if (prop.configValue) {
            var saleOrderTypes = JSON.parse(prop.configValue);
            if (saleOrderTypes) {
              if (saleOrderTypes.brojute == 1) {
                brojute = true;
              }
              if (saleOrderTypes.brosales == 1) {
                brosales = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.TAX_TYPES) {
          if (prop.configValue) {
            var taxTypes = JSON.parse(prop.configValue);
            if (taxTypes) {
              if (taxTypes.txgst == 1) {
                txgst = true;
              }
              if (taxTypes.txvat == 1) {
                txvat = true;
              }
              if (taxTypes.txnotax == 1) {
                txnotax = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.LABELS_TYPE) {
          if (prop.configValue === '1') {
            sendingMR = 1;
            legacy = true;
          } else if (prop.configValue === '2') {
            sendingMR = 2;
            corparate = true;
          } else if (prop.configValue === '3') {
            sendingMR = 3;
            ksk = true;
          }
        }
        if (prop.configParameter === configNames.INVOICING_PRINT_MODEL) {
          if (prop.configValue === '1') {
            invModels = 1;
            ingeneral = true;
          } else if (prop.configValue === '2') {
            invModels = 2;
            inrawgoods = true;
          } else if (prop.configValue === '2') {
            invModels = 3;
            fingoods = true;
          }
        }
      });

      this.setState({
        jute: jute,
        store: store,
        agent: agent,
        vendor: vendor,
        sogeneral: general,
        general: general,
        rowmaterials: rowmaterial,
        jutesalegoods: jutesalegoods,
        brosales: brosales,
        brojute: brojute,
        //brojute:brojute,
        txnotax: txnotax,
        txgst: txgst,
        txvat: txvat,
        sendingMR: sendingMR,
        legacy: legacy,
        corparate: corparate,
        ksk: ksk,
        invModels: invModels,
        ingeneral: ingeneral,
        inrawgoods: inrawgoods,
        fingoods: fingoods,
        configdata: configdata,
        companyId: localStorage.getItem("companyIdconfig"),
      });
    }
  }

  componentWillReceiveProps(props) {
    var jute = false, store = false, agent = false, vendor = false;
    var general = false, rowmaterial = false, jutesalegoods = false;
    var brojute = false, brosales = false;
    var txgst = false, txvat = false, txnotax = false;
    var sendingMR = 0;
    var legacy = false, corparate = false, ksk = false;
    var invModels = 0;
    var ingeneral = false, inrawgoods = false, fingoods = false;

    var configdata = props.configdata
    if (configdata) {
      configdata.forEach(prop => {
        if (prop.configParameter === configNames.SUPPLIER_TYPES) {
          if (prop.configValue) {
            var supplierTypes = JSON.parse(prop.configValue);
            if (supplierTypes) {
              if (supplierTypes.jute == 1) {
                jute = true;
              }
              if (supplierTypes.store == 1) {
                store = true;
              }
              if (supplierTypes.agent == 1) {
                agent = true;
              }
              if (supplierTypes.vendor == 1) {
                vendor = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.SALE_ORDER_TYPES) {
          if (prop.configValue) {
            var saleOrderTypes = JSON.parse(prop.configValue);
            if (saleOrderTypes) {
              if (saleOrderTypes.general == 1) {
                general = true;
              }
              if (saleOrderTypes.rowmaterial == 1) {
                rowmaterial = true;
              }
              if (saleOrderTypes.juteSale == 1) {
                jutesalegoods = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.BROKER_TYPES) {
          if (prop.configValue) {
            var saleOrderTypes = JSON.parse(prop.configValue);
            if (saleOrderTypes) {
              if (saleOrderTypes.brojute == 1) {
                brojute = true;
              }
              if (saleOrderTypes.brosales == 1) {
                brosales = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.TAX_TYPES) {
          if (prop.configValue) {
            var taxTypes = JSON.parse(prop.configValue);
            if (taxTypes) {
              if (taxTypes.txgst == 1) {
                txgst = true;
              }
              if (taxTypes.txvat == 1) {
                txvat = true;
              }
              if (taxTypes.txnotax == 1) {
                txnotax = true;
              }
            }
          }
        }
        if (prop.configParameter === configNames.LABELS_TYPE) {
          if (prop.configValue === '1') {
            sendingMR = 1;
            legacy = true;
          } else if (prop.configValue === '2') {
            sendingMR = 2;
            corparate = true;
          } else if (prop.configValue === '3') {
            sendingMR = 3;
            ksk = true;
          }
        }
        if (prop.configParameter === configNames.INVOICING_PRINT_MODEL) {
          if (prop.configValue === '1') {
            invModels = 1;
            ingeneral = true;
          } else if (prop.configValue === '2') {
            invModels = 2;
            inrawgoods = true;
          } else if (prop.configValue === '2') {
            invModels = 3;
            fingoods = true;
          }
        }
      });

      this.setState({
        jute: jute,
        store: store,
        agent: agent,
        vendor: vendor,
        sogeneral: general,
        general: general,
        rowmaterials: rowmaterial,
        jutesalegoods: jutesalegoods,
        brosales: brosales,
        brojute: brojute,
        //brojute:brojute,
        txnotax: txnotax,
        txgst: txgst,
        txvat: txvat,
        sendingMR: sendingMR,
        legacy: legacy,
        corparate: corparate,
        ksk: ksk,
        invModels: invModels,
        ingeneral: ingeneral,
        inrawgoods: inrawgoods,
        fingoods: fingoods,
        configdata: configdata,
        companyId: localStorage.getItem("companyIdconfig"),
      });
    }
  }

  render() {
    const { jute, store, agent, vendor, general, rowmaterial } = this.state;


    return (
      <>
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <Alerts alert={this.state.alert} severity={this.state.severity} notmsg={this.state.notmsg} />
        <div className="consoleFormContainer">
          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  Supplier Type
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Jute" name="jute" selected={this.state.jute} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Store" name="store" selected={this.state.store} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Agent" name="agent" selected={this.state.agent} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Vendor" name="vendor" selected={this.state.vendor} onChange={this.handleCheckBox}></Checkbox>
                </div>

              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  Sales Order Type
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="General" name="general" selected={this.state.sogeneral} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Raw Material" name="rowmaterial" selected={this.state.rowmaterials} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Jute Sale Goods" name="jutesalegoods" selected={this.state.jutesalegoods} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  Broker Type
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Jute" name="brojute" selected={this.state.brojute} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="Sales" name="brosales" selected={this.state.brosales} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{ marginTop: "10px" }}>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  Tax Type
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="GST" name="txgst" selected={this.state.txgst} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="VAT" name="txvat" selected={this.state.txvat} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <Checkbox label="NO Tax" name="txnotax" selected={this.state.txnotax} onChange={this.handleCheckBox}></Checkbox>
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>

              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  Label Type
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="Legacy" value="legacy" groupname="brokerType" checkednew={this.state.legacy} onChange={this.onClickRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="Corparate" value="corparate" groupname="brokerType" checkednew={this.state.corparate} onChange={this.onClickRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="KSK" value="ksk" groupname="brokerType" checkednew={this.state.ksk} onChange={this.onClickRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  Invoicing Models
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="General" value="general" groupname="invoicingModel" checkednew={this.state.ingeneral} onChange={this.onClickInvRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="Raw Goods" value="rawgoods" groupname="invoicingModel" checkednew={this.state.inrawgoods} onChange={this.onClickInvRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="Finished Goods" value="finishedgoods" groupname="invoicingModel" checkednew={this.state.fingoods} onChange={this.onClickInvRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>

              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <div style={{ padding: "10px", background: "#eeeeee", borderRadius: "10px", color: "#004b73", fontWeight: "bold" }}>
                  HSN Code
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="Yes" value="Yes" groupname="HSNType" checkednew={this.state.HSN_Yes} onChange={this.onClickHSNRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>
                  <RadioButton labelText="No" value="No" groupname="HSNType" checkednew={this.state.HSN_No} onChange={this.onClickHSNRadioButton} />
                </div>
                <div className="consoleTextFieldBlock" style={{ marginTop: "10px", color: "#004b73" }}>&nbsp;</div>
              </div>
            </Grid>
          </Grid>



        </div>


        <div className="consoleFormButtonBlock">
          <Link to="/invoice">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button variant="contained" type="button" className="saveButton" onClick={this.handleConfig}>
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companysList, companyscoulmns } = state.BasicDetails;
  const { configdatalist, configdata } = state.Configuration;
  return { companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
  setConfiguration
})(Structure);
