
/*
	Created By Nagesh Medisetty On 20-01-2022
*/
import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes, authProtectedRoutesWithOutInnerNavigation } from "./routes/";
import AppRoute from "./routes/route";
import NonAuthLayout from "./components/NonAuthLayout";


// Import scss
import "./assets/css/style.scss";
import Layout from "./Pages/Layout";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = { header_title: 'Default Title' };
		this.getLayout = this.getLayout.bind(this);
		this.setTitle = this.setTitle.bind(this);
		if(window.performance){
			if(performance.navigation.type === 1){	
          		window.location.href = "/dashboard"
			}
		}
	}
	setTitle(newTitle) {
        this.setState({
            title: newTitle
        });
    }
 	/**
	 * Returns the layout
	 */
	getLayout = () => {
		
	};

	

	render() {			

		return (
			<React.Fragment>
				<Router>
					<Switch>

						{authProtectedRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}

						{authProtectedRoutesWithOutInnerNavigation.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
							/>
						))}
						
						{publicRoutes.map((route, idx) => (
							<AppRoute
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
							/>
						))}

						
					</Switch>
				</Router>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};




export default connect(mapStateToProps, null)(App);

