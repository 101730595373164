import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

class RadioGroup extends React.Component {
    render() {
      const {
        Component,
        name,
        selectedValue,
        onClickRadioButton,
        children,
        ...rest
      } = this.props;
      return (
        <Component role="radiogroup" {...rest} name={name}>
          {React.Children.map(children, element =>
            React.cloneElement(element, {
              ...element.props,
              checked: selectedValue === element.props.labelText,
              onChange: () => onClickRadioButton(element.props.labelText),
              name
            })
          )}
        </Component>
      );
    }
  }
  
  RadioGroup.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
    Component: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.object
    ])
  };
  
  RadioGroup.defaultProps = {
    name: "",
    selectedValue: "",
    Component: "div"
  };
  
  export default RadioGroup;