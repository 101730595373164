import axios from "axios";

import cons, { getTenantId } from "./Consts";

const getList = (url, data) => {
  const userDit = JSON.parse(localStorage.getItem("authUser"));
  const companyId = localStorage.getItem("companyIdconfig");

  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    // CompanyID: userDit.orgId,
    CompanyID: companyId
  };

  return axios.post(url, data, { headers: headers }).then(response => {
    // console.log("Responce Message:", response);
    if (response.status === 400 || response.status === 500)
      throw response.data;
    return response.data;
  })
    .catch((err) => {
      throw err[1];
    });
};

const get_API_data = (url, data) => {
  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  const companyId = localStorage.getItem("companyIdconfig");

  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: companyId

  };
  // return axios
  //   .get(url, {
  //     headers: headers,
  //   })
    return axios.get(url,{headers:headers}).then(response => {
        return response.data;
      })
    .catch((err) => {
      throw err[1];
    });
};

const getListurl = (url, data) => {
  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  //     var companyId = JSON.parse(localStorage.getItem("authUser"))
  const companyId = localStorage.getItem("companyIdconfig");

  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    // CompanyID: companyId.orgId,
    CompanyID: companyId

  };
  return axios
    .get(url, {
      headers: headers,
    })
    // return axios.get(url, data, {headers:headers}).then(response => {
    //     console.log("Responce Message:", response);
    //     if (response.status === 400 || response.status === 500)
    //       throw response.data;
    //     return response.data;
    //   })
    .catch((err) => {
      throw err[1];
    });
};




const getListurlPhp = (url, data) => {
  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  return axios
    .get(url, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

const postAddPhp = (url, data) => {
  // const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  return axios
    .get(url, data, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

// postForgetPwd
const postAdd = (url, data) => {
  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: cons.COMPANY_ID,
  };
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];
      // console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postEdit = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .put(url, data, { headers: { Authorization: "Token " + sjson.data.token } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];
      // console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// postForgetPwd
const postAddmultipart = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);

  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };

  return axios
    .post(url, data,
      { headers: headers },
    )
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];
      console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postEditmultipart = (url, data) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .put(url, data, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];
      // console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};
const postDelete = (url) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .delete(url, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;
      // console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getRowData = (url) => {
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  return axios
    .get(url, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;
      // console.log(err.response);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getPostRowData = (url, data) => {
  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      // console.log(err.response);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postUpload = (url, csvfile) => {
  // console.log(csvfile);
  const user = localStorage.getItem("authUser");
  let sjson = JSON.parse(user);
  // var formData = new FormData();
  // formData.append("file", csvfile.files[0]);
  return axios
    .post(url, csvfile, {
      headers: {
        Authorization: "Token " + sjson.data.token,
        "content-type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err;
      // console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const postUploadImage = (url, data) => {

  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };
  // const user = localStorage.getItem("authUser");
  // let sjson = JSON.parse(user);
  return axios
    .post(url, data, { headers: headers })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response;
    })
    .catch((err) => {
      //throw err[1];
      // console.log(err);
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 400:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getProfileFileDownload = (url, name, type, callback) => {
  const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  var tenID = getTenantId(window.location.hostname);
  var headers = {
    Authorization: mtoken,
    "Content-Type": "application/json",
    "X-TenantId": tenID,
    CompanyID: 0,
  };
  return fetch(url, {
    headers: headers,
  }).then((response) =>
    response.blob().then((blob) => {
      var fileType = "image/" + type;
      if (type === "pdf" || type === "xlsx") fileType = "application/" + type;
      var file = new Blob([blob], { type: fileType });
      // file.name = name;
      var fileURL = URL.createObjectURL(file);
      callback(fileURL);
      //window.open(url);
    })

  ).catch((error) => {
    console.error(error);
  });

}

export async function getFileDownload(url, name, type, callback) {
 
  var headers = { Authorization: localStorage.getItem("token_type") + localStorage.getItem("access_token") };
  if (getTenantId(window.location.hostname) !== null) {
    headers = {
      Authorization: localStorage.getItem("token_type") + localStorage.getItem("access_token"),
      "X-TenantId": getTenantId(window.location.hostname),
      CompanyID: localStorage.getItem("companyIdconfig"),
    };
  } try {
    const response = await fetch(url, { headers: headers, }); const blob = await response.blob();
    // let url = window.URL.createObjectURL(blob);   
    var fileType = "image/" + type;
     if (type === "pdf") fileType = "application/" + type;
      if (type === "xlsx" || type === "xls") 
      fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" + type; 
      var file = new Blob([blob], { type: fileType }); 
       // file.name=name;   
        var fileURL = URL.createObjectURL(file);
         const url_1 = URL.createObjectURL(file);
         const link = document.createElement("a");
         link.href = url_1;
         link.setAttribute("download", name);
         // Append to html link element page  
          document.body.appendChild(link);
         // Start download  
          link.click();       
           // Clean up and remove the link  
           link.parentNode.removeChild(link);
          } catch (error) {
            console.error(error)
           }}


    export {
      getList,
      getListurl,
      getListurlPhp,
      postAdd,
      postEdit,
      postDelete,
      getRowData,
      postUpload,
      postAddmultipart,
      postEditmultipart,
      postUploadImage,
      getPostRowData,
      postAddPhp,
      getProfileFileDownload,
      get_API_data
    };
