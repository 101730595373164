import { BANK_ACCOUNT_LIST_API, BRANCH_SELECTION_LIST, CREATE_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, getListurl, postAdd } from '../../../helpers/Server_Helper';
import { bank_account_list_api_successfull, create_bank_account_successfull, update_bank_account_successfull,BranchSelectionListSuccessfull } from './actions';
import swal from "sweetalert";

function* BankAccountMasterListSaga({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        // response.data.sort((a, b) => (a.suppId < b.suppId) ? 1 : -1)
        yield put(bank_account_list_api_successfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* Create_Bank_Account({payload:{url,data,history}}){
    try{
        const response = yield call(postAdd,url,data);
        yield put(create_bank_account_successfull({response}));
        if(response.data.status===true){
            swal(response.data.message,{icon:"success"})
            history.push("/bank_account_master")
        }else{
            swal(response.data.message,{icon:"error"})
        }

    } catch(error){
        swal("error:" + error,{icon:"danger"});
        console.log(error)
    }
}

function* BranchSelectionLIstSaga({ payload: { url,history } }) {
    try {
        const response = yield call(getListurl,url);
        // response.data.sort((a, b) => (a.suppId < b.suppId) ? 1 : -1)
        yield put(BranchSelectionListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}


function* Update_Bank_Account({payload: { url, data, history }}){
    try{
        const response = yield call(postAdd,url,data);
        yield put(update_bank_account_successfull({response}))
        if(response.data.status === true){
            swal(response.data.message,{icon:"error"})
            history.push("/bank_account_master")
        } else {
            swal(response.data.message, { icon: "error" })
        }
    }catch(error){
        console.log(error);
        swal("error:" + error,{icon:"danger"});
    }
}

export function* watchBankAccountMasterList() {
    yield takeEvery(BANK_ACCOUNT_LIST_API, BankAccountMasterListSaga)
}

export function* watchCreateBankAccountMaster(){
    yield takeEvery(CREATE_BANK_ACCOUNT,Create_Bank_Account)
}

export function* watchUpdateBankAccountMaster(){
    yield takeEvery(UPDATE_BANK_ACCOUNT,Update_Bank_Account)
}
export function* watchBranchSelectionList(){
    yield takeEvery(BRANCH_SELECTION_LIST,BranchSelectionLIstSaga)
}
 
function* BankAccountSaga() {
    yield all([
        fork(watchBankAccountMasterList),
        fork(watchCreateBankAccountMaster),
        fork(watchUpdateBankAccountMaster),
        fork(watchBranchSelectionList)
       
    ])
}
export default BankAccountSaga;