import {
  API_ERRORS,
  BASICDETAILS_ADD,
  BASICDETAILS_ADD_SUCCESSFUL,
  COMPANYS,
  COMPANYS_LIST_SUCCESSFUL,
  IMAGE_CMP_UPLOAD,
  COMPANY_DIT,
  COMPANY_DIT_SUCCESS,
  CLIENT_LIST,
  CLIENT_LIST_SUCCESS,
  COMPANY_PROPS,
  COMPANY_TABLE_SUCCESS,
  COMPANY_TABLE,
  CLEAR_CLIENT_LIST
} from "./actionTypes";



export const getCompanys = (url, data, history) => { 
  return {
    type: COMPANYS,
    payload: {url, data, history }
  };
};

export const companyListSuccessful = (companys) => {
  return {
    type: COMPANYS_LIST_SUCCESSFUL,
    payload: companys,
  };
};

export const getClient = (url, data, history) => {
  return {
    type: CLIENT_LIST,
    payload: {url, data, history }
  };
};

export const clientListSuccess = (data) => {
  return {
    type: CLIENT_LIST_SUCCESS,
    payload: data,
  };
};
export const ClearclientListData = (data) => {
  return {
    type: CLEAR_CLIENT_LIST,
    payload: data,
  };
};
export const basicdetailsAdd = (url, data, id,updateView,history) => {  
  return {
    type: BASICDETAILS_ADD,
    payload: { url, data, id,updateView,history },
  };
};

export const basicdetailsAddSuccessful = (response,updateView, history) => {
  return {
    type: IMAGE_CMP_UPLOAD,
    payload: { response,updateView, history},
  };
};
export const basicdetailsAddSuccessfulWithoutUpload = (response,updateView, history) => {
  return {
    type: BASICDETAILS_ADD_SUCCESSFUL,
    payload: { response, updateView,history},
  };
};

export const getCompanyDit = (url, data, history) => {
  return {
      type: COMPANY_DIT,
      payload: { url, data, history },    
  }
}

export const companyditsuccess = (resp) => {
  return {
      type: COMPANY_DIT_SUCCESS,
      payload: resp
  }
}

export const companyprops = (resp) => {
  return {
      type: COMPANY_PROPS,
      payload: resp
  }
}

export const getCompanyTable = (url) => {
  return {
    type: COMPANY_TABLE,
    payload: {url}
  };
};

export const getCompanyTableSuccessful = (data) => {
  return {
    type: COMPANY_TABLE_SUCCESS,
    payload: data,
  };
};

export const apiErrors = (error) => {
  return {
    type: API_ERRORS,
    payload: error,
  };
};
