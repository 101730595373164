import {TAX_MASTER_LIST_API, TAX_MASTER_LIST_API_SUCCESSFULL, CREATE_TAX_MASTER, CREATE_TAX_MASTER_SUCCESSFULL} from './actionTypes';
  
  const initialState = {
      error: "sorry no data found",
      message: null,
      loading: false,
      TaxMasterListData:[],
      createTaxMasterList:[]
    };
  
    const TaxMasterReducer = (state = initialState, action) => {
      switch (action.type) {
          case TAX_MASTER_LIST_API:
              state = {
                ...state,
                loading: true,
              };
              break;
            case TAX_MASTER_LIST_API_SUCCESSFULL:
              state = {
                ...state,
                TaxMasterListData: action.payload.response,
                loading: true,
              };
              break;
              case CREATE_TAX_MASTER:
                state = {
                  ...state,
                  loading: true,
                };
                break;
              case CREATE_TAX_MASTER_SUCCESSFULL:
                state = {
                  ...state,
                  createTaxMasterList: action.payload.response,
                  loading: true,
                };
                break;
              
            default:
              state = { ...state };
              break;
      }
      return state;
    }
  
    export default TaxMasterReducer;