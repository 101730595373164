import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  LOCATIONS,
} from "./actionTypes";
import {
  locationListSuccessful,
} from "./actions";

// AUTH related methods
import {
  getFormList,
} from "../../helpers/fackBackend_Helper";
// import swal from "sweetalert";

function* getLocation() {
  try {
    const response = yield call(getFormList, "",
      {}
    );    
    yield put(locationListSuccessful({ response }));
    
  } catch (error) {
    console.log(error);
    // yield put(apiError(error));
  }
}
export function* watchgetLocations() {
  console.log("THis is Saga");
  yield takeEvery(LOCATIONS, getLocation);
}




function* LocationSaga() {
  yield all([
    fork(watchgetLocations),
  ]);
}

export default LocationSaga;
