import { BRANCH_SELECTION_LIST } from "../store/Master/BankAccountMaster/actionTypes";
import { getCookie } from "./Cookies";

export const showSpinner = () => {
  document.getElementById("spinner").classList.add("show");
};

export const hideSpinner = () => {
  setInterval(function () {
    document.getElementById("spinner").classList.remove("show");
  }, 5000);
};

export const cons = {
  AUTH: "auth",
  USER_ROLE_ID: "roleId",
  SUPP_CODE: "supplierCode",
  TOKEN_DATA: "tokenData",
  TOKEN_ID: "tokenId",
  TOKEN_TYPE: "tokentype",
  ACCESS_TOKEN: "accesstoken",
  CIPHER: "cipher",
  COMPANY_ID: "companyId",
  SELECTED_COMPANY: "SelectedValue",
  SELECTED_COMPANY_ID: "SelectedId",
  CONFIG_DATA: "",
};

export const configNames = {
  INVOICING_MODEL: "invoicingModel",
  INVOICING_PRINT_MODEL: "invoicingPrintModel",
  BACK_DATE_ALLOWABLE: "backDateAllowable",
  GATE_ENTRY_OUT: "gateEntryOut",
  MR_PRINT_MODE: "mrPrintMode",
  MR_INV_PRINT_MODE: "mrInvPrintMode",
  SUPPLIER_TYPES: "supplierTypes",
  LABELS_TYPE: "labelType",
  HSN_CODE: "hsnCode",
  FUTURE_DATE_ALLOWABLE: "futureDateAllowable",
  INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS: "indentWithMultiDepartments",
  INWARD_PO__CONFIG: "inwardPoConfig",
  APPROVAL_TASKS_LIST: "tasksList",
  GATE_ENTRY_WITHOUT_PO: "gateEntryWithoutPo",
  PO_PRINT_BEFORE_APPROVE: "poPrintBeforeApproval",
  EMAIL_ALERTS: "emailAlert",
  TAX_TYPES: "taxTypes",
  BROKER_TYPES: "brokerTypes",
  SENDING_MR_INVOICE: "sendingMR",
  CUSTOMER_INTERNAL_MAPPING: "customerInternalMapping",
  SALE_ORDER_TYPES: "saleOrderTypes",
  RAIL_RATE: "rail_rate",
  CONTAINER_RATE: "container_rate",
  TALLY_SYNC: "autoSync",
  INVOICE_NO_MODIFY: "invoiceNoMdicfication",
  CONSTRUCTION_FIELDS: "constructionFields",
  WHATSAPP_CONFIG: "whatsappConfiguration",
  FUTURE_DAYS_ALLOWABLE: "futureDaysAllowable",
  BACK_DAYS_ALLOWABLE: "backDaysAllowable",
  EMAIL_CONFIGURATION: "emailconfiguration",
  CURRENCY_TYPES: "currencyTypes",
  OPEN_INDENT: "openIndent",
  QUETATION_MANDATORY: "quetationMandatory",
  DELIVERYORDER_MANDATORY: "deliveryorderMandatory",
  INVOICE_MANDATORY: "invoiceMandatory",
  INVOICE_TYPE: "invoiceType",
  EWAY_BILL_EINVOICE: "eway_bill_einvoice",
};

export const MULTI_TENANT_DATA = [
  //new api created
  {
    host: "localhost",
    // serverIp: "http://tenant1.vowerp.com",
    // tenantId: "sls.vowerp.com",
    // serverIp: "https://devapi.vowerp.com",
    // tenantId: "devsw.vowerp.com",
    // serverIp: "https://qaapi.vowerp.com",
    // tenantId: "qa.vowerp.com",
    // serverIp: "https://tenantec2.vowerp.com",
    // tenantId: "sls.vowerp.com",
    // serverIp: "https://tenantec2.vowerp.com",
    // tenantId: "cloud.vowerp.com",
    serverIp: "https://devapi.vowerp.com",
    tenantId: "devsls.vowerp.com",
    // serverIp: "https://devapi.vowerp.com",
    // tenantId: "capextest.vowerp.com",
  },
  {
    host: "console.cloud.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "cloud.vowerp.com",
  },

  {
    host: "talbot.cloud.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "talbot2.vowerp.com",
  },

  {
    host: "console.slscloud.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "sls.vowerp.com",
  },
  {
    host: "console.qa.vowerp.com",
    serverIp: "https://qaapi.vowerp.com",
    tenantId: "qa.vowerp.com",
  },

  {
    host: "console.dev.vowerp.com",
    serverIp: "https://devapi.vowerp.com",
    tenantId: "devsw.vowerp.com",
  },
  {
    host: "console.demo.vowerp.com",
    serverIp: "https://devapi.vowerp.com",
    tenantId: "demo.vowerp.com",
  },
  {
    host: "console.hrms.astriadigital.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "dotsoft.vowerp.com",
  },
  {
    host: "qasls.cloud.vowerp.com",
    serverIp: "https://qaapi.vowerp.com",
    tenantId: "qasls.vowerp.com",
  },
  {
    host: "devsls.cloud.vowerp.com",
    serverIp: "https://devapi.vowerp.com",
    tenantId: "devsls.vowerp.com",
  },
  {
    host: "anay.cloud.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "anay.vowerp.com",
  },
  {
    host: "amcl.cloud.vowerp.com",
    serverIp: "https://tenantec2.vowerp.com",
    tenantId: "amcl.vowerp.com",
  }
];

export const serverConfig = {
  SERVER_URL:
    process.env.NODE_ENV === "development"
      ? getHostIp("localhost") + "/"
      : getHostIp(window.location.hostname) + "/", //put dev
};

export const serverConfigPhp = {
  SERVER_URL: "https://data.cloud.vowerp.com/",
  // SERVER_URL:
  //   process.env.NODE_ENV === "development"
  //     ? getHostIpPhp(window.location.hostname) + "/"
  //     : "", //put dev
};
export const serverVars = {
  SECURITY: serverConfig.SERVER_URL + "security-api/",
  USER_MANAGEMENT_API: serverConfig.SERVER_URL + "security-api/api/",
  MASTER_API: serverConfig.SERVER_URL + "security-api/api/",
  PO_API: serverConfig.SERVER_URL + "security-api/api/poapi/",
  PHP_API: serverConfigPhp.SERVER_URL,
  GST_VERIFICATION: serverConfig.SERVER_URL + "master-v2/thirdParty/api/",

};

export const serverApi = {
  OAUTH_LOGIN: serverVars.SECURITY + "oauth/token",
  LOGIN:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/authenticateUserLogin",
  // COMPANYMASTER_ADD: serverVars.MASTER_API + "company/addCompany",
  COMPANYMASTER_ADD: serverVars.MASTER_API + "company/addCompanyNew",
  COMPANYMASTER_LIST: serverVars.MASTER_API + "company/getAllCompanyList",
  COMPANYMASTER_LIST_BY_ORG_ID:
    serverVars.MASTER_API + "company/getAllCompanyListByOrgId",
  COMPANYMASTER_DETAILS_BYID:
    serverVars.MASTER_API + "company/getCompanyDetailsById",
  GET_ALL_STATES: serverVars.MASTER_API + "state/getStateNameSelectionList",

  CHANGE_PASSWORD:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/updatePassWord",
  FORGOT_PASSWORD:
    serverConfig.SERVER_URL +
    "security-api/api/easybusiness/user/forgotPassword",
  MODIFY_PASSWORD:
    serverConfig.SERVER_URL +
    "security-api/api/easybusiness/user/modifyPassword",

  FILE_UPLOAD: serverVars.MASTER_API + "files/Uploadfile",
  DOWNLOAD_PDF: serverVars.PO_API + "api/v1/downloadInvoicePdfPrintOuts/",
  COMPANYMASTER_UPDATE: serverVars.MASTER_API + "company/updateCompanyNew",

  BRANCHMASTER_DATATABLE:
    serverVars.MASTER_API + "branchMaster/getAllBranchList",

  BRANCHMASTER_ADD: serverVars.MASTER_API + "branchMaster/addBranchList",
  BRANCHMASTER_UPDATE: serverVars.MASTER_API + "branchMaster/updateBranchList",
  BRANCHMASTER_DETAILS_BYID:
    serverVars.MASTER_API + "branchMaster/getBranchDetailsById",

  GET_CONFIGURATION_BY_COMPANY: serverVars.PO_API + "api/v1/getConfiguration/",
  // GET_CONFIGURATION_BY_COMPANY_NEW: serverVars.PO_API + "api/v1/updateConfigurationWithNewConfig",

  UPDATE_CONFIGURATION: serverVars.PO_API + "api/v1/updateConfiguration",
  UPDATE_CONFIGURATION_NEW:
    serverVars.PO_API + "api/v1/updateConfigurationWithNewConfig",

  GET_MENUS_BY_ROLE:
    serverVars.USER_MANAGEMENT_API + "easybusiness/menu/getUserMenus/",

  GET_ALL_MENUS:
    serverVars.USER_MANAGEMENT_API + "easybusiness/menu/getConsoleUserMenus/",

  GET_ALL_USER_GROUP:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/getAllUserGroup/",

  GET_USER_GROUP_BY_ID:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/getRoleById/",

  // GET_ALL_USERS_LIST:
  //   serverVars.USER_MANAGEMENT_API + "easybusiness/user/getAllUserListByComId/",
  GET_ALL_USERS_LIST:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/getAllUserListByComId",

  GET_ALL_USER: serverVars.USER_MANAGEMENT_API + "easybusiness/user/getAllUser",
  GET_USER_DETAILS_BYID: serverVars.USER_MANAGEMENT_API+ "easybusiness/user/getUserDetailsById/",

  GET_ROLES_LIST_BY_TYPE:
    serverVars.USER_MANAGEMENT_API +
    "easybusiness/usergroup/getRolesByReqType/",
  COMPANY_LIST_BY_ORG_ID:
    serverVars.MASTER_API + "company/organizationMappedCompanies",

  ADD_USER_GROUP_ROLE:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/add",
  UPDATE_USER_GROUP_ROLE:
    serverVars.USER_MANAGEMENT_API + "easybusiness/usergroup/updateRole",


  GET_TASKS_LIST: serverVars.PO_API + "api/v1/getApproveMasterList",

  //ADD_USER_MAP_GRP: serverVars.USER_MANAGEMENT_API + "easybusiness/user/addUserGroupMap",
  ADD_USER_MAP_GRP:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/addNewUser",
  //UPDATE_USER: serverVars.USER_MANAGEMENT_API + "easybusiness/user/updateUser/",
  UPDATE_USER:
    serverVars.USER_MANAGEMENT_API + "easybusiness/user/updateNewUser",
  ADD_MENUS: serverVars.MASTER_API + "menumaster/addNewMenuCompanyMapping",
  UPDATE_APPOVE_HIERACHY: serverVars.PO_API + "api/v1/updateApproveMasterList",
  // GET_ALL_LEAVE_TYPE_LIST:
  //   serverVars.MASTER_API + "LeaveTypes/getLeaveTypesList/",
  GET_ALL_LEAVE_TYPE_LIST:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesListView",
  GET_LEAVE_TYPE_SELECTION:
    serverVars.MASTER_API + "LeaveTypes/getLeaveTypesSelectionList/",
  CREATE_LEAVE_TYPE: serverVars.MASTER_API + "LeaveTypes/postLeaveTypes",
  UPDATE_LEAVE_TYPE: serverVars.MASTER_API + "LeaveTypes/editLeaveTypes",
  GET_ALL_DEPARTMENT_MASTER_LIST:
    serverVars.MASTER_API + "mdepartment/getAllDepartmentList",
  CREATE_DEPARTMENT: serverVars.MASTER_API + "mdepartment/addDepartmentList",
  UPDATE_DEPARTMENT: serverVars.MASTER_API + "mdepartment/updateDepartmentList",
  // payscheme creation
  EMPLOYEE_PAY_SCHEME: serverVars.MASTER_API + "PayScheme/getAllPaySchemes",
  DESIGNATION:
    serverVars.MASTER_API + "mdesignation/getDesignationSelectionList/",
  WORK_LOCATION: serverVars.MASTER_API + "CustomerBranch/AllBranch/",
  ALL_PAY_WAGESMODES:
    serverVars.MASTER_API + "PayWagesMode/getAllPayWagesModes",
  PAY_SCHEME_BY_ID:
    serverVars.MASTER_API + "PayScheme/getPaySchemeByPaySchemeId/",
  PAY_SCHEME_BY_ORDERED_ID:
    serverVars.MASTER_API + "PayScheme/getComponentsByPaySchemeOrder/",
  ADD_PAYSCHEME: serverVars.MASTER_API + "PayScheme/addPayScheme",
  UPDATE_PAY_SCHEME: serverVars.MASTER_API + "PayScheme/updatePayScheme",
  CHECK_PAY_SCHEME: serverVars.MASTER_API + "PayProcess/checkPayscheme/",
  PAY_COMPONENTS: serverVars.MASTER_API + "PayComponents/getAllPayComponents",

  // Tax master Api
  TAX_MASTER_LIST: serverVars.MASTER_API + "tax/getAllTaxList",
  CREATE_TAX_MASTER_API: serverVars.MASTER_API + "tax/addTax",
  UPDATE_TAX_MASTER_API: serverVars.MASTER_API + "tax/updateTax",

  // Leave Policy Api
  GET_LEAVE_POLICY: serverVars.MASTER_API + "leavepolicy/getLeavePolicyList/",
  CREATE_LEAVE_POLICY: serverVars.MASTER_API + "leavepolicy/addLeavePolicy",
  LEAVE_POLICY_VIEW_BY_ID:
    serverVars.MASTER_API + "leavepolicy/getLeavePolicyById/",
  UPDATE_LEAVE_POLICY: serverVars.MASTER_API + "leavepolicy/updateLeavePolicy",

  //payscheme parameter api
  GET_ALL_PAYSCHEME_PARAMETERS_LIST:
    serverVars.MASTER_API + "PayComponents/getAllPayComponents",
  CREATE_PAYSCHEME_PARAMETERS:
    serverVars.MASTER_API + "PayComponents/addPayComponents",
  UPDATE_PAYSCHEME_PARAMETERS:
    serverVars.MASTER_API + "PayComponents/updatePayComponents",

  /// PHP API's List
  GET_ALL_MODULES_LIST: serverVars.PHP_API + "api/getAllModules/",
  UPDATE_MODULES: serverVars.PHP_API + "api/updateModules/",
  CREATE_MODULE_INVOCIE: serverVars.PHP_API + "api/createInvoice/",
  GET_ORG_INVOICE_LIST: serverVars.PHP_API + "api/getOrgInvoiceList/",
  GET_ORG_DASHBOARD_DATA: serverVars.PHP_API + "api/getConsoleDashboardData/",
  // GET_ORG_INVOICE_SUBSC: serverVars.PHP_API + "api/getOrgInvoiceSubscriptionDit/",
  // GETCOMPANYTABLEDAT:serverVars.PHP_API+"",
  CREATE_APPROVER_MASTER: serverVars.PHP_API + "api/createapprovermaster/",
  CREATE_CONFIGURATION_MASTER: serverVars.PHP_API + "api/createconfiguration/",

  UPLOADFILE: serverVars.MASTER_API + "files/Uploadfile",
  DELETEFILE: serverVars.MASTER_API + "files/deleteFile/",
  GET_ALL_FILES:
    serverVars.MASTER_API + "files/getFileBySourcemenuAndTaskIdAndCompanyId/",

  // BAnk Account Master
  BANK_ACCOUNT_LIST_API:
    serverVars.MASTER_API + "bankAccountsController/getAllBankAccountsDetails",
  BRANCH_SELECTION_LIST_API:
    serverVars.MASTER_API + "branchMaster/getBranchSelectionList/",
  CREATE_BANK_ACCOUNT:
    serverVars.MASTER_API + "bankAccountsController/addBankAccount",
  UPDATE_BANK_ACCOUNT:
    serverVars.MASTER_API + "bankAccountsController/updateBankAccount",

  //Supplier Types

  SUPPLIER_TYPES_LIST_API:
    serverVars.MASTER_API + "mastersupplier/getSupplierTypeMasterList",
  CREATE_SUPPLIER_TYPES:
    serverVars.MASTER_API + "mastersupplier/addSupplierTypeMaster",
  UPDATE_SUPPLIER_TYPES:
    serverVars.MASTER_API + "mastersupplier/updateSupplierTypeMaster",
  // master dropdown apis
  GST_VERIFICATION_API: serverVars.GST_VERIFICATION + "getGstDetails/",
};

export function getHostIp(hostName) {
  var hostIp = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostIp = prop.serverIp;
    }
  });
  if (hostIp !== "") return hostIp;
  else {
    alert("Host not found, Please contact your administrator..!!");
    return null;
  }
}

export function getHostIpPhp(hostName) {
  var hostIp = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostIp = prop.host;
      let result = hostIp.replace("console", "data");
      hostIp = result;
    }
  });
  if (hostIp !== "") {
    if (hostIp == "localhost") {
      hostIp = "https://data.cloud.vowerp.com";
    }
    return hostIp;
  } else {
    alert("Host not found, Please contact your administrator..!!");
    return null;
  }
}

// export function getHostIp(hostName) {
//   var hostIp = "";
//   var hostRrr ="";
//   MULTI_TENANT_DATA.forEach((prop) => {
//     hostRrr = hostName.split(/[ .]+/);
//     if(hostRrr[0]==='console'){
//       if (hostName === prop.host) {
//         hostIp = 'console.'+prop.serverIp;
//       }
//     }else{
//       if (hostName === prop.host) {
//         hostIp = prop.serverIp;
//       }
//     }

//   });
//   if (hostIp !== "") return hostIp;
//   else {
//     alert("Host not found, Please contact your administrator..!!");
//     return null;
//   }
// }
// export function getTenantId(hostName) {
//   var hostId = "";
//   var hostRrr = "";
//   MULTI_TENANT_DATA.forEach((prop) => {
//     hostRrr = hostName.split(/[ .]+/);
//     console.log("Host Name::TenentId",hostRrr);
//     if (hostRrr[0] === "console") {
//       if (hostName === prop.host) {
//         hostId = "console." + prop.tenantId;
//       }
//     } else {
//       if (hostName === prop.host) {
//         hostId = prop.tenantId;
//       }
//     }
//   });
//   if (hostId !== "") return hostId;
//   else {
//     return "";
//   }
// }

export function getTenantId(hostName) {
  var hostId = "";
  MULTI_TENANT_DATA.forEach((prop) => {
    if (hostName === prop.host) {
      hostId = prop.tenantId;
    }
  });
  if (hostId !== "") return hostId;
  else {
    return "";
  }
}

export const taskCodes = {
  LOGIN: 247,
  LOGOUT: 248,
};

export const ApprovalTasks = [
  { taskName: "Indent", id: 1 },
  { taskName: "PO", id: 2 },
  { taskName: "SR", id: 3 },
  { taskName: "MR", id: 4 },
  { taskName: "Jute Indent", id: 5 },
  { taskName: "Jute PO", id: 6 },
  { taskName: "Bill Pass", id: 7 },
  { taskName: "Jute Issue", id: 8 },
  { taskName: "Store Issue", id: 9 },
  { taskName: "Store Bill Pass", id: 10 },
  { taskName: "General Bills", id: 11 },
  { taskName: "Transactions", id: 12 },
  { taskName: "Quotations", id: 13 },
  { taskName: "Delivery Order", id: 39 },
  { taskName: "Invoices", id: 14 },
  { taskName: "Employee Database", id: 15 },
  { taskName: "Employee Attendance", id: 16 },
  { taskName: "Client Visit Plan", id: 17 },
  { taskName: "Employee Leave Request", id: 18 },
  { taskName: "Expense Booking", id: 19 },
  { taskName: "Salary Adjustment", id: 20 },
  { taskName: "Advance Request", id: 21 },
  { taskName: "Enquiries", id: 22 },
  { taskName: "Customer Package Mapping", id: 23 },
  { taskName: "Price Master", id: 24 },
  { taskName: "Store Stock Transfer", id: 25 },
  { taskName: "Store Stock Return", id: 26 },
  { taskName: "Pay Register", id: 27 },
  { taskName: "Sales Order", id: 28 },
  { taskName: "Jobs", id: 29 },
  { taskName: "Customer Employee Billing Pay Register", id: 30 },
  { taskName: "Jute Conversion", id: 31 },
  { taskName: "Supplier", id: 32 },
  { taskName: "Work Order", id: 33 },
  { taskName: "Material Inspection", id: 34 },
  { taskName: "Work Inspection", id: 35 },
  { taskName: "BOM", id: 36 },
  { taskName: "BOQ", id: 37 },
  { taskName: "Work Report", id: 38 },
  { taskName: "Open Indent", id: 43 },
  { taskName: "Debit Note", id: 41 },
  { taskName: "Regular Sales Order", id: 44 },
  { taskName: "Government Sales Order", id: 45 },
  { taskName: "Yarn Sales Order", id: 46 },
  { taskName: "Hessian Sales Order", id: 47 },
  { taskName: "Credit Note", id: 42 }
];

export default cons;
