import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";

import RadioButton from "../../components/RadioButton/RadioButton";
import BlankVideoImage from "../../assets/images/videoGif/BlankVideoImage.png";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration, getInwardPOconfiguration } from "../../store/Configuration/actions";
import POPrintWithoutApproval from "../../assets/images/videoGif/POPrintWithoutApproval.gif";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "../../assets/images/CloseIcon.png";
import { Link } from "react-router-dom";

class OpenIndentConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: "",
            notmsg: "",
            severity: "error",
            alert: "none",
            openIndent: 0,
            openIndentYes: false,
            openIndentNo: false,
            configdata: [],
            companyId: localStorage.getItem("companyIdconfig"),
            autoId: "",
        };
    }

    componentDidMount = () => {
        var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
        if (configdata) {
            configdata.forEach((prop) => {
                var config = prop.configParameter
                if (config === configNames.OPEN_INDENT) {
                    let openIndentValues = JSON.parse(prop.configValue)
                    if (openIndentValues.openIndent === 1) {
                        this.setState({
                            openIndent: 1,
                            openIndentYes: true,
                            openIndentNo: false,
                            configdata: configdata,
                            autoId: prop.autoId,
                        });
                    } else if (openIndentValues.openIndent === 0) {
                        this.setState({
                            openIndent: 2,
                            openIndentYes: false,
                            openIndentNo: true,
                            configdata: configdata,
                            autoId: prop.autoId,
                        });
                    }
                    this.setState({
                        companyId: localStorage.getItem("companyIdconfig"),
                    });
                }
            });
        } else {
            this.setState({
                autoId: null,
                openIndent: 0,
                openIndentYes: false,
                openIndentNo: false,
                configdata: [],
                companyId: localStorage.getItem("companyIdconfig"),
            });
        }
    };

    componentWillReceiveProps = (props) => {
        if (props.configdata) {
            if (props.configdata.length !== 0) {
                props.configdata.forEach((prop) => {
                    var config = prop.configParameter;

                    if (config == configNames.OPEN_INDENT) {
                        let openIndentValues = JSON.parse(prop.configValue)
                    if (openIndentValues.openIndent === 1) {
                        this.setState({
                            openIndent: 1,
                            openIndentYes: true,
                            openIndentNo: false,
                            configdata: config,
                            autoId: prop.autoId,
                        });
                    } else if (openIndentValues.openIndent === 0) {
                        this.setState({
                            openIndent: 2,
                            openIndentYes: false,
                            openIndentNo: true,
                            configdata: config,
                            autoId: prop.autoId,
                        });
                    }
                        this.setState({
                            companyId: localStorage.getItem("companyIdconfig"),
                        });
                    } else {
                        // console.log('else first',prop)
                        this.setState({
                            autoId: undefined,
                            openIndent: 0,
                            openIndentYes: false,
                            openIndentNo: false,
                            configdata: [],
                            companyId: localStorage.getItem("companyIdconfig"),
                        });
                    }
                });
            } else {
                this.setState({
                    autoId: undefined,
                    openIndent: 0,
                    openIndentYes: false,
                    openIndentNo: false,
                    configdata: [],
                    companyId: localStorage.getItem("companyIdconfig"),
                });
            }
        }
    };


    handleConfig = () => {
        if (this.state.openIndent == 0) {
            this.setState({
                alert: "block",
                severity: "error",
                notmsg: "Sorrry! Please Select Open Indent ",
            });
            setTimeout(() => {
                this.setState({ alert: "none" });
            }, 3000);
        } else if (!this.state.companyId) {
            this.setState({
                alert: "block",
                severity: "error",
                notmsg: "Sorrry!! Please Select Company",
            });
            setTimeout(() => {
                this.setState({ alert: "none" });
            }, 3000);
        } else {
            var data = {
                companyId: localStorage.getItem("companyIdconfig"),
                configList: [
                    {
                        autoId: this.state.autoId,
                        configParameter: configNames.OPEN_INDENT,
                        isActive: 1,
                        configValue: this.state.openIndent,
                    },
                ],
            };
            if (this.state.companyId) {
                var msg = "Updated Successfully.";
                this.props.getInwardPOconfiguration(
                    serverApi.UPDATE_CONFIGURATION_NEW,
                    data,
                    msg,
                    this.props.history
                );
            } else {
                this.setState({
                    alert: "block",
                    severity: "error",
                    notmsg: "Sorrry!!! Please Select Company",
                });
                setTimeout(() => {
                    this.setState({ alert: "none" });
                }, 3000);
            }
        }
    };
    onClickRadioButton = (selectedValue) => {
        let openIndent = 0;
        let openIndentYes = false;
        let openIndentNo = false;
        if (selectedValue === "Yes") {
            openIndent = 1;
            openIndentYes = true;
            openIndentNo = false;

        } else if (selectedValue === "No") {
            openIndent = 2;
            openIndentYes = false;
            openIndentNo = true;
        }
        this.setState({
            selectedValue: selectedValue,
            openIndent: openIndent,
            openIndentYes: openIndentYes,
            openIndentNo: openIndentNo,
        });
    };
    render() {
        return (
            <>
                <Alerts
                    alert={this.state.alert}
                    severity={this.state.severity}
                    notmsg={this.state.notmsg}
                />
                <div className="consoleFormContainer">
                    <h5>Open Indent Configuration</h5>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div className="consoleFormBlock">
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={2}
                                >
                                    <Grid item xs={3}>
                                        <div className="">
                                            <RadioButton
                                                labelText="Yes"
                                                value="yes"
                                                groupname="openIndent"
                                                checkednew={this.state.openIndentYes}
                                                onChange={this.onClickRadioButton}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className="">
                                            <RadioButton
                                                labelText="No"
                                                value="no"
                                                groupname="openIndent"
                                                checkednew={this.state.openIndentNo}
                                                onChange={this.onClickRadioButton}
                                            />
                                        </div>
                                    </Grid>
                                </Box>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="consoleFormButtonBlock">
                    <Link to="/CompanyList">
                        <Button variant="contained" className="cancelButton">
                            Cancel
                        </Button>
                    </Link>
                    <Button
                        variant="contained"
                        type="button"
                        className="saveButton"
                        onClick={this.handleConfig}
                    >
                        Save
                    </Button>
                </div>
            </>
        );
    }
}

const mapStatetoProps = (state) => {
    const { companysList, companyscoulmns } = state.BasicDetails;
    const { configdatalist, configdata } = state.Configuration;
    return { companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
    setConfiguration,
    getInwardPOconfiguration
})(OpenIndentConfig);