import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import Classic from "../../assets/images/classic.png";
import Modren from "../../assets/images/modren.png";
import Minimal from "../../assets/images/minimal.png";
import Contemporary from "../../assets/images/contemporary.png";

import RadioButton from "../../components/RadioButton/RadioButton";
import Popup from "../../components/Popup/Popup";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";
import { withRouter } from "react-router-dom";

class PrintStyles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCompanyId: localStorage.getItem("companyIdconfig"),
      chclassic: false,
      chmodern: false,
      chminimal: false,
      chcontemporary: false,
      selectedValue: "",
      printstyle: 0,
      notmsg: "",
      severity: "error",
      alert: "none",
      //printStyles: "",
      configdata: [],
    };

    // this.setTimeout = this.setTimeout.bind(this);
  }

  onClickRadioButton = (selectedValue) => {
    var printstyle = 0;
    var chclassic = false,
      chmodern = false,
      chminimal = false,
      chcontemporary = false;

    if (selectedValue === "Classic") {
      printstyle = 1;
      chclassic = true;
    } else if (selectedValue === "Modern") {
      printstyle = 2;
      chmodern = true;
    } else if (selectedValue === "Minimal") {
      printstyle = 3;
      chminimal = true;
    } else if (selectedValue === "Contemporary") {
      printstyle = 4;
      chcontemporary = true;
    }

    this.setState({
      selectedValue: selectedValue,
      printstyle: printstyle,
      chclassic: chclassic,
      chmodern: chmodern,
      chminimal: chminimal,
      chcontemporary: chcontemporary,
    });
  };

  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      });
    }
    return id;
  };

  handleConfig = () => {
    var printstyle = this.state.printstyle;

    if (!printstyle) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Invoice Print Style",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (!this.state.selectedCompanyId) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: this.state.selectedCompanyId,
        configList: [
          {
            autoId: this.getAutoIdFromList(configNames.INVOICING_PRINT_MODEL),
            configParameter: configNames.INVOICING_PRINT_MODEL,
            isActive: 1,
            configValue: this.state.printstyle,
          },
        ],
      };
      if (this.state.selectedCompanyId) {
        var msg = "Invoice Print Style Updated Successfully.";

        this.props.setConfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };

  // componentDidMount=() =>{
  // componentWillMount = () => {
  //   'var configdata = JSON.parse(localStorage.getItem("configdata"));
  //   console.log("------configdata-------", configdata);
  //   var printstyle = 0;
  //   var chclassic = false,
  //     chmodern = false,
  //     chminimal = false,
  //     chcontemporary = false;
  //   if (configdata) {
  //     configdata.forEach((prop) => {
  //       if (prop.configParameter === "invoicingPrintModel") {
  //         console.log("Magesj rpw", prop);
  //         if (prop.configValue === "1") {
  //           printstyle = 1;
  //           chclassic = true;
  //         } else if (prop.configValue === "2") {
  //           printstyle = 2;
  //           chmodern = true;
  //         } else if (prop.configValue === "3") {
  //           printstyle = 3;
  //           chminimal = true;
  //         } else if (prop.configValue === "4") {
  //           printstyle = 4;
  //           chcontemporary = true;
  //         }
  //         this.setState({
  //           printstyle: printstyle,
  //           chclassic: chclassic,
  //           chmodern: chmodern,
  //           chminimal: chminimal,
  //           chcontemporary: chcontemporary,
  //           selectedCompanyId: localStorage.getItem("companyIdconfig"),
  //           configdata: configdata,
  //         });
  //       }
  //     });
  //   }
  // };

  componentDidMount=()=> {
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";//JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    var printstyle = 0;
    var chclassic = false,
      chmodern = false,
      chminimal = false,
      chcontemporary = false;
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === "invoicingPrintModel") {
          if (prop.configValue === "1") {
            printstyle = 1;
            chclassic = true;
          } else if (prop.configValue === "2") {
            printstyle = 2;
            chmodern = true;
          } else if (prop.configValue === "3") {
            printstyle = 3;
            chminimal = true;
          } else if (prop.configValue === "4") {
            printstyle = 4;
            chcontemporary = true;
          }
          this.setState({
            printstyle: printstyle,
            chclassic: chclassic,
            chmodern: chmodern,
            chminimal: chminimal,
            chcontemporary: chcontemporary,
            selectedCompanyId: localStorage.getItem("companyIdconfig"),
            configdata: configdata,
          });
        }
      });
    }
  }

  componentWillReceiveProps(props) {
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";//JSON.parse(localStorage.getItem("configdata"));
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    var configdata = props.configdata
    var printstyle = 0;
    var chclassic = false,
      chmodern = false,
      chminimal = false,
      chcontemporary = false;
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === "invoicingPrintModel") {
          if (prop.configValue === "1") {
            printstyle = 1;
            chclassic = true;
          } else if (prop.configValue === "2") {
            printstyle = 2;
            chmodern = true;
          } else if (prop.configValue === "3") {
            printstyle = 3;
            chminimal = true;
          } else if (prop.configValue === "4") {
            printstyle = 4;
            chcontemporary = true;
          }
          this.setState({
            printstyle: printstyle,
            chclassic: chclassic,
            chmodern: chmodern,
            chminimal: chminimal,
            chcontemporary: chcontemporary,
            selectedCompanyId: localStorage.getItem("companyIdconfig"),
            configdata: configdata,
          });
        }
      });
    }
  }
  
  

  // componentDidUpdate=(previousProps, previousState) =>{
  //   if(previousState.selectedCompanyId !== localStorage.getItem("companyIdconfig")){
  //     var configdata = JSON.parse(localStorage.getItem("configdata"));
  //     console.log("Nagesh All Data", configdata);
  //     var printstyle = 0;
  //     var chclassic=false, chmodern=false, chminimal=false,  chcontemporary=false;
  //     if(configdata){
  //       configdata.forEach(prop => {
  //           if(prop.configParameter==="invoicingPrintModel"){
  //             console.log("Magesj rpw",prop);
  //             if(prop.configValue==='1'){
  //               printstyle = 1;
  //               chclassic=true;
  //             }else if(prop.configValue==='2'){
  //               printstyle = 2;
  //               chmodern=true;
  //             }else if(prop.configValue==='3'){
  //               printstyle = 3;
  //               chminimal=true;
  //             }else if(prop.configValue==='4'){
  //               printstyle = 4;
  //               chcontemporary=true;
  //             }
  //             this.setState({
  //               printstyle: printstyle,
  //               chclassic:chclassic,
  //               chmodern:chmodern,
  //               chminimal:chminimal,
  //               chcontemporary:chcontemporary,
  //               selectedCompanyId: localStorage.getItem("companyIdconfig"),
  //               configdata: configdata,
  //             })
  //           }
  //       })
  //     }

  //   }
  // }

  render() {
    
    
    
    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />

        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div className="radioButtonBlock">
              <Popup imageButton={Classic} popupImage={Classic} />
              <RadioButton
                labelText="Classic"
                value="classic"
                name="printstyle"
                checkednew={this.state.chclassic}
                groupname="printStyle"
                onChange={this.onClickRadioButton}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="radioButtonBlock">
              <Popup imageButton={Modren} popupImage={Modren} />
              <RadioButton
                labelText="Modern"
                value="modern"
                name="printstyle"
                checkednew={this.state.chmodern}
                groupname="printStyle"
                onChange={this.onClickRadioButton}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="radioButtonBlock">
              <Popup imageButton={Minimal} popupImage={Minimal} />
              <RadioButton
                labelText="Minimal"
                value="minimal"
                name="printstyle"
                checkednew={this.state.chminimal}
                groupname="printStyle"
                onChange={this.onClickRadioButton}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="radioButtonBlock">
              <Popup imageButton={Contemporary} popupImage={Contemporary} />
              <RadioButton
                labelText="Contemporary"
                value="contemporary"
                name="printstyle"
                checkednew={this.state.chcontemporary}
                groupname="printStyle"
                onChange={this.onClickRadioButton}
              />
            </div>
          </Grid>
        </Grid>
        <div className="consoleFormButtonBlock" style={{ float: "right" }}>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default withRouter(
  connect(mapStatetoProps, {
    setConfiguration,
  })(PrintStyles)
);
