import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Component } from "react";
import CloseIcon from "../../assets/images/CloseIcon.png";

class Popup extends Component {
  // There is no need of the constructor, as long as you use arrow => functions
  // Just declare the state like this
  state = {
    open: false,
  };

  render() {
    return (
      <div className="popupContainer">
        <button
          onClick={() => this.setState({ open: !this.state.open })}
          className="popupButton"
        >
          <img src={this.props.imageButton} alt="" />
        </button>
        <header>
          <Dialog open={this.state.open} typeof="div">
            <button
              onClick={() => this.setState({ open: !this.state.open })}
              className="closeButton"
            >
              <img src={CloseIcon} alt="" />
            </button>
            <DialogTitle id="simple-dialog-title">
              <img src={this.props.popupImage} alt="" />
            </DialogTitle>
          </Dialog>
        </header>
      </div>
    );
  }
}
export default Popup;
