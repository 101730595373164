import React, {Component} from "react";

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
          
        };
    
       
        
      }

    render () {
        return (
            <>
                <h1>Hello</h1>
            </>
        )
    }
}

export default Test;
