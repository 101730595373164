import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
// import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
// import PaySchemeFillDetails from "../../HR_Management/PaySchemeCreation/PaySchemeFillDetails";
import PaySchemeFillDetails from "./PaySchemeFillDetails";
// import PaySchemeAddItems from "../PaySchemeCreation/PaySchemeAddItems";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import swal from "sweetalert";

class CreatePayCreation extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 1,
    };
  }

  
  handleSelectChange = (selectedValue, selectedName, name) => {};
  render() {
    const { steps, currentStep } = this.state;
    // console.log('hhdhjikd',this.props.location.state.rowData.id)
    return (
      <div>
          {/* <h1>Create pay scheme</h1> */}
        <Grid className="packageMasterContainer">
          <Grid>
            {currentStep === 1 && (
              <>
                <PaySchemeFillDetails onSelectDep={this.handleSelectChange} currentStep={currentStep}/>
                
              </>
            )}
            
          </Grid>
        </Grid>
      </div>
    );
  }
}

// const mapStatetoProps = (state) => {};

export default withRouter(connect()(CreatePayCreation));
