export const PAY_SCHEME_BY_ID = "pay_scheme_by_id";
export const PAY_SCHEME_BY_ID_SUCCESSFULL="pay_scheme_by_id_successfull";
export const PAYSCHEME_BY_ID = "payScheme_by_id";
export const PAYSCHEME_BY_ID_SUCCESSFULL="payScheme_by_id_successfull";
export const CHECK_PAY_SCHEME= 'check_pay_scheme';
export const CHECK_PAY_SCHEME_SUCCESSFULL= 'check_pay_scheme_successfull';
export const ADD_PAY_SCHEME= 'add_pay_scheme';
export const ADD_PAY_SCHEME_SUCCESSFULL= 'add_pay_scheme_successfull';
export const UPDATE_PAY_SCHEME= 'update_pay_scheme';
export const UPDATE_PAY_SCHEME_SUCCESSFULL= 'update_pay_scheme_successfull';
export const PAY_WAGES_MODES = "pay_wages_modes";
export const PAY_WAGES_MODES_SUCCESSFULL = "pay_wages_modes_successfull";
export const PAY_COMPONENTS = 'pay_components';
export const PAY_COMPONENTS_SUCCESSFULL = "pay_components_successfull"

export const GET_ORDERED_PAYSCHEME_COMPONENT= 'get_ordered_pay_scheme';
export const GET_ORDERED_PAYSCHEME_COMPONENT_SUCCESSFULL= 'get_ordered_pay_scheme_successfull';

// Experience Details
export const EXPERIENCE_DETAILS_VIEW_BY_ID ='experience_details_view_by_id';
export const EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_='experience_details_view_by_id_successfull';
// Work Details 
export const DEPARTMENT_LIST ='department_list';
export const DEPARTMENT_LIST_SUCCESSFULL = 'department_list_successfull';
export const CATEGORY_LIST ='category_list';
export const CATEGORY_LIST_SUCCESSFULL='category_list_successfull';
export const EMP_LEVEL='emp_level';
export const EMP_LEVEL_SUCCESSFULL='emp_level_successfull';
export const REPORTING_MANAGER_LIST ='reporting_manager';
export const REPORTING_MANAGER_LIST_SUCCESSFULL='reporting_manager_successfull';
export const CONTRACTOR ='contractor';
export const CONTRACTOR_SUCCESSFULL='contractor_successfull';
export const WORK_LOCATION='work_location';
export const WORK_LOCATION_SUCCESSFULL='work_location_successfull';
export const DESIGNATION = 'designation';
export const DESIGNATION_SUCCESSFULL='designation_successfull';
// Leave Policy 
export const LEAVE_POLICY_LIST ='leave_policy_list';
export const LEAVE_POLICY_LIST_SUCCESSFULL='leave_policy_list_successfull';
export const LEAVE_LIST ='leave_list';
export const LEAVE_LIST_SUCCESSFULL= 'leave_list_successfull';
export const PAY_SCHEME ="pay_scheme";
export const PAY_SCHEME_SUCCESSFULL="pay_scheme_successfull";
export const LEAVE_POLICY_MAPPING ="leave_policy_mapping";
export const LEAVE_POLICY_MAPPING_SUCCESSFULL ="leave_policy_mapping_successfull";

export const UPDATE_LEAVE_POLICY ="update_leave_policy";
export const UPDATE_LEAVE_POLICY_SUCCESSFULL ="update_leave_policy_successfull";

export const GET_ALL_FILES= "get_all_files";
export const GET_ALL_FILES_SUCCESSFULL = "get_all_files_successfull";
export const ALL_FILES_LIST_SUCCESSFULL = "all_files_list_successfull";

export const DOWNLOAD_FILES ="download_files";
export const DOWNLOAD_FILES_SUCCESSFULL ="download_files_successfull";

export const UPDATE_FILE ='update_file'
export const UPDATE_FILE_SUCCESSFULL='update_file_successfull'

export const DOWNLOAD_ASSET_FORM ='download_asset_form'
export const DOWNLOAD_ASSET_FORM_SUCCESSFULL ='download_asset_form_successfull'

export const OFFER_LETTER_EMAIL_SERVICE = 'offer_letter_email_service'
export const OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL = 'offer_letter_email_service_successfull'
export const GET_PAYSCHEME_LIST = 'get_payscheme_list'
export const GET_PAYSCHEME_LIST_SUCCESSFULL = 'get_payscheme_list_successfull'

export const ADD_SALARY = 'add_salary';
export const ADD_SALARY_SUCCESSFULLY = 'add_salary_successfull';
export const UPDATE_SALARY = 'update_salary';
export const UPDATE_SALARY_SUCCESSFULLY = 'update_salary_successfull';

export const DISPLAY_EMAIL_TEMPLATE ='display_email_template';
export const DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY ='display_email_template_successfull';

export const GET_PROFILE_PICTURE_FILE ='get_profile_picture_file';
export const GET_PROFILE_PICTURE_FILE_SUCCESSFULLY ='get_profile_picture_file_successfull';
export const GET_PROFILE_PICTURE_FILE_SETNULL = "get_profile_picture_file_setnull"


export const GET_PROFILE_PICTURE ='get_profile_picture';
export const GET_PROFILE_PICTURE_SUCCESSFULLY ='get_profile_picture_successfull';

export const APPROVAL_HIERARCHY ='approval_hierarchy';
export const APPROVAL_HIERARCHY_SUCCESSFULLY ='approval_hierarchy_successfull';

// Employee Database Dashboard
export const HRMS_DASHBOARD = "hrms_dashboard";
export const HRMS_DASHBOARD_SUCCESSFULLY = "hrms_dashboard_successfull";
 
export const VIEW_BY_ID_APPROVAL ='view_by_id_approval'
export const VIEW_BY_ID_APPROVAL_SUCCESSFULLY='view_by_id_approval_successfully'

export const WORKER_DETAILS_PROGRESS ='worker_details_progress'
export const WORKER_DETAILS_PROGRESS_SUCCESSFULLY='worker_details_progress_successfully'

export const GET_WORKER_DETAILS_PROGRESS ='get_worker_details_progress'
export const GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY='get_worker_details_progress_successfully'
export const GET_LIST_ROW_DATA = 'get_list_row_data'
export const DELETE_WORKER_EDUCATIONAL_DETAILS ='delete_worker_educational_details'

export const DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY ='delete_worker_educational_details_successfully'

