/****
 * Created By Nagesh Medisetty 01-02-2022
 * TextField With Validation Required
 */
 import React, { Component } from 'react';
 import { required, number, email } from "../../Utilities/Validations";

 class TextField extends Component {

  constructor(props) {
      super(props);
      this.state = {
        error: false,
      };
      this.handleChange = this.handleChange.bind(this);
  }

 /* Handle Validations */
  handleChange = (event) => {
    
    const { value } = event.target;
    if(this.props.required){
      var error = required(value, "This field is required");
      this.setState({ error: error});
      if(this.props.caps==='ON'){
        this.props.onChange(value.toUpperCase()); 
      }else{
        this.props.onChange(value); 
      }   
    }  
    if(this.props.number){
      if(value){
        var errornum = number(value, "Invalid Phone Number");
        this.setState({ error: errornum});
        if(errornum===false)
          this.props.onChange(value); 
      }      
    }    
    if(this.props.numberreq){
      if(value){
        var errornum = number(value, "Invalid Phone Number");
        this.setState({ error: errornum});
        if(!errornum){
          this.props.onChange(value); 
        }         
      }else{
        var errornum = required(value, "This field is required");
        this.setState({ error: errornum});
        this.props.onChange(value);
      }     
    }
    if(this.props.email){      
      var erroremail = email(value, "Invalid Email Address");
      this.setState({ error: erroremail});
      if(this.props.caps==='ON'){
        this.props.onChange(value.toUpperCase()); 
      }else{
        this.props.onChange(value); 
      }    
    }
    if(this.props.emailreq){
      if(value){
        var erroremail = email(value, "Invalid Email Address");
        this.setState({ error: erroremail});
      }else{
        var error = required(value, "This field is required");
        this.setState({ error: error});
      }
      if(this.props.caps==='ON'){
        this.props. onChange(value.toUpperCase()); 
      }else{
        this.props.onChange(value); 
      }    
    }
    
      
  };
 
   render() {
         return(
          <div className="TextFieldBlock">
          {this.props.label && <label htmlFor="app-input-field" style={{fontSize: [this.props.fontSize]}}>{this.props.label}</label>}
          <input
          type={this.props.type}
          value={this.props.value}
          className= {this.state.error ? "TextField errorTextField" : "TextField" } 
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          style={{width: [this.props.width], marginLeft: [this.props.marginLeft]}}
          readOnly={this.props.readOnly}
          disabled={this.props.disabled}
        />
        {this.state.error && <span className="text-danger">{this.state.error.message}</span>}
    </div>
         )
     }
 }
 
 export default TextField;
