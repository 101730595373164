import React, {Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


class Testnew extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };    
  }



  render() {  

   
    
    return (
      <><div>Nagesh With Test New</div>
    </>
    );
  }

  
}
const mapStatetoProps = (state) => {
};
// export default CompanyCreation;
export default withRouter(connect(mapStatetoProps, {
})(Testnew));

