import {
    CATEGORY_LIST, CONTRACTOR, DEPARTMENT_LIST, DESIGNATION,
     EMP_LEVEL, LEAVE_LIST, LEAVE_POLICY_LIST, PERSONAL_DETAILS, REPORTING_MANAGER_LIST,
    WORK_LOCATION, PAY_SCHEME, PAY_SCHEME_BY_ID, CHECK_PAY_SCHEME, GET_PAYSCHEME_LIST,
    LEAVE_POLICY_MAPPING, GET_ALL_FILES, DOWNLOAD_FILES, UPDATE_FILE, DOWNLOAD_ASSET_FORM, OFFER_LETTER_EMAIL_SERVICE, EXPERIENCE_DETAILS_VIEW_BY_ID, DISPLAY_EMAIL_TEMPLATE, ADD_SALARY, PAY_WAGES_MODES, PAY_COMPONENTS, ADD_PAY_SCHEME, GET_PROFILE_PICTURE, GET_PROFILE_PICTURE_FILE, UPDATE_PAY_SCHEME, UPDATE_SALARY, HRMS_DASHBOARD, PAYSCHEME_BY_ID, APPROVAL_HIERARCHY, VIEW_BY_ID_APPROVAL, UPDATE_LEAVE_POLICY, WORKER_DETAILS_PROGRESS, GET_WORKER_DETAILS_PROGRESS, DELETE_WORKER_EDUCATIONAL_DETAILS,GET_PROFILE_PICTURE_FILE_SETNULL, GET_ORDERED_PAYSCHEME_COMPONENT
} from "./actionTypes";
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { serverApi, serverVars } from "../../../helpers/Consts";
import {
     
    PersonalDetailsSuccessfull, departmentListSuccessfull,
    categoryListSuccessfull, empLevelSuccessfull,
    ReportingManagerListSuccessfull, contractorSuccessfull,
    workLocationSuccessfull, designationSuccessfull,
    leavePolicySuccessfull, leaveListSuccessfull, paySchemeSuccessfull, ExperienceDetailsSuccessfull, downloadAssetFormsuccessfull,
    paySchemeIdSuccessfull, checkPaySchemeSuccessfull, leavePolicyMappingSuccessfull, AllFilesSuccessfull, downLoadFiles, updateFiles,
    offerLetterEmailSuccessfull, ViewByIdExperienceDetailsSuccessfull, getPayschemeList,
    getPayschemeListSuccessfull, addSalarySuccessfull, displayEmailTemplateSuccessfull, payWagesModesSuccessfull, payComponentsSuccessfull, AddPaySchemeSuccessfull,
    UpdatePaySchemeSuccessfull, getProfilePicSuccessfull, getProfilePicFileSuccessfull, getProfilePic, WorkerDetailsProgressSuccessfull,
    updateSalarySuccessfull, hrmsDashboardSuccessfull, paySchemeByIdSuccessfull, ApprovalHierarchySuccessfull, ApprovalHierarchy, ViewByIdApprovalSuccessfull, UpdateleavePolicySuccessfull, GetWorkerDetailsProgressSuccessfull
    , WorkerDetailsProgress, deleteEducationalDetailsSuccessfull, FilesRespSuccessfull, getOrderPaySchemeSuccessful
} from "./actions";
import { getList, postAdd, getListurl } from '../../../helpers/Server_Helper';
import swal from "sweetalert";

// Work Details
function* getDepartment({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        // console.log('Department',response)
        yield put(departmentListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// Category 
function* getCategoryList({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(categoryListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// Emp Level 
function* getEmpLevel({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url)
        yield put(empLevelSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// Reporting Manager 
function* getReportingManager({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log('Reporting Manager', response)
        yield put(ReportingManagerListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// Contractor 
function* getContractor({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log('Contractor', response)
        yield put(contractorSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// WorkLocation 
function* getWorkLocation({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        // console.log('WorkLocation', response)
        yield put(workLocationSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// Designation 
function* getDesignation({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(designationSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// Leave Policy 
function* getLeavePolicy({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log('LeavePolicy', response)
        yield put(leavePolicySuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* UpdateLeavePolicy({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        // console.log('LeavePolicy', response)
        yield put(UpdateleavePolicySuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* getLeaveList({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log('LeaveList', response)
        yield put(leaveListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getPaySchemeS({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        // console.log('PayScheme', response)
        yield put(paySchemeSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getPaySchemeId({ payload: { url, value, history } }) {
    try {
        const response = yield call(getListurl, url);
        if (response) {
            var obj = {};
            if (response.data) {
                if (response.data.paySchemeDetailsDTO) {
                    response.data.paySchemeDetailsDTO.map((prop) => {
                        obj = { ...obj, [prop.componentId]: prop.formula }
                    });
                    var url = serverApi.CHECK_PAY_SCHEME + '1111/' + value;
                    yield put(getPayschemeList(url, obj, history));
                    //   this.props.getPayschemeList(serverApi.CHECK_PAY_SCHEME + '1111/' + value, obj, history);
                }
            }
            yield put(paySchemeIdSuccessfull({ response }));
        }

    } catch (error) {
        console.log(error)
    }
}
function* getPaySchemeById({ payload: { url, value, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log(value)
        if (response) {
            var obj = {};
            if (response.data) {
                if (response.data.paySchemeDetailsDTO) {
                    response.data.paySchemeDetailsDTO.map((prop) => {
                        obj = { ...obj, [prop.componentId]: prop.formula }
                    });
                    console.log(obj)
                    let url = serverApi.CHECK_PAY_SCHEME + '1111/' + value;
                    yield put(getPayschemeList(url, obj, history));
                    //   this.props.getPayschemeList(serverApi.CHECK_PAY_SCHEME + '1111/' + value, obj, history);
                }
            }
            // yield put(paySchemeIdSuccessfull({ response }));
        }
        yield put(paySchemeByIdSuccessfull({ response }));
    } catch (error) {
        console.log(error)
    }
}

function* getPaySchemeByOrderedId({ payload: { url } }) {
    try {
        const response = yield call(getListurl, url);
        if (response) {
            if (response.data) {
                yield put(getOrderPaySchemeSuccessful({ response }));
            }
        }
    } catch (error) {
        console.log(error)
    }
}

function* addingSalary({ payload: { url, data, obj, history } }) {
    try {
        const response = yield call(getList, url, data);
        if (response) {
            let urls = serverApi.APPROVAL_BUTTON + JSON.parse(localStorage.getItem("authUser")).userId + "/15/company/" + localStorage.getItem("companyId") + "/branch/" + obj.branchId + "/status/" + obj.empStatus;
            yield put(getApprovalHierarchy(urls, history))
        }
        yield put(ApprovalHierarchy({ response }))
        swal(response.message, { icon: "success" });
    } catch (error) {
        console.log(error)
    }
}
function* updateSalary({ payload: { url, data, obj, history } }) {
    try {
        const response = yield call(getList, url, data);
        if (response) {
            if (response.status) {
                let urls = serverApi.APPROVAL_BUTTON + JSON.parse(localStorage.getItem("authUser")).userId + "/15/company/" + localStorage.getItem("companyId") + "/branch/" + obj.branchId + "/status/" + obj.empStatus;
                console.log(urls)
                yield put(ApprovalHierarchy(urls, history))
            }
            yield put(updateSalarySuccessfull({ response }))
            swal(response.message, { icon: "success" });
        }


    } catch (error) {
        console.log(error)
    }
}

function* getProfilePicFiles({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log(response)
        yield put(getProfilePicFileSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getProfilePicFilesSetNull({ payload: { data} }) {
    try {
        const response = null
        console.log(response)
        yield put(getProfilePicFileSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* getProfilePicUrl({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(getProfilePicSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* checkPayScheme({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        console.log('getting response from saga', response)

        yield put(checkPaySchemeSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* addPayScheme({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(AddPaySchemeSuccessfull({ response }))
        console.log('getting response from saga', response)
        swal(response.message, { icon: "success" });
        history.push("/payscheme")

        if(response){

            const data = {
                activeStatus: "",
                status: "",
                // orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
                // companyId: localStorage.getItem("companyIdconfig")
            };

            yield put(serverApi.EMPLOYEE_PAY_SCHEME, data,history);

            console.log("calling the api here",serverApi.EMPLOYEE_PAY_SCHEME)
        }else{
            const data = {
                activeStatus: "",
                status: "",
                // orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
                // companyId: localStorage.getItem("companyIdconfig")
            };

            yield put(serverApi.EMPLOYEE_PAY_SCHEME, data,history);
        }
    } catch (error) {
        // swal("error:" + error,{icon: "danger"});
        console.log(error)
    }
}
function* updatePayScheme({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(UpdatePaySchemeSuccessfull({ response }))
        console.log('getting response from saga', response)
        swal(response.message, { icon: "success" })
        history.push("/payscheme")

        // history.push("/payscheme")
        if(response){

            const data = {
                activeStatus: "",
                status: "",
                // orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
                // companyId: localStorage.getItem("companyIdconfig")
            };

            yield put(serverApi.EMPLOYEE_PAY_SCHEME, data,history);

            console.log("calling the api here",serverApi.EMPLOYEE_PAY_SCHEME)
        }else{
            const data = {
                activeStatus: "",
                status: "",
                // orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
                // companyId: localStorage.getItem("companyIdconfig")
            };

            yield put(serverApi.EMPLOYEE_PAY_SCHEME, data,history);
        }
    } catch (error) {
        // swal("error:" + error,{icon: "danger"});
        console.log(error)
    }
}
function* getPayComponents({ payload: { url, data, history } }) {
    try {
        const response = yield call(getListurl, url);
        console.log('PayScheme', response)
        yield put(payComponentsSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getPayschemeLists({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(getPayschemeListSuccessfull({ response }))
        console.log('getting response from saga payscheme', response)
    } catch (error) {
        console.log(error)
    }
}
function* leavePolicyMapping({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(leavePolicyMappingSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getAllFileList({ payload: { url, data, history } }) {
    try {
        const response = yield call(getListurl, url, data);
        console.log('Data', response)
        yield put(AllFilesSuccessfull({ response }))
        yield put(FilesRespSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* downLoadFile({ payload: { url, data, history } }) {
    try {
        const response = yield call(getListurl, url, data);
        yield put(downLoadFiles({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* updateFile({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(updateFiles({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* setOfferLetterEmail({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(offerLetterEmailSuccessfull({ response }))
        swal(response.data.message, { icon: "success" })
    } catch (error) {
        console.log(error)
        swal(error, { icon: "error" })
    }
}
function* getEmailTemplate({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(displayEmailTemplateSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* getViewByIdExperienceDetails({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(ViewByIdExperienceDetailsSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* downloadAssetForm({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(downloadAssetFormsuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}


function* payWagesModes({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(payWagesModesSuccessfull({ response }))
        // console.log("saga response", response)
        // swal(response.message, { icon: "success" });
    } catch (error) {
        console.log(error)
    }
}
function* getApprovalHierarchy({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(ApprovalHierarchySuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getApprovalHierarchyViewById({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(ViewByIdApprovalSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
// HRMS DASHBOARD
function* getHrmsDashboardData({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        yield put(hrmsDashboardSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* setWorkerDetailsProgress({ payload: { url, data, history } }) {
    try {
        const response = yield call(postAdd, url, data);
        yield put(WorkerDetailsProgressSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* getWorkerProgress({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(GetWorkerDetailsProgressSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}
function* deleteWorkerEducationalDetails({ payload: { url, history } }) {
    try {
        const response = yield call(getListurl, url);
        yield put(deleteEducationalDetailsSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

export function* watchEmployeeList() {
    // yield takeEvery(EDUCATIONAL_DETAILS, setEducationaldetails)
    yield takeEvery(DEPARTMENT_LIST, getDepartment)
    yield takeEvery(CATEGORY_LIST, getCategoryList)
    yield takeEvery(EMP_LEVEL, getEmpLevel)
    yield takeEvery(REPORTING_MANAGER_LIST, getReportingManager)
    yield takeEvery(CONTRACTOR, getContractor)
    yield takeEvery(WORK_LOCATION, getWorkLocation)
    yield takeEvery(PAY_WAGES_MODES, payWagesModes)
    yield takeEvery(DESIGNATION, getDesignation)
    yield takeEvery(LEAVE_POLICY_LIST, getLeavePolicy)
    yield takeEvery(LEAVE_LIST, getLeaveList)
    yield takeEvery(PAY_SCHEME, getPaySchemeS)
    // yield takeEvery(EXPERIENCE_DETAILS, setExperienceDetails)
    yield takeEvery(EXPERIENCE_DETAILS_VIEW_BY_ID, getViewByIdExperienceDetails)
    yield takeEvery(PAY_SCHEME_BY_ID, getPaySchemeId)
    yield takeEvery(PAYSCHEME_BY_ID, getPaySchemeById)
    yield takeEvery(CHECK_PAY_SCHEME, checkPayScheme)
    yield takeEvery(LEAVE_POLICY_MAPPING, leavePolicyMapping)
    yield takeEvery(GET_ALL_FILES, getAllFileList)
    yield takeEvery(DOWNLOAD_FILES, downLoadFile)
    yield takeEvery(UPDATE_FILE, updateFile)
    yield takeEvery(DOWNLOAD_ASSET_FORM, downloadAssetForm)
    yield takeEvery(OFFER_LETTER_EMAIL_SERVICE, setOfferLetterEmail)
    
    yield takeEvery(DISPLAY_EMAIL_TEMPLATE, getEmailTemplate)
    yield takeEvery(ADD_SALARY, addingSalary)
    yield takeEvery(UPDATE_SALARY, updateSalary)
    yield takeEvery(GET_PAYSCHEME_LIST, getPayschemeLists)
    
    yield takeEvery(GET_PROFILE_PICTURE, getProfilePic)
    yield takeEvery(GET_PROFILE_PICTURE_FILE, getProfilePicFiles)
    yield takeEvery(GET_PROFILE_PICTURE_FILE_SETNULL, getProfilePicFilesSetNull)
    yield takeEvery(GET_PROFILE_PICTURE, getProfilePicUrl)

    yield takeEvery(PAY_COMPONENTS, getPayComponents)
    yield takeEvery(ADD_PAY_SCHEME, addPayScheme)
    yield takeEvery(UPDATE_PAY_SCHEME, updatePayScheme)
    yield takeEvery(HRMS_DASHBOARD, getHrmsDashboardData)
    yield takeEvery(APPROVAL_HIERARCHY, getApprovalHierarchy)
    yield takeEvery(VIEW_BY_ID_APPROVAL, getApprovalHierarchyViewById)
    yield takeEvery(UPDATE_LEAVE_POLICY, UpdateLeavePolicy)
    yield takeEvery(WORKER_DETAILS_PROGRESS, setWorkerDetailsProgress)
    yield takeEvery(GET_WORKER_DETAILS_PROGRESS, getWorkerProgress)
    yield takeEvery(DELETE_WORKER_EDUCATIONAL_DETAILS,deleteWorkerEducationalDetails)
    yield takeEvery(GET_ORDERED_PAYSCHEME_COMPONENT, getPaySchemeByOrderedId)
}

function* EmployeeSaga() {
    yield all([
        fork(watchEmployeeList)
    ])
}

export default EmployeeSaga;
