import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from '../../../components/TextField/TextFieldNormalNew';
import { serverApi } from "../../../helpers/Consts";
import { create_bank_account,update_bank_account,BranchSelectionList} from '../../../store/Master/BankAccountMaster/actions';
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import moment from "moment";
import swal from "sweetalert";

class createBankAccountMaster extends Component {
  constructor() {
    super();
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem('companyId'),
      accountNo: '',
      accountName: '',
      IFSCCode: '',
      bankName: '',
      MICRCode: '',
      accountType: '',
      accountPurpose: '',
      createdBy: null,
      createdOn: "",
      accountTypeValue: '',
      id: '',
      createdDate: '',
      branch:'',
      branchList:[]
    };
    // this.validateFields = this.validateFields.bind(this);
  }
 
  onValidateFields(obj,msg){
    var validation = "";
    const { accountNo, accountName, IFSCCode, bankName } = this.state;
    
    if(accountNo === "" || accountNo === undefined){
      
      return validation = "Please Enter the Account No !!!";
    }
    if(accountName === "" || accountName === undefined){
      return validation = "Please Enter the Account Name !!!";
    }
    if(IFSCCode === "" || IFSCCode === undefined){
      return validation = "Please Enter the IFSC Code !!!";
    }
    if(bankName === "" || bankName === undefined){
      return validation = "Please Enter the Bank Name !!!";
    }
    return validation;
  }

onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    var validation = this.onValidateFields();
    
    if(validation===''){
       if (this.props.location.rowdata === undefined) {

        const data = {
          companyId:localStorage.getItem('companyIdconfig'),
          createdBy: userId.toString(),
          accountNo: this.state.accountNo,
          accountantName: this.state.accountName,
          ifscCode: this.state.IFSCCode,
          bankName: this.state.bankName,
          micrCode: this.state.MICRCode,
          accountType: this.state.accountTypeValue,
          accountPurpose: this.state.accountPurpose,
          createdOn: moment().format(),
          branch:this.state.branch,
        };
        var regex = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;
        var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        var pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$');
        if(regex.test(this.state.accountNo)){
          if(pattern.test(this.state.IFSCCode)){
            // alert("successfully")
            this.props.create_bank_account(serverApi.CREATE_BANK_ACCOUNT,data,this.props.history)
            // this.props.createBankAccount(serverApi.CREATE_BANK_ACCOUNT, data, this.props.history); // calling create bank account api
          }else{
            swal('Please enter valid ifsc code....');
          }          
        }else{
          swal('Please enter valid account number....');
        }
       
      }
      else {
        const data = {
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem('companyIdconfig'),
          createdBy: userId.toString(),
          accountNo: this.state.accountNo.toString(),
          accountantName: this.state.accountName,
          ifscCode: this.state.IFSCCode,
          bankName: this.state.bankName,
          micrCode: this.state.MICRCode,
          accountType: this.state.accountTypeValue,
          accountPurpose: this.state.accountPurpose,
          createdOn: moment().format(),
          id: this.state.id,
          branch: this.state.branch,
        }
        var regex = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;
        var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
        var pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$');
        if(regex.test(this.state.accountNo)){
          if(pattern.test(this.state.IFSCCode)){
            // alert("here the code")
            this.props.create_bank_account(serverApi.UPDATE_BANK_ACCOUNT, data, this.props.history); // calling create bank account api
          }else{
            swal('Please enter valid ifsc code....');
          }          
        }else{
          swal('Please enter valid account number....');
        }
      }
    }else{
      swal(validation);
    }

  }



    // if (
    //   accountNo !== "" && accountNo !== undefined &&
    //   accountName !== "" && accountName !== undefined &&
    //   IFSCCode !== "" && IFSCCode !== undefined &&
    //   bankName !== "" && bankName !== undefined
    // ) {
    //   if (this.props.location.rowdata === undefined) {

    //     const data = {
    //       companyId:localStorage.getItem('companyIdconfig'),
    //       createdBy: userId.toString(),
    //       accountNo: this.state.accountNo,
    //       accountantName: this.state.accountName,
    //       ifscCode: this.state.IFSCCode,
    //       bankName: this.state.bankName,
    //       micrCode: this.state.MICRCode,
    //       accountType: this.state.accountTypeValue,
    //       accountPurpose: this.state.accountPurpose,
    //       createdOn: moment().format(),
    //       branch:this.state.branch,
    //     };
    //     var regex = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;
    //     var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    //     var pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$');
    //     if(regex.test(this.state.accountNo)){
    //       if(pattern.test(this.state.IFSCCode)){
    //         // alert("successfully")
    //         this.props.create_bank_account(serverApi.CREATE_BANK_ACCOUNT,data,this.props.history)
    //         // this.props.createBankAccount(serverApi.CREATE_BANK_ACCOUNT, data, this.props.history); // calling create bank account api
    //       }else{
    //         swal('Please enter valid ifsc code....');
    //       }          
    //     }else{
    //       swal('Please enter valid account number....');
    //     }
       
    //   }
    //   else {
    //     const data = {
    //       cipher: this.state.userDit.cipher,
    //       companyId: localStorage.getItem('companyIdconfig'),
    //       createdBy: userId.toString(),
    //       accountNo: this.state.accountNo.toString(),
    //       accountantName: this.state.accountName,
    //       ifscCode: this.state.IFSCCode,
    //       bankName: this.state.bankName,
    //       micrCode: this.state.MICRCode,
    //       accountType: this.state.accountTypeValue,
    //       accountPurpose: this.state.accountPurpose,
    //       createdOn: moment().format(),
    //       id: this.state.id,
    //       branch: this.state.branch,
    //     }
    //     var regex = /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/;
    //     var reg = /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/;
    //     var pattern = new RegExp('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$');
    //     if(regex.test(this.state.accountNo)){
    //       if(pattern.test(this.state.IFSCCode)){
    //         // alert("here the code")
    //         this.props.create_bank_account(serverApi.UPDATE_BANK_ACCOUNT, data, this.props.history); // calling create bank account api
    //       }else{
    //         swal('Please enter valid ifsc code....');
    //       }          
    //     }else{
    //       swal('Please enter valid account number....');
    //     }
        
    //   }
    // } else {
    //  swal("Please Enter the mandatory fields",{icon:"error"})
    // }
    // alert('successfull');
  
  // Function for Previous Page
  onClickCancel = () => {
    this.props.history.push("/bank_account_master");
  };
  // Function For TextFiled
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    // if (key === "accountNo") {
    //   const accNo = event.replace(/[0-9]+/g, '');
    //   this.setState({
    //     accountNo: accNo,
    //   });
    // }

    if (key === "accountNo") {
      const numericRegex = /[0-9]+/g;
      const numbersOnly = event.match(numericRegex).join('');
    
      this.setState({
        accountNo: numbersOnly,
      });
    }
    
    if (key === "accountName") {
      this.setState({
        accountName: event,
      });
    }
    if (key === "IFSCCode") {
      const ifscCode = event.replace(/[^a-zA-Z0-9\s]+/g, '');
      this.setState({
        IFSCCode: ifscCode,
      });
    }
    if (key === "bankName") {
      const bankacc = event.replace(/[^a-zA-Z\s]+/g, '');  
      this.setState({
        bankName: bankacc,
      });
    }
    if(key=== "MICRCode"){
      this.setState({
        MICRCode:event,
      })
    }
    if (key === "accountPurpose") {
      const accpurpose = event.replace(/[^a-zA-Z\s]+/g, '');  
      this.setState({
        accountPurpose: accpurpose,
      });
    }
    if (key === "branch") {
      const branchname = event.replace(/[^a-zA-Z\s]+/g, '');  
      this.setState({
        branch: branchname,
      });
    }
    
  };
  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }
  componentDidMount() {
  window.addEventListener('scroll', this.handleScroll);


    this.props.BranchSelectionList(serverApi.BRANCH_SELECTION_LIST_API+localStorage.getItem("companyIdconfig"),this.props.history) // branch list api
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: this.state.companyId,
    };
    
    if(localStorage.getItem("companyName")){
      this.setState({
        accountName: localStorage.getItem("companyName")  
       
      })
     
    }
    if (this.props.location.rowdata) {
      this.setState({
        accountNo: this.props.location.rowdata.accountNo,
        createdBy: this.props.location.rowdata.createdBy,
        accountName: this.props.location.rowdata.accountantName,
        IFSCCode: this.props.location.rowdata.ifscCode,
        bankName: this.props.location.rowdata.bankName,
        MICRCode: this.props.location.rowdata.micrCode,
        accountTypeValue: this.props.location.rowdata.accountType,
        accountPurpose: this.props.location.rowdata.accountPurpose,
        id: this.props.location.rowdata.id,
        createdOn: this.props.location.rowdata.createDateTime,
        branch: this.props.location.rowdata.branch,
      });
      const dateEntered = this.props.location.rowdata.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdDate: dateIsValid,
      })
    }
  }

  componentWillReceiveProps = (props) => {
    var list = [];
    if(props.Branch_Selection_List){
      if(props.Branch_Selection_List.data){
        props.Branch_Selection_List.data.map((prop) => {
         list.push({
          name: prop.branch,
          label: prop.label,
          value: prop.label
         })
        })
        this.setState({
          branchList:list
        })
      }
    }
    if(localStorage.getItem("companyName")){
      this.setState({
        accountName: localStorage.getItem("companyName")  
       
      })
     
    }
  }


  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if(name==="branch"){
      this.setState({
        branch:selectedValue,
        
      })
    }
    if(name==="accountType"){
      this.setState({
        accountType: selectedName,
        accountTypeValue: selectedValue,
      });
    }

  }

  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    this.setState({
      isDropdownOpen:true
    })
  };
  
  render() {
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.accountNo}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("accountNo")}
                      label="Account No" />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.accountName}
                      readOnly={true}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("accountName")}
                      label="Account Holder Name" />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.IFSCCode}
                      caps="OFF"
                      minValue="0"
                      onChange={this.onhandlechangeValue("IFSCCode")}
                      label="IFSC Code" />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldReq
                      value={this.state.bankName}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("bankName")}
                      label="Bank Name" />
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.MICRCode}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("MICRCode")}
                      label="MICR Code" />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <label>Account Type</label>
                      <DynamicSelect
                        arrayOfData={accountType}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.accountTypeValue}
                        update={this.state.accountTypeValue ? 1 : 0}
                        name="accountType"
                        label="Account Type"/>
                      
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <TextFieldNormal
                      value={this.state.accountPurpose}
                      caps="OFF"
                      onChange={this.onhandlechangeValue("accountPurpose")}
                      label="Account Purpose" />
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <label>Branch</label>
                      <DynamicSelect
                        arrayOfData={this.state.branchList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.branch}
                        update={this.state.branch ? 1 : 0}
                        name="branch"
                        />
                      
                    </div>
                  </Box>
                  

                  {this.props.location.state === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">Created By : <b>{this.state.createdBy}</b></span>
                        </div>
                        <div>
                          <span className="taxLabel">Created On :<b> {this.state.createdDate}</b></span>
                        </div>
                      </div>
                    </Box>
                  )}

                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.rowdata !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
          {/* common popup for validation */}
          {/* {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">Please select all the mandatory fields</div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )} */}
        </div>
      </div>
    )
  }
}
  const accountType = [
    {
      value: 'null',
      label: 'Select',
      name: 'Select',
    },
    {
      value: 'Savings',
      label: 'Savings',
      name: 'Savings',
    },
    {
      value: 'Cash Credit',
      label: 'Cash Credit',
      name: 'Cash Credit',
    },
    {
      value: 'Current',
      label: 'Current',
      name: 'Current',
    },
    {
      value: 'Over Draft',
      label: 'Over Draft',
      name: 'Over Draft',
    },
    {
      value: 'Term Loan',
      label: 'Term Loan',
      name: 'Term Loan',
    },
    {
      value: 'Checking',
      label: 'Checking',
      name: 'Checking',
    },
    {
      value: 'Fixed Diposit',
      label: 'Fixed Diposit',
      name: 'Fixed Diposit',
    },
  ]

const mapStatetoProps = (state) => {
  const { create_bank_account, update_bank_account,Branch_Selection_List } = state.BankAccountReducer;
  return { create_bank_account, update_bank_account,Branch_Selection_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    create_bank_account,
    update_bank_account,
    BranchSelectionList
  })(createBankAccountMaster)
);
