import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";

class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLabel: "",
      selectedValue: "",
      selectedName: "",
    };
  }

  handleChange = (event) => {
    const selectedValue = event.value;
    const selectedName = event.name;
    const selectedLabel = event.label;
    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      selectedLabel: selectedLabel,
    });
    this.props.onSelectChange(selectedValue, selectedName, this.props.name, this.props.row, selectedLabel, this.props.index);
  };

  handleMenuOpen = () => {
    if (this.props.handleMenuOpen) {
      this.props.handleMenuOpen();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.arrayOfData) {
      nextProps.arrayOfData.forEach((row) => {
        if (nextProps.selected === row.value) {
          this.setState({
            selectedLabel: row.label,
            selectedValue: nextProps.selected,
            selectedName: row.label,
          });
        }
      });
    }
  }

  render() {
    const { arrayOfData, label, required, selected, isDisabled, isDropdownOpen, update } = this.props;
    const { selectedValue, selectedLabel, selectedName } = this.state;
    const selectedOption = selected ? { value: selectedValue, label: selectedLabel } : "";

    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div>
          <label htmlFor="app-input-field">{label}</label>
          {required && <div className="mandatoryField">*</div>}
        </div>
        <Select
          options={arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          defaultValue={selectedOption}
          value={selectedOption}
          placeholder="Select"
          onChange={this.handleChange}
          isDisabled={isDisabled}
          closeMenuOnScroll={false}
          onMenuOpen={this.handleMenuOpen}
          classNamePrefix="react-select"
          styles={{
            menu: (provided) => ({ ...provided, zIndex: 9999, maxHeight: '200px' }),
            menuList: (provided) => ({ ...provided, maxHeight: '200px', overflowY: 'auto' }),
          }}
        />
      </FormControl>
    );
  }
}

export default DynamicSelect;
