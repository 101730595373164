import React, { Component } from "react";
import { Grid,} from "@mui/material";
import { connect } from "react-redux";
import Tabs from "../../components/TabsNavigation/TabsNavigation";
import PrintStyles from "../../Pages/Configuration/PrintStyles";
import MrinInvoice from "../../Pages/Configuration/MrinInvoice";
import ModifyInvno from "../../Pages/Configuration/ModifyInvno";



class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    return (
      <>               
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs printstyles={<PrintStyles configdata={this.props.configdata} selectedCompanyId={this.props.selectedCompanyId}/>} mrininvoice={<MrinInvoice configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId}/>} modifyinvno={<ModifyInvno configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId}/>} />                  
          </Grid>                
        </Grid>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  // const { locationlist, model, rowdata } = state.Location;
  // return { locationlist, model, rowdata };
};

export default connect(mapStatetoProps, { 
})(Invoice);
