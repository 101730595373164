import React from "react";
// import "./table.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";

export default class RoleManagementGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allChecked: false,
      isExpand1: false,
      isExpand2: false,
      isExpand3: false,
      isExpand4: false,
      isExpand5: false,
      isExpand6: false,
      inner_tr_color: "#FFF",

      accordian: [
        {
          name: "Purchase",

          submodulesNumber: "3 Sections",
          subModules: [
            {
              name: "indent",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "purchase",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "Sale",
              view: false,
              add: false,
              update: false,
            },
          ],
          view: false,
          add: false,
          update: false,
        },
        {
          name: "Store",
          submodulesNumber: "2 Sections",
          subModules: [
            {
              name: "indent",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "purchase",
              view: false,
              add: false,
              update: false,
            },
          ],
          view: false,
          add: false,
          update: false,
        },
        {
          name: "Sales",
          submodulesNumber: "4 Sections",
          subModules: [
            {
              name: "indent",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "purchase",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "Sale",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "Store",
              view: false,
              add: false,
              update: false,
            },
          ],
          view: false,
          add: false,
          update: false,
        },
        {
          name: "HRMS",
          submodulesNumber: "3 Sections",
          subModules: [
            {
              name: "indent",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "purchase",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "Sale",
              view: false,
              add: false,
              update: false,
            },
          ],
          view: false,
          add: false,
          update: false,
        },
        {
          name: "Production",
          submodulesNumber: "1 Sections",
          subModules: [
            {
              name: "indent",
              view: false,
              add: false,
              update: false,
            },
          ],
          view: false,
          add: false,
          update: false,
        },
        {
          name: "Accounting",
          submodulesNumber: "2 Sections",
          subModules: [
            {
              name: "indent",
              view: false,
              add: false,
              update: false,
            },
            {
              name: "purchase",
              view: false,
              add: false,
              update: false,
            },
          ],
          view: false,
          add: false,
          update: false,
        },
      ],
    };
  }

  handleChange = (moduleName, moduleOperation) => {
    // step:1 :get the desired arry from local state.
    let accordian = this.state.accordian;
    // console.log('accordian', accordian)
    //   step:2  :find the index of the object based on this.props.
    let inddex = accordian.findIndex(
      (accordianObj) => accordianObj.name === moduleName
    );
    let desiredObj = accordian[inddex];
    // let desiredObj = accordian.find((accordianObj) => {
    //   return accordianObj.name === moduleName
    // })
    // step:3 :update the found object and respective operation key with relavent data.
    // switch (moduleOperation) {
    //   case 'view':
    desiredObj[moduleOperation] = !desiredObj[moduleOperation]; // Assign new key
    // console.log('viewOperation', desiredObj)
    let desiredSubModules = desiredObj.subModules;
    // console.log('desiredSubModules', desiredSubModules)
    let updatedSubModules = desiredSubModules.map((it, ind) => {
      it[moduleOperation] = desiredObj[moduleOperation];
      //   console.log(
      //     'it',
      //     it,
      //     ' !desiredObj[moduleOperation]',
      //     !desiredObj[moduleOperation],
      //   )
      return it;
    });
    desiredObj.subModules = updatedSubModules;
    // ...set state array obj update based on
    accordian[inddex] = desiredObj;
    this.setState({ accordian: accordian });
  };

  //     // tep:4 : get the desired submodules from the object

  //     // step: 5: update the respective operation key with relavent data in submodules too.

  //     // step:6  :replace the updated object in original array.
  //     // this.setState({
  //     //   isValue: !this.state.isValue,
  //     // })
  //   }

  handleClick = (idx, color) => {
    switch (idx) {
      case 0:
        return this.state.isExpand1 ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowDownIcon />
        );

      case 1:
        return this.state.isExpand2 ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowDownIcon />
        );

      case 2:
        return this.state.isExpand3 ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowDownIcon />
        );

      case 3:
        return this.state.isExpand4 ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowDownIcon />
        );

      case 4:
        return this.state.isExpand5 ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowDownIcon />
        );

      case 5:
        return this.state.isExpand6 ? (
          <KeyboardArrowDownIcon />
        ) : (
          <KeyboardArrowDownIcon />
        );

      default:
        return null;
    }
  };

  handleChangeChield(main, name, action, show) {
    let data = this.state.accordian;
    //alert("tdsdf");
    let newdata = data.map((view, index) => {
      if (view.name == main) {
        view.subModules.map((key, ind) => {
          //   if (key.view == false || key.add == false || key.update == false) {
          //     alert("test");
          //   } else {
          //     alert("tresxcfs");
          //   }
          if (key.name == name) {
            if (action == "view") {
              if (key.view == true) {
                key.view = false;
              } else {
                key.view = true;
              }
            }
            if (action == "add") {
              if (key.add == true) {
                key.add = false;
              } else {
                key.add = true;
              }
            }
            if (action == "update") {
              if (key.update == true) {
                key.update = false;
              } else {
                key.update = true;
              }
            }
          }
        });
      }
      return view;
    });
    this.setState({
      accordian: newdata,
    });

  }

  renderAccordianData = (d, idx) => {
    switch (idx) {
      case 0:
        return this.state.isExpand1 ? (
          <>
            {
              (
              d.subModules.map((i, ind) => {
                var show = i;
                console.log("hello world", ind);

                if (ind.length === 0) {
                  console.log("helllo world");
                } else {
                  return (
                    <tr key={ind}>
                      <td colSpan={1}>&nbsp;</td>
                      <td>{i.name} &nbsp;</td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          name="view"
                          className=""
                          value={i.view}
                          checked={i.view}
                          onChange={() =>
                            this.handleChangeChield(d.name, i.name, "view")
                          }
                        />
                        &nbsp;
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="checkbox"
                          name="add"
                          className=""
                          value={i.add}
                          checked={i.add}
                          onChange={() =>
                            this.handleChangeChield(d.name, i.name, "add")
                          }
                        />
                        &nbsp;
                      </td>
                      <td style={{ borderRight: "none", textAlign: "center" }}>
                        <input
                          type="checkbox"
                          name="update"
                          className=""
                          value={i.update}
                          checked={i.update}
                          onChange={() =>
                            this.handleChangeChield(d.name, i.name, "update")
                          }
                        />
                      </td>
                      <td style={{ borderLeft: "none" }}>&nbsp;</td>
                    </tr>
                  );
                }
              }))
            }
          </>
        ) : null;

      case 1:
        return this.state.isExpand2 ? (
          <>
            {
              (console.log(d, d.subModules, "saiiii"),
              d.subModules.map((i, ind) => {
                console.log(i);
                return (
                  <tr key={ind}>
                    <td colSpan={1}>&nbsp;</td>
                    <td>{i.name} &nbsp;</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="View"
                        className=""
                        style={{ textAlign: "center" }}
                        checked={i.view}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "view")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="add"
                        className=""
                        checked={i.add}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "add")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ borderRight: "none", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="update"
                        className=""
                        checked={i.update}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "update")
                        }
                      />
                    </td>
                    <td style={{ borderLeft: "none" }}>&nbsp;</td>
                  </tr>
                );
              }))
            }
          </>
        ) : null;

      case 2:
        return this.state.isExpand3 ? (
          <>
            {
              (console.log(d, d.subModules, "saiiii"),
              d.subModules.map((i, ind) => {
                console.log(i);
                return (
                  <tr key={ind}>
                    <td colSpan={1}>&nbsp;</td>
                    <td>{i.name} &nbsp;</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="view"
                        className=""
                        checked={i.view}
                        // onChange={() => this.handleOnChangeButton(i.name, 'view')}
                        // onChange={() => this.handleChange(d.name, 'view')}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "view")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="add"
                        className=""
                        checked={i.add}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "add")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ borderRight: "none", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="update"
                        className=""
                        checked={i.update}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "update")
                        }
                      />
                    </td>
                    <td style={{ borderLeft: "none" }}>&nbsp;</td>
                  </tr>
                );
              }))
            }
          </>
        ) : null;

      case 3:
        return this.state.isExpand4 ? (
          <>
            {
              (console.log(d, d.subModules, "saiiii"),
              d.subModules.map((i, ind) => {
                console.log(i);
                return (
                  <tr key={ind}>
                    <td colSpan={1}>&nbsp;</td>
                    <td>{i.name} &nbsp;</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="view"
                        className=""
                        checked={i.view}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "view")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="add"
                        className=""
                        checked={i.add}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "add")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ borderRight: "none", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="update"
                        className=""
                        checked={i.update}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "update")
                        }
                      />
                    </td>
                    <td style={{ borderLeft: "none" }}>&nbsp;</td>
                  </tr>
                );
              }))
            }
          </>
        ) : null;

      case 4:
        return this.state.isExpand5 ? (
          <>
            {
              (console.log(d, d.subModules, "saiiii"),
              d.subModules.map((i, ind) => {
                console.log(i);
                return (
                  <tr key={ind}>
                    <td colSpan={1}>&nbsp;</td>
                    <td>{i.name} &nbsp;</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="view"
                        className=""
                        checked={i.view}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "view")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="add"
                        className=""
                        checked={i.add}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "add")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ borderRight: "none", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="update"
                        className=""
                        checked={i.update}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "update")
                        }
                      />
                    </td>
                    <td style={{ borderLeft: "none" }}>&nbsp;</td>
                  </tr>
                );
              }))
            }
          </>
        ) : null;
      case 5:
        return this.state.isExpand6 ? (
          <>
            {
              (console.log(d, d.subModules, "saiiii"),
              d.subModules.map((i, ind) => {
                console.log(i);
                return (
                  <tr key={ind}>
                    <td colSpan={1}></td>
                    <td>{i.name}</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="view"
                        className=""
                        checked={i.view}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "view")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="add"
                        className=""
                        checked={i.add}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "add")
                        }
                      />
                      &nbsp;
                    </td>
                    <td style={{ borderRight: "none", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="update"
                        className=""
                        checked={i.update}
                        onChange={() =>
                          this.handleChangeChield(d.name, i.name, "update")
                        }
                      />
                    </td>
                    <td style={{ borderLeft: "none" }}>&nbsp;</td>
                  </tr>
                );
              }))
            }
          </>
        ) : null;

      default:
        return null;
    }
  };

  toggleChange = (idx) => {
    // this.setState({
    //   isExpand: !this.state.isExpand,
    // })
    switch (idx) {
      case 0:
        this.setState({
          isExpand1: !this.state.isExpand1,
        });
        return "foo";
      case 1:
        this.setState({
          isExpand2: !this.state.isExpand2,
        });
        return "foo";

      case 2:
        this.setState({
          isExpand3: !this.state.isExpand3,
        });
        return "bar";
      case 3:
        this.setState({
          isExpand4: !this.state.isExpand4,
        });
        return "foo";
      case 4:
        this.setState({
          isExpand5: !this.state.isExpand5,
        });
        return "foo";
      case 5:
        this.setState({
          isExpand6: !this.state.isExpand6,
        });
        return "foo";

      default:
        return "foo";
    }
  };

  //   handleChange = (moduleName, moduleOperation) => {
  // step:1 :get the desired arry from local state.
  //   step:2  :find the index of the object based on this.props.
  // step:3 :update the found object and respective operation key with relavent data.
  // tep:4 : get the desired submodules from the object
  // step: 5: update the respective operation key with relavent data in submodules too.
  // step:6  :replace the updated object in original array.
  // this.setState({
  //   isValue: !this.state.isValue,
  // })
  //     let accordian
  //   }
  render() {
    // console.log('Nagesh--->', this.state.accordian)
    console.log("Role Types are", this.props.roletypelist);
    const { roletypelist } = (this.props.roletypelist ? this.props.roletypelist : []);
    return (
      <div className="userGridContainer">
        <div className="userGridComponent">
          <table>
            <thead>
              <tr>
                <th>Modules</th>
                <th>Sub-Modules</th>
                <th className="tableHeadBlock">View</th>
                <th className="tableHeadBlock">Add</th>
                <th style={{ borderRight: "none" }} className="tableHeadBlock">
                  Update
                </th>
                <th style={{ borderLeft: "none" }}>&nbsp;</th>
              </tr>
            </thead>

            {this.state.accordian.map((d, idx) => {
              return (
                <tbody>
                  <tr
                    key={idx}
                    style={{
                      backgroundColor: idx % 2 === 0 ? "#ebfaff" : "#ffffff",
                    }}
                  >
                    <td>{d.name} &nbsp;</td>
                    <td>{d.submodulesNumber} &nbsp;</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="view"
                        className=""
                        value={d.view}
                        checked={d.view}
                        onChange={() => this.handleChange(d.name, "view")}
                      />
                      &nbsp;
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="add"
                        className=""
                        value={d.add}
                        checked={d.add}
                        onChange={() => this.handleChange(d.name, "add")}
                      />
                      &nbsp;
                    </td>

                    <td style={{ borderRight: "none", textAlign: "center" }}>
                      <input
                        type="checkbox"
                        name="update"
                        style={{ alignItems: "center" }}
                        className=""
                        value={d.update}
                        checked={d.update}
                        onChange={() => this.handleChange(d.name, "update")}
                      />
                    </td>
                    <td style={{ borderLeft: "none", textAlign: "right" }}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        value={d}
                        onClick={() => this.toggleChange(idx)}
                        sx={{ background: "#d4e8f1" }}
                      >
                        {this.handleClick(idx, idx % 2 === 0 ? "red" : "blue")}
                      </IconButton>
                    </td>
                  </tr>
                  <>{this.renderAccordianData(d, idx)}</>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
    );
  }
}
