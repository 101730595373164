import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  { serverApi } from "../../../helpers/Consts";
import {payschemeParametersList} from "../../../store/Master/PayschemeParameters/actions"
import GridView from "./ParameterGrid";
import GridViewSearch from "../../../components/Grid/GridViewSerch";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: 0, 
      userDit: JSON.parse(localStorage.getItem("authUser")),
      M_Data:[]
     };
  }
  componentDidMount() {
    this.props.payschemeParametersList(serverApi.GET_ALL_PAYSCHEME_PARAMETERS_LIST  + '/companyId/' + localStorage.getItem('companyIdconfig')+'/orgId/0', this.props.history); // calling api for department list
  }

  render() {
    const mdata = this.props.PayschemeParametersList ? this.props.PayschemeParametersList.data: "";
    return (
      <GridViewSearch mdata={mdata} mcolumns={mcolumns} pathname="/create_payscheme_parameters" actions={true} />
    )
  }
}
const mcolumns = [
  // {
  //   Header: "PayScheme Id",
  //   accessor: "id",
  //   hidden: false
  // },
  {
    Header: "Parameter Code ",
    // accessor: "code",
    dataField:"code",
    hidden: false
  },
  {
    Header: "Parameter Name",
    // accessor: "name",
    dataField:"name",
    hidden: false
  },
  {
    Header: "Description",
    // accessor: "description",
    dataField:"description",
    hidden: false
  },
  {
    Header: "Parameter Type",
    // accessor: "type",
    dataField:"typeDesc",
    hidden: false
  },
  // {
  //   Header: "Effective From",
  //   // accessor: "effectiveFrom",
  //   dataField:"effectiveFrom",
  //   hidden: false
  // },
  // {
  //   Header: "Ends On",
  //   // accessor: "endsOn",
  //   dataField:"endsOn",
  //   hidden: false
  // },
  {
    Header: " Is Custom Component",
    // accessor: "isCustomComponent",
    dataField:"isCustomComponent",
    hidden: false
  },
  {
    Header: "Is Occasionally",
    // accessor: "isOccasionally",
    dataField:"isOccasionally",
    hidden: false
  },
  {
    Header: "Is Displayable In Payslip",
    // accessor: "isDisplayableInPayslip",
    dataField:"isDisplayableInPayslip",
    hidden:false
  },
  // {
  //   Header: "Parent Id",
  //   accessor: "parentId",
  //   hidden: false
  // },
  {
    Header: "Default Value",
    // accessor: "defaultValue",
    dataField:"defaultValue",
    hidden: false
  },
  {
    Header: "Round To Decimal",
    // accessor: "roundOf",
    dataField:"roundOf",
    hidden: false
  },
 
]

const mapStatetoProps = (state) => {
  const { PayschemeParametersList } = state.PayschemeParaReducer;
  return { PayschemeParametersList };
};

export default withRouter(
  connect(mapStatetoProps, {
    payschemeParametersList,
  })(Index)
);
