export const CONFIGURATION = 'configuration';
export const CONFIGURATION_SUCCESSFUL = 'configuration_successfull';
export const CONFIGURATION_UPDATE = 'configuration_update';
// new action type for Date allowable
export const CONFIGURATION_DATA= 'configuration_data';
export const CONFIGURATION_DATA_SUCCESSFUL = 'configuration_data_successfull';

export const CURRENCY_CONFIGURATION='currency_configuration';
export const CURRENCY_CONFIGURATION_SUCCESSFULL='currency_configuration_successfull';

export const INWARD_PO_CONFIGURATION='inward_po_configuration';
export const INWARD_PO_CONFIGURATION_SUCCESSFULL='inward_po_configuration_successfull';
