import React, { Component } from "react";
import { Grid, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import Alerts from "../../components/notificationAlerts/Alerts";
import RadioButton from "../../components/RadioButton/RadioButton";
import TextFieldReq from "../../components/TextField/TextFieldReq";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import {
  setConfiguration,
  getConfigurationData,
} from "../../store/Configuration/actions";
class DateAllowable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      selectedFutureValue: "",
      selectedFuturedayValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      futureDate: 0,
      backdate: 0,
      bkyes: false,
      futureDateYes: false,
      bkno: false,
      futureDateNo: false,
      fuedate: 0,
      fuyes: false,
      funo: false,
      fdays: 0,
      bdays: 0,
      sdays: false,
      ftdays: false,
      afdays: false,
      otherdays: false,
      othervalue: 0,
      othervaldisplay: "none",
      daysdesible: false,
      backDaysdesible: false,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
      autoIdBackdate: null,
      autoIdFuturedate: null,
      autoIdFutureDays: null,
      autoIdBackDays: null,
      sBackdays: false,
      ftBackdays: false,
      afBackdays: false,
      otherBackdays: false,
      otherBackDateValue: 0,
      Backothervaldisplay: "none",
    };
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  onClickFutureDateSelect = (selectedFutureValue) => {
    var futureDate = 0;
    var fdays = 0;
    var futureDateYes = false,
      futureDateNo = false,
      daysdesible = false;
    var sdays = false,
      ftdays = false,
      afdays = false,
      otherdays = false;

    if (selectedFutureValue === "Yes") {
      futureDate = 1;
      futureDateYes = true;
      daysdesible = false;
    } else if (selectedFutureValue === "No") {
      futureDate = 2;
      futureDateNo = true;
      daysdesible = true;
      sdays = false;
      ftdays = false;
      afdays = false;
      otherdays = false;
      fdays = 0;
    }

    this.setState({
      futureDate: futureDate,
      futureDateYes: futureDateYes,
      futureDateNo: futureDateNo,
      daysdesible: daysdesible,
      sdays: sdays,
      ftdays: ftdays,
      afdays: afdays,
      otherdays: otherdays,
      fdays: fdays,
    });
  };
  onClickRadioButton = (selectedValue) => {
    var backdate = 0;
    var bdays = 0;
    var bkyes = false,
        bkno = false,
        backDaysdesible = false;
    var sBackdays = false,
      ftBackdays = false,
      afBackdays = false,
      otherBackdays = false;
 

    if (selectedValue === "Yes") {
      backdate = 1;
      bkyes = true;
      backDaysdesible = false;
    } else if (selectedValue === "No") {
      backdate = 2;
      bkno = true;
      backDaysdesible = true;
      sBackdays = false;
      ftBackdays = false;
      afBackdays = false;
      otherBackdays = false;
      bdays = 0;
    }
    this.setState({
      backdate: backdate,
      bkyes: bkyes,
      bkno: bkno,
      otherBackdays:otherBackdays,
      sBackdays: sBackdays,
      ftBackdays: ftBackdays,
      afBackdays: afBackdays,
      otherBackdays: otherBackdays,
      bdays: bdays,
    });
  };

  onClickRadioButtonFuturedays = (selectedFuturedayValue) => {
    var fdays = 0;
    var sdays = false,
      ftdays = false,
      afdays = false,
      display = false,
      otherdays = false;
    var othervaldisplay = "none";

    if (selectedFuturedayValue === "7 Days allowance") {
      fdays = 1;
      sdays = true;
      othervaldisplay = "none";
      display = false;
    } else if (selectedFuturedayValue === "14 Day allowance") {
      fdays = 2;
      ftdays = true;
      othervaldisplay = "none";
      display = false;
    } else if (selectedFuturedayValue === "More than 14 days allowance") {
      fdays = 3;
      afdays = true;
      othervaldisplay = "none";
      display = false;
    } else if (selectedFuturedayValue === "Others") {
      fdays = 4;
      otherdays = true;
      othervaldisplay = "block";
      display = true;
    }

    this.setState({
      selectedFuturedayValue: selectedFuturedayValue,
      fdays: fdays,
      sdays: sdays,
      ftdays: ftdays,
      afdays: afdays,
      otherdays: otherdays,
      othervaldisplay: othervaldisplay,
      display: display,
      othervalue: othervaldisplay === "none" ? 0 : this.state.othervalue,
    });
  };
  onClickRadioButtonBackdays = (selectedBackdayValue) => {
    var bdays = 0;
    var sBackdays = false,
      ftBackdays = false,
      afBackdays = false,
      otherBackdays = false;
    var Backothervaldisplay = "none";
    if (selectedBackdayValue === "7 Days allowance") {
      bdays = 1;
      sBackdays = true;
      Backothervaldisplay = "none";
    } else if (selectedBackdayValue === "14 Day allowance") {
      bdays = 2;
      ftBackdays = true;
      Backothervaldisplay = "none";
    } else if (selectedBackdayValue === "More than 14 days allowance") {
      bdays = 3;
      afBackdays = true;
      Backothervaldisplay = "none";
    } else if (selectedBackdayValue === "Others") {
      bdays = 4;
      otherBackdays = true;
      Backothervaldisplay = "block";
    }

    this.setState({
      bdays: bdays,
      sBackdays: sBackdays,
      ftBackdays: ftBackdays,
      afBackdays: afBackdays,
      otherBackdays: otherBackdays,
      Backothervaldisplay: Backothervaldisplay,
      otherBackDateValue: Backothervaldisplay === "none" ? 0 : this.state.otherBackDateValue,
    });
  };
  componentDidMount = () => {
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        if (
          prop.configParameter == configNames.BACK_DATE_ALLOWABLE ||
          prop.configParameter == configNames.FUTURE_DATE_ALLOWABLE ||
          prop.configParameter == configNames.FUTURE_DAYS_ALLOWABLE ||
          prop.configParameter == configNames.BACK_DAYS_ALLOWABLE
        ) {
          if (prop.configParameter === configNames.BACK_DATE_ALLOWABLE) {
            if (prop.configValue === "1") {
              this.setState({
                backdate: 1,
                bkyes: true,
                bkno: false,
                configdata: configdata,
                autoIdBackdate: prop.autoId,
              });
            } else if (prop.configValue === "2") {
              this.setState({
                backdate: 2,
                bkno: true,
                bkyes: false,
                configdata: configdata,
                autoIdBackdate: prop.autoId,
              });
            }
          }
          if (prop.configParameter === configNames.FUTURE_DATE_ALLOWABLE) {
            if (prop.configValue === "1") {
              this.setState({
                futureDate: 1,
                futureDateYes: true,
                futureDateNo: false,
                configdata: configdata,
                autoIdFuturedate: prop.autoId,
              });
            } else if (prop.configValue === "2") {
              this.setState({
                futureDate: 2,
                futureDateNo: true,
                futureDateYes: false,
                configdata: configdata,
                autoIdFuturedate: prop.autoId,
              });
            }
          }
          if (prop.configParameter === configNames.FUTURE_DAYS_ALLOWABLE) {
            var fdaysty = JSON.parse(prop.configValue);
            if (fdaysty.fdaystype == 1) {
              this.setState({
                fdays: 1,
                sdays: true,
                ftdays: false,
                afdays: false,
                otherdays: false,
                configdata: configdata,
                autoIdFutureDays: prop.autoId,
              });
            } else if (fdaysty.fdaystype == 2) {
              this.setState({
                fdays: 2,
                ftdays: true,
                sdays: false,
                afdays: false,
                otherdays: false,
                configdata: configdata,
                autoIdFutureDays: prop.autoId,
              });
            } else if (fdaysty.fdaystype == 3) {
              this.setState({
                fdays: 3,
                afdays: true,
                ftdays: false,
                sdays: false,
                otherdays: false,
                configdata: configdata,
                autoIdFutureDays: prop.autoId,
              });
            } else if (fdaysty.fdaystype == 4) {
              this.setState({
                fdays: 4,
                otherdays: true,
                afdays: false,
                ftdays: false,
                sdays: false,
                configdata: configdata,
                autoIdFutureDays: prop.autoId,
              });
            }
            this.setState({
              othervalue: Number(fdaysty.othervalue),
            });
          }
          if (prop.configParameter === configNames.BACK_DAYS_ALLOWABLE) {
            var bdaysty = JSON.parse(prop.configValue);
            if (bdaysty.bdaystype == 1) {
              this.setState({
                bdays: 1,
                sBackdays: true,
                ftBackdays: false,
                afBackdays: false,
                otherBackdays: false,
                configdata: configdata,
                autoIdBackDays: prop.autoId,
              });
            } else if (bdaysty.bdaystype == 2) {
              this.setState({
                bdays: 2,
                ftBackdays: true,
                sBackdays: false,
                afBackdays: false,
                otherBackdays: false,
                configdata: configdata,
                autoIdBackDays: prop.autoId,
              });
            } else if (bdaysty.bdaystype == 3) {
              this.setState({
                bdays: 3,
                afBackdays: true,
                ftBackdays: false,
                sBackdays: false,
                otherBackdays: false,
                configdata: configdata,
                autoIdBackDays: prop.autoId,
              });
            } else if (bdaysty.bdaystype == 4) {
              this.setState({
                bdays: 4,
                otherBackdays: true,
                afBackdays: false,
                ftBackdays: false,
                sBackdays: false,
                configdata: configdata,
                autoIdBackDays: prop.autoId,
              });
            }
            this.setState({
              otherBackDateValue: Number(bdaysty.otherBackDateValue),
            });
          }
        }
      });
    } else {
      this.setState({
        backdate: 0,
        bkyes: false,
        bkno: false,
        configdata: [],
        futureDate: 0,
        futureDateYes: false,
        futureDateNo: false,
        fdays: 0,
        sdays: false,
        ftdays: false,
        afdays: false,
        otherdays: false,
        othervalue: 0,
        autoIdFutureDays: null,
        autoIdFuturedate:null,
        autoIdBackdate:null,
        bdays:0,
        sBackdays: false,
        ftBackdays: false,
        afBackdays: false,
        otherBackdays: false,
        otherBackDateValue: 0,
        autoIdBackDays: null,
      });
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.configdata) {
      if (props.configdata.length !== 0) {
        props.configdata.forEach((prop) => {
          if (
            prop.configParameter == configNames.BACK_DATE_ALLOWABLE ||
            prop.configParameter == configNames.FUTURE_DATE_ALLOWABLE ||
            prop.configParameter == configNames.FUTURE_DAYS_ALLOWABLE ||
          prop.configParameter == configNames.BACK_DAYS_ALLOWABLE

          ) {
            if (prop.configParameter == configNames.BACK_DATE_ALLOWABLE) {
              if (prop.configValue == "1") {
                this.setState({
                  backdate: 1,
                  bkyes: true,
                  bkno: false,
                  configdata: prop.configdata,
                  autoIdBackdate: prop.autoId,
                });
              } else if (prop.configValue == "2") {
                this.setState({
                  backdate: 2,
                  bkno: true,
                  bkyes: false,
                  configdata: prop.configdata,
                  autoIdBackdate: prop.autoId,
                });
              }
            }
            if (prop.configParameter == configNames.FUTURE_DATE_ALLOWABLE) {
              if (prop.configValue == "1") {
                this.setState({
                  futureDate: 1,
                  futureDateYes: true,
                  futureDateNo: false,
                  configdata: prop.configdata,
                  autoIdFuturedate: prop.autoId,
                });
              } else if (prop.configValue == "2") {
                this.setState({
                  futureDate: 2,
                  futureDateNo: true,
                  futureDateYes: false,
                  configdata: prop.configdata,
                  autoIdFuturedate: prop.autoId,
                });
              }
            }
            if (prop.configParameter == configNames.FUTURE_DAYS_ALLOWABLE) {
              var fdaysty = JSON.parse(prop.configValue);
              if (fdaysty.fdaystype == 1) {
                this.setState({
                  fdays: 1,
                  sdays: true,
                  ftdays: false,
                  afdays: false,
                  otherdays: false,
                  configdata: prop.configdata,
                  autoIdFutureDays: prop.autoId,
                });
              } else if (fdaysty.fdaystype == 2) {
                this.setState({
                  fdays: 2,
                  ftdays: true,
                  sdays: false,
                  afdays: false,
                  otherdays: false,
                  configdata: prop.configdata,
                  autoIdFutureDays: prop.autoId,
                });
              } else if (fdaysty.fdaystype == 3) {
                this.setState({
                  fdays: 3,
                  afdays: true,
                  ftdays: false,
                  sdays: false,
                  otherdays: false,
                  configdata: prop.configdata,
                  autoIdFutureDays: prop.autoId,
                });
              } else if (fdaysty.fdaystype == 4) {
                this.setState({
                  fdays: 4,
                  otherdays: true,
                  afdays: false,
                  ftdays: false,
                  sdays: false,
                  configdata: prop.configdata,
                  autoIdFutureDays: prop.autoId,
                });
              } else if (fdaysty.fdaystype == 0) {
                this.setState({
                  fdays: 0,
                  otherdays: false,
                  afdays: false,
                  ftdays: false,
                  sdays: false,
                  configdata: prop.configdata,
                  autoIdFutureDays: prop.autoId,
                });
              }
              this.setState({
                othervalue: Number(fdaysty.othervalue),
              });
            }
            if (prop.configParameter == configNames.BACK_DAYS_ALLOWABLE) {
              var bdaysty = JSON.parse(prop.configValue);
              if (bdaysty.bdaystype == 1) {

                this.setState({
                  bdays: 1,
                  sBackdays: true,
                  ftBackdays: false,
                  afBackdays: false,
                  otherBackdays: false,
                  configdata: prop.configdata,
                  autoIdBackDays: prop.autoId,
                });
              } else if (bdaysty.bdaystype == 2) {
                this.setState({
                  bdays: 2,
                  ftBackdays: true,
                  sBackdays: false,
                  afBackdays: false,
                  otherBackdays: false,
                  configdata: prop.configdata,
                  autoIdBackDays: prop.autoId,
                });
              } else if (bdaysty.bdaystype == 3) {
                this.setState({
                  bdays: 3,
                  afBackdays: true,
                  ftBackdays: false,
                  sBackdays: false,
                  otherBackdays: false,
                  configdata: prop.configdata,
                  autoIdBackDays: prop.autoId,
                });
              } else if (bdaysty.bdaystype == 4) {
                this.setState({
                  bdays: 4,
                  otherBackdays: true,
                  afBackdays: false,
                  ftBackdays: false,
                  sBackdays: false,
                  configdata: prop.configdata,
                  autoIdBackDays: prop.autoId,
                });
              } else if (bdaysty.bdaystype == 0) {
                this.setState({
                  bdays: 0,
                  otherBackdays: false,
                  afBackdays: false,
                  ftBackdays: false,
                  sBackdays: false,
                  configdata: prop.configdata,
                  autoIdBackDays: prop.autoId,
                });
              }
              this.setState({
                otherBackDateValue: Number(bdaysty.otherBackDateValue),
              });
            }
          } else {


         this.setState({
          backdate: 0,
          bkyes: false,
          bkno: false,
          configdata: [],
          futureDate: 0,
          futureDateYes: false,
          futureDateNo: false,
          fdays: 0,
          sdays: false,
          ftdays: false,
          afdays: false,
          otherdays: false,
          othervalue: 0,
          autoIdFuturedate: null,
          autoIdFutureDays: null,
          bdays: 0,
          sBackdays: false,
          ftBackdays: false,
          afBackdays: false,
          otherBackdays: false,
          otherBackDateValue: 0,
          autoIdBackDays: null,
          autoIdBackdate: null,
        });
          }
        });
      } else {
        this.setState({
          backdate: 0,
          bkyes: false,
          bkno: false,
          configdata: [],
          futureDate: 0,
          futureDateYes: false,
          futureDateNo: false,
          fdays: 0,
          sdays: false,
          ftdays: false,
          afdays: false,
          otherdays: false,
          othervalue: 0,
          autoIdFuturedate: null,
          autoIdFutureDays: null,
          bdays: 0,
          sBackdays: false,
          ftBackdays: false,
          afBackdays: false,
          otherBackdays: false,
          otherBackDateValue: 0,
          autoIdBackDays: null,
          autoIdBackdate: null,
        });
      }
    }
  };

  handleConfig = () => {
    if (this.state.backdate == 0) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Back Date Allowable",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if(this.state.backdate == 1){
      if (this.state.bdays == 0) {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry!! Please Select Back Date Allowable Days",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }else{
        var bdays = {
          bdaystype: this.state.bdays,
          otherBackDateValue: Number(this.state.otherBackDateValue),
        };
        var fdays={
          fdaystype: this.state.fdays,
          othervalue: Number(this.state.othervalue), 
        }
        var data = {
          companyId: localStorage.getItem("companyIdconfig"),
          configList: [
            {
              autoId: this.state.autoIdBackdate,
              configParameter: configNames.BACK_DATE_ALLOWABLE,
              isActive: 1,
              configValue: this.state.backdate,
            },
            {
              autoId: this.state.autoIdFuturedate,
              configParameter: configNames.FUTURE_DATE_ALLOWABLE,
              isActive: 1,
              configValue: this.state.futureDate,
            },
            {
              autoId: this.state.autoIdFutureDays,
              configParameter: configNames.FUTURE_DAYS_ALLOWABLE,
              isActive: 1,
              configValue: JSON.stringify(fdays),
            },
            {
              autoId: this.state.autoIdBackDays,
              configParameter: configNames.BACK_DAYS_ALLOWABLE,
              isActive: 1,
              configValue: JSON.stringify(bdays),
            },
          ],
        };
        }
    } else if (this.state.futureDate == 0) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Future Date Allowable ",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (this.state.futureDate == 1) {
      if (this.state.fdays == 0) {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry!! Please Select Future Date Allowable Days",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      } else {
        var fdays = {
          fdaystype: this.state.fdays,
          othervalue: Number(this.state.othervalue),
        };
        var bdays = {
          bdaystype: this.state.bdays,
          otherBackDateValue: Number(this.state.otherBackDateValue),
        };
        var data = {
          companyId: localStorage.getItem("companyIdconfig"),
          configList: [
            {
              autoId: this.state.autoIdBackdate,
              configParameter: configNames.BACK_DATE_ALLOWABLE,
              isActive: 1,
              configValue: this.state.backdate,
            },
            {
              autoId: this.state.autoIdFuturedate,
              configParameter: configNames.FUTURE_DATE_ALLOWABLE,
              isActive: 1,
              configValue: this.state.futureDate,
            },
            {
              autoId: this.state.autoIdFutureDays,
              configParameter: configNames.FUTURE_DAYS_ALLOWABLE,
              isActive: 1,
              configValue: JSON.stringify(fdays),
            },
            {
              autoId: this.state.autoIdBackDays,
              configParameter: configNames.BACK_DAYS_ALLOWABLE,
              isActive: 1,
              configValue: JSON.stringify(bdays),
            },
          ],
        };
        if (localStorage.getItem("companyIdconfig")) {
          var msg = "Date Allowable Updated Successfully.";
          if (
            this.state.othervaldisplay === "block" ||
            this.state.othervaldisplay === "none"
          ) {
            if (this.state.fdays == 4) {
              if (this.state.othervalue == 0) {
                this.setState({
                  alert: "block",
                  severity: "error",
                  notmsg: "Enter the other value",
                });
                setTimeout(() => {
                  this.setState({ alert: "none" });
                }, 3000);
              } else {
                // this.props.getConfigurationData(
                //   serverApi.UPDATE_CONFIGURATION_NEW,
                //   data,
                //   msg,
                //   this.props.history
                // );
              }
            } else {
            //   this.props.getConfigurationData(
            //     serverApi.UPDATE_CONFIGURATION_NEW,
            //     data,
            //     msg,
            //     this.props.history
            //   );
            }
          }
        } else {
          this.setState({
            alert: "block",
            severity: "error",
            notmsg: "Sorrry! Please Select Company",
          });
          setTimeout(() => {
            this.setState({ alert: "none" });
          }, 3000);
        }
      }
    }else if (this.state.futureDate == 2) {
      var msg = "Date Allowable Updated Successfully.";
      var fdays = {
        fdaystype: this.state.fdays,
        othervalue: Number(this.state.othervalue),
      };
      var bdays = {
        bdaystype: this.state.bdays,
        otherBackDateValue: Number(this.state.otherBackDateValue),
      };
      var data = {
        companyId: localStorage.getItem("companyIdconfig"),
        configList: [
          {
            autoId: this.state.autoIdBackdate,
            configParameter: configNames.BACK_DATE_ALLOWABLE,
            isActive: 1,
            configValue: this.state.backdate,
          },
          {
            autoId: this.state.autoIdFuturedate,
            configParameter: configNames.FUTURE_DATE_ALLOWABLE,
            isActive: 1,
            configValue: this.state.futureDate,
          },
          {
            autoId: this.state.autoIdFutureDays,
            configParameter: configNames.FUTURE_DAYS_ALLOWABLE,
            isActive: 1,
            configValue: JSON.stringify(fdays),
          },
          {
            autoId: this.state.autoIdBackDays,
            configParameter: configNames.BACK_DAYS_ALLOWABLE,
            isActive: 1,
            configValue: JSON.stringify(bdays),
          },
        ],
      };
    }
    var msg = "Date Allowable Updated Successfully.";
     this.props.getConfigurationData(
        serverApi.UPDATE_CONFIGURATION_NEW,
        data,
        msg,
        this.props.history
      );
  };

  render() {
    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <div className="consoleFormContainer">
          <h5>Back Date Allowable</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={6}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="backDt"
                        checkednew={this.state.bkyes}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="backDt"
                        checkednew={this.state.bkno}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        {/* BackDays allowable */}
        {this.state.bkyes ? (
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="7 Days allowance"
                        value="seven"
                        groupname="bdates"
                        disabled={this.state.backDaysdesible}
                        checkednew={this.state.sBackdays}
                        onChange={this.onClickRadioButtonBackdays}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="14 Day allowance"
                        value="fourteen"
                        groupname="bates"
                        disabled={this.state.backDaysdesible}
                        checkednew={this.state.ftBackdays}
                        onChange={this.onClickRadioButtonBackdays}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="More than 14 days allowance"
                        value="morethan"
                        groupname="bdates"
                        disabled={this.state.backDaysdesible}
                        checkednew={this.state.afBackdays}
                        onChange={this.onClickRadioButtonBackdays}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Others"
                        value="others"
                        groupname="bdates"
                        disabled={this.state.backDaysdesible}
                        checkednew={this.state.otherBackdays}
                        onChange={this.onClickRadioButtonBackdays}
                      />
                    </div>
                    <Box
                      gridColumn="span 4"
                      style={{
                        display:
                          this.state.backdate === 1? this.state.otherBackdays ==true? "block" : this.state.Backothervaldisplay : this.state.Backothervaldisplay,
                      }}
                    >
                      <div className="consoleTextFieldBlock">
                        <div>
                          <TextFieldReq
                            value={this.state.otherBackDateValue}
                            type="text"
                            label=""
                            caps="OFF"
                            placeholder="Enter Others Value..."
                            onChange={this.handleChange("otherBackDateValue")}
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="consoleFormContainer">
          <h5>Future Date Allowable</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={6}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="futureDt"
                        checkednew={this.state.futureDateYes}
                        onChange={this.onClickFutureDateSelect}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="futureDt"
                        checkednew={this.state.futureDateNo}
                        onChange={this.onClickFutureDateSelect}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        {this.state.futureDateYes ? (
          <div className="consoleFormContainer">
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: "flex" }}>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="7 Days allowance"
                        value="seven"
                        groupname="fdates"
                        disabled={this.state.daysdesible}
                        checkednew={this.state.sdays}
                        onChange={this.onClickRadioButtonFuturedays}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="14 Day allowance"
                        value="fourteen"
                        groupname="fdates"
                        disabled={this.state.daysdesible}
                        checkednew={this.state.ftdays}
                        onChange={this.onClickRadioButtonFuturedays}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="More than 14 days allowance"
                        value="morethan"
                        groupname="fdates"
                        disabled={this.state.daysdesible}
                        checkednew={this.state.afdays}
                        onChange={this.onClickRadioButtonFuturedays}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Others"
                        value="others"
                        groupname="fdates"
                        disabled={this.state.daysdesible}
                        checkednew={this.state.otherdays}
                        onChange={this.onClickRadioButtonFuturedays}
                      />
                    </div>
                    <Box
                      gridColumn="span 4"
                      style={{
                        // display:
                        //   this.state.futureDate === 1 &&
                        //     this.state.othervalue > 0 &&
                        //   this.state.othervalue === ""
                        //     ? "block"
                        //     : this.state.othervaldisplay,
                        display:
                          this.state.futureDate === 1
                            ? this.state.othervalue > 0 ||
                              this.state.othervalue === ""
                              ? "block"
                              : this.state.othervaldisplay
                            : this.state.othervaldisplay,
                      }}
                    >
                      <div className="consoleTextFieldBlock">
                        <div>
                          <TextFieldReq
                            value={this.state.othervalue}
                            type="text"
                            label=""
                            caps="OFF"
                            placeholder="Enter Others Value..."
                            onChange={this.handleChange("othervalue")}
                          />
                        </div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="consoleFormButtonBlock">
          {/* <Link to="/date_allowable"> */}
          {/* <Link to=""> */}

          <Button variant="contained" className="cancelButton">
            Cancel
          </Button>
          {/* </Link> */}
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companysList, companyscoulmns } = state.BasicDetails;
  const { configdatalist, configdata } = state.Configuration;
  return { companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
  setConfiguration,
  getConfigurationData,
})(DateAllowable);
