import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";

// const Countries = [
//     { label: "Option 01", value: 355 },
//     { label: "Option 02", value: 54 },
//     { label: "Option 03", value: 43 },
//     { label: "Option 04", value: 61 },
//     { label: "Option 05", value: 965 },
//     { label: "Option 06", value: 46 },
//   ];

class DynamicSelect extends Component {
  constructor(props) { 
    super(props);
    this.state = {
      selectedlable: "",
    };
  }

  handleChange = (event) => {
    
    let selectedValue = event.value;
    let strUser = event.name;
    this.props.onSelectChange(selectedValue, strUser, this.props.name);
  };

  handleMenuOpen = () => {
    // if(this.props.handleMenuOpen){
      this.props.handleMenuOpen()
    // }
  }

  render() {
    var selectedlable = "";
    if (this.props.arrayOfData) {
      this.props.arrayOfData.forEach((row) => {
        if (this.props.selected == row.value) {
          selectedlable = row.label;
        }
      });
    }
    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div>
          <label htmlFor="app-input-field">{this.props.lable}</label>
        </div>
        <Select
          options={this.props.arrayOfData}
          menuPosition="fixed"
          menuPlacement="auto"
          defaultValue={{ value: this.props.selected, label: selectedlable }}
          onChange={this.handleChange}
          closeMenuOnScroll={() => this.props.isDropdownOpen}
          onMenuOpen={this.handleMenuOpen}
          value={{ value: this.props.selected, label: selectedlable }}
          classNamePrefix="react-select"
          isDisabled={this.props.isDisabled}
        />
      </FormControl>
    );
  }
}
export default DynamicSelect;

// import React, {Component} from 'react';
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";

// class DynamicSelect extends Component{
//     constructor(props){
//         super(props)
//     }

//     //On the change event for the select box pass the selected value back to the parent
//     handleChange = (event) =>
//     {
//         let selectedValue = event.target.value;
//         let strUser = event.target.options[event.target.selectedIndex].text;
//         this.props.onSelectChange(selectedValue, strUser);

//     }

//     render(){
//         let arrayOfData = this.props.arrayOfData;
//         let options = arrayOfData.map((data,key) =>

//             <option key={key} value={data.value} selected={data.value == this.props.selected ? "selected" : ""}>{data.name.split('^')[0]}</option>
//         );

//             return (
//                 <FormControl sx={{ width: "100%" }}  className="customDropdown">
//                     {this.props.lable && <div><label htmlFor="app-input-field">{this.props.lable}</label></div> }
//                     <select name="customSearch"
//                         labelId="demo-multiple-name-label"
//                         id="demo-multiple-name" onChange={this.handleChange}>
//                         {options}

//                     </select>
//                 </FormControl>
//             )
//     }
// }

// export default DynamicSelect;
