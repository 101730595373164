import React, { Component } from "react";
import { Grid, Box, Alert } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import DynamicSelect from "../../../components/Dropdown/DynamicSelectNew";
import DynamicSelectNew from "../../../components/Dropdown/DynamicSelect";
import TextFieldNormalNew from "../../../components/TextField/TextFieldNormalNew";
import Checkbox from "../../../components/CheckBox/Checkbox";
//import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createPayschemeParameters,
  updatePayschemeParameters,
} from "../../../store/Master/PayschemeParameters/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextField from "@mui/material/TextField";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { payschemeParametersList } from "../../../store/Master/PayschemeParameters/actions";
import moment from "moment";
import swal from "sweetalert";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

class createPayschemePara extends Component {
  constructor() {
    super();
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      recId: "",
      Code: "",
      Name: "",
      Description: "",
      defaultValue: "",
      tillDateValue: "",
      fromDateValue: "",
      payschemeId: 0,
      createdBy: null,
      type: "",
      typeId: null,
      roundOfType: "",
      roundOfTypeId: "",
      roundToDecimal: "",
      roundToDecimalId: "",
      parent: "",
      parentId: "",
      createdOn: "",
      lupdate: "",
      createddate: "",
      DisplayUpdateButton: true,
      status: null,
      checkboxList: [
        {
          menuName: "isExcelDownloadable",
          isExcelDownloadable: false,
        },
        {
          menuName: "isCustom",
          isCustom: false,
        },
        {
          menuName: "Occasionally",
          isDisplayable: false,
        },
        // {
        //   menuName: "isDisplayable",
        //   Occasionally: false,
        // },

      ],
    };
  }

  onValidateFields() {
    var validation = "";
    const {
      Code,
      Name,
      typeId,
      tillDateValue,
      fromDateValue,
      roundToDecimalId,
    } = this.state;
    if (Code === "" || Code === undefined) {
      return (validation = "Please Enter the Code !!!");
    }
    if (Name === "" || Name === undefined) {
      return (validation = "Please Enter the Name !!!");
    }
    if (typeId === "" || typeId === undefined || typeId === null) {
      return (validation = "Please Enter the Type !!!");
    }

    // if (fromDateValue === "" || fromDateValue === undefined) {
    //   return (validation = "Please Enter the Effective Form !!!");
    // }
    // if (tillDateValue === "" || tillDateValue === undefined) {
    //   return (validation = "Please Enter the Effective Till !!!");
    // }
    if (roundToDecimalId === "" || roundToDecimalId === undefined) {
      return (validation = "Please Enter the Round To Decimals !!!");
    }
    return validation;
  }

  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    var validation = this.onValidateFields();

    if (validation === "") {
      let isCustom = false;
      let Occasionally = false;
      let isDisplayable = false;
      let isExcelDownloadable = false;
      console.log("getting the created data created", this.state.checkboxList);
      this.state.checkboxList.map((item) => {
        if (item.menuName === "isCustom") {
          isCustom = item.checked;
        }
        if (item.menuName === "Occasionally") {
          Occasionally = item.checked;
        }
        if (item.menuName === "isDisplayable") {
          isDisplayable = item.checked;
        }
        if (item.menuName === "isExcelDownloadable") {
          isExcelDownloadable = item.checked;
        }
      });
      if (this.props.location.rowdata === undefined) {
        let fromDate = moment(this.state.fromDateValue).format("DD-MM-YYYY");
        let tillDate = moment(this.state.tillDateValue).format("DD-MM-YYYY");
        const data = {
          companyId: localStorage.getItem("companyIdconfig"),
          code: "C_" + this.state.Code + "_",
          name: this.state.Name,
          type: this.state.typeId,
          description: this.state.Description,
          effectiveFrom: "",
          endsOn: "",
          isCustomComponent: isCustom === undefined ? false : isCustom,
          // displayableInPayslip:
          //   isDisplayable === undefined ? false : isDisplayable,
          isExcelDownloadable:
            isExcelDownloadable === undefined ? false : isExcelDownloadable,
          occasionally: Occasionally === undefined ? false : Occasionally,
          parentId: this.state.parentId,
          defaultValue: this.state.defaultValue,
          roundOf: this.state.roundToDecimalId,
          lupdate: this.state.lupdate,
          roundOfType: this.state.roundOfTypeId,
          createdBy: userId.toString(),
        };
        this.props.createPayschemeParameters(
          serverApi.CREATE_PAYSCHEME_PARAMETERS,
          data,
          this.props.history
        ); // Calling Create Department API
      } else {
        let fromDate = moment(this.state.fromDateValue).format("DD-MM-YYYY");
        let tillDate = moment(this.state.tillDateValue).format("DD-MM-YYYY");
        const data = {
          companyId: localStorage.getItem("companyIdconfig"),
          id: this.state.payschemeId,
          code: this.state.Code,
          name: this.state.Name,
          type: this.state.typeId,
          description: this.state.Description,
          effectiveFrom: "",
          endsOn: "",
          isCustomComponent: isCustom === undefined ? false : isCustom,
          displayableInPayslip:
            isDisplayable === undefined ? false : isDisplayable,
          occasionally: Occasionally === undefined ? false : Occasionally,
          parentId: this.state.parentId,
          defaultValue: this.state.defaultValue,
          roundOf: this.state.roundToDecimalId,
          lupdate: this.props.location.rowdata.lupdate,
          roundOfType: this.state.roundOfTypeId,
          isExcelDownloadable:
            isExcelDownloadable === undefined ? false : isExcelDownloadable,
          createDate: this.state.createdOn,
          status: this.state.status == 2 ? 0 : this.state.status,
          modifiedBy: this.state.createdBy.toString(),
        };
        this.props.updatePayschemeParameters(
          serverApi.UPDATE_PAYSCHEME_PARAMETERS,
          data,
          this.props.history
        );
      }
    } else {
      swal(validation);
    }
  };
  //   const {
  //     Code,
  //     Name,
  //     typeId,
  //     tillDateValue,
  //     fromDateValue,
  //     roundToDecimalId,
  //   } = this.state;
  //   if (
  //     Code !== "" &&
  //     Code !== undefined &&
  //     Name !== "" &&
  //     Name !== undefined &&
  //     typeId !== "" &&
  //     typeId !== null &&
  //     fromDateValue !== "" &&
  //     fromDateValue !== undefined &&
  //     tillDateValue !== "" &&
  //     tillDateValue !== undefined &&
  //     roundToDecimalId !== "" &&
  //     roundToDecimalId !== null
  //   ) {
  //     let isCustom = false;
  //     let Occasionally = false;
  //     let isDisplayable = false;
  //     let isExcelDownloadable = false;
  //     console.log("getting the created data created", this.state.checkboxList);
  //     this.state.checkboxList.map((item) => {
  //       if (item.menuName === "isCustom") {
  //         isCustom = item.checked;
  //       }
  //       if (item.menuName === "Occasionally") {
  //         Occasionally = item.checked;
  //       }
  //       if (item.menuName === "isDisplayable") {
  //         isDisplayable = item.checked;
  //       }
  //       if (item.menuName === "isExcelDownloadable") {
  //         isExcelDownloadable = item.checked;
  //       }
  //     });
  //     if (this.props.location.rowdata === undefined) {
  //       let fromDate = moment(this.state.fromDateValue).format("DD-MM-YYYY");
  //       let tillDate = moment(this.state.tillDateValue).format("DD-MM-YYYY");
  //       const data = {
  //         companyId: localStorage.getItem("companyIdconfig"),
  //         code: this.state.Code,
  //         name: this.state.Name,
  //         type: this.state.typeId,
  //         description: this.state.Description,
  //         effectiveFrom: fromDate,
  //         endsOn: tillDate,
  //         isCustomComponent: isCustom === undefined ? false : isCustom,
  //         displayableInPayslip:
  //           isDisplayable === undefined ? false : isDisplayable,
  //         isExcelDownloadable:
  //           isExcelDownloadable === undefined ? false : isExcelDownloadable,
  //         occasionally: Occasionally === undefined ? false : Occasionally,
  //         parentId: this.state.parentId,
  //         defaultValue: this.state.defaultValue,
  //         roundOf: this.state.roundToDecimalId,
  //         lupdate: this.state.lupdate,
  //         roundOfType: this.state.roundOfTypeId,
  //         createdBy: userId.toString(),
  //       };
  //       this.props.createPayschemeParameters(
  //         serverApi.CREATE_PAYSCHEME_PARAMETERS,
  //         data,
  //         this.props.history
  //       ); // Calling Create Department API
  //       console.log("craeted successfully", data);
  //     } else {
  //       let fromDate = moment(this.state.fromDateValue).format("DD-MM-YYYY");
  //       let tillDate = moment(this.state.tillDateValue).format("DD-MM-YYYY");
  //       const data = {
  //         companyId: localStorage.getItem("companyIdconfig"),
  //         id: this.state.payschemeId,
  //         code: this.state.Code,
  //         name: this.state.Name,
  //         type: this.state.typeId,
  //         description: this.state.Description,
  //         effectiveFrom: fromDate,
  //         endsOn: tillDate,
  //         isCustomComponent: isCustom === undefined ? false : isCustom,
  //         displayableInPayslip:
  //           isDisplayable === undefined ? false : isDisplayable,
  //         occasionally: Occasionally === undefined ? false : Occasionally,
  //         parentId: this.state.parentId,
  //         defaultValue: this.state.defaultValue,
  //         roundOf: this.state.roundToDecimalId,
  //         lupdate: this.props.location.rowdata.lupdate,
  //         roundOfType: this.state.roundOfTypeId,
  //         isExcelDownloadable:
  //           isExcelDownloadable === undefined ? false : isExcelDownloadable,
  //         createDate: this.state.createdOn,
  //         status: this.props.location.rowdata.status,
  //         modifiedBy: this.state.createdBy.toString(),
  //       };
  //       this.props.updatePayschemeParameters(
  //         serverApi.UPDATE_PAYSCHEME_PARAMETERS,
  //         data,
  //         this.props.history
  //       ); // Calling Update Department API
  //       console.log(
  //         "updated successfully",
  //         isExcelDownloadable,
  //         Occasionally,
  //         isCustom,
  //         data
  //       );
  //     }
  //   } else {
  //     swal("Please select all the mandatory fields", { icon: "error" });
  //     this.setState({
  //       confirm: true,
  //     });
  //   }
  //};
  // Function for Previous Page
  onClickCancel = () => {
    this.props.history.push("/payscheme_parameters");
  };
  // function for dropdown
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "type") {
      console.log("getting the selected value", selectedValue);
      this.setState({
        type: selectedValue,
        typeId: selectedValue,
      });
    }
    if (name === "roundOfType") {
      this.setState({
        roundOfType: selectedValue,
        roundOfTypeId: selectedValue,
      });
    }
    if (name === "roundToDecimal") {
      this.setState({
        roundToDecimal: selectedValue,
        roundToDecimalId: selectedValue,
      });
    }
    if (name === "parent") {
      this.setState({
        parent: selectedValue,
        parentId: selectedValue,
      });
    }
    if (name === "status") {
      this.setState({
        status: selectedValue,
      });
    }
  };
  handleInputChange = (event) => {
    if (event.target.value.length <= 20) {
      const paycode = new RegExp(/[^a-zA-Z0-9\s]+/g);
      let whiteSapce = new RegExp(/\s/);
      if (whiteSapce.test(event.target.value) || paycode.test(event.target.value)) {
        swal("Spaces and Special Characters are not allowed", {
          icon: "error",
        });
        this.setState({
          Code: "",
          Name: "",
        });
      }else{
        this.setState({
          Code: event.target.value,
          Name: event.target.value,
        });
      }
    }else{
      swal("Parameter Code length Should not be Greater than 20", {
        icon: "error",
      });
      this.setState({
        Code: "",
        Name: "",
      });
    }
  }
  // Function For TextFiled
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "Code") {
      if (event.target.value.length <= 20) {
        const paycode = new RegExp(/[^a-zA-Z0-9\s]+/g);
        let whiteSapce = new RegExp(/\s/);
        if (whiteSapce.test(event) || paycode.test(event)) {
          swal("Spaces and Special Characters are not allowed", {
            icon: "error",
          });
          this.setState({
            Code: "",
            Name: "",
          });
        } else {
          this.setState({
            Code: event.target.value,
            Name: event.target.value,
          });
        }
      } else {
        swal("Parameter Code length Should not be Greater than 20", {
          icon: "error",
        });
        this.setState({
          Code: "",
          Name: "",
        });
      }
    }
    if (key === "Name") {
      this.setState({
        Name: event,
      });
    }
    if (key === "Description") {
      this.setState({
        Description: event,
      });
    }
    if (key === "defaultValue") {
      this.setState({
        defaultValue: event,
      });
    }
  };
  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.payschemeParametersList(
      serverApi.GET_ALL_PAYSCHEME_PARAMETERS_LIST +
        "/companyId/" +
        localStorage.getItem("companyIdconfig") +
        "/orgId/0",
      this.props.history
    );
    if (this.props.location.rowdata) {
      const row = this.props.location.rowdata;
      if (this.props.location.rowdata.companyId == 0) {
        this.setState({
          DisplayUpdateButton: false,
        });
      } else {
        this.setState({
          DisplayUpdateButton: true,
        });
      }
      var checkData = this.state.checkboxList.map((item) => {
        console.log(
          "getting various selected value",
          this.props.location.rowdata
        );
        if (item.menuName === "isCustom") {
          item.checked = row.isCustomComponent;
        }
        if (item.menuName === "isDisplayable") {
          item.checked = row.isDisplayableInPayslip;
        }
        if (item.menuName === "Occasionally") {
          item.checked = row.isOccasionally;
        }
        if (item.menuName === "isExcelDownloadable") {
          item.checked = row.isExcelDownloadable;
        }
        return item;
      });
      this.setState({
        checkboxList: checkData,
        companyId: row.companyId,
        Code: row.code,
        cummulative: row.cummulative,
        defaultValue: row.defaultValue,
        Description: row.description,
        fromDateValue: row.effectiveFrom,
        tillDateValue: row.endsOn,
        payschemeId: row.id,
        isCustom: row.isCustomComponent,
        isDisplayable: row.isDisplayableInPayslip,
        isExcelDownloadable: row.isExcelDownloadable,
        Occasionally: row.isOccasionally,
        lupdate: row.lupdate,
        Name: row.name,
        parentId: row.parentId,
        roundToDecimalId: row.roundOf,
        roundOfTypeId: row.roundOfType,
        typeId: row.type,
        status: row.status,
        createdBy: row.createdBy,
        createdOn: row.createDate,
        status: row.status == 0 ? 2 : row.status,
      });
      const dateEntered = this.props.location.rowdata.autoDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createddate: dateIsValid,
      });
    }
  }

  handleKeyDown = (event) => {
    if (event === 32) {
      swal("Space is not Allowed..!!");
    }
  };
  ///function for date formate
  handleSelect_Date = (e, name) => {
    if (name === "fromDate") {
      var fromDate = moment(e).format();
      var fromDat = fromDate.split("T", 1).toString();
      this.state.fromDateValue = fromDate;
      this.setState({
        fromDateValue: fromDate,
      });
      console.log("getting the date", fromDate);
    }
    if (name === "tillDate") {
      var tillDate = moment(e).format();
      var tillDat = tillDate.split("T", 1).toString();
      this.setState({
        tillDateValue: tillDat,
      });
    }
  };
  // Function for Check box
  handleChange = (name, value) => {
    console.log("getting the value on click", value);
    let newMarkers = this.state.checkboxList.map((el) =>
      el.menuName === name ? { ...el, checked: value } : el
    );
    console.log("getting the checkBox list", newMarkers);
    this.setState({ checkboxList: newMarkers });
  };

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    console.log("Scrolling...");
    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
    const mdata = this.props.PayschemeParametersList
      ? this.props.PayschemeParametersList.data
      : "";
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <>
              <div className="departmentMasterContainer">
                <div className="consoleFormContainer">
                  <h5>Fill Details</h5>
                  <div className="consoleFormBlock">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                        >
                          {this.props.location.rowdata === undefined ? (
                            ""
                          ) : (
                            <>
                              <Box gridColumn="span 3">
                                <div className="consoleTextFieldBlock">
                                  <TextFieldNormalNew
                                    value={this.state.payschemeId}
                                    //     readOnly={this.props.location.rowdata !== undefined ? true : false}
                                    caps="OFF"
                                    minValue="0"
                                    onChange={this.onhandlechangeValue(
                                      "payschemeId"
                                    )}
                                    label="Pay scheme Id"
                                    disabled={
                                      this.props.location.rowdata !== undefined
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </Box>
                            </>
                          )}
                          <Box gridColumn="span 2">
                            <div className="consoleAdormentBlock">
                         { this.props.location.rowdata !== undefined ?     <TextFieldReq
                                label="Code"
                                required
                                value={this.state.Code}
                                onChange={this.onhandlechangeValue("Code")}
                                handleKeyDown={this.handleKeyDown}
                                disabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />:
                              <>
                                <label>Code</label>
                                <TextField
                                  className="Code"
                                  value={this.state.Code}
                                  onChange={(e) => this.handleInputChange(e)}
                                  handleKeyDown={this.handleKeyDown}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        C_
                                      </InputAdornment>
                                    ),
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        _
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </>}
                            </div>
                          </Box>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldReq
                                label="Name"
                                required
                                value={this.state.Name}
                                onChange={this.onhandlechangeValue("Name")}
                                disabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <label>
                                Type <span style={{ color: "red" }}>*</span>
                              </label>
                              <DynamicSelectNew
                                arrayOfData={Type}
                                className="dropdownBlockContainer"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                onSelectChange={this.handlePropSelectChange}
                                isDropdownOpen={this.state.isDropdownOpen}
                                handleMenuOpen={this.handleMenuOpen}
                                selected={this.state.typeId}
                                // update={this.state.typeId? 1 : 0}
                                name="type"
                                label="Type"
                                required
                                isDisabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>
                          {/* <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <div>
                                <div className="TextFieldBlock">
                                  <label>
                                    Effective From{" "}
                                    <label style={{ color: "red" }}>*</label>
                                  </label>
                                </div>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd-MM-yyyy"
                                    value={this.state.fromDateValue}
                                    fullWidth
                                    onChange={(e) =>
                                      this.handleSelect_Date(e, "fromDate")
                                    }
                                    renderInput={({
                                      inputRef,
                                      inputProps,
                                      InputProps,
                                    }) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        className="consoleDatePicker"
                                      >
                                        <input
                                          ref={inputRef}
                                          {...inputProps}
                                          readOnly={true}
                                        />
                                        {InputProps?.endAdornment}
                                      </Box>
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                          </Box> */}
                          {/* <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <div>
                                <div className="TextFieldBlock">
                                  <label>
                                    Effective Till{" "}
                                    <label style={{ color: "red" }}>*</label>
                                  </label>
                                </div>
                              
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DesktopDatePicker
                                    inputFormat="dd-MM-yyyy"
                                    value={this.state.tillDateValue}
                                    minDate={this.state.fromDateValue}
                                    fullWidth
                                    onChange={(e) =>
                                      this.handleSelect_Date(e, "tillDate")
                                    }
                                    renderInput={({
                                      inputRef,
                                      inputProps,
                                      InputProps,
                                    }) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        className="consoleDatePicker"
                                      >
                                        <input
                                          ref={inputRef}
                                          {...inputProps}
                                          readOnly={true}
                                        />
                                        {InputProps?.endAdornment}
                                      </Box>
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                          </Box> */}
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <DynamicSelect
                                arrayOfData={
                                  mdata &&
                                  mdata.length !== 0 &&
                                  mdata.map((item) => ({
                                    label: item.code,
                                    value: item.id,
                                    name: item.code,
                                  }))
                                }
                                className="dropdownBlockContainer"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                onSelectChange={this.handlePropSelectChange}
                                isDropdownOpen={this.state.isDropdownOpen}
                                handleMenuOpen={this.handleMenuOpen}
                                selected={this.state.parentId}
                                update={this.props.location.state ? 1 : 0}
                                name="parent"
                                label="Parent"
                                isDisabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                          className="paySchemeCheckboxBlock"
                        >
                          {this.state.checkboxList
                            ? this.state.checkboxList.map((prop, inx) => {
                                console.log(prop);
                                return (
                                  <>
                                    <Box gridColumn="span 3">
                                      <Checkbox
                                        label={prop.menuName}
                                        selected={prop.checked}
                                        value={prop.isCustom}
                                        name={prop.menuName}
                                        onChange={this.handleChange}
                                        disabled={
                                          this.props.location.rowdata !==
                                          undefined
                                            ? true
                                            :prop.menuName=="isCustom" ? true
                                            :prop.menuName=="Occasionally" ? true
                                            : false
                                        }
                                        
                                      />
                                    </Box>
                                  </>
                                );
                              })
                            : ""}
                        </Box>
                        <span style={{ color: "red" }}>
                        Check isExcelDownloadable option if there is monthly change to this figure, else if fixed then do not check.
                        </span> <br/>
                        <span style={{ color: "red" }}>
                          Customize controls given to the user...
                        </span>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                        >
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <DynamicSelect
                                arrayOfData={RoundToDecimal}
                                className="dropdownBlockContainer"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                onSelectChange={this.handlePropSelectChange}
                                isDropdownOpen={this.state.isDropdownOpen}
                                handleMenuOpen={this.handleMenuOpen}
                                selected={this.state.roundToDecimalId}
                                update={this.props.location.state ? 1 : 0}
                                name="roundToDecimal"
                                label="Round To Decimals"
                                required
                                isDisabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <DynamicSelect
                                arrayOfData={RoundOfType}
                                className="dropdownBlockContainer"
                                menuPosition="fixed"
                                menuPlacement="auto"
                                onSelectChange={this.handlePropSelectChange}
                                isDropdownOpen={this.state.isDropdownOpen}
                                handleMenuOpen={this.handleMenuOpen}
                                selected={this.state.roundOfTypeId}
                                update={this.props.location.state ? 1 : 0}
                                name="roundOfType"
                                label="Round Of Type"
                                isDisabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>

                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldNormalNew
                                value={this.state.defaultValue}
                                caps="OFF"
                                minValue="0"
                                onChange={this.onhandlechangeValue(
                                  "defaultValue"
                                )}
                                label="Default Value"
                                disabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldNormalNew
                                label="Description"
                                type="textarea"
                                onChange={this.onhandlechangeValue(
                                  "Description"
                                )}
                                value={this.state.Description}
                                rows={5}
                                disabled={
                                  this.props.location.rowdata !== undefined
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </Box>
                          {this.props.location.rowdata !== undefined ? (
                            <Box gridColumn="span 3">
                              <div className="consoleTextFieldBlock">
                                <DynamicSelect
                                  arrayOfData={StatusData}
                                  className="dropdownBlockContainer"
                                  menuPosition="fixed"
                                  menuPlacement="auto"
                                  onSelectChange={this.handlePropSelectChange}
                                  isDropdownOpen={this.state.status}
                                  handleMenuOpen={this.handleMenuOpen}
                                  selected={this.state.status}
                                  update={this.state.status ? 1 : 0}
                                  name="status"
                                  label="Status"
                                />
                              </div>
                            </Box>
                          ) : (
                            ""
                          )}
                          {this.props.location.rowdata === undefined ? (
                            ""
                          ) : (
                            <>
                              <Box gridColumn="span 6">
                                <div className="consoleUpdateTextBlock">
                                  <div>
                                    <span className="taxLabel">
                                      Created By : <b>{this.state.createdBy}</b>
                                    </span>
                                  </div>
                                  <div>
                                    <span className="taxLabel">
                                      Created On :
                                      <b> {this.state.createddate}</b>
                                    </span>
                                  </div>
                                </div>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={this.onClickCancel}
                  />
                </Link>
                {this.props.location.rowdata !== undefined &&
                this.state.DisplayUpdateButton == true ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                ) : (
                  ""
                )}
                {this.props.location.rowdata == undefined ? (
                  <CustomButton
                    label={"Create"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                ) : (
                  ""
                )}

                {/* Popup For Validation */}
                {
                  this.state.confirm && ""
                  // <CommonPopup>
                  //   <div className="delAddItem">
                  //     <div>Please select all the mandatory fields</div>
                  //     <div className="delAddItemBtns">
                  //       <input
                  //         type="button"
                  //         onClick={this.onclickPopUP.bind(this)}
                  //         value="Ok!"
                  //         className="delYesBtn"
                  //       />
                  //     </div>
                  //   </div>
                  // </CommonPopup>
                }
              </div>
            </>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const Type = [
  // { label: "Select...", value: null, name: "Select" },
  { label: "No Calculation", value: 0, name: "NoCalculation" },
  { label: "Earning", value: 1, name: "Earning" },
  { label: "Deduction", value: 2, name: "Deduction" },
  { label: "Summary", value: 3, name: "Summary" },
];

const RoundOfType = [
  // { label: "Select...", value: null, name: "Select" },
  { label: "HALF_EVEN", value: 1, name: "R/HALF_EVEN" },
  { label: "DOWN", value: 2, name: "DOWN" },
  { label: "UP", value: 3, name: "UP" },
];
const StatusData = [
  { label: "Active", value: 1, name: "Active" },
  { label: "InActive", value: 2, name: "InActive" },
];
const RoundToDecimal = [
  // { label: "Select", value: null, name: "Select" },
  { label: "0", value: 1, name: "0" },
  { label: "1", value: 2, name: "1" },
  { label: "2", value: 3, name: "2" },
  { label: "3", value: 4, name: "3" },
  { label: "4", value: 5, name: "4" },
  { label: "5", value: 6, name: "5" },
  { label: "6", value: 7, name: "6" },
];

const mapStatetoProps = (state) => {
  const { Create_Payscheme, Update_Payscheme } = state.PayschemeParaReducer;
  const { PayschemeParametersList } = state.PayschemeParaReducer;
  return { Update_Payscheme, Create_Payscheme, PayschemeParametersList };
};

export default withRouter(
  connect(mapStatetoProps, {
    createPayschemeParameters,
    updatePayschemeParameters,
    payschemeParametersList,
  })(createPayschemePara)
);
