// /* Author : Niranjan
// Created on : 23-02-2022
//*/

import React, { Component } from "react";
import { Card, Box, CardContent, Typography } from "@mui/material";

class DashboardCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="dashboardCardContainer">
          <Card sx={{ display: "flex" }} className={this.props.dashboardCardBg}>
            <Box className="dashboardCardIconBlock">
              <Box className={this.props.iconClass}>
                <img src={this.props.CardIcon} alt="" />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent
                sx={{ flex: "1 0 auto" }}
                className="dashboardCardContent"
              >
                <Typography
                  component="div"
                  variant="p"
                  className="dashboardCardHeading"
                >
                  {this.props.cardType}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                  className="dashboardCount"
                >
                  {this.props.cardCount}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </div>
      </>
    );
  }
}
export default DashboardCard;
