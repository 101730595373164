import {LEAVETYPELIST, LEAVETYPELIST_SUCCESSFULL,
    CREATE_LEAVE_TYPE, CREATE_LEAVE_TYPE_SUCCESSFULL,
    UPDATE_LEAVE_TYPE, UPDATE_LEAVE_TYPE_SUCCESSFULL, API_ERRORS} from './actionTypes';



export const getLeaveTypeList = (url,data,history) =>{
    console.log("getting the api here", url,data)
    return {
        type:LEAVETYPELIST,
        payload:{url,data,history}
    }
}

export const LeaveTypeListSuccessfull = (data)=>{
    return {
        type:LEAVETYPELIST_SUCCESSFULL,
        payload:data
    }
}
export const createLeaveType = (url, data, history) => {
    return {
        type: CREATE_LEAVE_TYPE,
        payload: { url, data, history }
    }
}

export const createLeaveTypeSuccessfull = (data) => {
    return {
        type: CREATE_LEAVE_TYPE_SUCCESSFULL,
        payload: data
    }
}
export const updateLeaveType = (url, data, history) => {
    return {
        type: UPDATE_LEAVE_TYPE,
        payload: { url, data, history }
    }
}

export const updateLeaveTypeSuccessfull = (data) => {
    return {
        type: UPDATE_LEAVE_TYPE_SUCCESSFULL,
        payload: data
    }
}

