import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import cons, { serverApi, getTenantId } from "../../helpers/Consts";
import swal from "sweetalert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NextDueIcon from "../../assets/images/invoiceNextDueIcon.png";
import { DataGrid } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import { GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import GridArrowIcon from "../../assets/images/gridArrowIcon.png";
import Button from "../../components/Buttons/Buttons";
import { getModules } from "../../store/ModuleSelection/actions";
import CustomButton from "../../components/Buttons/Buttons";
import { NoEncryption } from "@mui/icons-material";

class BillingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Billing Details",
      companyId: localStorage.getItem("companyIdconfig"),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      orgId: localStorage.getItem("companyupdateId"),
      companypage: "",
      createbranchbtn: 0,
      module: 4,
      invoiceList: [],
      invoiceDataGrid: "block",
      invoicePreview: "none",
      invoice: [],
      invoiceNo: "",
      invoiceDate: "",
      previousplans: "none",
      previousplansbuttontitle:"Show Previous Plans",
      previousplanstoggle:0,
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = () => {
    this.props.getModules(
      serverApi.GET_ORG_INVOICE_LIST + this.state.userDit.orgId
    );
  };
  componentWillReceiveProps(props) {
    if (props.modulelist) {
      this.setState({ invoiceList: props.modulelist.result });
      var invData = props.modulelist.result;
      if (invData) {
        if (invData.invoiceData) {
          invData.invoiceData.map((invs) => {
            this.setState({
              invoiceNo: invs.invoice_no_string,
              invoiceDate: invs.invoice_date,
            });
          });
        }
      }
    }
  }

  onChange = (name, page, btn) => {
    this.setState({
      parentName: name,
      companypage: page,
      createbranchbtn: btn,
      buttonbranch: 100,
    });
  };

  onInvoiceRowClick = (params) => {
    this.setState({
      invoice: params.invoice,
      invoiceDataGrid: "none",
      invoicePreview: "block",
    });
  };

  handleChangePlan = () => {
    this.props.history.push('/ModuleSelection');
  }

  handlePdf = () => {
    // let indentId = this.props.RowData.id;

    // this.props.getFileDownload(serverApi.DOWNLOAD_PDF+indentId+'/'+this.state.userDit.userId)

    fetch(
      serverApi.DOWNLOAD_PDF + this.state.userDit.userId + "/" + 2 + "/" + 1,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/pdf",
          "X-TenantId": getTenantId(window.location.hostname),
          Authorization:
            localStorage.getItem("token_type") +
            localStorage.getItem("access_token"),
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice" + ".pdf");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };
  handleBackToList = () => {
    this.setState({
      invoiceDataGrid: "block",
      invoicePreview: "none",
    });
  };
  handlePreviousPlanshow = () => {
    if(this.state.previousplanstoggle==0){
      this.setState({
        previousplans: "block",
        previousplansbuttontitle:"Hide Previous Plans",
        previousplanstoggle:1,
      })
    }else{
      this.setState({
        previousplans: "none",
        previousplansbuttontitle:"Show Previous Plans",
        previousplanstoggle:0,
      })
    }
    
  }
  render() {
    const rows = [
      // { id: 222, invoiceno: "INV002522", invoice_date: "15-02-2022", totalamt: "1000.00", dueamt: "₹ " + 29.88 },
      // { id: 333, invoiceno: "INV002522", invoice_date: "15-02-2022", totalamt: "1250.00", dueamt: "₹ " + 29.88  },
    ];
    if (this.state.invoiceList) {
      var invoicedata = this.state.invoiceList.invoiceData;
      if (invoicedata) {
        invoicedata.map((inv) => {
          var obj = {
            id: inv.invoice_id,
            invoiceno: inv.invoice_no_string,
            invoice_date: inv.invoice_date,
            totalamt: inv.grand_total,
            dueamt: inv.due_amount,
            invoice: inv,
          };
          rows.push(obj);
        });
      }
    }

    const columns = [
      // { field: "id", headerName: "Invoice No.", width: 150, status: "" },
      { field: "invoiceno", headerName: "Invoice No", width: 200, status: "" },
      {
        field: "invoice_date",
        headerName: "Invoice Date",
        width: 150,
        status: "",
      },
      {
        field: "totalamt",
        headerName: "Total Amount",
        width: 160,
      },
      {
        field: "dueamt",
        headerName: "Due Amount",
        width: 160,
      },
      {
        field: "status",
        headerName: "Status",
        width: 180,
        sortable: false,
        filter: false,
        renderCell: (params) => {
          var dueamt = params.row.dueamt;
          return (
            <div
              className={Number(dueamt) > 0 ? "paymentDue" : "statusCompleted"}
            >
              {Number(dueamt) > 0 ? "Payment Due" : "Completed"}
            </div>
          );
        },
      },
      {
        field: "action",
        headerName: "Actions",
        sortable: false,
        width: 180,
        renderCell: (params) => {
          // const onClick = (e) => {
          //   // e.stopPropagation(); // don't select this row after clicking

          //   // const api: GridApi = params.api;
          //   // const thisRow: Record<string, GridCellValue> = {};

          //   // api
          //   //   .getAllColumns()
          //   //   .filter((c) => c.field !== "__check__" && !!c)
          //   //   .forEach(
          //   //     (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          //   //   );

          //   // return alert(JSON.stringify(thisRow, null, 4));
          //   console.log(params);
          //   console.log(e);
          // };

          return (
            // <Button onClick={this.onInvoiceRowClick} className="greenButton">View Invoice</Button>
            <CustomButton
              label="View Invoice"
              className="lightBlueButton"
              type="button"
              handleClick={() => this.onInvoiceRowClick(params.row)}
            />
            // <Link href="/ModuleSelection" style={{ position: "absolute", right: 30 }}> View Invoice</Link>
          );
        },
      },
    ];
    function ccyFormat(num) {
      return `${num.toFixed(2)}`;
    }

    function priceRow(qty, unit) {
      return qty * unit;
    }

    function createRow(desc, qty, unit) {
      const price = "₹ " + ccyFormat(priceRow(qty, unit));
      return { desc, qty, unit, price };
    }
    function createRowTot(desc, tot) {
      const price = "₹ " + ccyFormat(tot);
      return { desc, price };
    }

    const rows1 = [
      createRow("Modules (2)", 100, 1.15),
      createRow("No. of Users (400)", 10, 45.99),
      createRow("No. of Companies (8)", 2, 17.99),
      createRow("Additions (2)", 2, 17.99),
      createRowTot("Total Amount", 646.86),
    ];
    if (this.state.invoiceList) {
      var due_amount = this.state.invoiceList.due_amount;
      if (this.state.invoiceList.addons) {
        var addons = this.state.invoiceList.addons;
        var addons_data = [];
        var noofaddons = 0;
        var addonsamt = 0;
        var noofmodules = 0;
        var modulesamt = 0;
        var totalamt = 0;
        addons.map((addonrow) => {
          if (addonrow.addonId == 6002) {
            var mobj = {
              desc: "No of Users : ",
              value: addonrow.transactions_count,
            };
            addons_data.push(mobj);
          } else if (addonrow.addonId == 6001) {
            var mobj = {
              desc: "No of Companys : ",
              value: addonrow.transactions_count,
            };
            addons_data.push(mobj);
          } else if (
            addonrow.addonId == 1 ||
            addonrow.addonId == 2 ||
            addonrow.addonId == 3 ||
            addonrow.addonId == 4 ||
            addonrow.addonId == 5 ||
            addonrow.addonId == 6
          ) {
            noofaddons = noofaddons + 1;
            addonsamt = addonrow.addons_price;
          } else {
            noofmodules = noofmodules + 1;
            modulesamt = addonrow.module_price;
          }
          totalamt = addonrow.total_price;
        });

        var mobj = {
          desc: "Modules(" + noofmodules + ") : ",
          value: "₹ " + modulesamt,
        };
        addons_data.push(mobj);
        var mobj = {
          desc: "Addons(" + noofaddons + ") : ",
          value: "₹ " + addonsamt,
        };
        addons_data.push(mobj);
        var mobj = {
          desc: "Totals : ",
          value: "₹ " + totalamt,
        };
        addons_data.push(mobj);
      }
    }


    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Box
            className="invoiceSection"
            sx={{
              boxShadow: 3,
              m: 0,
            }}
          >
            {/* <Box className="invoiceSectionHeader">
              <h1>Invoice No. {this.state.invoiceNo}</h1>
            </Box> */}
            <Grid sx={{ p: 2.5 }} container className="billingDetailsHeader">
              {/* <TableRow>
                <TableCell>Invoice Date</TableCell>
                <TableCell align="right">{this.state.invoiceDate}</TableCell>
              </TableRow> */}     
              <h5>Current Plan Details</h5>        
            </Grid>
            <TableContainer>
              {/* <h5>Other Details</h5> */}
              <Table sx={{ minWidth: 100 }} aria-label="spanning table">
                <TableBody>
                  {/* {rows1.map((row) => (
                    <TableRow key={row.desc}>
                      <TableCell>{row.desc}</TableCell>
                      <TableCell align="right">
                        {row.price}
                      </TableCell>
                    </TableRow>
                  ))} */}

                  {/* {ccyFormat(row.price)} */}
                  {addons_data ? (
                    addons_data.map((row) => (
                      <TableRow key={row.value}>
                        <TableCell>{row.desc}</TableCell>
                        <TableCell align="right">{row.value}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="invoiceSectionFooter">
              {/* <Box className="invoiceFooterContent">
                <h5>Payment Details</h5>
                <Box className="invoicePaidInfo">
                  <span>Payment Status</span>
                  {due_amount ? (
                    <span style={{ color: "green" }}>
                      Paid <i className="fa fa-check"></i>
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>
                      Due <i className="fa fa-times"></i>
                    </span>
                  )}
                </Box>
              </Box> */}
              <Box sx={{ p: 2 }}>
                <Box>
                  <CustomButton
                    label="Change Plan"
                    className="greenBorderButton"
                    type="button"
                    handleClick={() => this.handleChangePlan()}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <CustomButton label={this.state.previousplansbuttontitle} className="greenButton" type="button" handleClick={()=>this.handlePreviousPlanshow()} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={9} style={{ display: this.state.invoiceDataGrid }}>
          <div className="dashboardDataGrid">
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid rows={rows} columns={columns} pageSize={5} />
            </div>
          </div>
        </Grid>
        <Grid item xs={9} style={{ display: this.state.invoicePreview }}>
          <table
            className="invoiceTable table table-borderless"
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td colSpan={3}>
                  {this.state.invoice.customer_name
                    ? this.state.invoice.customer_name
                    : ""}
                </td>
                <td colSpan={3}>
                  Order ID :{" "}
                  {this.state.invoice.payment_id
                    ? this.state.invoice.payment_id
                    : ""}
                </td>
              </tr>
              <tr>
                <td colspan="1">
                  <b>Invoice No: </b>
                </td>
                <td colspan="2">
                  {this.state.invoice.invoice_no_string
                    ? this.state.invoice.invoice_no_string
                    : ""}
                </td>
                <td colspan="1">
                  <b>Invoice Date : </b>
                </td>
                <td colspan="2">
                  {this.state.invoice.invoice_date
                    ? this.state.invoice.invoice_date
                    : ""}
                </td>
              </tr>
              <tr>
                <td colSpan={3} class="font-weight-bold">
                <span>Billing address</span>
                  <br />
                  {this.state.invoice.billing_address
                    ? this.state.invoice.billing_address
                    : ""}
                  <br />
                  {this.state.invoice.billing_state_name
                    ? this.state.invoice.billing_state_name
                    : ""}
                  <br />
                  {this.state.invoice.billing_state_code
                    ? this.state.invoice.billing_state_code
                    : ""}
                </td>
                <td colSpan={3} class="font-weight-bold">
                  <span>Shipping address</span>
                  <br />
                  {this.state.invoice.shipping_address
                    ? this.state.invoice.shipping_address
                    : ""}
                  <br />
                  {this.state.invoice.shipping_state_name
                    ? this.state.invoice.shipping_state_name
                    : ""}
                  <br />
                  {this.state.invoice.shipping_state_code
                    ? this.state.invoice.shipping_state_code
                    : ""}
                </td>
              </tr>
              <tr className="tableHeading">
                <td>Item Description</td>
                <td>Item Group</td>
                <td>Item Name</td>
                <td>Quantity</td>
                <td>Rate</td>
                <td class="text-center">Total</td>
              </tr>
              {this.state.invoice.invLineItems &&
                this.state.invoice.invLineItems.map((data, index) => (
                  <tr key={index}>
                    <td>{data.item_description}</td>
                    <td>{data.item_group}</td>
                    <td>{data.item_name}</td>
                    <td>{data.quantity}</td>
                    <td>{data.rate}</td>
                    <td>{data.total_amount}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="consoleFormButtonBlock">
            <CustomButton
              label="Download"
              className="greenButton"
              handleClick={this.handlePdf}
              type="button"
            />
            <CustomButton
              label="Back to list"
              className="greenButton"
              handleClick={this.handleBackToList}
              type="button"
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{display:this.state.previousplans}}>
        <div class="table-responsive">
          <table className="invoiceTable table table-sm" border={1}>
            <thead>
                  <tr>
                    <th colspan="10">PREVIOUS PLANS</th>
                  </tr>
                  <tr>
                    <th>S.NO</th>
                    <th>Date Of Plan</th>
                    <th>Companys</th>
                    <th>Users</th>
                    <th>Modules</th>
                    <th>Addon's</th>
                    <th>Modules Amt</th>
                    <th>Addon's Amt</th>
                    <th>Total Amt</th>
                  </tr>
            </thead>
            <tbody>
              {
                this.state.invoiceList ? this.state.invoiceList.previousplans ? this.state.invoiceList.previousplans.map((plan, inv) => (
                  <>
                    <tr>
                    <td>{inv+1}</td>
                    <td>{plan.created_date}</td>
                    <td>{plan.companys}</td>
                    <td>{plan.users}</td>
                    <td>{plan.modulesData}</td>
                    <td>{plan.addonsData}</td>
                    <td>{plan.module_price}</td>
                    <td>{plan.addons_price}</td>
                    <td>{plan.total_price}</td>
                    </tr>
                  </>
                )):<></> : <></>
              }
            </tbody>
          </table>
          </div>
        </Grid>
      </Grid>
      
    );
  }
}

const mapStatetoProps = (state) => {
  const { modulelist } = state.ModuleSelection;
  return { modulelist };
};

export default withRouter(
  connect(mapStatetoProps, {
    getModules,
  })(BillingDetails)
);
