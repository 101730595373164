import React, { Component } from "react";
import { Grid, Box, Paper, Button} from "@mui/material";
import { connect } from "react-redux";
import Tabs from "../../components/TabsNavigation/TabsNavigationUserCreate";
import CreateUser from "../../Pages/UserManagement/userCreate3";
import CreatePassword from "../../Pages/Authentication/ChangePassword";

class UserCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHedder: "Basic Details",
      companyname: "",
      setdate:"",
      dtvalue:"",
      companyId:0,
      errors:false,
    };
  }

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };

  render() {
    return (
      <>           
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tabs printstyles={<CreateUser configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId} />} mrininvoice={<CreatePassword configdata={this.props.configdata}  selectedCompanyId={this.props.selectedCompanyId} />}  />                  
            </Grid>                
          </Grid>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  // const { locationlist, model, rowdata } = state.Location;
  // return { locationlist, model, rowdata };
};

export default connect(mapStatetoProps, {
})(UserCreate);
