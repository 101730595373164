import React, { Component, Fragment } from "react";
import { Grid, Box, Paper, Button, TextField } from "@mui/material";
import ConsoleDatePicker from "../../components/DatePicker/DatePicker";
import InputAdorment from "../../components/InputAdorment/InputAdorment";
import ConsoleUpload from "../../components/PictureUpload/PictureUpload";

import ConsoleDropdown from "../../components/Dropdown/Dropdown";
import ConsoleTextField from "../../components/TextField/TextField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cons, { serverApi } from "../../helpers/Consts";
import CustomButton from "../../components/Buttons/Buttons";
import ExportIcon from "../../assets/images/exportIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import Header from "../../components/Header/Header";
import BootstrapTable from "react-bootstrap-table-next";

import { getbranchlist } from "../../store/UserManagement/actions";
import Buttons from "../../components/Buttons/Buttons";
import Dropdown from "../../components/Dropdown/Dropdown";
import DocumentUpload from "../../components/DocumentUpload/DocumentUpload";
import PlaceHolder from "../../components/PlaceHolders/PlaceHolderComponent";
import { getCompanys } from "../../store/Company/BasicDetails/actions";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

class BranchList extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: 0 };
  }
  componentDidMount() {
    // console.log("getting data of the companyId while creation", this.props.basicDetails.company.companyId);

    // if(this.props.basicDetails) {
      // console.log("getting data of the companyId while creation", this.props.basicDetails.company.companyId);

      // if(this.props.basicDetails.company){
        // console.log("getting data of the companyId while creation full up", this.props.companyresponse.response);
        if(this.props.headerData) {  
          if(this.props.headerData.companyId){  
          var data = {
            companyId: this.props.headerData.companyId,
            cipher: "fb815aa1774a7063ccb7c532866d306c",
          };
          this.props.getbranchlist(
            serverApi.BRANCHMASTER_DATATABLE,
            data,
            this.props.history
          );
        }
       }else {
        
        // if(this.props.companyresponse !== null){
        //   console.log("getting data of the companyId while creation up", this.props.companyresponse.response);

        //   if(this.props.companyresponse.response){
        //     console.log("getting data of the companyId while creation", this.props.companyresponse.response);

        //     var data = {
        //       companyId: this.props.headerData.companyId,
        //       cipher: "fb815aa1774a7063ccb7c532866d306c",
        //     };
        //     this.props.getbranchlist(
        //       serverApi.BRANCHMASTER_DATATABLE,
        //       data,
        //       this.props.history
        //     );
        //   }
        // }
       }

        
        // }
     
     
    // }
    
   
  }

  editRow = (row) => {
    cons.COMPANY_ID = row.companyId;
    this.props.history.push({ pathname: "/createbranch", rowdata: row });
    localStorage.setItem("companyupdateId", row.companyId);
  };
  deleteRow(row) {
    cons.COMPANY_ID = row.companyId;
  }

  render() {
    const mdata = this.props.branchList;
    // const mcolumns = this.props.branchListColumnsData;
    const mcolumns = [];


    if (mdata) {
      var products = mdata.map((row, order) => ({
        ...row,
        chk: (
          <div
            className="checkbox form-check"
            style={{ border: "1px solid green" }}
          >
            <div className="custom-checkbox custom-control custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={this.handleChange}
                value={row.companyId}
                id={row.companyId}
              />
              <label className="custom-control-label" for={row.companyId}></label>
            </div>{" "}
          </div>
        ),
        actions: (
          <Fragment>
            <EditIcon onClick={() => this.editRow(row)} />
            {/* <DeleteIcon onClick={() => this.deleteRow(row)} /> */}
          </Fragment>
        ),
      }));
    } else {
      var products = [];
    }
    const { SearchBar } = Search;
    const headerSortingStyle = { backgroundColor: "#e3edf8" };
    const columns = [
      {
        dataField: "companyId",
        text: "Company Code",
        headerAlign: "left",
        align: "left",
      },
      
    ];
    if (mcolumns) {
      for (let i = 0; i < mcolumns.length; i++) {
        var coldata = {
          dataField: mcolumns[i].accessor,
          // text: mcolumns[i].Header,

          // text:"Branch Id",
          hidden:true,

          headerAlign: "left",
          align: "left",
        };
        columns.push(coldata);
      }
      var brname={
        dataField: "brname",
        text: "Branch Name",
        headerAlign: "left",
        align: "left",
      }
      columns.push(brname);

      var address={
        dataField: "address",
        text: "Address",
        headerAlign: "left",
        align: "left",
      }
      columns.push(address);

      var actions = {
        dataField: "actions",
        text: "Actions",
        headerAlign: "left",
        align: "left",
      };
      columns.push(actions);
    } else {
      const columns = [];
    }
    const defaultSorted = [
      {
        dataField: "companyId",
        order: "asc",
      },
    ];

    const MyExportCSV = (props) => {
      const handleExportClick = () => {
        props.onExport();
      };
      return (
        <CustomButton
          label="Export"
          className="lightBlueButton"
          type="button"
          icon={ExportIcon}
          handleClick={handleExportClick}
        />
      );
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: true,
    };
    return (
      <>
        {mdata ? (
          <Grid>
            <LeftSidebar />
            <Grid className="">
              <div className="consoleContainer">
                <div className="consoleFormContainer">
                  <div className="gridContainer">
                    <div className="gridComponent">
                      <PaginationProvider
                        pagination={paginationFactory({
                          custom: true,
                          totalSize: products.length,
                          page: 1,
                          sizePerPage: 5,
                          nextPageText: "Next >",
                          prePageText: "< Prev",
                          withFirstAndLast: false,
                          showTotal: true,
                          sizePerPageList: [
                            {
                              text: "10",
                              value: 10,
                            },
                            {
                              text: "30",
                              value: 30,
                            },
                            {
                              text: "50",
                              value: 50,
                            },
                            {
                              text: "All",
                              value: products.length,
                            },
                          ],
                          hideSizePerPage: products.length === 0,
                        })}
                        keyField="companyId"
                        columns={columns}
                        data={products}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="companyId"
                            columns={columns}
                            data={products}
                            search
                            exportCSV={{
                              fileName: "productList.csv",
                              onlyExportSelection: true,
                              exportAll: true,
                            }}
                          >
                            {(toolkitprops) => (
                              <div>
                                <Grid
                                  container
                                  spacing={2}
                                  className="buttonBlock"
                                >
                                  <div className="gridButtonBlock">
                                    <SearchBar {...toolkitprops.searchProps} />
                                    <MyExportCSV {...toolkitprops.csvProps} />
                                  </div>
                                </Grid>
                                <BootstrapTable
                                  {...toolkitprops.baseProps}
                                  {...paginationTableProps}
                                  // selectRow={selectRow}
                                  defaultSorted={defaultSorted}
                                  defaultSortDirection="asc"
                                  striped
                                  noDataIndication="No Data Is Available"
                                />
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />

                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <div className="consoleFormContainer">
            <PlaceHolder />
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companyslist, companyscoulmns, headerData, companyresponse } = state.BasicDetails;
  const { branchList, branchListColumnsData } = state.UserManagement;
  const { accounttypeslist, basicDetails } = state.OffcialDetails;
  // const { companyresponse} = state.BasicDetails;


  return { companyslist, companyscoulmns, branchList, branchListColumnsData, basicDetails, headerData, companyresponse };
};

export default withRouter(
  connect(mapStatetoProps, {
    getbranchlist,
    getCompanys,
  })(BranchList)
);
