import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "../../components/Buttons/Buttons";
import LinkMi from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Logout from "../../assets/images/logout.png";
import ProfileArrowIcon from "../../assets/images/profileHeaderArrowIcon.png";
import BranchCreation from "../../Pages/CompanyCreation/BranchCreation";
import UserCreate from "../../Pages/UserManagement/UserCreate";
import Billing from "../../Pages/Billing/Billing";
import UserCreateNew from "../../Pages/UserManagement/UserCreateNew";
import CreateRole from "../../Pages/UserManagement/CreateRole";
import { companyprops } from "../../store/Company/BasicDetails/actions";
import { getTenantId } from "../../helpers/Consts";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorElUser: false,
      anchorElNav: false,
      buttonbranch: this.props.buttonbranch,
      userDit: JSON.parse(localStorage.getItem("authUser")),
    };

    this.handleOpenUserMenu = this.handleOpenUserMenu.bind(this);
    this.handleCloseUserMenu = this.handleCloseUserMenu.bind(this);
    this.handleCloseNavMenu = this.handleCloseNavMenu.bind(this);

    // console.log("Header", this.state);
  }
  handleCreateBranch = () => {
    this.setState({
      buttonbranch: 0,
    });
    this.props.onNameChange("Branch Creation", <BranchCreation />, 1);
  };
  handleCreateRole = () => {
    // alert("sss");
    this.setState({
      buttonbranch: 0,
    });

    this.props.onNameChange(
      "Role Creation",
      <CreateRole rolemanagetype={1} />,
      0
    );
    // <CreateRole />
  };
  handleCreateUser = () => {
    this.setState({
      buttonbranch: 0,
    });

    this.props.onNameChange("User Creation", <UserCreate />, 1);
  };

  handleBillingDetials = () => {
    this.setState({
      buttonbranch: 0,
    });

    this.props.onNameChange("User Creation", <Billing />, 0);
  };

  handleOpenUserMenu(event) {
    this.setState({
      anchorElUser: event.currentTarget,
    });

    // setAnchorElUser(event.currentTarget);
  }

  handlecreateCompany = () => {
    this.props.companyprops(null);
  };

  handleCloseNavMenu = () => {
    // setAnchorElNav(null);
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleCloseUserMenu = () => {
    // setAnchorElUser(null);
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleClick = (value) => () => {
    if (value == 1) {
      this.props.history.push("/CreatePassword");
    } else if (value == 2) {
      window.open(`https://${getTenantId(window.location.hostname)}/`);
    }
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleLogout = () => {
    this.props.history.push("/Logout");
  };

  render() {
    var data = [
      {
        menuId: 1,
        menuName: "Change Password",
        link: "ResetPassword",
      },
      {
        menuId: 2,
        menuName: "Login Portal",
        link: "PortalLogin",
      },
    ];
    // let userDit = JSON.parse(localStorage.getItem("authUser"));
    // function onChange(newName) {   this.setState({ parentName: newName });}
    return (
      <AppBar position="static" className="consoleHeaderContainer">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid container xs={12}>
              <Grid item xs={4}>
                <h3 className="consoleHeadingText"> {this.props.childName}</h3>
              </Grid>
            </Grid>
            <Grid
              item
              xs={8}
              className="headerRightBlock"
              style={{ marginRight: "10px" }}
            >
              <Box sx={{ flexGrow: 0 }}>
                {this.props.buttonbranch === 0 ? (
                  <Link to="/Billing">
                    <Button label="Billing Details" className="billingButton" />
                  </Link>
                ) : (
                  ""
                )}

                {this.props.buttonbranch === 1 ? (
                  <Link to="/createbranch">
                    <Button label=" + Create Branch" className="greenButton" />
                  </Link>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 3 ? (
                  <div class="headerButtonBlock">
                    <Link to={{ pathname: "/create_role", rolemanagetype: 1 }}>
                      {/* <Link to={{pathname:'/create_role', rolemanagetype: 1}} > */}
                      {/* <Link to="/create_role"> */}
                      <Button
                        label=" + Create Role"
                        className="greenButton"
                        handleClick={this.handleCreateRole}
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 2 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_user">
                      <Button
                        label="+ Create User"
                        className="greenButton"
                        handleClick={this.handleCreateUser}
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 4 ? (
                  <>
                    <div class="headerButtonBlock">
                      <Link
                        to="/basicdetails"
                        onClick={this.handlecreateCompany}
                      >
                        <Button
                          label=" + Create Company"
                          className="greenButton"
                          onClick={this.handlecreateCompany}
                        />
                      </Link>
                      <Link to="/Billing">
                        <Button
                          label="Billing Details"
                          className="billingButton"
                        />
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}

                {this.props.buttonbranch === 5 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_leave_types">
                      <Button
                        label=" + Create Leave Type"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 6 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_department_master">
                      <Button
                        label=" + Create Department Master"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 7 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_payScheme_creation">
                      <Button
                        label=" + Create Pay Scheme"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 8 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_leave_policy">
                      <Button
                        label=" + Create Leave Policy"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {this.props.buttonbranch === 9 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_payscheme_parameters">
                      <Button
                        label=" + Create Pay Scheme Parameters"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}

{/* As per the requirement create tax master is not needed anymore */}
                {/* {this.props.buttonbranch === 10 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_tax_master">
                      <Button
                        label=" + Create Tax Master"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )} */}

                {this.props.buttonbranch === 11 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_bank_account_master">
                      <Button
                        label=" + Create Bank Account Master"
                        className="greenButton"
                      />
                    </Link>
                    <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link>
                  </div>
                ) : (
                  ""
                )}

{this.props.buttonbranch === 12 ? (
                  <div class="headerButtonBlock">
                    <Link to="/create_supplier_types">
                      <Button
                        label=" + Create Supplier Types"
                        className="greenButton"
                      />
                    </Link>
                    {/* <Link to="/Billing">
                      <Button
                        label="Billing Details"
                        className="billingButton"
                      />
                    </Link> */}
                  </div>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
            <Grid item xs={1} className="headerRightBlock">
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Profile Settings">
                  <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="" />
                    <img
                      src={ProfileArrowIcon}
                      alt=""
                      className="profileArrowIcon"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={this.state.anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(this.state.anchorElUser)}
                  onClose={this.handleCloseUserMenu}
                >
                  <div className="profileContainer">
                    <Grid container className="profileHeader">
                      <Grid item xs={2}>
                        <Avatar alt="Remy Sharp" src="" />
                      </Grid>
                      <Grid item xs={10}>
                        <Grid item xs={12}>
                          <h3>
                            {this.state.userDit
                              ? this.state.userDit.userName
                              : ""}
                          </h3>
                        </Grid>
                        <Grid item xs={12}>
                          <h6>
                            {this.state.userDit ? this.state.userDit.email : ""}
                          </h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    {data.map((menu) => (
                      <MenuItem
                        key={menu.menuId}
                        className="profileLinksBlock"
                        onClick={this.handleClick(menu.menuId)}
                      >
                        <Typography textAlign="center" name={menu.menuName}>
                          {menu.menuName}
                        </Typography>
                      </MenuItem>
                    ))}
                    <Grid container className="profileFooter">
                      <Grid item xs={12}>
                        <MenuItem className="profileLinksBlock" onClick={this.handleLogout}>
                          <Typography textAlign="center">
                            <img src={Logout} alt="" />
                            <Link href="/" className="" >
                              Logout
                            </Link>
                          </Typography>
                          
                        </MenuItem>
                      </Grid>
                    </Grid>
                  </div>
                </Menu>
              </Box>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(connect(mapStatetoProps, { companyprops })(Header));
// export default Header;
