import React, { Component } from "react";
import Select from "react-select";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

class DynamicSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedlable: "",
      selectedValue:"",
      selectedName:"",
      selectedItemCode: "",
      
    };
  }

  handleChange = (event) => {

    let selectedValue = event.value;
    let selectedName = event.name;
    let selectedItemCode = event.variant
    
    this.setState({
      selectedValue: selectedValue,
      selectedName: selectedName,
      selectedItemCode: selectedItemCode
    })
    this.props.onSelectChange(selectedValue, selectedName, this.props.name, this.props.row, selectedItemCode);
  };

  handleMenuOpen = () => {
    // if(this.props.handleMenuOpen){
      this.props.handleMenuOpen()
    // }
  }

  componentWillReceiveProps(props) {
    // console.log("arrayOfData",props.arrayOfData);
    if (this.props.arrayOfData) {
      this.props.arrayOfData.forEach((row) => {
        
        if (this.props.selected == row.value) {
          // selectedlable = row.label;   
          // console.log("getting itemCode from dynamic select", row.variant)   
          this.setState({
            selectedlable: row.label,
            selectedValue: this.props.selected,
            selectedName: row.label,
            selectedItemCode: row.variant
          })    
        }
      });
    }
  }

  render() {
    if(this.props.update === 1) {
      if(this.props.arrayOfData){
        this.props.arrayOfData.map((row) => {
          if(this.props.selected === row.value) {
            this.state.selectedValue = this.props.selected
            this.state.selectedlable = row.label
            this.state.selectedName = row.label
            this.state.selectedItemCode =row.variant
          }
        })
      }
    }else {

        if(this.props.arrayOfData){
            this.props.arrayOfData.map((row) => {
              if(this.props.selected === row.value) {
                this.state.selectedValue = this.props.selected
                this.state.selectedlable = row.label
                this.state.selectedName = row.label
                this.state.selectedItemCode =row.variant
              }
            })
          }
          
    }

    
  
    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <div style={{display:"flex"}}>
          <label htmlFor="app-input-field" style={{paddingRight:"10px", marginBottom:"0px"}}>{this.props.label} </label>
          {this.props.required && <div className="mandatoryField">*</div>}
            {this.props.ErrorOutlineIcon &&
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title={this.props.toolTipTitle}
                placement={"top-end"}
                arrow
              >
                <IconButton
                style={{padding:"0px"}}
                >
                  <ErrorOutlineIcon style={{ color: "rgb(144 130 232)", marginRight: "5px" ,width:"15px",height:"20px"}} />
                </IconButton>
              </Tooltip>
            }
            </div>
        {/* {console.log('update props',this.props.update)} */}
        {this.props.update === 1?
        <Select
        classNamePrefix="react-select"        
            options={this.props.arrayOfData}
            menuPosition="fixed"
            menuPlacement="auto"
            defaultValue={this.props.selected ? { value: this.props.selected, label: this.state.selectedlable, variant: this.state.selectedItemCode}: ""}
            value={this.props.selected ? { value: this.state.selectedValue, label: this.state.selectedName, variant: this.state.selectedItemCode}: ""}
            placeholder="Select"
            onChange={this.handleChange}
            isDisabled={this.props.isDisabled}
            closeMenuOnScroll={() => this.props.isDropdownOpen}
            onMenuOpen={this.handleMenuOpen}
      />: <Select
      classNamePrefix="react-select"
        options={this.props.arrayOfData}
        menuPosition="fixed"
        menuPlacement="auto"
        defaultValue={this.props.selected ? { value: this.props.selected, label: this.state.selectedlable, variant: this.state.selectedItemCode}: ""}
        value={this.props.selected ? { value: this.state.selectedValue, label: this.state.selectedName, variant: this.state.selectedItemCode}: ""}
        placeholder="Select"
        onChange={this.handleChange}
        closeMenuOnScroll={() => this.props.isDropdownOpen}
        onMenuOpen={this.handleMenuOpen}
      />}
      </FormControl>
    );
  }
}
export default DynamicSelect;
