import React, {Component} from "react";
import Select from "react-select";
import  FormControl  from "@mui/material/FormControl";

class DynamicSelectBranch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedlabel: ""
        }
    }

    handleChange = (event) => {
        let selectedValue = event.value;
        let strUser = event.name;
        this.props.onSelectChange(selectedValue, strUser, this.props.name);
    }

    render() {
        var selectedlabel = "";
        //console.log("getting data for branch", this.props.selected.label)
        if (this.props.arrayOfData) {
            this.props.arrayOfData.forEach((row) => {
                // console.log("getting data for branch", row.label)
              if (this.props.selected.label === row.label) {
                selectedlabel = row.label;
              }
            });
        }

        return (
            <FormControl sx={{ width: "100%" }} className="customDropdown">
                <div>
                    <label htmlFor="app-input-field">{this.props.lable}</label>
                </div>
                <Select
                    options={this.props.arrayOfData}
                    menuPosition="fixed"
                    menuPlacement="auto"
                    defaultValue={{ value: this.props.selected, label: selectedlabel }}
                    onChange={this.handleChange}
                    classNamePrefix="react-select"
                />

            </FormControl>
        )

    }
}

export default DynamicSelectBranch;
