import * as React from "react";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import VolumeUp from "@mui/icons-material/VolumeUp";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function InputSlider(props) {
  const [value, setValue] = React.useState(props.value);
  const [NoOfCompanies, setNoOfCompanies] = React.useState([]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
    props.onSelectChange(newValue, props.lable);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
    props.onSelectChange(event.target.value, props.lable);
  };
useEffect(()=>{
if(props.value){
  setValue(props.value)
}
},[props.value])
  const handleBlur = () => {
    if (value < 0) {
      setValue(1, props.lable);
      props.onSelectChange(0);
    } else if (value > 250) {
      setValue(250);
      props.onSelectChange(250, props.lable);
    }
  };
  return (
    <Box>
      <div className="rangeBlock">
        <div className="rangeBlockHeader">
          <label id="input-slider">{props.name}</label>
          <Grid item>
            <Input
              value={ value}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: [props.step],
                min: 1,
                max: [props.number],
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Grid>
        </div>
        <Grid container alignItems="center" className="rangeContainer">
          {/* <Grid item>
          <VolumeUp />
        </Grid> */}
          <Grid item xs className="range">
            
            <Slider
              value={value}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
              min={1}
              max={props.number}
              step={props.step}
            />
            <div id="tickmarks">
              {props.number === 10 && (
                <>
                  {Array(props.number)
                    .fill(1)
                    .map((_, i) => (
                      <p>{i + props.step}</p>
                    ))}
                </>
              )}
              {props.number === 250 && (
                <>
                  {Array(11)
                    .fill(1)
                    .map((_, i) => (
                      <p>{(i * props.number) / 10}</p>
                    ))}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
