import {
  DEPARTMENT_LIST,
  DEPARTMENT_LIST_SUCCESSFULL,
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESSFULL,
  EMP_LEVEL,
  EMP_LEVEL_SUCCESSFULL,
  REPORTING_MANAGER_LIST,
  REPORTING_MANAGER_LIST_SUCCESSFULL,
  CONTRACTOR,
  CONTRACTOR_SUCCESSFULL,
  WORK_LOCATION,
  WORK_LOCATION_SUCCESSFULL,
  DESIGNATION,
  DESIGNATION_SUCCESSFULL,
  LEAVE_POLICY_LIST,
  LEAVE_POLICY_LIST_SUCCESSFULL,
  LEAVE_LIST,
  LEAVE_LIST_SUCCESSFULL,
  PAY_SCHEME,
  PAY_SCHEME_SUCCESSFULL,
  // EXPERIENCE_DETAILS,
  // EXPERIENCE_DETAILS_SUCCESSFULL,
  PAY_SCHEME_BY_ID,
  PAY_SCHEME_BY_ID_SUCCESSFULL,
  CHECK_PAY_SCHEME,
  CHECK_PAY_SCHEME_SUCCESSFULL,
  LEAVE_POLICY_MAPPING,
  LEAVE_POLICY_MAPPING_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  ALL_FILES_LIST_SUCCESSFULL,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_SUCCESSFULL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESSFULL,
  DOWNLOAD_ASSET_FORM,
  DOWNLOAD_ASSET_FORM_SUCCESSFULL,
  OFFER_LETTER_EMAIL_SERVICE,
  OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL,
  EXPERIENCE_DETAILS_VIEW_BY_ID,
  EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_,
  GET_PAYSCHEME_LIST,
  GET_PAYSCHEME_LIST_SUCCESSFULL,
  ADD_SALARY,
  ADD_SALARY_SUCCESSFULLY,
  DISPLAY_EMAIL_TEMPLATE,
  DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY,
  PAY_WAGES_MODES,
  PAY_WAGES_MODES_SUCCESSFULL,
  PAY_COMPONENTS,
  PAY_COMPONENTS_SUCCESSFULL,
  ADD_PAY_SCHEME,
  ADD_PAY_SCHEME_SUCCESSFULL,
  UPDATE_PAY_SCHEME,
  UPDATE_PAY_SCHEME_SUCCESSFULL,
  GET_PROFILE_PICTURE,
  GET_PROFILE_PICTURE_SUCCESSFULLY,
  GET_PROFILE_PICTURE_FILE,
  GET_PROFILE_PICTURE_FILE_SUCCESSFULLY,
  GET_PROFILE_PICTURE_FILE_SETNULL,
  HRMS_DASHBOARD,
  HRMS_DASHBOARD_SUCCESSFULLY,
  PAYSCHEME_BY_ID,
  PAYSCHEME_BY_ID_SUCCESSFULL,
  APPROVAL_HIERARCHY,
  APPROVAL_HIERARCHY_SUCCESSFULLY ,
  UPDATE_SALARY,
  UPDATE_SALARY_SUCCESSFULLY,
  VIEW_BY_ID_APPROVAL,
  VIEW_BY_ID_APPROVAL_SUCCESSFULLY,
  UPDATE_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY_SUCCESSFULL,
  WORKER_DETAILS_PROGRESS,
  WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_WORKER_DETAILS_PROGRESS,
  GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_LIST_ROW_DATA,
  DELETE_WORKER_EDUCATIONAL_DETAILS,
  DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY,

  // PAY SCHEME ORDERING ACTION TYPES
  GET_ORDERED_PAYSCHEME_COMPONENT,
  GET_ORDERED_PAYSCHEME_COMPONENT_SUCCESSFULL
  
} from "./actionTypes";

// Experience details View By Id
export const ViewByIdExperienceDetails = (url, history) => {
  return {
    type: EXPERIENCE_DETAILS_VIEW_BY_ID,
    payload: { url, history },
  };
};
export const ViewByIdExperienceDetailsSuccessfull = (data) => {
  return {
    type: EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_,
    payload: data,
  };
};
// Work Details

// Department
export const getDepartmentList = (url, history) => {
  return {
    type: DEPARTMENT_LIST,
    payload: { url, history },
  };
};
export const departmentListSuccessfull = (data) => {
  return {
    type: DEPARTMENT_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Category
export const getCategoryList = (url, history) => {
  return {
    type: CATEGORY_LIST,
    payload: { url, history },
  };
};
export const categoryListSuccessfull = (data) => {
  return {
    type: CATEGORY_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Emp Level
export const getEmpLevel = (url, history) => {
  return {
    type: EMP_LEVEL,
    payload: { url, history },
  };
};
export const empLevelSuccessfull = (data) => {
  return {
    type: EMP_LEVEL_SUCCESSFULL,
    payload: data,
  };
};
// Reporting Manager
export const getReportingManagerList = (url, history) => {
  return {
    type: REPORTING_MANAGER_LIST,
    payload: { url, history },
  };
};
export const ReportingManagerListSuccessfull = (data) => {
  return {
    type: REPORTING_MANAGER_LIST_SUCCESSFULL,
    payload: data,
  };
};
// Contractor
export const getContractor = (url, history) => {
  return {
    type: CONTRACTOR,
    payload: { url, history },
  };
};
export const contractorSuccessfull = (data) => {
  return {
    type: CONTRACTOR_SUCCESSFULL,
    payload: data,
  };
};
// Work Location
export const getWorkLocation = (url, history) => {
  return {
    type: WORK_LOCATION,
    payload: { url, history },
  };
};
export const workLocationSuccessfull = (data) => {
  return {
    type: WORK_LOCATION_SUCCESSFULL,
    payload: data,
  };
};
// Designation
export const getDesignation = (url, history) => {
  return {
    type: DESIGNATION,
    payload: { url, history },
  };
};
export const designationSuccessfull = (data) => {
  return {
    type: DESIGNATION_SUCCESSFULL,
    payload: data,
  };
};

// Leave Policy
export const getLeavePolicyList = (url, history) => {
  return {
    type: LEAVE_POLICY_LIST,
    payload: { url, history },
  };
};
export const leavePolicySuccessfull = (data) => {
  return {
    type: LEAVE_POLICY_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getLeaveList = (url, history) => {
  return {
    type: LEAVE_LIST,
    payload: { url, history },
  };
};
export const leaveListSuccessfull = (data) => {
  return {
    type: LEAVE_LIST_SUCCESSFULL,
    payload: data,
  };
};
export const getPayScheme = (url, data, history) => {
  return {
    type: PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const paySchemeSuccessfull = (data) => {
  return {
    type: PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};
export const getPaySchemeById = (url, value, history) => {
  return {
    type: PAY_SCHEME_BY_ID,
    payload: { url, value, history },
  };
};

export const paySchemeIdSuccessfull = (data) => {
  return {
    type: PAY_SCHEME_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const PaySchemeById = (url, value, history) => {
  return {
    type: PAYSCHEME_BY_ID,
    payload: { url, value, history },
  };
};

export const paySchemeByIdSuccessfull = (data) => {
  return {
    type: PAYSCHEME_BY_ID_SUCCESSFULL,
    payload: data,
  };
};

export const checkPayScheme = (url, data, history) => {
  return {
    type: CHECK_PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const checkPaySchemeSuccessfull = (data) => {
  return {
    type: CHECK_PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};
export const AddPayScheme = (url, data, history) => {
  return {
    type: ADD_PAY_SCHEME,
    payload: { url, data, history },
  };
};
export const AddPaySchemeSuccessfull = (data) => {
  return {
    type: ADD_PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};

export const UpdatePayScheme = (url, data, history) => {
  return {
    type: UPDATE_PAY_SCHEME,
    payload: { url, data, history },
  };
};

export const UpdatePaySchemeSuccessfull = (data) => {
  return {
    type: UPDATE_PAY_SCHEME_SUCCESSFULL,
    payload: data,
  };
};

export const getOrderPayScheme = (url) => {
  return {
    type: GET_ORDERED_PAYSCHEME_COMPONENT,
    payload: { url },
  };
};

export const getOrderPaySchemeSuccessful = (data) => {
  return {
    type: GET_ORDERED_PAYSCHEME_COMPONENT_SUCCESSFULL,
    payload: data,
  };
};

export const PayComponents = (url, history) => {
  return {
    type: PAY_COMPONENTS,
    payload: { url, history },
  };
};
export const payComponentsSuccessfull = (data) => {
  return {
    type: PAY_COMPONENTS_SUCCESSFULL,
    payload: data,
  };
};

export const leavePolicyMapping = (url, data, history) => {
  return {
    type: LEAVE_POLICY_MAPPING,
    payload: { url, data, history },
  };
};
export const leavePolicyMappingSuccessfull = (data) => {
  return {
    type: LEAVE_POLICY_MAPPING_SUCCESSFULL,
    payload: data,
  };
};

export const UpdateleavePolicy = (url, data, history) => {
  return {
    type: UPDATE_LEAVE_POLICY,
    payload: { url, data, history },
  };
};
export const UpdateleavePolicySuccessfull = (data) => {
  return {
    type:UPDATE_LEAVE_POLICY_SUCCESSFULL,
    payload: data,
  };
};
export const getAllFiles = (url, history) => {
  return {
    type: GET_ALL_FILES,
    payload: { url, history },
  };
};
export const AllFilesSuccessfull = (data) => {
  
  return {
    type: GET_ALL_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const FilesRespSuccessfull = (data) => {
  
  return {
    type: ALL_FILES_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const downLoadFiles = (url, history) => {
  return {
    type: DOWNLOAD_FILES,
    payload: { url, history },
  };
};
export const DownLoadFilesSuccessfull = (data) => {
  return {
    type: DOWNLOAD_FILES_SUCCESSFULL,
    payload: data,
  };
};
export const updateFiles = (url, data, history) => {
  return {
    type: UPDATE_FILE,
    payload: { url, data, history },
  };
};
export const updateFilesSuccessfull = (data) => {
  return {
    type: UPDATE_FILE_SUCCESSFULL,
    payload: data,
  };
};

export const downloadAssetForm = (url, history) => {
  return {
    type: DOWNLOAD_ASSET_FORM,
    payload: { url, history },
  };
};
export const downloadAssetFormsuccessfull = (data) => {
  return {
    type: DOWNLOAD_ASSET_FORM_SUCCESSFULL,
    payload: data,
  };
};
export const offerLetterEmail = (url, data, history) => {
  return {
    type: OFFER_LETTER_EMAIL_SERVICE,
    payload: { url, data, history },
  };
};
export const offerLetterEmailSuccessfull = (data) => {
  return {
    type: OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL,
    payload: { data },
  };
};

export const getPayschemeList = (url, data, history) => {
  console.log("Nagesh getting url for checkpayscheme", url);
  return {
    type: GET_PAYSCHEME_LIST,
    payload: { url, data, history },
  };
};

export const getPayschemeListSuccessfull = (data) => {
  return {
    type: GET_PAYSCHEME_LIST_SUCCESSFULL,
    payload: data,
  };
};

export const addSalary = (url, data,obj ,history) => {
  return {
    type: ADD_SALARY,
    payload: { url, data,obj ,history },
  };
};
export const addSalarySuccessfull = (data) => {
  return {
    type: ADD_SALARY_SUCCESSFULLY,
    payload: data,
  };
};
export const updateSalary = (url, data, obj,history) => {
  return {
    type: UPDATE_SALARY,
    payload: { url, data,obj ,history },
  };
};
export const updateSalarySuccessfull = (data) => {
  return {
    type: UPDATE_SALARY_SUCCESSFULLY,
    payload: data,
  };
};
export const displayEmailTemplate = (url, data, history) => {
  return {
    type: DISPLAY_EMAIL_TEMPLATE,
    payload: { url, data, history },
  };
};
export const displayEmailTemplateSuccessfull = (data) => {
  return {
    type: DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY,
    payload: data,
  };
};

export const payWagesModes = (url, history) => {
  return {
    type: PAY_WAGES_MODES,
    payload: { url, history },
  };
};
export const payWagesModesSuccessfull = (data) => {
  return {
    type: PAY_WAGES_MODES_SUCCESSFULL,
    payload: data,
  };
};
export const getProfilePicFile = (url, history) => {
  return {
    type: GET_PROFILE_PICTURE_FILE,
    payload: { url, history },
  };
};
export const getProfilePicFileSetNull = (data) => {
  return {
    type: GET_PROFILE_PICTURE_FILE_SETNULL,
    payload: { data },
  };
};
export const getProfilePicFileSuccessfull = (data) => {
  console.log("GET_PROFILE_PICTURE_FILE",data)
  return {
    type: GET_PROFILE_PICTURE_FILE_SUCCESSFULLY,
    payload: data,
  };
};

export const getProfilePic = (url,type,callBack, history) => {
  console.log('profile',url,type,callBack, history)
  return {
    type: GET_PROFILE_PICTURE,
    payload: { url, history },
  };
};
export const getProfilePicSuccessfull = (data) => {
  return {
    type: GET_PROFILE_PICTURE_SUCCESSFULLY,
    payload: data,
  };
};

// Dashboard
export const hrmsDashboard = (url, data, history) => {
  return {
    type: HRMS_DASHBOARD,
    payload: { url, data, history },
  };
};
export const hrmsDashboardSuccessfull = (data) => {
  return {
    type: HRMS_DASHBOARD_SUCCESSFULLY,
    payload: data,
  };
};

// approval hierarchy
export const ApprovalHierarchy = (url, history) => {
  return {
    type: APPROVAL_HIERARCHY,
    payload: { url, history },
  };
};
export const ApprovalHierarchySuccessfull = (data) => {
  return {
    type: APPROVAL_HIERARCHY_SUCCESSFULLY ,
    payload: data,
  };
};

export const ViewByIdApproval = (url, history) => {
  return {
    type: VIEW_BY_ID_APPROVAL,
    payload: { url, history },
  };
};
export const ViewByIdApprovalSuccessfull = (data) => {
  return {
    type: VIEW_BY_ID_APPROVAL_SUCCESSFULLY ,
    payload: data,
  };
};

export const WorkerDetailsProgress = (url, data,history) => {
  return {
    type: WORKER_DETAILS_PROGRESS,
    payload: { url,data, history },
  };
};
export const WorkerDetailsProgressSuccessfull = (data) => {
  return {
    type: WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
    payload: data,
  };
};

export const GetWorkerDetailsProgress = (url,history) => {
  return {
    type: GET_WORKER_DETAILS_PROGRESS,
    payload: { url, history },
  };
};
export const GetWorkerDetailsProgressSuccessfull = (data) => {
  return {
    type: GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
    payload: data,
  };
};

export const getListRowData = (data) => {
  return {
    type: GET_LIST_ROW_DATA,
    payload: data,
  };
};

export const deleteEducationalDetails = (url,history) => {
  return {
    type: DELETE_WORKER_EDUCATIONAL_DETAILS,
    payload: { url, history },
  }
};

export const deleteEducationalDetailsSuccessfull = (data) => {
  return {
    type:DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY,
    payload: data,
  };
};