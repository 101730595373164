import { combineReducers  } from 'redux';

import Layout from './layout/reducer';

// Authentication Module
// import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import BasicDetails from './Company/BasicDetails/reducer';
import OffcialDetails from './Company/OfficialDetails/reducer';
import BranchCreation from './Company/BranchCreation/reducer';
import Form from './Form/reducer';
import Configuration from './Configuration/reducer';
import UserManagement from './UserManagement/reducer';
import ModuleSelection from './ModuleSelection/reducer';
import LeaveTypeListReducer from "./Master/LeaveTypeMaster/reducers";
import DepartmentReducer from "./Master/DepartmentMaster/reducer";
import PaySchemeReducer from "./Master/PaySchemecreation/reducers";
import profilePicFile from "./Master/PaySchemecreation/reducers"
import LeavePolicyReducer from './Master/LeavePolicy/reducers';
import PayschemeParaReducer from "./Master/PayschemeParameters/reducer";
import UploadFileReducer from './UploadFile/reducer';
import TaxMasterReducer from './Master/TaxMaster/reducer';
import BankAccountReducer from './Master/BankAccountMaster/reducers';
import SupplierTypesReducer from './Master/SupplierTypeMaster/reducers';
const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    // Account,
    Login,
    BasicDetails,
    OffcialDetails,
    BranchCreation,
    Form,
    Configuration,
    UserManagement,
    ModuleSelection,
    LeaveTypeListReducer,
    DepartmentReducer,
    PaySchemeReducer,
    LeavePolicyReducer,
    PayschemeParaReducer,
    UploadFileReducer,
    profilePicFile,
    TaxMasterReducer,
    BankAccountReducer,
    SupplierTypesReducer,
});

export default rootReducer;