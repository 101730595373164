import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";
import TextFieldReq from "../../components/TextField/TextFieldReq";
import TextFieldEmailReq from "../../components/TextField/TextFieldEmailReq";
import TextFieldAlfaNumReq from "../../components/TextField/TextFieldAlfaNumReq";
import DynamicSelect from "../../components/Dropdown/DynamicSelect";
import DynamicSelectBranch from "../../components/Dropdown/DynamicSelectBranch";
import RadioButton from "../../components/RadioButton/RadioButton";
import Checkbox from "../../components/CheckBox/Checkbox";
import Alerts from "../../components/notificationAlerts/Alerts"
import cons, { serverApi, configNames } from "../../helpers/Consts";
import {
  getbranchlist,
} from "../../store/UserManagement/actions";
import { setConfiguration } from "../../store/Configuration/actions";
import {Link} from "react-router-dom"

class Configur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",  
      hostname:"",
      emailname: "",
      emailaddress: "",
      password: "",
      whatsappno: "",
      selectedWhatsappValue: "",
      notmsg: "",
      severity: "error",
      alert:'none',
      emailAlert: "",
      emailAlertval:0,
      emailAlertCheck:false,
      autosync:"N",
      syncyes:false,
      syncno:false,
      whatsappyes:true,
      whatsappno:false,
      whatsappnumber:"",
      serverurl:"",
      portno:"",
      selectedCompanyId:0,
      companyId:localStorage.getItem("companyIdconfig"),
      configdata: [],
      branchName:"",
      branchId:"",
      branchIndex:0,
    };

  }


  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
  };
  handleSelectChange = (selectedValue, selectedId) => {
    
    this.setState({
      branchId: selectedValue,
      branchName: selectedId
    });
  }  

  
onClickRadioButton = (selectedValue) =>{
  var autosync = "N";
  
    var syncyes=false, syncno=false;
    if(selectedValue==='Yes'){
      autosync = "Y";
      syncyes=true;      
    }else if(selectedValue==='No'){
      autosync = "N";
      syncno=true;     
    }

    this.setState({
      selectedValue: selectedValue,
      autosync: autosync,
      syncyes:syncyes,
      syncno:syncno,
    })
  
}

onClickRadioWhatsappButton = (selectedWhatsappValue) =>{
    var whatsappyes=false, whatsappno=false, whatsno=2;
    if(selectedWhatsappValue==='Yes'){      
      whatsappyes=true;   
      whatsno=1;
    }else if(selectedWhatsappValue==='No'){
      whatsappno=true; 
      whatsno=2;    
    }
    this.setState({
      selectedWhatsappValue: selectedWhatsappValue,
      whatsappno: whatsappno,
      whatsappyes:whatsappyes,
      whatsno:whatsno,
    })
  
}
onClickEamilAlert = (name, value) =>{
  
  this.setState({
    emailAlert: value,
    emailAlertval: value ? 1 : 0,
  })
}

getAutoIdFromList=(configParameter)=>{
  var list=this.state.configdata;
  var id=undefined;
  if(list.length>0){
      list.forEach(prop=>{
          if(prop.configParameter===configParameter){
              id= prop.autoId;

          }
      })
  }
  return id;
};


handleConfig=() => {
  if(!this.state.syncyes && !this.state.syncno){
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry! Please Select TallySync",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }else if(!this.state.companyId){
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry! Please Select Company",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }else if(!this.state.selectedWhatsappValue){
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry! Please Select Whatsaap Configuration  ",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }else{
    var emailconfig = {hostname:this.state.hostname, emailname: this.state.emailname,emailaddress: this.state.emailaddress, password: this.state.password};
    var tallyautosync = {tallyautosync: this.state.autosync, branch: this.state.branchId, serverurl: this.state.serverurl, portno: this.state.portno};
    var whatsapp = {whatsappno: this.state.whatsappnumber, whatsappconfig: this.state.whatsno};
   
    var data ={
      "companyId": this.state.companyId,
      "configList": [
          {
              "autoId": this.getAutoIdFromList(configNames.EMAIL_ALERTS),
              "configParameter": configNames.EMAIL_ALERTS,
              "isActive": 1,
              "configValue":this.state.emailAlertval,
          },    
          {
            "autoId": this.getAutoIdFromList(configNames.EMAIL_CONFIGURATION),
            "configParameter": configNames.EMAIL_CONFIGURATION,
            "isActive": 1,
            "configValue":JSON.stringify(emailconfig),
          },   
          {
            "autoId": this.getAutoIdFromList(configNames.TALLY_SYNC),
            "configParameter": configNames.TALLY_SYNC,
            "isActive": 1,
            "configValue": JSON.stringify(tallyautosync),
          },  
          {
            "autoId": this.getAutoIdFromList(configNames.WHATSAPP_CONFIG),
            "configParameter": configNames.WHATSAPP_CONFIG,
            "isActive": 1,
            "configValue": JSON.stringify(whatsapp),
          },  
      ]
    };
  
  if(this.state.companyId){
    var msg = "Configur Updated Successfully.";
      
      this.props.setConfiguration(serverApi.UPDATE_CONFIGURATION_NEW, data, msg, this.props.history)
  } else {
    this.setState({
      alert:'block',
      severity: 'error',
      notmsg: "Sorrry! Please Select Company",
    });
    setTimeout(() => {
      this.setState({ alert: 'none' });
    }, 3000);
  }
  }


}



// componentWillMount=()=> {
  
  
  
// }

componentDidMount() {
  if(localStorage.getItem("companyIdconfig"))
    var data = { companyId: localStorage.getItem("companyIdconfig"), cipher: "fb815aa1774a7063ccb7c532866d306c" };
      this.props.getbranchlist(
        serverApi.BRANCHMASTER_DATATABLE,
        data,
        this.props.history
      );
  
  //var configdata = JSON.parse(localStorage.getItem("configdata"));
  var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
  var emailAlertval = 0;
  var whatsappnumber="";
  var autosync = 'N';
  var whatsappno=false;
  var emailAlertCheck=false, syncyes=false, syncno=false,  whatsappyes=false, whatsno=2;   
  var hostname="",emailname="",emailaddress="",password=""; 
  var branchId="", serverurl="",portno="";
  if(configdata){
    configdata.forEach(prop => {
        if(prop.configParameter===configNames.EMAIL_ALERTS){
          if(prop.configValue==='1'){
            emailAlertval=1;
            emailAlertCheck=true;
          }
          //this.state.emailAlertCheck = true
        }
        
        if(prop.configParameter===configNames.EMAIL_CONFIGURATION){
          var emailconfigdata = JSON.parse(prop.configValue);
          if(emailconfigdata){
            hostname = emailconfigdata.hostname;
            emailname = emailconfigdata.emailname;
            emailaddress = emailconfigdata.emailaddress;
            password = emailconfigdata.password;   
          }
        }
        if(prop.configParameter===configNames.TALLY_SYNC){
          var tallysunc = JSON.parse(prop.configValue);
          
          if(tallysunc.tallyautosync==='Y'){
            autosync = 'Y';
            syncyes=true;      
          }else if(tallysunc.tallyautosync==='N'){
            autosync = 'N';
            syncno=true;     
          }   
          serverurl = tallysunc.serverurl;
          portno = tallysunc.portno;
          branchId = tallysunc.branch;
          
        }

        if(prop.configParameter===configNames.WHATSAPP_CONFIG){
          var whatsappconfigdata = JSON.parse(prop.configValue);
          
          if(whatsappconfigdata){
            whatsappnumber = whatsappconfigdata.whatsappno;
            if(whatsappconfigdata.whatsappconfig==1){
              whatsappyes=true;
              whatsno=1;
            }
            if(whatsappconfigdata.whatsappconfig==2){
              whatsappno=true;
              whatsno=2;
            }
            
          }
          
        }
    }) 
     
   
    this.setState({
      emailAlertval:emailAlertval,
      emailAlertCheck:emailAlertCheck,
      hostname: hostname,
      emailname: emailname,
      emailaddress: emailaddress,
      password: password,
      autosync: autosync,
      syncyes:syncyes,
      syncno:syncno,
      whatsappno: whatsappno,
      whatsappyes: whatsappyes,
      whatsno: whatsno,
      whatsappnumber: whatsappnumber,
      serverurl: serverurl,
      portno:portno,
      branchId: branchId,
      companyId:localStorage.getItem("companyIdconfig"),
      configdata: configdata,
    })   
  }

}


componentWillReceiveProps = (props) => {
  if(localStorage.getItem("companyIdconfig"))
    var data = { companyId: localStorage.getItem("companyIdconfig"), cipher: "fb815aa1774a7063ccb7c532866d306c" };
      // this.props.getbranchlist(
      //   serverApi.BRANCHMASTER_DATATABLE,
      //   data,
      //   this.props.history
      // );
  var configdata = props.configdata
  var emailAlertval = 0;
  var whatsappnumber="";
  var autosync = 'N';
  var whatsappno=false;
  var emailAlertCheck=false, syncyes=false, syncno=false,  whatsappyes=false, whatsno=2;   
  var hostname="",emailname="",emailaddress="",password=""; 
  var branchId="", serverurl="",portno="";
  if(configdata){
    configdata.forEach(prop => {
        if(prop.configParameter===configNames.EMAIL_ALERTS){
          if(prop.configValue==='1'){
            emailAlertval=1;
            emailAlertCheck=true;
          }
          //this.state.emailAlertCheck = true
        }
        
        if(prop.configParameter===configNames.EMAIL_CONFIGURATION){
          var emailconfigdata = JSON.parse(prop.configValue);
          if(emailconfigdata){
            hostname = emailconfigdata.hostname;
            emailname = emailconfigdata.emailname;
            emailaddress = emailconfigdata.emailaddress;
            password = emailconfigdata.password;   
          }
        }
        if(prop.configParameter===configNames.TALLY_SYNC){
          var tallysunc = JSON.parse(prop.configValue);
          
          if(tallysunc.tallyautosync==='Y'){
            autosync = 'Y';
            syncyes=true;      
          }else if(tallysunc.tallyautosync==='N'){
            autosync = 'N';
            syncno=true;     
          }   
          serverurl = tallysunc.serverurl;
          portno = tallysunc.portno;
          branchId = tallysunc.branch;
          
        }

        if(prop.configParameter===configNames.WHATSAPP_CONFIG){
          var whatsappconfigdata = JSON.parse(prop.configValue);
          
          if(whatsappconfigdata){
            whatsappnumber = whatsappconfigdata.whatsappno;
            if(whatsappconfigdata.whatsappconfig==1){
              whatsappyes=true;
              whatsno=1;
            }
            if(whatsappconfigdata.whatsappconfig==2){
              whatsappno=true;
              whatsno=2;
            }
            
          }
          
        }
    }) 
     
   
    this.setState({
      emailAlertval:emailAlertval,
      emailAlertCheck:emailAlertCheck,
      hostname: hostname,
      emailname: emailname,
      emailaddress: emailaddress,
      password: password,
      autosync: autosync,
      syncyes:syncyes,
      syncno:syncno,
      whatsappno: whatsappno,
      whatsappyes: whatsappyes,
      whatsno: whatsno,
      whatsappnumber: whatsappnumber,
      serverurl: serverurl,
      portno:portno,
      branchId: branchId,
      companyId:localStorage.getItem("companyIdconfig"),
      configdata: configdata,
    })   
  }
}





  render() {
    const { hostname, emailname, emailaddress, password, whatsappno, serverurl, portno, whatsappnumber } = this.state;
    const branchList = [];
    var branchInx =0;
    if (this.props.branchList) {
      this.props.branchList.map((prop, indx) => {
        var obj = {
          title: prop.brname,
          value: prop.id,
          label: prop.brname,
        };
        branchList.push(obj);
        if(this.state.branchId){
          if(this.state.branchId==prop.id){
            branchInx = indx;
          }
        }
      });
    }
   
    return (
      <>
        <Alerts alert = {this.state.alert} severity={this.state.severity} notmsg={this.state.notmsg}/>
          <div className="consoleFormContainer">
            <div className="consoleFormContent">
              <h6>Email Configuration</h6>
              <div className="consoleFormBlock1">
                <Grid item xs={12}>
                  <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <TextFieldReq
                            value={hostname}
                            type="text"
                            label="Host Name"
                            caps="OFF"
                            placeholder="Enter Host Name..."
                            onChange={this.handleChange("hostname")}
                          />
                        </div>
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <TextFieldAlfaNumReq
                            value={emailname}
                            label="Email Name"
                            placeholder="Enter text here..."
                            onChange={this.handleChange("emailname")}
                          />
                        </div>
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <TextFieldEmailReq
                            value={emailaddress}
                            type="text"
                            label="Email Address"
                            caps="OFF"
                            placeholder="Enter text here..."
                            onChange={this.handleChange("emailaddress")}
                          />
                        </div>
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <div>
                          <TextFieldReq
                            value={password}
                            type="password"
                            label="Password"
                            placeholder="Enter text here..."
                            onChange={this.handleChange("password")}
                          />
                        </div>
                      </div>
                    </Box>
                    <Box gridColumn="span 12">
                      <div className="consoleTextFieldBlock">
                        <Checkbox label="Do you want to receive Email Alerts?" name="emailAlert" selected={this.state.emailAlertCheck} onChange={this.onClickEamilAlert}></Checkbox>
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </div>
            </div>
          </div>
          <div className="consoleFormContainer">
            <div className="consoleFormContent">
              <h6>Tally Auto Sync </h6>
              <div className="consoleFormBlock1">
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                      <Grid item xs={6}>
                        <div className="">
                          <RadioButton labelText="Yes" value="yes"  groupname="tallySync" checkednew={this.state.syncyes}  onChange={this.onClickRadioButton}/>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="">
                          <RadioButton labelText="No" value="no"  groupname="tallySync" checkednew={this.state.syncno}  onChange={this.onClickRadioButton}/>
                        </div>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                    sx={{ mt: 1 }}
                  >
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectBranch
                          lable="Branch"
                          star=""
                          arrayOfData={branchList}
                          selected={branchList[branchInx]}
                          onSelectChange={this.handleSelectChange}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <TextFieldReq
                          value={serverurl}
                          type="text"
                          label="Server URL"
                          caps="OFF"
                          placeholder="Enter text here..."
                          onChange={this.handleChange("serverurl")}
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 4">
                      <div className="consoleTextFieldBlock">
                        <TextFieldReq
                          value={portno}
                          type="text"
                          label="Port No."
                          caps="OFF"
                          placeholder="Enter text here..."
                          onChange={this.handleChange("portno")}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </div>
            </div>
          </div>
          <div className="consoleFormContainer">
            <div className="consoleFormContent">
              <h6>Whatsaap Configuration</h6>
              <div className="consoleFormBlock1">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                      <Grid item xs={6}>
                        <div className="">
                          <RadioButton labelText="Yes" value="yes" groupname="whatsapp" checkednew={this.state.whatsappyes}  onChange={this.onClickRadioWhatsappButton}/>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="">
                          <RadioButton labelText="No" value="no" groupname="whatsapp" checkednew={this.state.whatsappno} onChange={this.onClickRadioWhatsappButton}/>
                        </div>
                      </Grid>
                    </Box>
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} sx={{mt:1}}>
                      <Grid item xs={12}>
                        <div className="consoleTextFieldBlock">
                          <TextFieldAlfaNumReq
                          
                            value={whatsappnumber}
                            type="text"
                            label="Whatsapp number"
                            placeholder="91+"
                            onChange={this.handleChange("whatsappnumber")}
                          />
                        </div>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>


          <div className="consoleFormButtonBlock">
            <Link to="/invoice">
              <Button variant="contained" className="cancelButton">
                Cancel
              </Button>
            </Link>
            <Button variant="contained" type="button" className="saveButton" onClick={this.handleConfig}>
              Save
            </Button>
          </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { branchList } = state.UserManagement;
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return { branchList, companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
  getbranchlist, setConfiguration
})(Configur);
