import React, { Component } from 'react';

class TextFieldNormal extends Component {

    handleChange = (event) => {
       const { value } = event.target
       
       if(this.props.caps){
        this.props.onChange(value.toUpperCase())
       }else{
        this.props.onChange(value)
       }
       
    }
    render() {
        return (
            <div className="TextFieldBlock">
                {this.props.label && (
                    <label htmlFor="app-input-field">{this.props.label} <label className="starcolor">{this.props.required&&"*"}</label></label>
                )}
                <input
                    disabled={this.props.disabled}
                    readOnly={this.props.readOnly}
                    type={this.props.type}
                    value={this.props.value}
                    className='TextField'
                    // placeholder={this.props.placeholder}
                    placeholder="Enter here"
                    onChange={this.handleChange}
                    InputProps={this.props.InputProps}
                />

            </div>
        )
    }
}
export default TextFieldNormal