import { CHECK_LOGIN, CHECK_TOKEN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS, RESET_PASSWORD, CHECK_TOKEN_SUCCESSFUL, FIRST_COMPANY, FIRST_COMPANY_SUCCESSFULL } from './actionTypes';

export const checkLogin = (url, user, history) => {
    return {
        type: CHECK_LOGIN,
        payload: { url, user, history }
    }
}

export const loginUserSuccessful = (user) => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
}

export const checkTokenUserSuccessful = (user) => {
    return {
        type: CHECK_TOKEN_SUCCESSFUL,
        payload: user
    }
}


export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const checkToken = (url, user, history) => {
    return {
        type: CHECK_TOKEN,
        payload: { url, user, history }
    }

}

export const logoutUser = (history) => {
    return {
        type: LOGOUT_USER,
        payload: { history }
    }
}

export const logoutUserSuccess = () => {
    return {
        type: LOGOUT_USER_SUCCESS,
        payload: {}
    }
}

export const resetpassword = (url, user, history) => {
    return {
        type: RESET_PASSWORD,
        payload: { url, user, history }
    }
}

export const getFistCompanys = (url, data, history) => {
    return {
        type: FIRST_COMPANY,
        payload: { url, data, history }
    };
};

export const firstcompanyListSuccessful = (companys) => {
    return {
        type: FIRST_COMPANY_SUCCESSFULL,
        payload: companys,
    };
};
