export const BRANCH_API_ERRORS = 'branchdetails';
export const BRANCHDETAILS_ADD = 'branch_add';
export const BRANCHDETAILS_ADD_SUCCESSFUL = 'branch_add_successfull';

export const CLEAR_FORM_BRANCH = 'branch_clear_from';
export const GET_STATES_LIST = 'get_states_list';
export const STATES_LIST_SUCCESS = 'states_list_success';


export const GST_VERIFICATION = 'gst_verification';
export const GST_VERIFICATION_SUCCESSFULL = 'gst_verirfication_successfull';
 