import React, { Component } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

class DynamicSelect extends Component {
  constructor(props) {
    super(props);
  }

  //On the change event for the select box pass the selected value back to the parent
  handleChange = (event) => {
    let selectedValue = event.target.value;
    let selectedId = event.target.name;
    let strUser = event.target.options[event.target.selectedIndex].text;
    this.props.onSelectChange(selectedValue, strUser);
  };

  render() {
    let arrayOfData = this.props.arrayOfData;
    let options = arrayOfData.map((data) => (
      <option key={data.id} value={data.id} className="test">
        {data.name}
      </option>
    ));

    return (
      <FormControl sx={{ width: "100%" }} className="customDropdown">
        <select
          name="customSearch"
          className=""
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          onChange={this.handleChange}
        >
          <option className="test">Select Item</option>
          {options}
        </select>
      </FormControl>
    );
  }
}

export default DynamicSelect;
