import {DELETEFILE, DELETEFILE_SUCCESSFULL, UPLOADFILE,UPLOADFILE_SUCCESSFULL} from './actionTypes';



export const postUploadFileList = (url,data,history) =>{
    return {
        type:UPLOADFILE,
        payload:{url,data,history}
    }
}

export const UploadFileListSuccessfull = (data)=>{
    return {
        type:UPLOADFILE_SUCCESSFULL,
        payload:data
    }
}

export const deleteFile = (url,history,pageName) =>{
    return {
        type:DELETEFILE,
        payload:{url,history,pageName}
    }
}

export const deleteFileSuccessfull = (data)=>{
    return {
        type:DELETEFILE_SUCCESSFULL,
        payload:data
    }
}




