import { CREATE_TAX_MASTER, TAX_MASTER_LIST_API } from './actionTypes';
import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import { getList, postAdd } from '../../../helpers/Server_Helper';
import {taxMasterListSuccessfull, createTaxMasterSuccessfull} from "../TaxMaster/actions"
import swal from "sweetalert";
import { hideSpinner, showSpinner } from '../../../helpers/Consts';

function* TaxMasterListSaga({ payload: { url, data, history } }) {
    try {
        const response = yield call(getList, url, data);
        // response.data.sort((a, b) => (a.suppId < b.suppId) ? 1 : -1)
        yield put(taxMasterListSuccessfull({ response }))
    } catch (error) {
        console.log(error)
    }
}

function* CreateTaxMasterSaga({ payload: { url, data, history } }) {
    try {
        showSpinner()
        const response = yield call(getList, url, data);
        yield put(createTaxMasterSuccessfull({ response }))
        console.log("getting the response here", response)
        if(response.status === true){
            swal(response.message, {icon:"success"});
            history.push("/tax_master")
            hideSpinner(true);
        }else{
            swal(response.message,{icon:"error"})
        }
    } catch (error) {
        console.log(error)
    }
}
// function* CreateDepartment({ payload: { url, data, history } }) {
//     try {
//         const response = yield call(postAdd, url, data);
//         yield put(createDepartmentSuccessfull({ response }));
//         if (response.data.status === true) {
//             swal(response.data.message, { icon: "success" })
//             history.push("/department_master")
//         } else {
//             swal(response.data.message, { icon: "error" })
//         }
//     } catch (error) {
//         swal("error:" + error, { icon: "danger" });
//         console.log(error)
//     }
// }
// function* UpdateDepartment({ payload: { url, data, history } }) {
//     try {
//         const response = yield call(postAdd, url, data);
//         yield put(updateDepartmentSuccessfull({ response }))
//         if (response.data.status === true) {
//             swal(response.data.message, { icon: "success" })
//             history.push("/department_master")
//         } else {
//             swal(response.data.message, { icon: "error" })
//         }
//     } catch (error) {
//         console.log(error)
//         swal("error:" + error, { icon: "danger" });
//     }
// }

export function* watchtaxmasterList() {
    yield takeEvery(TAX_MASTER_LIST_API, TaxMasterListSaga)
}

export function* watchCreateTaxMaster() {
    yield takeEvery(CREATE_TAX_MASTER, CreateTaxMasterSaga)
}

// export function* watchCreateDepartmentMaster() {
//     yield takeEvery(CREATE_DEPARTMENT, CreateDepartment)
// }
// export function* watchUpdateDepartmentMaster() {
//     yield takeEvery(UPDATE_DEPARTMENT, UpdateDepartment)
// }

function* TaxMasterSaga() {
    yield all([
        fork(watchtaxmasterList),
        fork(watchCreateTaxMaster)
    ])
}
export default TaxMasterSaga;