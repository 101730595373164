import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
import { getFileDownload } from "../../helpers/Server_Helper";
import { serverVars } from "../../helpers/Consts";

import {
  branchCreationAdd,
  getMasterStates,
  getGstVerification,
  gstVerificationSuccessful,
} from "../../store/Company/BranchCreation/actions";
import cons, { serverApi } from "../../helpers/Consts";
import DynamicSelect from "../../components/Dropdown/DynamicSelect";
import DynamicSelectSimple from "../../components/Dropdown/DynamicSelectSimple";
//import Link from "@mui/material/Link";
import TextFieldReq from "../../components/TextField/TextFieldReq";
import TextFieldMobileReq from "../../components/TextField/TextFieldMobileReq";
import TextFieldEmailReq from "../../components/TextField/TextFieldEmailReq";
import TextFieldGstVerify from "../../components/TextField/TextFieldGstVerify";
import { withRouter, Link } from "react-router-dom";
import swal from "sweetalert";
import panUploadIcon from "../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../assets/images/picEditIcon.png";
import TickIcon from "../../assets/images/fileUploadedTick.png";
import { postUploadFileList, deleteFile } from "../../store/UploadFile/actions";
import { getUploadedFilesList } from "../../store/Company/OfficialDetails/actions";

class BranchCreation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      selectedValue: "Nothing selected",
      selectedId: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      branchname: "",
      address: "",
      addressType: "",
      statecode: "",
      brhead: "",
      contactno: "",
      email: "",
      gstnum: "",
      branchId: 0,
      gstcard_file: null,
      gstcard_fileId: 0,
      ShowUpload: true,
      ShowUploadBlock: false,
      gstUploadedFilesList: [],
      deleteFile: false,
      StateList: [],
      pinCode: "",
      cityName: "",
      gstVerificationStatus: false,
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  onClickVerify = (name) => {
    this.props.getGstVerification(
      serverApi.GST_VERIFICATION_API + this.state.gstnum,
      this.props.history
    );
  };

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "gstnum") {
      if (event !== "") {
        this.setState({
          ShowUploadBlock: true,
          gstnum: event,
          gstVerificationStatus: false,
        });
      } else {
        this.setState({
          ShowUploadBlock: false,
          gstnum: event,
          gstVerificationStatus: false,
        });
      }
    }
    if (key === "branchname") {
      // const bname = event.replace(/[^a-zA-Z\s]+/g, '');
      this.setState({
        branchname: event,
      });
    }

    if (key === "brhead") {
      const branchHead = event.replace(/[^a-zA-Z\s]+/g, "");
      this.setState({
        brhead: branchHead,
      });
    }
    if (key === "city") {
      const City = event.replace(/[^a-zA-Z\s]+/g, "");
      this.setState({
        cityName: City,
      });
    }

    if (key === "pincode") {
      this.setState({
        pinCode: event,
      });
    }
  };


  handleSelectChange = (selectedValue, selectedId) => {
    this.setState({
      selectedValue: selectedValue,
      selectedId: selectedId,
      pinCode: "",
    });

    let stateslist = this.props.stateslist;
    var statecode = 0;
    var gstnum = 0;
    stateslist.map((state, key) => {
      if (state.value == selectedValue) {
        statecode = state.name.split("^")[1];
        gstnum = state.name.split("^")[1];
        this.setState({
          statecode: state.name.split("^")[1],
          gstnum: state.name.split("^")[1],
        });
      }
    });
  };

  onhandleSelectChange = (selectedValue, selectedId) => {
    this.setState({
      addressType: selectedValue,
    });
  };

  handleValidSubmit = (event) => {
    // event.preventDefault();
    var id = this.state.branchId;
    var branchname = this.state.branchname;
    var address = this.state.address;
    var state = this.state.selectedId;
    var statecode = this.state.statecode;
    var brhead = this.state.brhead;

    var brcontact = this.state.contactno;
    var bremail = this.state.email;
    var brgst = this.state.gstnum;
    var compareBoth = brgst.slice(0, 2);
    let cityName = this.state.cityName;
    let pinCode = this.state.pinCode;

    // var brpan = this.state.pancard;
    if (this.state.branchId) {
      var data = {
        branch: {
          id: this.state.branchId,
          brname: branchname ? branchname : "",
          address: address ? address : "",
          addressType: this.state.addressType,
          futureReference: 1,
          isActive: 1,
          isGstAvailable: 0,
          isGstVerified: this.state.gstVerificationStatus ? 1 : 0,
          stateName: state ? state : "",
          stateCode: statecode ? statecode : 0,
          personName: brhead ? brhead : "",
          personAddress: "",
          contactNo: brcontact ? brcontact : "",
          email: bremail ? bremail : "",
          createdBy: this.state.userDit.userId,
          gstNo: brgst ? brgst : "",
          cityTown: cityName ? cityName : "",
          pinCode: pinCode ? pinCode : "",
        },
        postAuthenticationDto: {
          companyId: this.state.companyId,
          cipher: this.state.userDit.cipher,
        },
      };
      if (this.state.branchname === "") {
        swal("Please Enter the Branch Name !!!");
        return false;
      }
      if (this.state.address === "") {
        swal("Please Enter the Address !!!");
        return false;
      }
      if (this.state.statecode === "") {
        swal("Please Enter the State Code !!!");
        return false;
      }
      if (this.state.cityName === "") {
        swal("Please Enter the City Name !!!");
        return false;
      }
      if (this.state.pinCode === "") {
        swal("Please Enter the Pin Code !!!");
        return false;
      }
      if (this.state.brhead === "") {
        swal("Please Enter the Branch Head Name !!!");
        return false;
      }
      if (this.state.contactno === "") {
        swal("Please Enter the Contact Number !!!");
        return false;
      }
      if (this.state.email === "") {
        swal("Please Enter the Email Address  !!!");
        return false;
      }

      if (!data.branch.email) {
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.branch.email)
      ) {
        swal("Invalid email address", { icon: "error" });
        return false;
      }
      // else if(this.state.gstnum !== this.state.statecode){
      //   swal('GST Number Should Be Same As State Code !!!', { icon: "error" });
      //   return false;
      // }

      let updateView = true;
      if (statecode === compareBoth) {
        this.props.branchCreationAdd(
          serverApi.BRANCHMASTER_UPDATE,
          data,
          updateView,
          this.props.history
        );
      } else {
        if (brgst === "" || brgst === undefined || brgst === null) {
          this.props.branchCreationAdd(
            serverApi.BRANCHMASTER_UPDATE,
            data,
            updateView,
            this.props.history
          );
        } else {
          swal(
            "First Two letters of State Code and Gst Number Should be Equal"
          );
        }
      }
    } else {
      var data = {
        branch: {
          brname: branchname ? branchname : "",
          address: address ? address : "",
          stateName: state ? state : "",
          stateCode: statecode ? statecode : 0,
          personName: brhead ? brhead : "",
          personAddress: "",
          contactNo: brcontact ? brcontact : "",
          email: bremail ? bremail : "",
          createdBy: this.state.userDit.userId,
          gstNo: brgst ? brgst : "",
          cityTown: cityName ? cityName : "",
          pinCode: pinCode ? pinCode : "",
          addressType: this.state.addressType,
          futureReference: 1,
          isActive: 1,
          isGstAvailable: 0,
          isGstVerified: this.state.gstVerificationStatus ? 1 : 0,
        },
        postAuthenticationDto: {
          companyId: this.state.companyId,
          cipher: this.state.userDit.cipher,
        },
      };
      if (this.state.branchname === "") {
        swal("Please Enter the Branch Name !!!");
        return false;
      }
      if (this.state.address === "") {
        swal("Please Enter the Address !!!");
        return false;
      }
      if (this.state.statecode === "") {
        swal("Please Enter the State Code !!!");
        return false;
      }
      if (this.state.brhead === "") {
        swal("Please Enter the Branch Head Name !!!");
        return false;
      }
      if (this.state.contactno === "") {
        swal("Please Enter the Contact Number !!!");
        return false;
      }
      if (this.state.email === "") {
        swal("Please Enter the Email Address !!!");
        return false;
      }

      // if (
      //   this.state.branchname !== ""  &&
      //   this.state.address !== "" &&
      //   this.state.statecode === "" &&
      //   this.state.brhead !== "" &&
      //   this.state.contactno !== "" &&
      //   this.state.email !== ""
      // ) {

      if (!data.branch.email) {
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.branch.email)
      ) {
        swal("Invalid email address", { icon: "error" });
        return false;
      }
      // else if(this.state.gstnum !== this.state.statecode){
      //   swal('GST Number Should Be Same As State Code !!!', { icon: "error" });
      //   return false;
      // }
      let updateView = false;
      if (statecode === compareBoth) {
        this.props.branchCreationAdd(
          serverApi.BRANCHMASTER_ADD,
          data,
          updateView,
          this.props.history
        );
      } else {
        if (brgst === "" || brgst === undefined || brgst === null) {
          this.props.branchCreationAdd(
            serverApi.BRANCHMASTER_ADD,
            data,
            updateView,
            this.props.history
          );
        } else {
          swal(
            "First Two letters of State Code and Gst Number Should be Equal"
          );
        }
      }
      // } else {
      //   swal("Please Enter the mandatory field", { icon: 'error' });
      // }
    }
  };

  componentDidMount() {
    this.props.gstVerificationSuccessful(false);
    if (localStorage.getItem("companyupdateId")) {
      this.setState({
        companyId: localStorage.getItem("companyupdateId"),
      });
    }

    if (this.props.location.rowdata) {
      var row = this.props.location.rowdata;
      this.setState({
        branchId: row.id,
        branchname: row.brname,
        address: row.address,
        addressType: row.addressType,
        statecode: row.stateCode,
        brhead: row.personName,
        contactno: row.contactNo,
        email: row.email,
        gstnum: row.gstNo,
        ShowUploadBlock: row.gstNo !== "" && row.gstNo !== null ? true : false,
        pancard: row.brname,
        selectedId: row.stateName,
        cityName: row.cityTown,
        pinCode: row.pinCode,
      });
      this.props.getUploadedFilesList(
        serverApi.GET_ALL_FILES +
          "46/" +
          localStorage.getItem("companyupdateId") +
          "/" +
          localStorage.getItem("companyupdateId"),
        this.props.history,
        2
      );
      this.setState({
        deleteFile: true,
      });
    }

    this.props.getMasterStates(serverApi.GET_ALL_STATES);
  }

  componentWillReceiveProps(props) {
    if (props.Upload_File_List) {
      if (props.Upload_File_List.data) {
        this.setState({
          gstcard_fileId: props.Upload_File_List.data.id,
        });
      }
    }
    if (props.stateslist) {
      let filteredList = props.stateslist.filter((data) => {
        if (data.value !== 0) {
          return props;
        }
      });
      let selectObj = {
        label: "select",
        masterDept: null,
        masterDeptId: 0,
        name: "select",
        value: 0,
        variant: null,
      };
      const stateList = [selectObj, ...filteredList];
      this.setState({
        StateList: stateList,
      });
    }
    if (props.gstVerificationStatus) {
      if (props.gstVerificationStatus.response) {
        if (props.gstVerificationStatus.response.data.status) {
          let status = props.gstVerificationStatus.response.data.status;
          this.setState({
            gstVerificationStatus: status,
          });
        }
      }
    }
    if (this.state.deleteFile === true) {
      if (props.files_list) {
        if (props.files_list.data) {
          if (props.files_list.data.length > 0) {
            let fileName = props.files_list.data[0].fileName;
            let fileUploadId = props.files_list.data[0].fileUploadId;
            let filesList = props.files_list.data;
            this.setState({
              gstUploadedFilesList: filesList,
              ShowUploadBlock: true,
              ShowUpload: false,
              gstcard_file: fileName,
              gstcard_fileId: fileUploadId,
            });
          }
        }
      }
    }
  }

  handleUploadFile = (key) => (event) => {
    var companyId = this.state.companyId;
    var fileName = "";
    var sourceMenu = event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    let fileExt = file.name.split(".").pop();
    var selectedFileName = event.target.files[0].name;
    const formData = new FormData();
    formData.append("fileName", selectedFileName);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 46);
    formData.append("displayName", selectedFileName);
    formData.append("taskUniqueId", companyId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", companyId);

    this.setState({
      gstcard_file: selectedFileName + "." + fileExt,
      ShowUpload: false,
    });
    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  };
  handleDeleteFile = (fileId) => {
    if (
      this.state.gstcard_fileId !== "" &&
      this.state.gstcard_fileId !== undefined
    ) {
      this.props.deleteFile(serverApi.DELETEFILE + this.state.gstcard_fileId);
      this.setState({
        gstcard_file: "",
        gstcard_fileId: "",
        ShowUpload: true,
        deleteFile: false,
      });
    }
  };
  getFileDownload = (fileUploadId, fileName, fileExtension) => {
    let url =
      serverVars.USER_MANAGEMENT_API + "files/downloadfile/" + fileUploadId;
    getFileDownload(url, this.state.gstcard_file, "pdf");
  };
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };
  render() {
  
    const {
      branchname,
      address,
      statecode,
      brhead,
      contactno,
      email,
      gstnum,
      pancard,
    } = this.state;
    return (
      <>
        <div className="consoleFormContainer">
          <h5>Branch Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={branchname}
                        type="text"
                        label="Branch Name"
                        star="*"
                        placeholder="Enter Branch Name..."
                        onChange={this.handleChange("branchname")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={address}
                        type="text"
                        label="Address"
                        star="*"
                        placeholder="Enter Address..."
                        onChange={this.handleChange("address")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      {/* <div>
                          <label>State</label>
                        </div> */}
                      <div className="consoleTextFieldBlock">
                        <DynamicSelectSimple
                          arrayOfData={this.state.StateList}
                          selected={this.state.selectedId}
                          onSelectChange={this.handleSelectChange}
                          lable="State"
                        />
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={statecode}
                        type="text"
                        label="State Code"
                        star="*"
                        placeholder="Enter State Code..."
                        onChange={this.handleChange("statecode")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.cityName}
                        type="text"
                        label="City/Town/District"
                        star="*"
                        placeholder="Enter city/town/district..."
                        onChange={this.handleChange("city")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.pinCode}
                        type="number"
                        label="Pincode"
                        star="*"
                        placeholder="Enter Pincode..."
                        onChange={this.handleChange("pincode")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={brhead}
                        type="text"
                        label="Branch Head Name"
                        star="*"
                        placeholder="Enter Branch Head Name..."
                        onChange={this.handleChange("brhead")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldMobileReq
                        value={contactno}
                        type="text"
                        label="Contact Number"
                        star="*"
                        placeholder="Enter Contact Number..."
                        onChange={this.handleChange("contactno")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldEmailReq
                        value={email}
                        type="text"
                        label="Email Address"
                        star="*"
                        placeholder="Enter text here..."
                        onChange={this.handleChange("email")}
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      {/* <div>
                          <label>State</label>
                        </div> */}
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={addressTypeList}
                          selected={this.state.addressType}
                          onSelectChange={this.onhandleSelectChange}
                          lable="Address Type"
                          handleMenuOpen={this.handleMenuOpen}
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormContainer">
          <h5>GST Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleAdormentBlock">
                      <TextFieldGstVerify
                        value={gstnum}
                        type="text"
                        label="GST Number"
                        length="15"
                        caps="YES"
                        placeholder="Enter Gst Number..."
                        onChange={this.handleChange("gstnum")}
                        onVerify={this.onClickVerify}
                        isGstVerified={this.state.gstVerificationStatus}
                      />
                    </div>
                  </Box>
                  {this.state.ShowUploadBlock === true ? (
                    <Box gridColumn="span 6">
                      <div
                        className="ducumentUploadBlock"
                        style={{ marginTop: "20px" }}
                      >
                        {this.state.ShowUpload === true ? (
                          <div className={"documentUploadContent "}>
                            <input
                              type="file"
                              name="file"
                              id="file-upload"
                              onChange={this.handleUploadFile()}
                              className="hiddenInput"
                            />
                            <label
                              htmlFor="file-upload"
                              style={{ display: "flex" }}
                            >
                              <Button
                                variant="contained"
                                component="span"
                                //endIcon={<GetAppIcon />}
                                className="documentUpload"
                              >
                                <img src={panUploadIcon} alt="" /> Upload File
                              </Button>
                            </label>
                          </div>
                        ) : (
                          <div>
                            <div className="uploadedInfo">
                              <img src={TickIcon} alt="" className="tickIcon" />
                              <div
                                className="selectedFileName"
                                onClick={() => {
                                  this.getFileDownload(
                                    this.state.gstcard_fileId
                                  );
                                }}
                              >
                                {this.state.gstcard_file}
                              </div>
                              <button
                                className="deleteButton"
                                onClick={() => {
                                  this.handleDeleteFile();
                                }}
                              >
                                <img src={DeleteIcon} alt="" />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/branchlist">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          {this.props.location.rowdata === undefined ? (
            <Button
              variant="contained"
              className="saveButton"
              type="submit"
              onClick={() => {
                this.handleValidSubmit();
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              className="saveButton"
              type="submit"
              onClick={() => {
                this.handleValidSubmit();
              }}
            >
              Update
            </Button>
          )}
        </div>
      </>
    );
  }
}

const addressTypeList = [
  {
    name: "Shipping",
    label: "Shipping",
    value: 1,
  },
  {
    name: "Billing and Shipping",
    label: "Billing and Shipping",
    value: 2,
  },
];

const mapStatetoProps = (state) => {
  const { stateslist, gstVerificationStatus } = state.BranchCreation;
  const { companyresponse } = state.BasicDetails;
  const { Upload_File_List } = state.UploadFileReducer;
  const { accounttypeslist, basicDetails, files_list } = state.OffcialDetails;
  return {
    stateslist,
    basicDetails,
    companyresponse,
    Upload_File_List,
    files_list,
    gstVerificationStatus,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getMasterStates,
    branchCreationAdd,
    postUploadFileList,
    getUploadedFilesList,
    deleteFile,
    getFileDownload,
    getGstVerification,
    gstVerificationSuccessful,
  })(BranchCreation)
);
