import React, { Component } from "react";
import { Grid, } from "@mui/material";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import InnerNavigation from "../../components/InnerNavigation/InnerNavigation";
import Header from "../../components/Header/Header";
import CreateRole from "./CreateRole";

class UpdateRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Role Management",
      companypage: <CreateRole rolemanagetype={2}/>,
      createbranchbtn: 3,
      module: 5,
      rolename: "",
      roletype: "",
      userDit: JSON.parse(localStorage.getItem("authUser")),
      inner_tr_color: "#FFF",
    };

  }

  onChange = (name, page, btn) => {
    this.setState({
      parentName: name,
      companypage: page,
      createbranchbtn: btn,
    });
  };

  render() {
    
    return (
      <>
        <Grid container className="mainContainer">
         
         
          <Grid className="mainContent">
            
            
            <div className="consoleContainer">
              
              {this.state.companypage}
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default UpdateRole;
