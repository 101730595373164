import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


export default function PlaceHolderComponent() {
  return (
    <Grid container className="mainContainer">  
      <Box
        sx={{
          boxShadow: 3,
          width: "100%",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          p: 1,
          m: 1,
          borderRadius: 2,
          textAlign: "center",
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
        className="rolePlaceholderBlock"
      >
        
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="placeHolderHeading"
          >
            No Roles yet
          </Typography>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="p"
            className="placeHolderSubhead"
          >
            No roles created yet. Assign a role name in role type and select
            role assign for a particular task
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}
