import React, { Component, Fragment } from "react";
import { Grid } from "@mui/material";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cons, { serverApi } from "../../helpers/Consts";
import CustomButton from "../../components/Buttons/Buttons";
import ExportIcon from "../../assets/images/exportIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import BootstrapTable from "react-bootstrap-table-next";
import PlaceHolder from "../../components/PlaceHolders/PlaceHolderComponent";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

class GridView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
    };
  }
  editRow = (row) => {
    cons.COMPANY_ID = row.companyId;
    this.props.history.push({ pathname: this.props.pathname, rowdata: row });
    // this.props.history.push(this.props.pathname);

    localStorage.setItem("companyupdateId", row.companyId);
  };
  // deleteRow(row) {
  //   cons.COMPANY_ID = row.companyId;
  //   console.log("Nagesh Row Delete", row);
  // }

  render() {
    if (this.props.mdata) {
      var products = this.props.mdata.map((row, order) => ({
        ...row,
        chk: (
          <div
            className="checkbox form-check"
            style={{ border: "1px solid green" }}
          >
            <div className="custom-checkbox custom-control custom-control-inline">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={this.handleChange}
                value={row.companyId}
                id={row.companyId}
              />
              <label
                className="custom-control-label"
                for={row.companyId}
              ></label>
            </div>{" "}
          </div>
        ),
        actions: (
          <Fragment>
            <EditIcon onClick={() => this.editRow(row)} />
            {/* <DeleteIcon onClick={() => this.deleteRow(row)} /> */}
          </Fragment>
        ),
      }));
    } else {
      var products = [];
    }
    const { SearchBar } = Search;
    const headerSortingStyle = { backgroundColor: "#e3edf8" };
    const columns = [
      // {
      //   dataField: "companyId",
      //   text: "ID",
      //   headerAlign: "left",
      //   align: "left",
      // },
    ];
    if (this.props.mcolumns) {
      for (let i = 0; i < this.props.mcolumns.length; i++) {
        var coldata = {
          dataField: this.props.mcolumns[i].accessor,
          text: this.props.mcolumns[i].Header,
          headerAlign: "left",
          align: "left",
        };
        columns.push(coldata);
      }
      var actions = {
        dataField: "actions",
        text: "Actions",
        headerAlign: "left",
        align: "left",
        csvExport: false,
      };
      columns.push(actions);
    } else {
      const columns = [];
    }
    const defaultSorted = [
      {
        dataField: "companyId",
        order: "asc",
      },
    ];
    const MyExportCSV = (props) => {
      const handleExportClick = () => {
        props.onExport();
      };
      return (
        <CustomButton
          label="Export"
          className="lightBlueButton"
          type="button"
          icon={ExportIcon}
          handleClick={handleExportClick}
        />
      );
    };
    return (
      <>
        {/* <GridView mdata={mdata} mcolumns={mcolumns}/> */}
        {this.props.mdata ? (
          <Grid>
            {/* <LeftSidebar /> */}
            <Grid>
              <div className="consoleFormContainer">
                <div className="gridContainer">
                  <div className="gridComponent">
                    <PaginationProvider
                      pagination={paginationFactory({
                        custom: true,
                        totalSize: products.length,
                        // page: 1,
                        // sizePerPage: 5,
                        // nextPageText: "Next >",
                        // prePageText: "< Prev",
                        // withFirstAndLast: false,
                        // showTotal: true,
                        firstPageText: "First",
                        prePageText: "< Prev",
                        nextPageText: "Next >",
                        lastPageText: "Last",
                        nextPageTitle: "First page",
                        prePageTitle: "Pre page",
                        firstPageTitle: "Next page",
                        lastPageTitle: "Last page",

                        sizePerPageList: [
                          {
                            text: "10",
                            value: 10,
                          },
                          {
                            text: "30",
                            value: 30,
                          },
                          {
                            text: "50",
                            value: 50,
                          },
                          {
                            text: "All",
                            value: products.length,
                          },
                        ],
                        hideSizePerPage: products.length === 0,
                      })}
                      keyField="companyId"
                      columns={columns}
                      data={products}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="companyId"
                          columns={columns}
                          data={products}
                          search
                          exportCSV={{
                            fileName: this.state.filter_form,
                            exportAll: true,
                            noAutoBOM: false,
                            blobType: "text/csv;charset=ansi",
                          }}
                        >
                          {(toolkitprops) => (
                            <div>
                              <Grid
                                container
                                spacing={2}
                                className="buttonBlock"
                              >
                                <div className="gridButtonBlock">
                                  <SearchBar {...toolkitprops.searchProps} />
                                  {/* </Grid>
                                <Grid item xs={12} md={2}> */}
                                  <MyExportCSV {...toolkitprops.csvProps} />
                                </div>
                              </Grid>
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                // selectRow={selectRow}
                                defaultSorted={defaultSorted}
                                defaultSortDirection="asc"
                                striped
                                noDataIndication="No Data Is Available"
                                                              />
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />

                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        ) : (
          <div className="consoleFormContainer">
            <PlaceHolder />
          </div>
        )}
      </>
    );
  }
}
const mapStatetoProps = (state) => {};
export default withRouter(
  connect(mapStatetoProps, {
    //   getbranchlist,
    //   getCompanys,
    //   getLeaveTypeList
  })(GridView)
);
