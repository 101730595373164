/* Form Component Created By Nagesh Medisetty
On 08-02-2022
*/

import React, { Component } from "react";
import { Grid, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import {
    getLocations,    
  } from "../../store/Form/actions";
import TextField from "../../components/TextField/TextField";
import TextFieldReq from "../../components/TextField/TextFieldReq";
import TextFieldMobile from "../../components/TextField/TextFieldMobile";
import TextFieldMobileReq from "../../components/TextField/TextFieldMobileReq";
import TextFieldEmail from "../../components/TextField/TextFieldEmail";
import TextFieldEmailReq from "../../components/TextField/TextFieldEmailReq";
import DynamicSelect from "../../components/Dropdown/DynamicSelect";



class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {}            
    }
    handleChange = (key) => (event) => {
        this.setState({ [key]: event });
    };  
    componentDidMount() {    
        this.props.getLocations();
    }
    handleSelectChange = (selectedValue, selectedId) => {
        this.setState({
          selectedValue: selectedValue,
          selectedId: selectedId
        });
    }   

    render() {       
        var feildsdata = this.props.locationlist[0];
        if(feildsdata){
            var myfeilds = feildsdata.feilds.map((row, order) => ({
                ...row, 
                value: this.state[row.name] || "" ,        
            }));            
        }
        if(!myfeilds){
            return null;
        }        
        return (
            <>
                <form onSubmit={this.handleValidSubmit}>
                    <div className="consoleFormContainer">
                        <div className="consoleFormBlock">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                      
                                        {
                                            myfeilds.map((feild, key) => (
                                                
                                                feild.componet == "TextField" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">
                                                            <TextField
                                                                value={feild.value}
                                                                type={feild.type}
                                                                caps={feild.caps}
                                                                label={feild.lable}
                                                                placeholder={feild.placeholder}
                                                                onChange={this.handleChange(feild.name)}
                                                                />
                                                        </div>
                                                    </Box>
                                                ):(
                                                feild.componet == "TextFieldReq" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">
                                                            <TextFieldReq
                                                                value={feild.value}
                                                                type={feild.type}
                                                                caps={feild.caps}
                                                                label={feild.lable}
                                                                placeholder={feild.placeholder}
                                                                onChange={this.handleChange(feild.name)}
                                                                />
                                                        </div>
                                                    </Box>
                                                ):(
                                                feild.componet == "TextFieldMobile" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">
                                                            <TextFieldMobile
                                                                value={feild.value}
                                                                type={feild.type}
                                                                caps={feild.caps}
                                                                label={feild.lable}
                                                                placeholder={feild.placeholder}
                                                                onChange={this.handleChange(feild.name)}
                                                                />
                                                        </div>
                                                    </Box>
                                                ):( 
                                                feild.componet == "TextFieldMobileReq" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">
                                                            <TextFieldMobileReq
                                                                value={feild.value}
                                                                type={feild.type}
                                                                caps={feild.caps}
                                                                label={feild.lable}
                                                                placeholder={feild.placeholder}
                                                                onChange={this.handleChange(feild.name)}
                                                                />
                                                        </div>
                                                    </Box>
                                                ):(
                                                feild.componet == "TextFieldEmail" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">
                                                            <TextFieldEmail
                                                                value={feild.value}
                                                                type={feild.type}
                                                                caps={feild.caps}
                                                                label={feild.lable}
                                                                placeholder={feild.placeholder}
                                                                onChange={this.handleChange(feild.name)}
                                                                />
                                                        </div>
                                                    </Box>
                                                ):(
                                                feild.componet == "TextFieldEmailReq" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">
                                                            <TextFieldEmailReq
                                                                value={feild.value}
                                                                type={feild.type}
                                                                caps={feild.caps}
                                                                label={feild.lable}
                                                                placeholder={feild.placeholder}
                                                                onChange={this.handleChange(feild.name)}
                                                                />
                                                        </div>
                                                    </Box>
                                                ):(
                                                feild.componet == "DynamicSelect" ? (
                                                    <Box gridColumn="span 4">
                                                        <div className="consoleTextFieldBlock">                                                        
                                                            <DynamicSelect
                                                             lable= {feild.lable}
                                                             arrayOfData={feild.arrayOfData} onSelectChange={this.handleSelectChange} />
                                                        </div>
                                                    </Box>
                                                ):(<></>)
                                            ))))))))
                                            
                                        }

                                        
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div className="consoleFormButtonBlock">
                        <Button variant="contained" className="cancelButton">
                            Cancel
                        </Button>
                        <Button variant="contained" className="saveButton" type="submit">
                            Save
                        </Button>
                    </div>
                </form>
            </>
        );
    }
}


const mapStatetoProps = (state) => {
    const { locationlist} = state.Form;
    return { locationlist };
  };
  
  export default connect(mapStatetoProps, {
    getLocations,    
  })(Form);