import React, { Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  { serverApi } from "../../../helpers/Consts";
import {taxMasterList} from "../../../store/Master/TaxMaster/actions"
import GridView from "../../../components/Grid/GridView";
import GridViewSearch from "../../../components/Grid/GridViewSerch";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: 0, userDit: JSON.parse(localStorage.getItem("authUser")),
    mdata:[],
    listcolumns:[]
   };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyIdconfig"),
      taxname:null,
      taxper:null
    }
    this.props.taxMasterList(serverApi.TAX_MASTER_LIST,data, this.props.history); // calling api for department list
  }
  render() {
    console.log("getting the response for taxList", this.props.TaxMasterListData.data)
    const mdata = this.props.TaxMasterListData ? this.props.TaxMasterListData.data : "";
    const mcolumns = this.props.TaxMasterListData ? this.props.TaxMasterListData.column : "";
    return (
        // <></>
      <GridViewSearch mdata={mdata} mcolumns={listcolumns} pathname="/create_tax_master"/>
    )
  }
}

const listcolumns=[
  {
Header:"Tax Name",
dataField:"taxName",
hidden:false,
  },
  {
Header:"Tax Percentage",
dataField:"taxPercentage",
hidden:false,
  }

]

const mapStatetoProps = (state) => {
  const { TaxMasterListData } = state.TaxMasterReducer;
  return { TaxMasterListData };
};

export default withRouter(
  connect(mapStatetoProps, {
    taxMasterList,
  })(Index)
);
