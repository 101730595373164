import {Chart} from "react-google-charts";

export const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
]

export const options = {
    title: "My Daily Activity"
}

const chart = (prop) => {
    return (
        <Chart
          chartType={prop.chart.chartType}
          data={prop.chart.data}
          options={prop.chart.options}
          width={prop.chart.width}
          height={prop.chart.height}
        />
      )
}

export default chart