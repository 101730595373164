// /* Author : Niranjan
// Created on : 23-02-2022
//*/

import React, { Component } from "react";
import {
  LineChart,
  ResponsiveContainer,
  Legend,
  Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
class LineChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="lineChartContainer">
          <div className="lineChartHeader">
            <div className="lineChartBlock">
              <h1>
                {this.props.statusOf}
                &nbsp;{this.props.statusMobile}
              </h1>
              <p>
                {this.props.fromDate} - {this.props.toDate}
              </p>
            </div>
            <div className="lineChartBlock">
              <h2>
                {this.props.actualCount} <span>{this.props.totalCount}</span>
              </h2>
              <p>{this.props.lastUpdateMsg}</p>
            </div>
          </div>
          <ResponsiveContainer width="100%" aspect={3}>
            <LineChart data={this.props.data} margin={{ right: 10, left: 10 }}>
              {/* <CartesianGrid /> */}
              {/* <Legend /> */}
              <XAxis
                dataKey="name"
                interval={"preserveStartEnd"}
                stroke="#c1d3dd"
                fontSize="11px"
              />
              {/* <YAxis></YAxis> */}

              <Tooltip />
              <Line
                dataKey="student"
                stroke={this.props.strokeColor}
                strokeWidth={3}
                activeDot={{ r: 8 }}
              />
              {/* <Line dataKey="fees" stroke="red" activeDot={{ r: 8 }} /> */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </>
    );
  }
}
export default LineChartComponent;
