import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getFileDownload } from "../../helpers/Server_Helper"
import { serverVars } from "../../helpers/Consts"


import {
  officialdetailsAdd,
  getMasterAccountTypes,
  getCompanyDits,
  clearForm,
  basicDetailsData,
  getUploadedFilesList,
  getTanUploadedFilesList,
  postUploadTanFileList,
  postUploadCinFileList,
  getCinUploadedFilesList
} from "../../store/Company/OfficialDetails/actions";
import { postUploadFileList, deleteFile } from "../../store/UploadFile/actions";
import cons, { serverApi } from "../../helpers/Consts";
import panUploadIcon from "../../assets/images/panUploadIcon.png";
import DeleteIcon from "../../assets/images/picEditIcon.png";
import TickIcon from "../../assets/images/fileUploadedTick.png";
import DocumentUpload from "../../components/DocumentUpload/DocumentUpload";
import {
  Validators,
  Validatorslenght,
  Validatorsrequired,
} from "../../Utilities/Validator";
import TextFieldGstVerify from "../../components/TextField/TextFieldGstVerify";
import TextFieldPanCard from "../../components/TextField/TextFieldPanCard";
import Link from "@mui/material/Link";
import swal from "sweetalert";
import moment from "moment"


const arrayOfData = [
  {
    id: "1",
    name: "SB Account",
  },
  {
    id: "2",
    name: "Current Acccount",
  },
];

class OfficialDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHedder: "Official Details",
      companyId: 0,
      accounttype: "",
      type_name: "Select Account Type",
      type_id: 0,
      selectedValue: "Nothing selected",
      selectedId: 0,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      alertMailHost: null,
      alertMailId: null,
      alertMailName: null,
      alertMailPassword: null,
      bucketName: "",
      // cinNo: null,
      companyAddress1: null,
      companyAddress2: null,
      companyCode: "",
      companyId: 0,
      companyName: "",
      contactNo: "",
      contactPerson: "",
      createdBy: null,
      emailId: "",
      financialDate: "",
      folderName: "",
      gstNo: "",
      gstNo2: null,
      juteMrBccMail: null,
      jutePoBccMail: null,
      orgId: localStorage.getItem("companyupdateId"),
      panNO: "",
      tanNo: "",
      cinNo: "",
      portNo: null,
      stateCode1: null,
      stateCode2: null,
      stateName1: null,
      stateName2: null,
      storeMrBccMail: null,
      storePoBccMail: null,
      tallySync: null,
      yourUrl: "",
      pancard_file: null,
      pancard_fileId: 0,
      ShowUpload: true,
      ShowUploadBlock: false,
      panUploadedFilesList: [],
      deleteFile: false,
      tancard_file: null,
      tancard_fileId: 0,
      ShowtanUpload: true,
      ShowTanUploadBlock: false,
      tanUploadedFilesList: [],
      cincard_file: null,
      cincard_fileId: 0,
      ShowcinUpload: true,
      ShowCinUploadBlock: false,
      cinUploadedFilesList: [],
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    // this.handleCancel = this.handleCancel(this);
  }

  componentDidMount() {
    if (this.props.headerData) {
      if (this.props.headerData.panNO !== "") {
        this.props.getUploadedFilesList(serverApi.GET_ALL_FILES + "44/" + this.props.headerData.companyId + "/" + this.props.headerData.companyId, this.props.history, 2);
      }
      if (this.props.headerData.gstNo2 !== "") {
        this.props.getTanUploadedFilesList(serverApi.GET_ALL_FILES + "47/" + this.props.headerData.companyId + "/" + this.props.headerData.companyId, this.props.history, 2);
      }
      if (this.props.headerData.cinNo !== null) {
        this.props.getCinUploadedFilesList(serverApi.GET_ALL_FILES + "48/" + this.props.headerData.companyId + "/" + this.props.headerData.companyId, this.props.history, 2);
      }
      this.setState({
        deleteFile: true
      })
    }
  }

  componentWillReceiveProps(props) {
    // if (props.rowdata) {
    //   var rowd = props.rowdata.data;
    //   this.setState({
    //     gstno: rowd.gstNo,
    //     pancard: rowd.panNO,
    //     companyId: rowd.companyId,
    //   });
    // }
    if (props.headerData) {
      this.setState({
        alertMailHost: props.headerData.alertMailHost,
        alertMailId: props.headerData.alertMailId,
        alertMailName: props.headerData.alertMailName,
        alertMailPassword: props.headerData.alertMailPassword,
        bucketName: props.headerData.bucketName,
        cinNo: props.headerData.cinNo,
        companyAddress1: props.headerData.companyAddress1,
        companyAddress2: props.headerData.companyAddress2,
        companyCode: props.headerData.companyCode,
        companyId: props.headerData.companyId,
        companyName: props.headerData.companyName,
        contactNo: props.headerData.contactNo,
        contactPerson: props.headerData.contactPerson,
        createdBy: props.headerData.createdBy,
        emailId: props.headerData.emailId,
        financialDate: moment(props.headerData.financialDate).format("DD-MM-YYYY"),
        folderName: props.headerData.folderName,
        gstNo: props.headerData.gstNo,
        gstNo2: props.headerData.gstNo2,
        juteMrBccMail: props.headerData.juteMrBccMail,
        jutePoBccMail: props.headerData.jutePoBccMail,
        orgId: props.headerData.orgId,
        panNO: props.headerData.panNO,
        cinNo: props.headerData.cinNo,
        ShowUploadBlock: props.headerData.panNO !== "" && props.headerData.panNO !== null ? true : false,
        ShowTanUploadBlock: props.headerData.gstNo2 !== "" && props.headerData.gstNo2 !== null ? true : false,
        ShowCinUploadBlock: props.headerData.cinNo !== "" && props.headerData.cinNo !== null ? true : false,
        portNo: props.headerData.portNo,
        stateCode1: props.headerData.stateCode1,
        stateCode2: props.headerData.stateCode2,
        stateName1: props.headerData.stateName1,
        stateName2: props.headerData.stateName2,
        storeMrBccMail: props.headerData.storeMrBccMail,
        storePoBccMail: props.headerData.storePoBccMail,
        tallySync: props.headerData.tallySync,
        yourUrl: props.headerData.yourUrl,
      });
    } else {

      this.setState({
        alertMailHost: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.alertMailHost : "" : "",
        alertMailId: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.alertMailId : "" : "",
        alertMailName: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.alertMailName : "" : "",
        alertMailPassword: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.alertMailPassword : "" : "",
        bucketName: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.bucketName : "" : "",
        cinNo: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.cinNo : "" : "",
        companyAddress1: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.companyAddress1 : "" : "",
        companyAddress2: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.companyAddress2 : "" : "",
        companyCode: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.companyCode : "" : "",
        companyId: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.companyId : "" : "",
        companyName: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.companyName : "" : "",
        contactNo: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.contactNo : "" : "",
        contactPerson: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.contactPerson : "" : "",
        createdBy: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.createdBy : "" : "",
        emailId: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.emailId : "" : "",
        financialDate: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.financialDate : "" : "",
        folderName: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.folderName : "" : "",
        gstNo: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.gstNo : "" : "",
        gstNo2: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.gstNo2 : "" : "",
        juteMrBccMail: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.juteMrBccMail : "" : "",
        jutePoBccMail: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.jutePoBccMail : "" : "",
        orgId: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.orgId : "" : "",
        panNO: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.panNO : "" : "",
        portNo: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.portNo : "" : "",
        stateCode1: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.stateCode1 : "" : "",
        stateCode2: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.stateCode2 : "" : "",
        stateName1: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.stateName1 : "" : "",
        stateName2: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.stateName2 : "" : "",
        storeMrBccMail: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.storeMrBccMail : "" : "",
        storePoBccMail: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.storePoBccMail : "" : "",
        tallySync: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.tallySync : "" : "",
        yourUrl: props.basicDetails ? props.basicDetails.company ? props.basicDetails.company.yourUrl : "" : "",
      });

    }
    if (props.Upload_File_List) {
      if (props.Upload_File_List.data) {
        this.setState({
          pancard_fileId: props.Upload_File_List.data.id
        })
      }
    }
    if (props.tanUploadFilesList) {
      if (props.tanUploadFilesList.data) {
        this.setState({
          tancard_fileId: props.tanUploadFilesList.data.id
        })
      }
    }
    if (props.cinUploadedFilesList) {
      if (props.cinUploadedFilesList.data) {
        this.setState({
          cincard_fileId: props.cinUploadedFilesList.data.id
        })
      }
    }

    if (this.state.deleteFile === true) {
      if (props.files_list) {
        if (props.files_list.data) {
          if (props.files_list.data.length > 0) {
            let fileName = props.files_list.data[0].fileName
            let fileUploadId = props.files_list.data[0].fileUploadId
            let filesList = props.files_list.data
            this.setState({
              panUploadedFilesList: filesList,
              ShowUploadBlock: true,
              ShowUpload: false,
              pancard_file: this.state.panNO !== "" ? fileName : "",
              pancard_fileId: fileUploadId,
            })
          }
        }
      }
    }

    if (this.state.deleteFile === true) {
      if (props.gettanUploadFilesList) {
        if (props.gettanUploadFilesList.data) {
          let tanUploadFilesList = props.gettanUploadFilesList.data
          if (tanUploadFilesList.data.length > 0) {
            let fileName = tanUploadFilesList.data[0].fileName
            let fileUploadId = tanUploadFilesList.data[0].fileUploadId
            let filesList = tanUploadFilesList.data
            this.setState({
              tanUploadedFilesList: filesList,
              ShowTanUploadBlock: true,
              ShowtanUpload: false,
              tancard_file: this.state.gstNo2 !== "" ? fileName : "",
              tancard_fileId: fileUploadId,
            })
          }
        }
      }
    }
    if (this.state.deleteFile === true) {
      if (props.getcinUploadFilesList) {
        if (props.getcinUploadFilesList.data) {
          let cinUploadFilesList = props.getcinUploadFilesList.data
          if (cinUploadFilesList.data.length > 0) {
            let fileName = cinUploadFilesList.data[0].fileName
            let fileUploadId = cinUploadFilesList.data[0].fileUploadId
            let filesList = cinUploadFilesList.data

            this.setState({
              cinUploadedFilesList: filesList,
              ShowCinUploadBlock: true,
              ShowcinUpload: false,
              cincard_file: this.state.cinNo !== "" ? fileName : "",
              cincard_fileId: fileUploadId,
            })
          }
        }
      }
    }

  }

  handleGstNo = (e) => {
    this.setState({
      gstno: e.target.value,
    });
  };
  handleChange = (key) => (event) => {
    this.setState({ [key]: event.toUpperCase() });
    this.props.headerData[key] = event.toUpperCase();
    if (key === "panNO") {
      if (event !== "") {
        this.setState({
          ShowUploadBlock: true,
          ShowUpload: true
        })
      } else {
        this.setState({
          ShowUploadBlock: false
        })
      }
    }
    if (key === "gstNo2") {
      if (event !== "") {
        this.setState({
          ShowTanUploadBlock: true,
          ShowtanUpload: true
        })
      } else {
        this.setState({
          ShowTanUploadBlock: false
        })
      }
    }
    if (key === "cinNo") {
      if (event !== "") {
        this.setState({
          ShowCinUploadBlock: true,
          ShowcinUpload: true
        })
      } else {
        this.setState({
          ShowCinUploadBlock: false
        })
      }
    }
  };

  handleUploadFile = (key) => (event) => {
    var companyId = this.state.companyId;
    var fileName = "";
    var sourceMenu = ""
    if (key === "pan") {
      sourceMenu = 44
    } else if (key === "tan") {
      sourceMenu = 47
    } else if (key === "cin") {
      sourceMenu = 48
    }

    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    let fileExt = file.name.split(".").pop();
    var selectedFileName = event.target.files[0].name;
    const formData = new FormData();
    formData.append("fileName", selectedFileName);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", sourceMenu);
    formData.append("displayName", selectedFileName);
    formData.append("taskUniqueId", companyId)
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", companyId);

    if (sourceMenu === 44) {
      this.setState({
        pancard_file: selectedFileName + "." + fileExt,
        ShowUpload: false
      });
      this.props.postUploadFileList(
        serverApi.UPLOADFILE,
        formData,
        this.props.history
      );
    }
    if (sourceMenu === 47) {
      this.setState({
        tancard_file: selectedFileName + "." + fileExt,
        ShowtanUpload: false
      });
      this.props.postUploadTanFileList(
        serverApi.UPLOADFILE,
        formData,
        this.props.history
      );
    }
    if (sourceMenu === 48) {
      this.setState({
        cincard_file: selectedFileName + "." + fileExt,
        ShowcinUpload: false
      });
      this.props.postUploadCinFileList(
        serverApi.UPLOADFILE,
        formData,
        this.props.history
      );
    }

  }


  handleDeleteFile = (fileId) => {
    if (fileId === "pan") {
      if (this.state.pancard_fileId !== "" && this.state.pancard_fileId !== undefined) {
        this.props.deleteFile(serverApi.DELETEFILE + this.state.pancard_fileId)
        this.setState({
          pancard_file: "",
          pancard_fileId: "",
          ShowUpload: true,
          deleteFile: false
        })
      }
    }
    if (fileId === "tan") {
      if (this.state.tancard_fileId !== "" && this.state.tancard_fileId !== undefined) {
        this.props.deleteFile(serverApi.DELETEFILE + this.state.tancard_fileId)
        this.setState({
          tancard_file: "",
          tancard_fileId: "",
          ShowtanUpload: true,
          deleteFile: false
        })
      }
    }
    if (fileId === "cin") {
      if (this.state.cincard_file !== "" && this.state.cincard_fileId !== undefined) {
        this.props.deleteFile(serverApi.DELETEFILE + this.state.cincard_fileId)
        this.setState({
          cincard_file: "",
          cincard_fileId: "",
          ShowcinUpload: true,
          deleteFile: false
        })
      }
    }

  };





  handleValidSubmit = (event) => {
    var userDit = this.state.userDit;
    event.preventDefault();

    if (this.props.headerData) {
      var rowd = this.props.headerData;
      var companyId = this.props.headerData.companyId;
      var companyCode = this.state.companyCode.slice(0, 3).toUpperCase()
      var data = {
        company: {
          companyId: companyId,
          companyName: this.state.companyName,
          companyCode: companyCode,
          companyAddress1: this.state.companyAddress1,
          companyAddress2: this.state.companyAddress2,
          gstNo: this.state.gstNo,
          stateCode1: this.state.stateCode1,
          stateCode2: this.state.stateCode2,
          gstNo2: this.state.gstNo2,
          createdBy: userDit.userId,
          orgId: userDit.orgId,
          tallySync: this.state.tallySync,
          contactPerson: this.state.contactPerson,
          stateName1: this.state.stateName1,
          stateName2: this.state.stateName2,
          contactNo: this.state.contactNo,
          emailId: this.state.emailId,
          panNO: this.state.panNO,
          cinNo: this.state.cinNo,
          yourUrl: this.state.yourUrl,
          financialDate: this.state.financialDate,

          bucketName: "vowcloud",
          folderName: companyId + "_" + companyCode
        },
        postAuthenticationDto: {
          cipher: "",
        },
      };
      this.props.officialdetailsAdd(
        serverApi.COMPANYMASTER_UPDATE,
        data,
        companyId,
        this.props.history
      );
    } else if (this.props.companyresponse && this.props.companyresponse.response) {
      var companyId = this.props.companyresponse.response.id;

      var data = {
        company: {
          companyId: companyId,
          companyName: this.state.companyName,
          companyCode: companyCode,
          companyAddress1: this.state.companyAddress1,
          companyAddress2: this.state.companyAddress2,
          gstNo: this.state.gstNo,
          stateCode1: this.state.stateCode1,
          stateCode2: this.state.stateCode2,
          gstNo2: this.state.gstNo2,
          createdBy: userDit.userId,
          orgId: userDit.orgId,
          tallySync: this.state.tallySync,
          contactPerson: this.state.contactPerson,
          stateName1: this.state.stateName1,
          stateName2: this.state.stateName2,
          contactNo: this.state.contactNo,
          emailId: this.state.emailId,
          panNO: this.state.panNO,
          cinNo: this.state.cinNo,
          yourUrl: this.state.yourUrl,
          financialDate: this.state.financialDate,
          bucketName:"vowcloud",
        },
        postAuthenticationDto: {
          cipher: "",
        },
      };
      this.props.basicDetailsData(data)
      this.props.officialdetailsAdd(
        serverApi.COMPANYMASTER_UPDATE,
        data,
        companyId,
        this.props.history
      );
    } else {
      swal("First Fill Basic Details");
    }
  };
  getFileDownload = (fileUploadId, fileName, fileExtension) => {
    let url = serverVars.USER_MANAGEMENT_API + "files/downloadfile/" + fileUploadId; getFileDownload(url, this.state.pancard_file, "pdf")
  };

  render() {

    return (
      <>
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <div className="consoleAdormentBlock">
          {/* <h5>GST Details</h5> */}
          <div className="consoleFormBlock" style={{ display: "none" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldGstVerify
                        value={this.state.gstNo}
                        type="text"
                        label="GST Number"
                        // star="*"
                        varifyurl="api/getVerify"
                        placeholder="Enter GST Number..."
                        validators={[
                          {
                            check: Validatorsrequired.required,
                            message: "This field is required",
                          },
                        ]}
                        onChange={this.handleChange("gstNo")}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          {/* <h5>PAN Details</h5> */}
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldPanCard
                          value={this.state.panNO}
                          type="text"
                          label="Pan Number"
                          length="10"
                          placeholder="Enter text here..."
                          validators={[
                            {
                              check: Validators.special,
                              message: "Invalid Pancard Number",
                            },
                          ]}
                          validatorlenght={[
                            {
                              length: Validatorslenght.length,
                              message: "Incorrect Pancard Number",
                            },
                          ]}
                          validatorsrequired={[
                            {
                              check: Validatorsrequired.required,
                              message: "This field is required",
                            },
                          ]}
                          onChange={this.handleChange("panNO")}
                        />
                      </div>
                    </div>
                  </Box>

                  {/* <Box gridColumn="span 4" style={{ display: this.state.panNO !== "" ? "block" : "none" }}>
                      <DocumentUpload handleUploadFile={this.handleUploadFile} pancard_file={this.state.pancard_file} />
                      <div>
                        {this.state.pancard_file}
                      </div>
                    </Box> */}
                  {/* Pancard upload block */}

                  {this.state.ShowUploadBlock === true ?
                    <Box gridColumn="span 6">
                      <div className="ducumentUploadBlock" style={{ marginTop: "20px" }}>
                        {this.state.ShowUpload === true ?
                          <div
                            className={"documentUploadContent "}
                          >
                            <input
                              type="file"
                              name="file"
                              id="file-upload"
                              onChange={this.handleUploadFile("pan")}
                              className="hiddenInput"
                            />
                            <label htmlFor="file-upload" style={{ display: "flex" }}>
                              <Button
                                variant="contained"
                                component="span"
                                //endIcon={<GetAppIcon />}
                                className="documentUpload"
                              >
                                <img src={panUploadIcon} alt="" />Upload Pan File
                              </Button>
                            </label>
                          </div>
                          : this.state.ShowUpload === false && this.state.pancard_file !== "" ?
                            <div>
                              <div className="uploadedInfo" >
                                <img src={TickIcon} alt="" className="tickIcon" />
                                <div className="selectedFileName" onClick={() => {
                                  this.getFileDownload(this.state.pancard_fileId)
                                }}>
                                  {this.state.pancard_file}
                                </div>
                                <button className="deleteButton" onClick={() => {
                                  this.handleDeleteFile("pan")
                                }}>
                                  <img src={DeleteIcon} alt="" />
                                </button>
                              </div>
                            </div> : ""}

                      </div>
                    </Box> : ""}
                </Box>

                <Box display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}>
                  {/* tan card upload  */}
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldPanCard
                          value={this.state.gstNo2}
                          type="text"
                          label="Tan Number"
                          length="10"
                          placeholder="Enter text here..."
                          validators={[
                            {
                              check: Validators.special,
                              message: "Invalid Pancard Number",
                            },
                          ]}
                          validatorlenght={[
                            {
                              length: Validatorslenght.length,
                              message: "Incorrect Pancard Number",
                            },
                          ]}
                          validatorsrequired={[
                            {
                              check: Validatorsrequired.required,
                              message: "This field is required",
                            },
                          ]}
                          onChange={this.handleChange("gstNo2")}
                        />
                      </div>
                    </div>
                  </Box>
                  {this.state.ShowTanUploadBlock === true ?
                    <Box gridColumn="span 6">
                      <div className="ducumentUploadBlock" style={{ marginTop: "20px" }}>
                        {this.state.ShowtanUpload === true ?
                          <div
                            className={"documentUploadContent "}
                          >
                            <input
                              type="file"
                              name="tanfile"
                              id="tanfile-upload"
                              onChange={this.handleUploadFile("tan")}
                              className="hiddenInput"
                            />
                            <label htmlFor="tanfile-upload" style={{ display: "flex" }}>
                              <Button
                                variant="contained"
                                component="span"
                                //endIcon={<GetAppIcon />}
                                className="documentUpload"
                              >
                                <img src={panUploadIcon} alt="" />Upload Tan File
                              </Button>
                            </label>
                          </div>
                          : this.state.ShowtanUpload === false && this.state.tancard_file !== "" ?
                            <div>
                              <div className="uploadedInfo">
                                <img src={TickIcon} alt="" className="tickIcon" />
                                <div className="selectedFileName" onClick={() => {
                                  this.getFileDownload(this.state.tancard_fileId)
                                }}>
                                  {this.state.tancard_file}
                                </div>
                                <button className="deleteButton" onClick={() => {
                                  this.handleDeleteFile("tan")
                                }}>
                                  <img src={DeleteIcon} alt="" />
                                </button>
                              </div>
                            </div> : ""}
                      </div>
                    </Box> : ""}
                </Box>
                {/* Cin card upload  */}
                <Box display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldPanCard
                          value={this.state.cinNo}
                          type="text"
                          label="Cin Number"
                          length="10"
                          placeholder="Enter text here..."
                          validators={[
                            {
                              check: Validators.special,
                              message: "Invalid Pancard Number",
                            },
                          ]}
                          validatorlenght={[
                            {
                              length: Validatorslenght.length,
                              message: "Incorrect Pancard Number",
                            },
                          ]}
                          validatorsrequired={[
                            {
                              check: Validatorsrequired.required,
                              message: "This field is required",
                            },
                          ]}
                          onChange={this.handleChange("cinNo")}
                        />
                      </div>
                    </div>
                  </Box>
                  {this.state.ShowCinUploadBlock === true ?
                    <Box gridColumn="span 6">
                      <div className="ducumentUploadBlock" style={{ marginTop: "20px" }}>
                        {this.state.ShowcinUpload === true ?
                          <div
                            className={"documentUploadContent "}
                          >
                            <input
                              type="file"
                              name="cinfile"
                              id="cinfile-upload"
                              onChange={this.handleUploadFile("cin")}
                              className="hiddenInput"
                            />
                            <label htmlFor="cinfile-upload" style={{ display: "flex" }}>
                              <Button
                                variant="contained"
                                component="span"
                                //endIcon={<GetAppIcon />}
                                className="documentUpload"
                              >
                                <img src={panUploadIcon} alt="" />Upload Cin File
                              </Button>
                            </label>
                          </div>
                          : this.state.ShowcinUpload === false && this.state.cincard_file !== "" ?
                            <div>
                              <div className="uploadedInfo">
                                <img src={TickIcon} alt="" className="tickIcon" />
                                <div className="selectedFileName" onClick={() => {
                                  this.getFileDownload(this.state.tancard_fileId)
                                }}>
                                  {this.state.cincard_file}
                                </div>
                                <button className="deleteButton" onClick={() => {
                                  this.handleDeleteFile("cin")
                                }}>
                                  <img src={DeleteIcon} alt="" />
                                </button>
                              </div>
                            </div> : ""}
                      </div>
                    </Box> : ""}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link href="/CompanyList">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button variant="contained" type="submit" className="saveButton" onClick={this.handleValidSubmit}>
            Save and Next
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { accounttypeslist, basicDetails, files_list, gettanUploadFilesList, tanUploadFilesList, cinUploadedFilesList, getcinUploadFilesList } = state.OffcialDetails;
  const { headerData, companyresponse } = state.BasicDetails;
  const { allFiles, files_response } = state.PayschemeParaReducer;
  const { Upload_File_List } = state.UploadFileReducer

  return { accounttypeslist, headerData, basicDetails, companyresponse, files_list, Upload_File_List, gettanUploadFilesList, tanUploadFilesList, cinUploadedFilesList, getcinUploadFilesList };
};

export default withRouter(
  connect(mapStatetoProps, {
    officialdetailsAdd,
    getMasterAccountTypes,
    getCompanyDits,
    clearForm,
    basicDetailsData,
    deleteFile,
    postUploadFileList,
    getUploadedFilesList,
    postUploadTanFileList,
    getTanUploadedFilesList,
    postUploadCinFileList,
    getCinUploadedFilesList
  })(OfficialDetails)
);
