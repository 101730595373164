import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  GET_LEAVE_POLICY_LIST, GET_LEAVE_SELECTION_LIST, CREATE_LEAVE_POLICY, LEAVE_POLICY_VIEW_BY_ID_LIST
} from "./actionTypes";
import {
    getLeavePolicyListSuccessfull, getLeaveSelectionListSuccessfull, 
    createLeavePolicySuccessfull, leavePolicyViewByIdSuccessfull
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getLeavePolicyAllList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getLeavePolicyListSuccessfull({ response }));
    console.log("LeaveType policy.....", response);
  } catch (error) {
    console.log(error);
  }
}

function* getLeaveSelectionAllList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(getLeaveSelectionListSuccessfull({ response }));
    console.log("LeaveType policy.....", response);
  } catch (error) {
    console.log(error);
  }
}

function* createLeavePolicies({ payload: { url, data,history } }) {
  try {
    const response = yield call(getList, url, data);
    console.log("gettttting status", response)
    if(response.status === true) {
      yield put(createLeavePolicySuccessfull({ response }));
      swal(response.message, {icon: "success"});
      history.push("/leavePolicies")
    }else {
      swal(response.message, {icon: "error"});
    }
    console.log("LeaveType policy.....", response);
  } catch (error) {
    console.log(error);
  }
}

function* LeavePolicyViewByIdAllList({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    yield put(leavePolicyViewByIdSuccessfull({ response }));
    console.log("LeaveType policy.....", response);
  } catch (error) {
    console.log(error);
  }
}


export function* watchLeaveTypePolicyList() {
  yield takeEvery(GET_LEAVE_POLICY_LIST, getLeavePolicyAllList);
  yield takeEvery(GET_LEAVE_SELECTION_LIST, getLeaveSelectionAllList);
  yield takeEvery(CREATE_LEAVE_POLICY, createLeavePolicies)
  yield takeEvery(LEAVE_POLICY_VIEW_BY_ID_LIST, LeavePolicyViewByIdAllList)
}


function* LeavePolicySaga() {
  yield all([
    fork(watchLeaveTypePolicyList),
  
  ]);
}

export default LeavePolicySaga;
