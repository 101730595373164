import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "./DynamicSelect";
import CustomButton from "../../../components/Buttons/Buttons";
import { serverApi } from "../../../helpers/Consts";
import TextFieldNormal from "../../../components/TextField/TextFieldNormal";
import TextFieldNormalNew from "../../../components/TextField/TextFieldNormalNew";
import InputAdornment from "../../../components/InputAdorment/InputAdormentNew";
import { PayComponents } from "../../../store/Master/PaySchemecreation/actions";
import TextArea from "../../../components/TextField/TextArea";
import swal from "sweetalert";

class PaySchemeAddItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowcount: 1,
      show: false,
      Component: "",
      FormulaComponent: "",
      ComponentValue: "",
      value: "",
      formula: "",
      ComponentsList: [],
      Operators_conditions: "",
      formulaType: 1,
      defaultValue: "",
      componentId: 0,
      isActive: "",
      confirmPopUp: this.props.confirmPopUp
    };
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.props.PayComponents(
      serverApi.PAY_COMPONENTS +
        "/companyId/" +
        localStorage.getItem("companyIdconfig") +
        "/orgId/" +
        JSON.parse(localStorage.getItem("authUser")).orgId,
      this.props.history
    );
  }
  componentWillReceiveProps(props) {
    if (props.payComponents_list) {
      let listComponents = [];
      props.payComponents_list.data.map((compo) => {
        listComponents.push({
          label: compo.name,
          value: compo.id,
          name: compo.code,
        });
      });
      this.setState({
        ComponentsList: listComponents,
      });
    }
  }
  handlePropSelectChange = (
    selectedValue,
    selectedName,
    name,
    row,
    selectedlable
  ) => {
    console.log(
      "selectedValue",
      selectedValue,
      selectedName,
      name,
      selectedlable
    );
    if (name === "Component") {
      if (this.props.payComponents_list) {
        console.log(this.props.payComponents_list);
        this.props.payComponents_list.data.map((compo) => {
          if (selectedValue === compo.id) {
            this.setState({
              formulaType: compo.type,
              defaultValue: compo.defaultValue,
              Component: selectedlable,
              componentId: selectedValue,
              isActive: compo.status,
            });
          }
        });
      }
    }
    if (name === "FormulaComponent") {
      let formula = this.state.formula;
      this.setState({
        formula: formula + selectedName,
        FormulaComponent: selectedName,
      });
    }
    if (name === "Operator/Condition") {
      let formula = this.state.formula;
      this.setState({
        formula: formula + selectedName,
        Operators_conditions: selectedName,
      });
    }
  };
  removeChar = (key) => (event) => {
    this.setState({ [key]: event });
    let formula = this.state.formula;

    // Remove last character of string
    formula = formula.substring(0, formula.length - 1);
    this.setState({
      formula: formula,
    });
  };
  onhandlechange = (key) => (event) => {
    console.log("getting the default value", key, event);
    // console.log("getting the selected default value", )

    this.setState({ [key]: event });
    if (key === "value") {
      this.setState({
        value: event,
      });
    }
    if (key === "formula") {
      this.setState({
        formula: event,
      });
    }
    if (key === "defaultValue") {
      this.setState({
        defaultValue: event,
      });
    }
  };

  handleAddValue = () => {
    let formula = this.state.formula;
    if (this.state.value !== "") {
      this.setState({
        formula: formula + this.state.value,
        value: "",
      });
    } else {
      swal("Enter value to add");
    }
  };
  AddFormula = () => {
    if(this.props.componentsIds.includes(this.state.componentId)) {
      swal("Can't add duplicate component, change the component to proceed further");
      return;
    }

    if (this.state.formulaType === 0) {
      let obj0 = {
        formula: this.state.defaultValue,
        componentName: this.state.Component,
        componentId: this.state.componentId,
        type:this.state.formulaType,
        isActive:this.state.isActive
      };
      this.props.getFormula(obj0,this.state.formulaType);
    } else if (this.state.formulaType === 1) {
      let obj1 = {
        formula: this.state.formula,
        componentId: this.state.componentId,
        componentName: this.state.Component,
        type:this.state.formulaType,
        value: '',
        isActive:this.state.isActive
      };
      this.props.getFormula(obj1,this.state.formulaType);
    }else if (this.state.formulaType === 2) {
      let obj2 = {
        formula: this.state.formula,
        componentId: this.state.componentId,
        componentName: this.state.Component,
        type:this.state.formulaType,
        value: '',
        isActive:this.state.isActive
      };
      this.props.getFormula(obj2,this.state.formulaType);
    }
     else {
      let obj3 = {
        formula: this.state.formula,
        componentId: this.state.componentId,
        componentName: this.state.Component,
        type:this.state.formulaType,
        value: '',
        isActive:this.state.isActive
      };
      this.props.getFormula(obj3,this.state.formulaType);
    }
  };
  cancelFormula = () => {
    this.props.onclickOk();
  };

  handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    console.log('Scrolling...');
    this.setState({
      isDropdownOpen:true
    })
  };

  render() {
    return (
      <div className="packageMasterContainer">
        <div className="">
          <div className="consoleFormBlock note">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={this.state.ComponentsList}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.Component}
                        name="Component"
                        label="Component"
                        required
                      />
                    </div>
                  </Box>
                  {this.state.formulaType !== 0 && (
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={this.state.ComponentsList}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.FormulaComponent}
                          name="FormulaComponent"
                          label="Formula Component"
                        />
                      </div>
                    </Box>
                  )}

                  {this.state.formulaType === 0 && (
                    <Box gridColumn="span 3">
                      <div className="consoleTextFieldBlock">
                        <TextFieldNormalNew
                          label="Default Value"
                          type="number"
                          onChange={this.onhandlechange("defaultValue")}
                          value={this.state.defaultValue}
                          rows={5}
                          // required
                        />
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
              {this.state.formulaType === 0 && (
                <p className="informationText" style={{ marginTop: "7px" }}>
                  <strong>Note:</strong> Select either Formula or Default
                  Value..!!
                </p>
              )}
            </Grid>
          </div>
          <div style={{ height: "10px" }}></div>
          {this.state.formulaType !== 0 && (
            <div className="consoleFormBlock">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 6">
                      <div className="consoleTextFieldBlock">
                        <DynamicSelect
                          arrayOfData={Operators}
                          className="dropdownBlockContainer"
                          menuPosition="fixed"
                          menuPlacement="auto"
                          onSelectChange={this.handlePropSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          selected={this.state.Operators_conditions}
                          name="Operator/Condition"
                          label="Operator/Condition"
                        />
                      </div>
                    </Box>
                    {this.state.formulaType !== 0 && (
                      <Box gridColumn="span 6">
                        <div className="consoleTextFieldBlock">
                          <label>Value</label>
                          <InputAdornment
                            value={this.state.value}
                            handleClick={this.onhandlechange("value")}
                            btnLable="ADD"
                            onClick={this.handleAddValue}
                          />
                        </div>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </div>
          )}

          <div style={{ height: "10px" }}></div>
          {this.state.formulaType !== 0 && (
            <div className="consoleFormBlock note">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={2}
                  >
                    <Box gridColumn="span 9">
                      <div className="consoleTextFieldBlock">
                        <TextArea
                          label="Formula"
                          onChange={this.onhandlechange("formula")}
                          value={this.state.formula}
                          required
                        />
                      </div>
                    </Box>
                    <Box gridColumn="span 3">
                      <div
                        className="consoleTextFieldBlock"
                        style={{ marginTop: "8px" }}
                      >
                        <label>&nbsp;</label>
                        <CustomButton
                          label={"Undo"}
                          className="greenBorderButton"
                          handleClick={this.removeChar("undo")}
                        />
                      </div>
                    </Box>
                  </Box>
                </Grid>
                <p className="informationText">
                  <strong>Note:</strong> Select either Formula or Default
                  Value..!!
                </p>
              </Grid>
            </div>
          )}
          <div className="consoleFormButtonBlock">
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.cancelFormula}
              type="sumbit"
            />
            <CustomButton
              label={"Add"}
              className="greenButton"
              handleClick={this.AddFormula}
            />
          </div>
        </div>
      </div>
    );
  }
}

export const Operators = [
  { label: "Select", value: "", name: "" },
  { label: "+", value: 0, name: "+" },
  { label: "-", value: 2, name: "-" },
  { label: "*", value: 3, name: "*" },
  { label: "/", value: 4, name: "/" },
  { label: "%", value: 5, name: "%" },
  { label: "=", value: 6, name: "=" },
  { label: ";", value: 7, name: ";" },
  { label: ">", value: 8, name: ">" },
  { label: "<", value: 9, name: "<" },
  { label: "space", value: 10, name: " " },
  { label: "(", value: 11, name: "(" },
  { label: ")", value: 12, name: ") result=" },
  { label: "if", value: 13, name: "var result=0; if (" },
  { label: "then", value: 14, name: "then" },
  { label: "else if", value: 15, name: "else if (" },
  { label: "else", value: 16, name: "else result=" },
  { label: "end if", value: 17, name: "end if" },
  { label: "result", value: 18, name: "result" },
  { label: "var", value: 19, name: "var" },
];

const mapStatetoProps = (state) => {
  const { payComponents_list } = state.PaySchemeReducer;
  return { payComponents_list };
};

export default withRouter(
  connect(mapStatetoProps, {
    PayComponents,
  })(PaySchemeAddItems)
);
