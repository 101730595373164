import React, { Component } from "react";

class TextArea extends Component {
  handleChange = (event) => {
    const { value } = event.target;
    this.props.onChange(value);
  };
  render() {
    return (
      <div className="TextFieldBlock">
        {this.props.label && (
          <label htmlFor="app-input-field">
            {this.props.label}{" "}
            <label className="starcolor">{this.props.required && "*"}</label>
          </label>
        )}
        <textarea
          disabled={this.props.disabled}
          readOnly={this.props.readOnly}
          rows={5}
          value={this.props.value}
          className="TextField"
          placeholder="Enter here"
          onChange={this.handleChange}
          InputProps={this.props.InputProps}
          style={{height:'100px'}}
        />
      </div>
    );
  }
}
export default TextArea;
