import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";

import RadioButton from "../../components/RadioButton/RadioButton";
import BlankVideoImage from "../../assets/images/videoGif/BlankVideoImage.png";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration ,getInwardPOconfiguration } from "../../store/Configuration/actions";
import POPrintWithoutApproval from "../../assets/images/videoGif/POPrintWithoutApproval.gif";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "../../assets/images/CloseIcon.png";
import { Link } from "react-router-dom";

class InwardPoConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      poprint: 0,
      poyes: false,
      pono: false,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
      autoId: "",
    };
  }

  handleConfig = () => {
    if (this.state.poprint ==0) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select PO Print before approval",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (!this.state.companyId) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry!! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: localStorage.getItem("companyIdconfig"),
        configList: [
          {
            autoId: this.state.autoId,
            configParameter: configNames.INWARD_PO__CONFIG,
            isActive: 1,
            configValue: this.state.poprint,
          },
        ],
      };
      if (this.state.companyId) {
        var msg = "Updated Successfully.";
        this.props.getInwardPOconfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry!!! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };
  onClickRadioButton = (selectedValue) => {
    var poprint = 0;
    var poyes = false,
      pono = false;
    if (selectedValue === "Yes") {
      poprint = 1;
      poyes = true;
      pono = false;

    } else if (selectedValue === "No") {
      poprint = 2;
      pono = true;
      poyes = false
    }
    this.setState({
      selectedValue: selectedValue,
      poprint: poprint,
      poyes: poyes,
      pono: pono,
    });
  };

  componentDidMount = () => {
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        var config= prop.configParameter
        if (config === configNames.INWARD_PO__CONFIG) {
          if (prop.configValue === "1") {
            this.setState({
              poprint: 1,
              poyes: true,
              pono: false,
              configdata: configdata,
              autoId: prop.autoId,
            });
          } else if (prop.configValue === "2") {
            this.setState({
              poprint: 2,
              pono: true,
              poyes: false,
              configdata: configdata,
              autoId: prop.autoId,
            });
          }
          this.setState({
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    } else {
      this.setState({
        autoId: null,
        poprint: 0,
        poyes: false,
        pono: false,
        configdata: [],
        companyId: localStorage.getItem("companyIdconfig"),
      });
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.configdata) {
      if (props.configdata.length !== 0) {
        props.configdata.forEach((prop) => {
          var config=prop.configParameter;

          if (config == configNames.INWARD_PO__CONFIG) {
            if (prop.configValue == 1) {
              this.setState({
                autoId: prop.autoId,
                poprint: 1,
                poyes: true,
                pono: false,
                configdata: props.configdata,
              });
            } else if (prop.configValue == 2) {
              this.setState({
                autoId: prop.autoId,
                poprint: 2,
                pono: true,
                poyes: false,
                configdata: props.configdata,
              });
            }
            this.setState({
              companyId: localStorage.getItem("companyIdconfig"),
            });
          } else{
            // console.log('else first',prop)
            this.setState({
              autoId: undefined,
              poprint: 0,
              poyes: false,
              pono: false,
              configdata: [],
              companyId: localStorage.getItem("companyIdconfig"),
            });
          }
        });
      } else {
        this.setState({
          autoId: undefined,
          poprint: 0,
          poyes: false,
          pono: false,
          configdata: [],
          companyId: localStorage.getItem("companyIdconfig"),
        });
      }
    } 
  };
  render() {
    return (
      <>
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        <div className="consoleFormContainer">
          <h5>Inward Without PO</h5>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="consoleFormBlock">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="poPrint"
                        checkednew={this.state.poyes}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="poPrint"
                        checkednew={this.state.pono}
                        onChange={this.onClickRadioButton}
                      />
                      
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>

            {/* <Grid item xs={6}>
              <div className="VideoGifContainer">
                <img
                  src={POPrintWithoutApproval}
                  alt=""
                  onClick={() => this.setState({ open: true })}
                />
              </div>
              <Dialog open={this.state.open} maxWidth="md">
                <img
                  src={CloseIcon}
                  className="closeButton"
                  alt=""
                  onClick={() => this.setState({ open: false })}
                />
                <img src={POPrintWithoutApproval} alt="indent" />
              </Dialog>
            </Grid> */}
          </Grid>
        </div>

        <div className="consoleFormButtonBlock">
          <Link to="/invoice">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companysList, companyscoulmns } = state.BasicDetails;
  const { configdatalist, configdata } = state.Configuration;
  return { companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
  setConfiguration,
  getInwardPOconfiguration
})(InwardPoConfig);