import {
  ROLE_MANAGEMENT,
  ROLE_MANAGEMENT_SUCCESS,
  ADD_ROLES,
  ADD_ROLES_SUCCESS,
  ROLE_TYPES_LIST,
  ROLE_TYPES_LIST_SUCCESS,
  USER_GROUPS,
  USER_GROUPS_SUCCESS,
  USER_GROUP_ROW,
  USER_GROUP_ROW_SUCCESS,
  USERS_LIST,
  USERS_LIST_SUCCESS,
  GET_PORTAL_LIST,
  GET_PORTAL_LIST_SUCCESS,
  GET_APP_ROLE_LIST,
  GET_APP_ROLE_SUCCESS,
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_SUCCESS,
  GET_BRANCH_LIST,
  GET_BRANCH_LIST_SUCCESS,
  CLIENT_LIST,
  CLIENT_LIST_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_ROLES,
  UPDATE_ROLES_SUCCESS,
  ADD_APPROVAL_HIERARCY,
  ADD_APPROVAL_HIERARCY_SUCCESS,
  USER_DETAILS_BYID,
  USER_DETAILS_BYID_SUCCESS,
  USER_MANAGEMENT_LINEITMS
  // ADD_ROLES,
  // ADD_ROLES_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  roletypelist: [],
  roleslist: [],
  usergrouplist: [],
  usergroupcolumns: [],
  rowdata: "",
  rolesSinglelist: [],
  userslist: [],
  userslistcolumns: [],
  portalList: [],
  clientList: [],
  appList: [],
  companyList: [],
  branchList: [],
  branchListColumnsData: [],
  addRole: [],
  addApproval: [],
  model: false,
  Update_Role_Management:[],
  userDetailsByIdData:{},
  userManagementLineItem:[
    {
    "id":0,
    "company": null,
    "branch": null,
    "branchList":[],
    "approle": null,
    "portalrole": null,
    "isEnable": 0
    }
],
};

const usermanagement = (state = initialState, action) => {
  switch (action.type) {
    case USER_GROUPS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case USER_GROUPS_SUCCESS:
      state = {
        ...state,
        usergrouplist: action.payload.response.data,
        usergroupcolumns: action.payload.response.column,
        loading: false,
      };
      break;
    case USERS_LIST:
      state = {
        ...state,
        loading: false,
      };
      break;
    case USERS_LIST_SUCCESS:
      state = {
        ...state,
        userslist: action.payload.response.data,
        userslistcolumns: action.payload.response.column,
        loading: false,
      };
      break;
    case USER_GROUP_ROW:
      state = {
        ...state,
        loading: false,
      };
      break;
    case USER_GROUP_ROW_SUCCESS:
      state = {
        ...state,
        rolesSinglelist: action.payload.response.data,
        loading: false,
      };
      break;
    case ROLE_TYPES_LIST:
      state = {
        ...state,
        loading: false,
      };
      break;
    case ROLE_TYPES_LIST_SUCCESS:
      state = {
        ...state,
        roletypelist: action.payload.response.data,
        loading: false,
      };

      break;
    case GET_PORTAL_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PORTAL_LIST_SUCCESS:
      state = {
        ...state,
        portalList: action.payload.response.data,
        loading: false,
      };
      break;
      case CLIENT_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CLIENT_LIST_SUCCESS:
      state = {
        ...state,
        clientList: action.payload.response.data,
        loading: false,
      };
      break;
    case GET_APP_ROLE_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_APP_ROLE_SUCCESS:
      state = {
        ...state,
        appList: action.payload.response.data,
        loading: false,
      };
      break;
    case GET_COMPANY_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_COMPANY_LIST_SUCCESS:
      state = {
        ...state,
        companyList: action.payload.response.data,
        loading: false,
      };
      break;

    case GET_BRANCH_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_BRANCH_LIST_SUCCESS:
      state = {
        ...state,
        branchList: action.payload.response.data,
        branchListColumnsData: action.payload.response.column,
        loading: false,
      };
      break;

    case ADD_ROLES:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_ROLES_SUCCESS:
      state = {
        ...state,
        addRole: action.payload.response.data,
        loading: false,
      };
      break;


      case UPDATE_ROLES:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case UPDATE_ROLES_SUCCESS:
        state = {
          ...state,
          Update_Role_Management: action.payload.response.data,
          loading: false,
        };
        break;

      case ADD_APPROVAL_HIERARCY:
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_APPROVAL_HIERARCY_SUCCESS:
      state = {
        ...state,
        addApproval: action.payload.response.data,
        loading: false,
      };
      break;
     
    case CREATE_USER:
      state = {
        ...state,
        loading: false
      }  
      break;

    case CREATE_USER_SUCCESS: 
     state = {
       ...state,
       message: action.payload,
       loading: false,
       model: false
     }  
     break;
     case USER_DETAILS_BYID:
      state = {
        ...state,
        loading: false
      }  
      break;

    case USER_DETAILS_BYID_SUCCESS: 
     state = {
       ...state,
       userDetailsByIdData: action.payload,
     } 
     break;
     case USER_MANAGEMENT_LINEITMS:
      state = {
        ...state,
        userManagementLineItem: action.payload
      }  
      break;

     case UPDATE_USER:
      state = {
        ...state,
        loading: false
      }  
      break;

    case UPDATE_USER_SUCCESS: 
     state = {
       ...state,
       message: action.payload,
       loading: false,
       model: false
     }  
     break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default usermanagement;
