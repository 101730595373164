import { takeEvery, fork, put, all, call } from "redux-saga/effects";
// Login Redux States
import {
  ROLE_TYPES_LIST,
  USER_GROUPS,
  USER_GROUP_ROW,
  USERS_LIST,
  GET_PORTAL_LIST,
  GET_APP_ROLE_LIST,
  GET_COMPANY_LIST,
  GET_BRANCH_LIST,
  ADD_ROLES,
  CLIENT_LIST,
  CREATE_USER,
  UPDATE_USER,
  ADD_APPROVAL_HIERARCY,
  UPDATE_ROLES,
  USER_DETAILS_BYID,
} from "./actionTypes";
import {
  getUserGroupRowsuccess,
  getUserGroups,
  roletypesuccess,
  usergroupsuccess,
  userslistsuccess,
  portalListsuccess,
  appListsuccess,
  companyListsuccess,
  branchListsuccess,
  addRoleTypsSuccess,
  clientListsuccess,
  userCreateSuccess,
  userUpdateSuccess,
  addApprovalSuccess,
  getRoleTyps,
  updateRoleTypsSuccess,
  userDetailsByIdSuccess,
  getbranchlist,
  userManagementLineItems
} from "./actions";
import { postAdd, getListurl, getList, get_API_data } from "../../helpers/Server_Helper";
import swal from "sweetalert";
import { getClient } from "../Company/BasicDetails/actions";
import { serverApi, showSpinner, hideSpinner } from "../../helpers/Consts";

function* getRoleTypes({ payload: { url, history, companyId } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url, {});
    yield put(roletypesuccess({ response }));
    // history.push('/Configuration');
    hideSpinner();
  } catch (error) {
    console.log(error);
  }
}
function* getUsersListData({ payload: { url, data, history } }) {
  showSpinner()
  try {
    const response = yield call(getList, url, data);
    yield put(userslistsuccess({ response }));
    hideSpinner()
    // history.push('/Configuration');
  } catch (error) {
    console.log(error);
  }
}

function* getAllUserGroups({ payload: { url, data,history } }) {
  try {
    const response = yield call(getList, url,data);
    yield put(usergroupsuccess({ response }));
    // history.push('/Configuration');
  } catch (error) {
    console.log(error);
  }
}

function* getUserGroupRowData({ payload: { url, history } }) {
  try {
    showSpinner();
    const response = yield call(getListurl, url, {});
    yield put(getUserGroupRowsuccess({ response }));
    if (response) {
      if (response.data) {
        if (response.data.roleDtl) {
          console.log("Nextss PropData", response.data.roleDtl.reqSource);
          var CustomerUrl =
            serverApi.GET_MENUS_BY_ROLE +
            response.data.roleDtl.reqSource +
            "/" +
            localStorage.getItem("companyIdconfig");
          yield put(getRoleTyps(CustomerUrl, history));
        }
      }
    }
    hideSpinner();
    // history.push({ pathname: "/create_role", rolemanagetype: 2 });
  } catch (error) {
    console.log(error);
  }
}

function* getMasterPortalList({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url, {});
    yield put(portalListsuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getMasterClientList({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url, {});
    yield put(clientListsuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getMasterAppList({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url, {});
    yield put(appListsuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getMasterCompanyList({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url, {});
    yield put(companyListsuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* getMasterBranchList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    response.data.sort((a, b) => (a.id < b.id) ? 1 : -1)
    
    yield put(branchListsuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

function* addRole({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(addRoleTypsSuccess({ response }));
    if (response) {
      if (response.status) {
        swal(response.message, { icon: "success" });
        history.push("/role_management");
      } else {
        swal(response.error, { icon: "error" });
      }
    }
  } catch (error) {
    console.log(error);
  }
}
function* UpdateRoleData({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(updateRoleTypsSuccess({ response }));
    if (response) {
      if (response.status) {
        swal(response.message, { icon: "success" });
        history.push("/role_management");
      }else{
        swal(response.error, { icon: "error" });
      }
    }

  } catch (error) {
    console.log(error);
  }
}

function* addApproval({ payload: { url, data, history, company, branch } }) {
  console.log("company Branch", company, branch);
  try {
    const response = yield call(getList, url, data);
    yield put(addApprovalSuccess({ response }));
    swal("Updated Successfully", { icon: "success" });
    history.push("/approve_hierachy");
    if (response) {
      var data1 = {
        branchId: branch,
        companyId: company,
      };
      var CustomerUrl = serverApi.GET_TASKS_LIST;
      yield put(getClient(CustomerUrl, data1, history));
    }
  } catch (error) {
    console.log(error);
  }
}

function* addUserCreate({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    if(response.status === true){
      swal(response.message, { icon: "success" });
    }else{
    swal(response.message, { icon: "error" });
    }
    yield put(userCreateSuccess({ response, data }));
    history.push("/UserManagement");
  } catch (error) {
    swal(error, { icon: "error" });

    console.log(error);
  }
}

function* addUserUpdate({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    swal(response.message, { icon: "success" });
    yield put(userUpdateSuccess({ response, data }));
    history.push("/UserManagement");
    // }
  } catch (error) {
    // console.log('response',error)
    // swal(error, { icon: "error" })
  }
}

// function* addUserUpdate({ payload: { url, data, history } }) {
//   try {
//     const response = yield call(getList, url, data);
//     if(response.status === true) {
//       swal("User Updated Successfully", { icon: "success" });
//     }
//     yield put(userUpdateSuccess({ response, data }));
//     history.push("/UserManagement");
//   } catch (error) {
//     console.log(error);
//     swal("Sorry! something went wrong", { icon: "error" });
//   }
// }

function* getUserDetailsById({ payload: { url } }) {
  try {
    const response = yield call(get_API_data, url);
    if (response.userManagementLineItem !== null) {
      yield put(userManagementLineItems(response.userManagementLineItem))
      yield all(
        response.userManagementLineItem.map((item) => {
          if(item.isEnable !== 0 && item.company === Number(localStorage.getItem("companyIdconfig"))){
              let data = {
                companyId: item.company,
                cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
                };
              return put(getbranchlist(serverApi.BRANCHMASTER_DATATABLE, data,));
        }
        })
      );
    }
    yield put(userDetailsByIdSuccess({ response }));
  } catch (error) {
    console.log(error);
  }
}

export function* watchMasterPortalListData() {
  yield takeEvery(GET_PORTAL_LIST, getMasterPortalList);
}
export function* watchMasterCientListData() {
  yield takeEvery(CLIENT_LIST, getMasterClientList);
}
export function* watchMasterAppListData() {
  yield takeEvery(GET_APP_ROLE_LIST, getMasterAppList);
}
export function* watchMasterCompanyListData() {
  yield takeEvery(GET_COMPANY_LIST, getMasterCompanyList);
}
export function* watchMasterBranchListData() {
  yield takeEvery(GET_BRANCH_LIST, getMasterBranchList);
}
export function* watchgetRoleTypesList() {
  yield takeEvery(ROLE_TYPES_LIST, getRoleTypes);
}
export function* watchgetUserGroups() {
  yield takeEvery(USER_GROUPS, getAllUserGroups);
}

export function* watchgetUserGroupRowData() {
  yield takeEvery(USER_GROUP_ROW, getUserGroupRowData);
}

export function* watchgetUsersList() {
  yield takeEvery(USERS_LIST, getUsersListData);
}

export function* watchaddRole() {
  yield takeEvery(ADD_ROLES, addRole);
}
export function* watchUpdateRole() {
  yield takeEvery(UPDATE_ROLES, UpdateRoleData);
}

export function* watchaddApproval() {
  yield takeEvery(ADD_APPROVAL_HIERARCY, addApproval);
}

export function* watchMasterUserCreate() {
  yield takeEvery(CREATE_USER, addUserCreate);
}

export function* watchMasterUserUpdate() {
  yield takeEvery(UPDATE_USER, addUserUpdate);
}
export function* watchUserDetails() {
  yield takeEvery(USER_DETAILS_BYID, getUserDetailsById);
}

function* ConfigurationSaga() {
  yield all([
    fork(watchgetRoleTypesList),
    fork(watchgetUserGroups),
    fork(watchgetUserGroupRowData),
    fork(watchgetUsersList),
    fork(watchMasterPortalListData),
    fork(watchMasterAppListData),
    fork(watchMasterCompanyListData),
    fork(watchMasterBranchListData),
    fork(watchaddRole),
    fork(watchUpdateRole),
    fork(watchMasterUserCreate),
    fork(watchMasterUserUpdate),
    fork(watchaddApproval),
    fork(watchUserDetails)
  ]);
}

export default ConfigurationSaga;
