import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import Link from "@mui/material/Link";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CustomButton from "../../../components/Buttons/Buttons";
import TextFieldNormal from '../../../components/TextField/TextFieldNormal'
import { serverApi } from "../../../helpers/Consts";
import { createDepartment, updateDepartment } from "../../../store/Master/DepartmentMaster/actions";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import moment from "moment";
import swal from "sweetalert";

class createDepartmentMaster extends Component {
  constructor() {
    super();
    this.state = {
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem('companyId'),
      recId: '',
      departmentCode: null,
      departmentName: '',
      createdBy: null,
      createdOn: "",
    };
  }
  /* Calling Create and Update Api's to Actions */
  onClickCreateUpdate = () => {
    const userId = this.state.userDit.userId;
    const { departmentName } = this.state;
    if (departmentName !== "" && departmentName !== undefined) {
      if (this.props.location.rowdata === undefined) {
        const data = {
          companyId: localStorage.getItem("companyIdconfig"), 
          departmentName: this.state.departmentName,
          createdBy: userId.toString(),
        };
        this.props.createDepartment(serverApi.CREATE_DEPARTMENT, data, this.props.history);  // Calling Create Department API
      }
      else {
        const dateEntered = this.props.location.rowdata.createdDate;
        const dateIsValid = moment(dateEntered).format("LLLL");
        const data = {
          cipher: this.state.userDit.cipher,
          companyId: localStorage.getItem("companyIdconfig"),
          departmentName: this.state.departmentName,
          id: this.state.departmentCode,
          orgId: this.state.userDit.orgId.toString(),
          recId: this.state.recId,
          updatedBy: this.state.userDit.userId.toString(),
          createdDate: this.props.location.rowdata.createdDate,
          createdBy: dateIsValid,
        }
        this.props.updateDepartment(serverApi.UPDATE_DEPARTMENT, data, this.props.history);  // Calling Update Department API
      }
    } else {
      // alert("Please select mandatory fields")
      swal("Please Enter the Department Name  !!!")
      this.setState({
        confirm: true,
      });
    }
  }
  // Function for Previous Page
  onClickCancel = () => {
    this.props.history.push("/department_master");
  };
  // Function For TextFiled
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    // if (key === "departmentName") {
    //   this.setState({
    //     departmentName: event,
    //   });
    // }
    if (key === "departmentName") {
      // const dept = event.replace(/[^a-zA-Z\s]+/g, '');
      this.setState({
        departmentName: event,
      });
    }
  };
  // Function For Displaying Common Popup
  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  componentDidMount() {
    if (this.props.location.rowdata) {
      this.setState({
        companyId: this.props.location.rowdata.companyId,
        departmentCode: this.props.location.rowdata.id,
        departmentName: this.props.location.rowdata.departmentName,
        createdBy: this.props.location.rowdata.createdBy,
        recId: this.props.location.rowdata.recId,
        createdOn: this.props.location.rowdata.createdDate,
      });
      const dateEntered = this.props.location.rowdata.createdDate;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdOn: dateIsValid
      })
    }
  }
  render() {
    return (
      <div>
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <>
              <div className="departmentMasterContainer">
                <div className="consoleFormContainer">
                  <h5>Fill Details</h5>
                  <div className="consoleFormBlock">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box
                          display="grid"
                          gridTemplateColumns="repeat(12, 1fr)"
                          gap={2}
                        >

                          {this.props.location.rowdata === undefined ? (
                            ""
                          ) : (
                            <><Box gridColumn="span 3">
                              <div className="consoleTextFieldBlock">
                                <TextFieldNormal
                                placeholder="Enter here"
                                  type="number"
                                  value={this.state.departmentCode}
                                  readOnly={this.props.location.rowdata !== undefined ? true : false}
                                  caps="OFF"
                                  minValue="0"
                                  onChange={this.onhandlechangeValue("departmentCode")}
                                  label="Department Code" />
                              </div>
                            </Box>
                            </>
                          )}
                          <Box gridColumn="span 3">
                            <div className="consoleTextFieldBlock">
                              <TextFieldReq
                                label="Department Name"
                                value={this.state.departmentName}
                                caps="OFF"
                                onChange={this.onhandlechangeValue("departmentName")}
                              />
                            </div>
                          </Box>
                          
                        </Box>
                        {this.props.location.rowdata === undefined ? (
                            ""
                          ) : (
                            <>
                              <Box gridColumn="span 6" style={{marginTop:"20px"}}>
                                <div className="consoleUpdateTextBlock">
                                  <div>
                                    <span className="taxLabel">Created By : <b>{this.state.createdBy}</b></span>
                                  </div>
                                  <div>
                                    <span className="taxLabel">Created On :<b> {this.state.createdOn}</b></span>
                                  </div>
                                </div>
                              </Box>
                            </>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className="consoleFormButtonBlock">
                <Link>
                  <CustomButton
                    label={"Cancel"}
                    className="greenBorderButton"
                    handleClick={this.onClickCancel}
                  />
                </Link>
                {this.props.location.rowdata !== undefined ? (
                  <CustomButton
                    label={"Update"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                ) : (
                  <CustomButton
                    label={"Create"}
                    className="greenButton"
                    handleClick={this.onClickCreateUpdate}
                    type="sumbit"
                  />
                )}

                {/* Popup For Validation */}
                {this.state.confirm && (
                  ""
                )}
              </div>
            </>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { Create_Department, Update_Department } = state.DepartmentReducer;
  return { Create_Department, Update_Department };
};

export default withRouter(
  connect(mapStatetoProps, {
    createDepartment,
    updateDepartment
  })(createDepartmentMaster)
);
