import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { serverApi } from "../../../helpers/Consts";
import { getSupplierTypeMasterList } from "../../../store/Master/SupplierTypeMaster/actions";
import GridView from "../../../components/Grid/GridView";
import GridViewSearch from "../../../components/Grid/GridViewSerch";


class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: 0,
            userDit: JSON.parse(localStorage.getItem("authUser")),
            mdata: [],
            mcolumns: [],
            cipher:"",
		   startIndex:"",
		   lastIndex:""
        };
    }
    componentDidMount() {
        const data = {
            cipher: this.state.userDit.cipher,
            companyId: localStorage.getItem("companyIdconfig"),
            startIndex:this.state.startIndex,
		   lastIndex:this.state.lastIndex
        }
        console.log("suppliertype",data);
        this.props.getSupplierTypeMasterList(serverApi.SUPPLIER_TYPES_LIST_API, data, this.props.history); 
    }
    componentWillReceiveProps(props) {
        if (props.SupplierTypesList) {
            console.log("props.SupplierTypesList", props.SupplierTypesList)
            if (props.SupplierTypesList.data) {
                this.setState({
                    mdata: props.SupplierTypesList.data
                })
            }
            if (props.SupplierTypesList.column) {
                let list = []
                props.SupplierTypesList.column.map((props) => {
                    list.push({
                        filterable: props.filterable,
                        accessor: props.accessorName,
                        Header: props.headerName,
                        hashMap2: {
                            textAlign: "center"
                        },
                        minWidth: 150,
                        sortable: "true"
                    },)
                })
                console.log(list)
                this.setState({
                    mcolumns: list

                })
            }
        }

    }
    render() {
        console.log("getting the response for taxList", this.state.mdata, this.state.mcolumns)
        // const mdata = this.props.SupplierTypesList ? this.props.SupplierTypesList.data : "";
        // const mcolumns = this.props.SupplierTypesList ? this.props.SupplierTypesList.column : "";
        return (
            // <></>
            <GridViewSearch mdata={this.state.mdata} mcolumns={mcolumns} pathname="/create_supplier_types" />
        )
    }
}

const mcolumns = [
    // {
    //     Header: "suppTypeId",
    //     accessor: "suppTypeId",
    //     hidden: false,
    //   },

     {
        Header: "Supplier Type Code",
        // accessor: "suppTypeCode",
        dataField:"suppTypeCode",
        hidden: false,
      },
      
      {
        Header: "Supplier Type Description",
        // accessor: "suppTypeDesc",
        dataField:"suppTypeDesc",
        hidden: false,
      },
     
    //   {
    //     Header: "isActive",
    //     accessor: "isActive",
    //     hidden: false,
    //   },
    // {
    //     "filterable": "true",
    //     "accessorName": "suppTypeId",
    //     "headerName": "suppTypeId",
    //     "hashMap2": {
    //         "textAlign": "center"
    //     },
    //     "minWidth": 150,
    //     "sortable": "true"
    // },
    // {
    //     "filterable": "true",
    //     "accessorName": "suppTypeDesc",
    //     "headerName": "suppTypeDesc",
    //     "hashMap2": {
    //         "textAlign": "left"
    //     },
    //     "minWidth": 200,
    //     "sortable": "true"
    // },
    // {
    //     "filterable": "true",
    //     "accessorName": "suppTypeCode",
    //     "headerName": "suppTypeCode",
    //     "hashMap2": {
    //         "textAlign": "left"
    //     },
    //     "minWidth": 200,
    //     "sortable": "true"
    // }
]

const mapStatetoProps = (state) => {
    const { SupplierTypesList } = state.SupplierTypesReducer;
    return { SupplierTypesList };
};

export default withRouter(
    connect(mapStatetoProps, {
        getSupplierTypeMasterList,
    })(Index)
);
