//import { Switch } from "react-router-dom";

import {
  DELETEFILE,
    DELETEFILE_SUCCESSFULL,
    UPLOADFILE,
    UPLOADFILE_SUCCESSFULL,
  } from "./actionTypes";
  
  const initialState = {
    error: "sorry no data found",
    message: null,
    loading: false,
    Upload_File_List:[],
    delete_File:[],
    error_msg: null,
  };
  
  const UploadFileReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPLOADFILE:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case UPLOADFILE_SUCCESSFULL:
        state = {
          ...state,
          Upload_File_List: action.payload.response,
          loading: false,
        };
        break;
        case DELETEFILE:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case DELETEFILE_SUCCESSFULL:
        state = {
          ...state,
          delete_File: action.payload.response,
          loading: false,
        };
        break;
        
      default:
        state = { ...state };
        break;
    }
  
    return state;
  };
  
  export default UploadFileReducer;
  