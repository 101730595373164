import {
  OFFICIAL_API_ERRORS,
  OFFICIALDETAILS,
  OFFICIALDETAILS_ADD,
  OFFICIALDETAILS_ADD_SUCCESSFUL,
  GET_ACCTYPES_LIST,
  ACCTYPES_LIST_SUCCESS,
  COMPANY_DIT,
  COMPANY_DIT_SUCCESS,
  BASIC_DETAILS_DATA,
  FILE_UPLOAD_LIST,
  FILE_UPLOAD_LIST_SECCESSFULL,
  UPLOADTANFILE,
  UPLOADTANFILE_SUCCESSFULL,
  UPLOADTANFILESLIST,
  UPLOADTANFILESLIST_SUCCESSFULL,
  UPLOADCINFILE,
  UPLOADCINFILE_SUCCESSFULL,
  UPLOADCINFILESLIST,
  UPLOADCINFILESLIST_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  accounttypeslist: [],
  basicDetails: [],
  model: false,
  error_msg: null,
  rowdata: null,
  files_list: [],
  tanUploadFilesList: [],
  gettanUploadFilesList:[],
  cinUploadFilesList: [],
  getcinUploadFilesList:[],
};

const officialdit = (state = initialState, action) => {
  switch (action.type) {
    case OFFICIALDETAILS_ADD:
      state = {
        ...state,
        loading: false,
      };
      break;
    case OFFICIALDETAILS_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case OFFICIAL_API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    case GET_ACCTYPES_LIST:
      state = {
        ...state,
        loading: true
      }
      break;
    case ACCTYPES_LIST_SUCCESS:
      state = {
        ...state,
        accounttypeslist: action.payload.response.data,
        loading: false
      }
      break;
    case COMPANY_DIT:
      state = {
        ...state,
        loading: true
      }
      break;
    case COMPANY_DIT_SUCCESS:
      state = {
        ...state,
        rowdata: action.payload.response.data,
        loading: false
      }
      break;
    case BASIC_DETAILS_DATA:
      console.log("getting the payload from basic details", action.payload)
      state = {
        ...state,
        basicDetails: action.payload,
        loading: false
      }
      break;
    case FILE_UPLOAD_LIST:
      state = {
        ...state,
        loading: true
      }
      break;
    case FILE_UPLOAD_LIST_SECCESSFULL:
      state = {
        ...state,
        files_list: action.payload.response.data,
        loading: false
      }
      break;
      //tan files
    case UPLOADTANFILE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOADTANFILE_SUCCESSFULL:
      state = {
        ...state,
        tanUploadFilesList: action.payload.response,
        loading: false,
      };
      break;
    case UPLOADTANFILESLIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPLOADTANFILESLIST_SUCCESSFULL:
      state = {
        ...state,
        gettanUploadFilesList: action.payload.response,
        loading: false,
      };
      break;
      //cin files
      case UPLOADCINFILE:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case UPLOADCINFILE_SUCCESSFULL:
        state = {
          ...state,
          cinUploadFilesList: action.payload.response,
          loading: false,
        };
        break;
      case UPLOADCINFILESLIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case UPLOADCINFILESLIST_SUCCESSFULL:
        state = {
          ...state,
          getcinUploadFilesList: action.payload.response,
          loading: false,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default officialdit;
