import {GET_LEAVE_POLICY_LIST, GET_LEAVE_POLICY_LIST_SUCCESSFULL,
     GET_LEAVE_SELECTION_LIST, GET_LEAVE_SELECTION_LIST_SUCCESSFULL,
    CREATE_LEAVE_POLICY, CREATE_LEAVE_POLICY_SUCCESSFULL, LEAVE_POLICY_VIEW_BY_ID_LIST, LEAVE_POLICY_VIEW_BY_ID_LIST_SUCCESSFULL
    } from "./actionTypes";
export const getLeavePolicyListAll = (url,history) =>{
    console.log("getting the api here", url)
    return {
        type:GET_LEAVE_POLICY_LIST,
        payload:{url,history}
    }
}
 export const getLeavePolicyListSuccessfull = (data)=>{
        return {
            type:GET_LEAVE_POLICY_LIST_SUCCESSFULL,
            payload:data
        }
}
export const getLeaveSelectionList = (url,history) =>{
    console.log("getting the api here", url)
    return {
        type: GET_LEAVE_SELECTION_LIST,
        payload:{url,history}
    }
}
 export const getLeaveSelectionListSuccessfull = (data)=>{
        return {
            type: GET_LEAVE_SELECTION_LIST_SUCCESSFULL,
            payload:data
        }
}

export const createLeavePolicy = (url,data,history) =>{
    console.log("getting the api here", url)
    return {
        type: CREATE_LEAVE_POLICY,
        payload:{url,data,history}
    }
}
 export const createLeavePolicySuccessfull = (data)=>{
        return {
            type: CREATE_LEAVE_POLICY_SUCCESSFULL,
            payload:data
        }
}

export const leavePolicyViewById = (url,history) =>{
    return {
        type: LEAVE_POLICY_VIEW_BY_ID_LIST,
        payload:{url,history}
    }
}
 export const leavePolicyViewByIdSuccessfull = (data)=>{
    console.log("getting the api heresssss", data)

    return {
        type: LEAVE_POLICY_VIEW_BY_ID_LIST_SUCCESSFULL,
        payload:data
    }
}


