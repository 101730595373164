/* 
  Nagesh Medisetty On 01-01-2022 
*/
import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Logout from "../../assets/images/logout.png";
import ProfileArrowIcon from "../../assets/images/profileHeaderArrowIcon.png";
import CustomButton from "../../components/Buttons/Buttons";
import {getTenantId} from '../../helpers/Consts'
// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class DashboardHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorElUser: false, anchorElNav: false };
  }

  handleOpenUserMenu = (event) => {
    this.setState({
      anchorElNav: true,
      anchorElUser: true,
    });
  };
  handleCloseUserMenu = () => {
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };
  handleClick = (value) => () => {
    if (value == 1) {
      this.props.history.push("/CreatePassword");
    }else if(value ==2){
      window.open(`https://${getTenantId(window.location.hostname)}/`)

    }
    this.setState({
      anchorElNav: false,
      anchorElUser: false,
    });
  };

  handleLogout = () => {
    this.props.history.push("/Logout");
  };

  render() {
    var data = [
      {
        menuId: 1,
        menuName: "Change Password",
        link: "ResetPassword",
      },
      {
        menuId: 2,
        menuName: "Login Portal",
        link: "",
      },
    ];
    let userDit = JSON.parse(localStorage.getItem("authUser"));
    return (
      <AppBar position="static" className="dashboardHeaderContainer">
        {/* <Container maxWidth="xl"> */}
        <Toolbar disableGutters>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <h3 className="consoleHeadingText">
                Dashboard - Organisation Name {this.state.anchorElUser}
              </h3>
            </Grid>
            <Grid item xs={6} className="headerRightBlock">
              <Box className="headerRightContent">
                {/* <Link href="/CompanyCreation" className="dashBoardButton">
                    {"+ Create Company"}
                  </Link> */}
                <Link href="/Billing">
                  <CustomButton
                    className="greenButton"
                    label="Billing Details"
                  />
                </Link>      
                <Box  sx={{flexGrow:0}}>         
                <Tooltip title="Profile settings">
                  <IconButton onClick={this.handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="" />
                    <img
                      src={ProfileArrowIcon}
                      alt=""
                      className="profileArrowIcon"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={this.state.anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(this.state.anchorElUser)}
                  onClose={this.handleCloseUserMenu}
                >
                  <div className="profileContainer">
                    <Grid container className="profileHeader">
                      <Grid item xs={2}>
                        <Avatar alt="Remy Sharp" src="" />
                      </Grid>
                      <Grid item xs={10}>
                        <Grid item xs={12}>
                          <h3>{userDit.userName}</h3>
                        </Grid>
                        <Grid item xs={12}>
                          <h6>{userDit.email}</h6>
                        </Grid>
                      </Grid>
                    </Grid>
                    {data.map((menu) => (
                      <MenuItem
                        key={menu.menuId}
                        className="profileLinksBlock"
                        onClick={this.handleClick(menu.menuId)}
                      >
                        <Typography textAlign="center" name={menu.menuName}>
                          {menu.menuName}
                        </Typography>
                      </MenuItem>
                    ))}
                    <Grid container className="profileFooter">
                      <Grid item xs={12}>
                        <MenuItem className="profileLinksBlock"  onClick={this.handleLogout}>
                          <Typography textAlign="center">
                            <img src={Logout} alt="" />
                            <Link href="/" className="">
                              Logout
                            </Link>
                          </Typography>
                        </MenuItem>
                      </Grid>
                    </Grid>
                  </div>
                </Menu>
                </Box> 
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
    );
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(connect(mapStatetoProps, {})(DashboardHeader));
