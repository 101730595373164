export const OFFICIALDETAILS = 'officialdetails';
export const OFFICIALDETAILS_ADD = 'officialdetails_add';
export const OFFICIALDETAILS_ADD_SUCCESSFUL = 'officialdetails_add_successfull';

export const OFFICIAL_API_ERRORS = 'official_api_failed';
export const CLEAR_FORM = 'official_clear_from';
export const GET_ACCTYPES_LIST = 'get_acctypes_list';
export const ACCTYPES_LIST_SUCCESS = 'acctypes_list_success';
export const COMPANY_DIT = "company_dit";
export const COMPANY_DIT_SUCCESS = "company_dit_success";
export const BASIC_DETAILS_DATA = "basic_details_data";

export const FILE_UPLOAD_LIST = "file_upload_list";
export const FILE_UPLOAD_LIST_SECCESSFULL = "file_upload_list_seccessfull";

export const UPLOADTANFILE = "upload_tan_file";
export const UPLOADTANFILE_SUCCESSFULL = "upload_tan_file_successfull";

export const UPLOADTANFILESLIST = "upload_tan_filesList";
export const UPLOADTANFILESLIST_SUCCESSFULL = "upload_tan_filesList_successfull";

export const UPLOADCINFILE = "upload_cin_file";
export const UPLOADCINFILE_SUCCESSFULL = "upload_cin_file_successfull";


export const UPLOADCINFILESLIST = "upload_cin_filesList";
export const UPLOADCINFILESLIST_SUCCESSFULL = "upload_cin_filesList_successfull";
