import React, { Component } from "react";
import { Grid, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import {
  branchCreationAdd,
  getMasterStates,
} from "../../store/Company/BranchCreation/actions";
import {
  userCreate,
  userUpdate,
  userDetailsById,
} from "../../store/UserManagement/actions";
import { serverApi } from "../../helpers/Consts";
import NewDynamicSelect from "../../components/Dropdown/NewDynamicSelect";
import TextFieldReq from "../../components/TextField/TextFieldReq";
import TextFieldMobileReq from "../../components/TextField/TextFieldMobileReq";
import AutoMultiselect from "../../components/Dropdown/MultiSelectionDropDown";
import NormalTextField from "../../components/TextField/NormalTextField";
import swal from "sweetalert";
import {
  getportallist,
  getapplist,
  getcompanylist,
  getbranchlist,
  getUsersList,
} from "../../store/UserManagement/actions";
import { withRouter } from "react-router-dom";
import Checkbox from "../../components/CheckBox/Checkbox";
import { Link } from "react-router-dom";

class UserCreate3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: null,
      selectedValue: "Nothing selected",
      selectedId: 0,
      buttonbranch: 0,
      count1: 1,
      rowCount: 1,
      username: "",
      status: "",
      profileName: "",
      contactno: "",
      block: "block",
      none: "none",
      email: "",
      company: "",
      list: [],
      users: "",
      role: null,
      userLineItem: [],
      userslist: [],
      BranchList: [],
      BranchListData: [],
      CompanyList: [],
      PortalList: [],
      AppList: [],
      showMandatoryField: false,
      ShowInValidText: false,
      mappedUserId: "",
      criteria: [
        {
          id: 0,
          approle: null,
          branch: [],
          company: localStorage.getItem("companyIdconfig"),
          portalrole: null,
          isEnable: 1,
        },
      ],
      showPopUp: false,
    };
  }
  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "email") {
      if (event === "") {
        this.setState({
          showMandatoryField: true,
          ShowInValidText: false,
        });
      } else {
        this.setState({
          showMandatoryField: false,
        });
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(event)) {
          this.setState({
            ShowInValidText: false,
          });
        } else {
          this.setState({
            ShowInValidText: true,
          });
        }
      }
    }
  };
  handleAddEvent = (evt) => {
    let rowId = this.state.rowCount;
    let product = {
      id: 0,
      approle: null,
      branch: [],
      company: localStorage.getItem("companyIdconfig"),
      portalrole: null,
      isEnable: 1,
    };
    this.setState({ rowCount: rowId + 1 });
    this.state.criteria.push(product);
  };
  handleSelectChange = (selectedValue, selectedName, name, selectedRow) => {
    let lastVal = selectedRow.id;
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow = this.state.criteria[this.state.criteria.length - 1];
    if (name === "company") {
      var data = {
        companyId: selectedValue,
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      };
      this.props.getbranchlist(
        serverApi.BRANCHMASTER_DATATABLE,
        data,
        this.props.history
      );
      var filteredproducts = this.state.criteria.filter(
        (item) => item.isEnable !== 0
      );
      let check = !!filteredproducts.find(
        ({ company }) => company === selectedValue
      );
      let companyId = localStorage.getItem("companyIdconfig");
      if (Number(companyId) === selectedValue) {
        swal("You cont't select the same company");
      } else {
        // selectedRow.company = selectedValue;
        this.setState({
          mappedCompanyId: selectedValue,
        });
        let data2 = {
          comId: selectedValue,
          cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
        };
        this.props.getUsersList(
          serverApi.GET_ALL_USERS_LIST,
          data2,
          this.props.history
        );
        if (selectedRow === lastRow && lastVal === 0 && selectedValue !== "0") {
          this.handleAddEvent();
        }
      }
    }
    if (name === "userName") {
      this.setState({
        mappedUserId: selectedValue,
      });
    }
    if (name === "portalrole") {
      selectedRow.portalrole = selectedValue;
    }
    if (name === "approle") {
      selectedRow.approle = selectedValue;
    }
  };

  getMopt = (selectedValue, name, selectedRow) => {
    selectedRow.branch = selectedValue;
  };

  handleValidSubmit = (event) => {
    var List = this.state.criteria.filter((item) => {
      if (
        item.company !== "" &&
        item.company !== undefined &&
        item.company !== null
        // && item.isEnable !== 0
      ) {
        return item;
      }
    });
    var LineItems = [];
    List.map((data) => {
      var list = [];
      data.branch.map((p) => {
        list.push(p.id);
      });
      var obj = {
        approle: data.approle,
        branch: list,
        company: data.company,
        portalrole: data.portalrole,
        isEnable: data.isEnable,
      };
      LineItems.push(obj);
    });
    if (this.state.username === "") {
      swal("Please Enter the User Name");
      return false;
    }
    if (this.state.profileName=== "") {
      swal("Please Enter Profile Name");
      return false;
    }
    if (this.state.contactno === "") {
      swal("Please Enter the Contact Number");
      return false;
    }
    if (this.state.criteria[0].approle === null && this.state.criteria[0].portalrole === null) {
      swal("App Role or Portal Role is mandatory Select At least one and Proceed");
      return false;
    }

    const data = {
      userManagementHeader: {
        createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
        orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
        username: this.state.username,
        contactno: this.state.contactno,
        email: this.state.email,
      },
      userManagementLineItem: LineItems,
    };
    this.props.userCreate(serverApi.ADD_USER_MAP_GRP, data, this.props.history);
  };

  handleUpdate = () => {
    if (this.props.location.rowdata !== undefined) {
      var List = this.state.criteria.filter((item) => {
        if (
          item.company !== "" &&
          item.company !== undefined &&
          item.company !== null
          // && item.isEnable !== 0
        ) {
          return item;
        }
      });
      var LineItems = [];
      List.map((data) => {
        var list = [];
        if (data.branch) {
          if (data.branch.length !== 0) {
            data.branch.map((p) => {
              list.push(p.id);
            });
          }
        }
        var obj = {
          approle: data.approle,
          branch: list,
          company: data.company,
          portalrole: data.portalrole,
          isEnable: data.isEnable,
        };
        LineItems.push(obj);
      });

      if (this.state.username === "") {
        swal("Please Enter the User Name !!!");
        return false;
      }
      if (this.state.contactno === "") {
        swal("Please Enter the Contact Number !!!");
        return false;
      }

      const data = {
        userManagementHeader: {
          createdBy: JSON.parse(localStorage.getItem("authUser")).userId,
          orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
          username: this.state.username,
          contactno: this.state.contactno,
          email: this.state.email,
          id: this.props.location.rowdata.userId,
        },
        userManagementLineItem: LineItems,
      };
      this.props.userUpdate(
        serverApi.UPDATE_USER + "/1",
        data,
        this.props.history
      );
    }
  };
  onDel = (data) => (event) => {
    const { key, row, del } = data;
    if (this.state.criteria.length == 1) {
      swal("Can't be removed");
    } else {
      var indexitem = this.state.criteria.findIndex((item) => item == row);
      var lineItems = this.state.criteria;
      lineItems[indexitem].isEnable = 0;
      this.setState({
        criteria: lineItems,
      });
      // let lineItems = this.state.criteria.filter((item) => item != row);
      // this.setState({
      //   criteria: lineItems,
      // });
    }
  };
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    let userDit = JSON.parse(localStorage.getItem("authUser"));
    this.props.getportallist(
      serverApi.GET_ROLES_LIST_BY_TYPE +
        "0" +
        "/" +
        localStorage.getItem("companyIdconfig") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher
    );
    this.props.getapplist(
      serverApi.GET_ROLES_LIST_BY_TYPE +
        "1" +
        "/" +
        localStorage.getItem("companyIdconfig") +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher
    );

    //company list api call
    this.props.getcompanylist(
      serverApi.COMPANY_LIST_BY_ORG_ID +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).orgId +
        "/" +
        JSON.parse(localStorage.getItem("authUser")).cipher
    );
    if (this.props.location.rowdata !== undefined) {
      this.props.userDetailsById(
        serverApi.GET_USER_DETAILS_BYID +
          localStorage.getItem("companyIdconfig") +
          "/" +
          this.props.location.rowdata.userId
      );
    }

    // var orgId = userDit.orgId;
    // var data2 = { comId: orgId, cipher: "" };
    // this.props.getUsersList(
    //   serverApi.GET_ALL_USERS_LIST,
    //   data2,
    //   this.props.history
    // );

    // if (this.props.location.rowdata !== undefined) {
    //   this.setState({
    //     createdBy: this.props.location.rowdata.userId,
    //     orgId: this.props.location.rowdata.companyId,
    //     username: this.props.location.rowdata.userName,
    //     contactno: this.props.location.rowdata.mobile,
    //     email: this.props.location.rowdata.email,
    //     criteria: this.props.location.rowdata.userManagementLineItem,
    //     userId: this.props.location.rowdata.userId,
    //   });
    //   console.log(
    //     "RowData",
    //     this.props.location
    //   );
    //   let productData= []
    //   if(this.props.location.rowdata.userManagementLineItem !==null){
    //     productData = this.props.location.rowdata.userManagementLineItem.map(
    //     (value) => {
    //       return {
    //         approle: value.approle,
    //         branch: value.branch,
    //         company: value.company,
    //         portalrole: value.portalrole,
    //         isEnable: value.isEnable,
    //       };
    //     }
    //   );
    //   }
    // productData.push({
    //     id: 0,
    //     approle: null,
    //     branch: [],
    //     company: null,
    //     portalrole: null,
    //     isEnable: 1,
    //   });
    //   this.setState({
    //     criteria: productData,
    //   });
    //   if(this.props.location.rowdata.userManagementLineItem !==null){

    //   this.props.location.rowdata.userManagementLineItem.map((item) => {
    //     var data = {
    //       companyId: item.company,
    //       cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    //     };
    //     this.props.getbranchlist(
    //       serverApi.BRANCHMASTER_DATATABLE,
    //       data,
    //       this.props.history
    //     );
    //   });
    // }
    // }
  }

  componentWillReceiveProps(props) {
    if (props.userslist) {
      var list = [];
      props.userslist.map((prop, ind) => {
        var obj = {
          title: prop.userName,
          value: prop.userId,
          label: prop.userName,
        };
        list.push(obj);
      });
      this.setState({
        userslist: list,
      });
    }
    if (props.branchList) {
      this.setState({
        BranchList: props.branchList,
      });
    }

    if (props.companyList) {
      const lists = [];
      props.companyList.map((prop) => {
        lists.push({
          name: prop.name,
          value: prop.value,
          label: prop.label,
        });
      });
      this.setState({
        CompanyList: lists,
      });
    }
    
    if (props.portalList) {
      let portalList = props.portalList.slice(); // Clone the array to avoid mutating props directly
      let newObj = {
        value: "",
        name: "Select",
        label: "Select",
      };
      portalList.unshift(newObj); // Use push to add newObj to appRoleList
      this.setState({
        PortalList: portalList, // Update state with the modified appRoleList
      });
    }
    if (props.appList) {
      let appRoleList = props.appList.slice(); // Clone the array to avoid mutating props directly
      let newObj = {
        value: "",
        name: "Select",
        label: "Select",
      };
      appRoleList.unshift(newObj); // Use push to add newObj to appRoleList
      this.setState({
        AppList: appRoleList, // Update state with the modified appRoleList
      });
    }

    if (props.location.rowdata !== undefined) {
      if (props.userDetailsByIdData) {
        if (props.userDetailsByIdData.response !== undefined) {
          this.setState({
            createdBy: props.userDetailsByIdData.response.userId,
            orgId: props.userDetailsByIdData.response.companyId,
            username: props.userDetailsByIdData.response.userName,
            profileName: props.userDetailsByIdData.response.profileName,
            contactno: props.userDetailsByIdData.response.mobile,
            email: props.userDetailsByIdData.response.email,
            userId: props.userDetailsByIdData.response.userId,
            status: props.userDetailsByIdData.response.status,
          });
          let userManagementLineItem = [];
          if (props.userManagementLineItem) {
            userManagementLineItem = props.userManagementLineItem;
          }
          let productData = [];
          if (userManagementLineItem !== null) {
            productData = userManagementLineItem.map((value) => {
              let arrayContainsNull = false;
              if (value.branch !== null) {
                arrayContainsNull = value.branch.some((item) => item === null);
              }
              return {
                approle: value.approle,
                branch: arrayContainsNull ? [] : value.branch,
                company: value.company,
                portalrole: value.portalrole,
                isEnable: value.isEnable,
              };
            });
          }
          productData.push({
            id: 0,
            approle: null,
            branch: [],
            company: null,
            portalrole: null,
            isEnable: 1,
          });
          this.setState({
            criteria: productData,
          });
        }
      }
    }
  }

  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen: true,
    });
  };
  componentDidUpdate(prevProps) {
    // Check if userDetailsByIdData props has changed
    if (prevProps.userDetailsByIdData !== this.props.userDetailsByIdData) {
      // If props have changed, update state accordingly
      this.setUserDetails();
    }
  }

  showPopUp = (type) => {
    let isShowPopUp = this.state.showPopUp;

    if (type === "mapUser") {
      if (this.state.mappedUserId !== "" && this.state.mappedUserId !== "") {
        this.props.userDetailsById(
          serverApi.GET_USER_DETAILS_BYID +
            localStorage.getItem("companyIdconfig") +
            "/" +
            this.state.mappedUserId
        );
        this.setState({
          showPopUp: !isShowPopUp,
        });
      } else {
        swal("Please Select the Mandatory Fields");
      }
    } else {
      this.setState({
        mappedCompanyId: "",
        mappedUserId: "",
        showPopUp: !isShowPopUp,
      });
    }
  };

  spanStyles = {
    color: "red",
    float: "right",
    cursor: "pointer",
    fontWeight: 400,
  };

  setUserDetails = () => {
    if (this.props.userDetailsByIdData) {
      if (this.props.userDetailsByIdData.response !== undefined) {
        this.setState({
          createdBy: this.props.userDetailsByIdData.response.userId,
          orgId: this.props.userDetailsByIdData.response.companyId,
          username: this.props.userDetailsByIdData.response.userName,
          profileName: this.props.userDetailsByIdData.response.profileName,
          contactno: this.props.userDetailsByIdData.response.mobile,
          email: this.props.userDetailsByIdData.response.email,
          userId: this.props.userDetailsByIdData.response.userId,
          status: this.props.userDetailsByIdData.response.status,
        });
        let userManagementLineItem = [];
        if (this.props.userManagementLineItem) {
          userManagementLineItem = this.props.userManagementLineItem;
        }
        let productData = [];
        let branchList = [];
        if (userManagementLineItem !== null) {
          productData = userManagementLineItem.map((value) => {
            if (
              value.branch !== null &&
              value.company === Number(localStorage.getItem("companyIdconfig"))
            ) {
              branchList = value.branch;
            }
            return {
              approle: null,
              branch: branchList,
              company: value.company,
              portalrole: null,
              isEnable: value.isEnable,
            };
          });
        }
        productData.push({
          id: 0,
          approle: null,
          branch: [],
          company: null,
          portalrole: null,
          isEnable: 1,
        });
        this.setState({
          criteria: productData,
        });
      }
    }
  };
  render() {
    var filteredproducts = this.state.criteria.filter(
      (item) => item.isEnable !== 0
    );
    if (this.props.location.rowdata !== undefined) {
      var NewList = [];
      this.state.criteria.map((value) => {
        if (value.branch) {
          if (value.branch !== 0) {
            value.branch.map((val) => {
              this.state.BranchList.forEach((branch) => {
                if (val == branch.id) {
                  if (branch !== null) {
                    NewList.push({
                      id: branch.id,
                      title: branch.brname,
                      label: branch.brname,
                    });
                    value.branch = NewList;
                  }
                }
              });
            });
          }
        }
      });
    }
    return (
      <>
        <div className="consoleFormContainer">
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.username}
                        type="text"
                        label="User Name"
                        placeholder="Enter User Name..."
                        onChange={this.handleChange("username")}
                      />
                    </div>
                    {this.props.location.rowdata === undefined && (
                      <div>
                        <span
                          style={{
                            color: "red",
                            float: "right",
                            cursor: "pointer",
                            fontWeight: 400,
                            marginTop: "5px",
                          }}
                          onClick={() => {
                            this.showPopUp();
                          }}
                        >
                          Select user from group company
                        </span>
                      </div>
                    )}
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        value={this.state.profileName}
                        type="text"
                        label="Profile Name"
                        placeholder="Enter Profile Name"
                        onChange={this.handleChange("profileName")}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <NormalTextField
                        value={this.state.email}
                        type="text"
                        label="Email"
                        star="*"
                        placeholder="Enter emial..."
                        onChange={this.handleChange("email")}
                      />
                      {this.state.showMandatoryField && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            This Field is Required
                          </span>
                        </div>
                      )}
                      {this.state.ShowInValidText && (
                        <div className="TextFieldBlock">
                          <span className="text-danger">
                            Invalid Email Address
                          </span>
                        </div>
                      )}
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <TextFieldMobileReq
                        value={this.state.contactno}
                        type="text"
                        label="Contact Number"
                        placeholder="Enter contact no..."
                        onChange={this.handleChange("contactno")}
                      />
                    </div>
                  </Box>

                  <Box gridColumn="span 4">
                    <NewDynamicSelect
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      label={"App Role"}
                      arrayOfData={this.state.AppList && this.state.AppList}
                      selected={
                        filteredproducts[0].approle &&
                        filteredproducts[0].approle
                      }
                      ErrorOutlineIcon={true}
                      toolTipTitle={
                        "App Role or Portal Role is mandatory Select At least one and Proceed"
                      }
                      name={"approle"}
                      placeholder="Select"
                      row={filteredproducts[0]}
                      update={this.props.location.state ? 1 : 0}
                    />
                  </Box>
                  <Box gridColumn="span 4">
                    <NewDynamicSelect
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      label={"Portal Role"}
                      arrayOfData={
                        this.state.PortalList && this.state.PortalList
                      }
                      selected={
                        filteredproducts[0].portalrole &&
                        filteredproducts[0].portalrole
                      }
                      ErrorOutlineIcon={true}
                      toolTipTitle={
                        "App Role or Portal Role is mandetory Select At least one and Proceed"
                      }
                      name={"portalrole"}
                      placeholder="Select"
                      row={filteredproducts[0]}
                      update={this.props.location.state ? 1 : 0}
                    />
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <NormalTextField
                        value={this.state.status}
                        type="text"
                        label="Status"
                        placeholder="Enter User Name..."
                        onChange={this.handleChange("status")}
                        disabled={true}
                      />
                    </div>
                  </Box>

                  {/* <Box gridColumn="span 4">
                    <NewDynamicSelect
                      onSelectChange={this.handleSelectChange}
                      isDropdownOpen={this.state.isDropdownOpen}
                      handleMenuOpen={this.handleMenuOpen}
                      label={"Company"}
                      arrayOfData={
                        this.state.CompanyList && this.state.CompanyList
                      }
                      selected={
                        filteredproducts[0].company &&
                        filteredproducts[0].company
                      }
                      name={"company"}
                      placeholder="Select"
                      row={filteredproducts[0]}
                      update={this.props.location.state ? 1 : 0}
                    />
                  </Box> */}
                  <Box gridColumn="span 4">
                    <AutoMultiselect
                      checkbox={true}
                      defaultValue={filteredproducts[0].branch}
                      onSelectChange={this.handleSelectChange}
                      options={
                        this.state.BranchList
                          ? this.state.BranchList.map((item) => ({
                              label: item.brname,
                              id: item.id,
                              title: item.brname,
                            }))
                          : ""
                      }
                      selectedValues={filteredproducts[0].branch}
                      name={"branch_" + filteredproducts[0].id}
                      getOpt={this.getMopt}
                      row={filteredproducts[0]}
                      label="Branch"
                      placeholder="Select..."
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <br />
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link to="/UserManagement">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          {this.props.location.rowdata == undefined ? (
            <Button
              variant="contained"
              className="saveButton"
              type="submit"
              onClick={this.handleValidSubmit}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              className="saveButton"
              type="submit"
              onClick={this.handleUpdate}
            >
              Update
            </Button>
          )}
        </div>

        {this.state.showPopUp === true && (
          <div className="popup-overlay">
            <div className="popup-content">
              <div className="consoleFormBlock">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="grid"
                      gridTemplateColumns="repeat(12, 1fr)"
                      gap={2}
                    >
                      <Box gridColumn="span 6">
                        <NewDynamicSelect
                          onSelectChange={this.handleSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          label={"Company"}
                          arrayOfData={
                            this.state.CompanyList && this.state.CompanyList
                          }
                          selected={this.state.mappedCompanyId}
                          name={"company"}
                          placeholder="Select"
                          row={filteredproducts[0]}
                          update={this.props.location.state ? 1 : 0}
                          required
                        />
                      </Box>
                      <Box gridColumn="span 6">
                        <NewDynamicSelect
                          onSelectChange={this.handleSelectChange}
                          isDropdownOpen={this.state.isDropdownOpen}
                          handleMenuOpen={this.handleMenuOpen}
                          label={"User Name"}
                          arrayOfData={ this.state.mappedCompanyId !==""?
                            this.state.userslist && this.state.userslist:[]
                          }
                          selected={this.state.mappedUserId}
                          name={"userName"}
                          placeholder="Select"
                          row={filteredproducts[0]}
                          update={this.props.location.state ? 1 : 0}
                          required
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>
              <div className="consoleFormButtonBlock">
                <Button
                  variant="contained"
                  className="cancelButton"
                  onClick={() => {
                    this.showPopUp("mapUser");
                  }}
                >
                  Ok
                </Button>
                <Button
                  variant="contained"
                  className="saveButton"
                  type="submit"
                  onClick={() => {
                    this.showPopUp("cancel");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    portalList,
    appList,
    companyList,
    branchList,
    userslist,
    userDetailsByIdData,
    userManagementLineItem,
  } = state.UserManagement;

  return {
    portalList,
    appList,
    companyList,
    branchList,
    userslist,
    userDetailsByIdData,
    userManagementLineItem,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getportallist,
    getcompanylist,
    getapplist,
    getMasterStates,
    getbranchlist,
    branchCreationAdd,
    userCreate,
    userUpdate,
    getUsersList,
    userDetailsById,
  })(UserCreate3)
);
