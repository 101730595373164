import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LeavePolicyLineItem from "./LeavePolicyLineItem"

class LeavePolicyAddItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowcount: 1,
            show: false,
            checkAll: false,
            companyId: localStorage.getItem('companyId')
        };
        this.state.filterText = "";
        this.state.tooltip = "item description...."
    }

    render() {
        return (
            <div>

                <LeavePolicyLineItem
                    tableHeaders={tableHeaders}
                    RowData={RowData}
                    products={this.props.products}
                    header={this.props.header}
                    delete={this.props.delete}
                    viewByIdResponse={this.props.viewByIdResponse}
                />
            </div>
        );
    }
}
const RowData = [
    {
        no: 1,
        component: "DynamicSelect",
        type: "carryFrwStatus",
        dropDown: "dropDown",
        name: 'carryFrwStatus',
        Items: true,
    },

    {
        no: 2,
        component: "DynamicSelect",
        type: "encashmentStatus",
        dropDown: "dropDown",
        name: 'encashmentStatus',
        Items: true,
    },
    {
        no: 3,
        component: "DynamicSelect",
        type: "leaveTypeId",
        dropDown: "dropDown",
        name: 'leaveTypeId',
        Items: true,
    },
    {
        no: 4,
        component: "Inputadorment",
        type: "leavesPerYear",
    },
    {
        no: 5,
        component: "Inputadorment",
        type: "maxConsecutiveCount",

    },
    {
        no: 6,
        component: "Inputadorment",
        type: "maxLeavesPerYear",
    },
    {
        no: 7,
        component: "Date",
        type: "yearStartDate",
        name: "yearStartDate"
    },
    {
        no: 8,
        component: "Date",
        type: "yearEndDate",
        name: "yearEndDate"
    },

];

const tableHeaders = [
    {
        header: "Carry Forward Status",
    },
    {
        header: "Encashment Status",
    },
    {
        header: "Leave Type",
    },
    {
        header: "Leave Per Year",
    },
    {
        header: "Max Consecutive Count",
    },
    {
        header: "Max Leave Per Year",
    },
    {
        header: "Year Start Date",
    },
    {
        header: "Year End Date",
    },
    {
        header: "Actions"
    }
];

const mapStatetoProps = (state) => { };

export default withRouter(
    connect(mapStatetoProps, {
    })(LeavePolicyAddItems)
);
