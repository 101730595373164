export const BANK_ACCOUNT_LIST_API="bank_account_list_api";
export const BANK_ACCOUNT_LIST_API_SUCCESSFULL="bank_account_list_api_successfull";
export const CREATE_BANK_ACCOUNT = "create_bank_account";
export const CREATE_BANK_ACCOUNT_SUCCESSFULL ="create_bank_account_successfull";
export const UPDATE_BANK_ACCOUNT ="update_bank_account";
export const UPDATE_BANK_ACCOUNT_SUCCESSFULL ="update_bank_account_successfull";
export const BRANCH_SELECTION_LIST = "branch_selection_list";
export const BRANCH_SELECTION_LIST_SUCCESSFULL ="branch_selection_list_successfull";


export const API_ERRORS ='api_failed';