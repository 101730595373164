import {
  BRANCH_API_ERRORS,
  BRANCHDETAILS,
  BRANCHDETAILS_ADD,
  BRANCHDETAILS_ADD_SUCCESSFUL,
  GET_STATES_LIST,
  STATES_LIST_SUCCESS,
  GST_VERIFICATION,GST_VERIFICATION_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  stateslist: [],
  model: false,
  error_msg: null,
  rowdata: {},
  gstVerificationStatus:""
};

const branchdit = (state = initialState, action) => {
  switch (action.type) {
    case BRANCHDETAILS_ADD:
      state = {
        ...state,
        loading: false, 
      };
      break;
    case BRANCHDETAILS_ADD_SUCCESSFUL:
      state = {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
      };
      break;
    case BRANCH_API_ERRORS:
      state = {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
      break;
    case GET_STATES_LIST:
      state = {
        ...state,
        loading: true
      }
      break;
    case STATES_LIST_SUCCESS:
      state = {
        ...state,
        stateslist: action.payload.response.data,
        loading: false
      }
      break;
      case GST_VERIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GST_VERIFICATION_SUCCESSFULL:
        state = {
          ...state,
          gstVerificationStatus: action.payload,
          loading: false,
        };
     break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default branchdit;
