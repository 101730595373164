import React, { Component } from "react";
import { connect } from "react-redux";
import { Grid, Button } from "@mui/material";
import Classic from "../../assets/images/classic.png";
import Modren from "../../assets/images/modren.png";
import Minimal from "../../assets/images/minimal.png";
import Contemporary from "../../assets/images/contemporary.png";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";

import RadioButton from "../../components/RadioButton/RadioButton";
import LegacyMode from "../../assets/images/LegacyMode.png";

import LatestMode from "../../assets/images/LatestMode.png";
import Popup from "../../components/Popup/Popup";
import {Link} from "react-router-dom"

class MRPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      mrprint: 0,
      legacy: false,
      latest: false,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
    };
  }
  onClickRadioButton = (selectedValue) => {
    var mrprint = 0;
    var legacy = false,
      latest = false;

    if (selectedValue === "Legacy Mode") {
      mrprint = 1;
      legacy = true;
    } else if (selectedValue === "Latest Mode") {
      mrprint = 2;
      latest = true;
    }
    this.setState({
      selectedValue: selectedValue,
      mrprint: mrprint,
      legacy: legacy,
      latest: latest,
    });
  };

  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      });
    }
    return id;
  };

  handleConfig = () => {
    var printstyle = this.state.selectedValue;
    if (!printstyle) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select MR Print",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (!this.state.companyId) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry!! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: this.state.companyId,
        configList: [
          {
            autoId: this.getAutoIdFromList(configNames.MR_PRINT_MODE),
            configParameter: configNames.MR_PRINT_MODE,
            isActive: 1,
            configValue: this.state.mrprint,
          },
        ],
      };
      if (this.state.companyId) {
        var msg = "MR Print Updated Successfully.";

        this.props.setConfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };

   componentDidMount=()=> {
  //componentWillMount = () => {
    var mrprint = 0;
    var legacy = false,
      latest = false;
    //var configdata = JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.MR_PRINT_MODE) {
          if (prop.configValue === "1") {
            mrprint = 1;
            legacy = true;
           
          } else if (prop.configValue === "2") {
            mrprint = 2;
            latest = true;
          }
          this.setState({
            mrprint: mrprint,
            legacy: legacy,
            latest: latest,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  };

  componentWillReceiveProps = (props) => {
    var mrprint = 0;
    var legacy = false,
      latest = false;
    //var configdata = JSON.parse(localStorage.getItem("configdata"));
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    var configdata = props.configdata;
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.MR_PRINT_MODE) {
          if (prop.configValue === "1") {
            mrprint = 1;
            legacy = true;
           
          } else if (prop.configValue === "2") {
            mrprint = 2;
            latest = true;
          }
          this.setState({
            mrprint: mrprint,
            legacy: legacy,
            latest: latest,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  }

  render() {
    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        <Grid container spacing={2}>
          
          <Grid item xs={3}>
            <div className="radioButtonBlock">
              <Popup imageButton={LegacyMode} popupImage={LegacyMode} />
              {/* <RadioButton labelText="Legacy Mode" value="legacymode"  /> */}
              <RadioButton
                labelText="Legacy Mode"
                value="legacymode"
                name="printstyle"
                checkednew={this.state.legacy}
                groupname="printStyle"
                onChange={this.onClickRadioButton}
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="radioButtonBlock">
              <Popup imageButton={LatestMode} popupImage={LatestMode} />
              {/* <RadioButton labelText="Latest Mode" value="letestmode" /> */}
              <RadioButton
                labelText="Latest Mode"
                value="latestmode"
                name="printstyle"
                checkednew={this.state.latest}
                groupname="printStyle"
                onChange={this.onClickRadioButton}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="consoleFormButtonBlock">
              <Link to="/invoice">
              <Button variant="contained" className="cancelButton">
                Cancel
              </Button>
              </Link>
              <Button
                variant="contained"
                type="button"
                className="saveButton"
                onClick={this.handleConfig}
              >
                Save and Next
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default connect(mapStatetoProps, {
  setConfiguration,
})(MRPrint);
