
import {
    PAYSCHEME_PARAMETERS_LIST, PAYSCHEME_PARAMETERS_LIST_SUCCESSFULL,
    CREATE_PAYSCHEME_PARAMETERS, CREATE_PAYSCHEME_PARAMETERS_SUCCESSFULL,
    API_ERRORS,
    UPDATE_PAYSCHEME_PARAMETERS,
    UPDATE_PAYSCHEME_PARAMETERS_SUCCESSFULL
} from './actionTypes';

export const payschemeParametersList = (url, data, history) => {
    return {
        type: PAYSCHEME_PARAMETERS_LIST,
        payload: { url, data, history }
    }
}
export const payschemeParametersListSuccessfull = (data) => {
    return {
        type: PAYSCHEME_PARAMETERS_LIST_SUCCESSFULL,
        payload: data
    }
}
export const createPayschemeParameters = (url, data, history) => {
    return {
        type: CREATE_PAYSCHEME_PARAMETERS,
        payload: { url, data, history }
    }
}

export const createPayschemeParametersSuccessfull = (data) => {
    return {
        type: CREATE_PAYSCHEME_PARAMETERS_SUCCESSFULL,
        payload: data
    }
}
export const updatePayschemeParameters = (url, data, history) => {
    return {
        type: UPDATE_PAYSCHEME_PARAMETERS,
        payload: { url, data, history }
    }
}

export const updatePayschemeParametersSuccessfull = (data) => {
    return {
        type: UPDATE_PAYSCHEME_PARAMETERS_SUCCESSFULL,
        payload: data
    }
}
export const apiErrors = (error) => {
    return {
        type: API_ERRORS,
        payload: error,
    };
};