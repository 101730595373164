import React, { Component, Fragment } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { Grid } from "@mui/material";
import cons from "../../helpers/Consts";

import CustomButton from "../../components/Buttons/Buttons";
import ExportIcon from "../../assets/images/exportIcon.png";
import EditIcon from "@mui/icons-material/Edit";
import PlaceHolder from "../../components/PlaceHolders/PlaceHolderComponent";

class GridViewSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: localStorage.getItem("companyIdconfig"),
            userDit: JSON.parse(localStorage.getItem("authUser")),
        };
    }

    editRow = (row) => { // redirect updated method with path name and data
        cons.COMPANY_ID = row.companyId;
        this.props.history.push({ pathname: this.props.pathname, rowdata: row });
        localStorage.setItem("companyupdateId", row.companyId);
    };

    render() {
        


        let str = JSON.stringify(this.props.mdata, (k, v) => (v == null ? "" : v));
        var mdataaaa = JSON.parse(str);


        if (mdataaaa) {
            var products = mdataaaa.map((row, order) => ({
                ...row,
                chk: (
                    <div
                        className="checkbox form-check"
                        style={{ border: "1px solid green" }}
                    >
                        <div className="custom-checkbox custom-control custom-control-inline">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={this.handleChange}
                                value={row.companyId}
                                id={row.companyId}
                            />
                            <label
                                className="custom-control-label"
                                for={row.companyId}
                            ></label>
                        </div>{" "}
                    </div>
                ),
                actions: (
                    <Fragment>
                        <EditIcon onClick={() => this.editRow(row)} />
                    </Fragment>
                ),
            }));
        } else {
            var products = [];
        }
        const { SearchBar } = Search;
        const headerSortingStyle = { backgroundColor: "#e3edf8" };
        const columns = [];
        if (this.props.mcolumns) {
            for (let i = 0; i < this.props.mcolumns.length; i++) {
                var coldata = {
                    dataField: this.props.mcolumns[i].dataField,
                    text: this.props.mcolumns[i].Header,
                    headerAlign: "left",
                    align: "left",
                    key: i,
                };
                columns.push(coldata);
            }
            var actions = {
                dataField: "actions",
                text: "Actions",
                headerAlign: "left",
                align: "left",
                csvExport: false,
                
            };
            columns.push(actions);
        } else {
            const columns = [];
        }

        const MyExportCSV = (props) => {
            const handleExportClick = () => {
                props.onExport();
            };
            return (
                <CustomButton
                    label="Export"
                    className="lightBlueButton"
                    type="button"
                    icon={ExportIcon}
                    handleClick={handleExportClick}
                />
            );
        };

        return (
            <>
                {mdataaaa ? (<>
                    <Grid>
                        <Grid>
                            <div className="consoleFormContainer">
                                <div className="gridContainer">
                                    <div className="gridComponent">
                                        <ToolkitProvider keyField="id" data={products} columns={columns} search>
                                            {(props) => (
                                                <div>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        className="buttonBlock"
                                                    >
                                                        <div className="gridButtonBlock">
                                                            <SearchBar {...props.searchProps} placeholder="Search..." />
                                                            <MyExportCSV {...props.csvProps} />
                                                        </div>
                                                    </Grid>
                                                    <BootstrapTable
                                                        {...props.baseProps}
                                                        pagination={paginationFactory()}
                                                    />
                                                </div>
                                            )}
                                        </ToolkitProvider>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>

                </>) : (<div className="consoleFormContainer"><PlaceHolder /></div>)}
            </>);
    }
}

const mapStatetoProps = (state) => { };
export default withRouter(
    connect(mapStatetoProps, {})(GridViewSearch)
);