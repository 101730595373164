import { MODULE_LIST, MODULE_LIST_SUCCESSFUL, ADD_MODULE,ADD_MODULE_SUCCESS, ADDON_LIST, ADDON_LIST_SUCCESSFULL, MODULES_UPDATION, MODULES_UPDATION_SUCCESSFULL } from "./actionTypes";

export const getModules = (url) => {
  return {
    type: MODULE_LIST,
    payload: {url}
  };
};

export const moduleListSuccessful = (data) => {
  return {
    type: MODULE_LIST_SUCCESSFUL,
    payload: data,
  };
};

export const getAddons = () => {
  return {
    type: ADDON_LIST
  }
}

export const getAddonslistsuccessfull = (data) => {
  return {
    type: ADDON_LIST_SUCCESSFULL,
    payload:data
  }
}

export const addModule = (url, data, history) => {  
  return {
    type: ADD_MODULE,
    payload: { url, data, history }, 
  };
};

export const addModuleSuccess = (data) => {
  return {
    type: ADD_MODULE_SUCCESS,
    payload: data,
  };
};

export const updateModules = (url, data, history) => {  
  return {
    type: MODULES_UPDATION,
    payload: { url, data, history }, 
  };
};

export const updateModulesSuccess = (data) => {
  return {
    type: MODULES_UPDATION_SUCCESSFULL,
    payload: data,
  };
};
