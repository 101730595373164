import React, { Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import  { serverApi } from "../../../helpers/Consts";
import {departmentMasterList} from "../../../store/Master/DepartmentMaster/actions"
import GridView from "../../../components/Grid/GridView";
import GridViewSearch from "../../../components/Grid/GridViewSerch";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: 0, userDit: JSON.parse(localStorage.getItem("authUser")) };
  }
  componentDidMount() {
    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyIdconfig"),
    }
    this.props.departmentMasterList(serverApi.GET_ALL_DEPARTMENT_MASTER_LIST,data, this.props.history); // calling api for department list
  }
  render() {
    const mdata = this.props.DepartmentMasterList ? this.props.DepartmentMasterList.data : "";
    const mcolumns = this.props.DepartmentMasterList ? this.props.DepartmentMasterList.column : "";
    return (
      <GridViewSearch mdata={mdata} mcolumns={Columns} pathname="/create_department_master"/>
    )
  }
}

// Department List Headers
const Columns = [
  // {
  //   Header: "Department Id",
  //   accessor: "id",
  //   hidden: false,
  // },
  //
  {
    Header: "Department Code",
    // accessor: "mDeptCode",
    dataField:"mDeptCode",
    hidden: false
  },
  {
    Header: "Department Name",
    dataField:"departmentName",
    // accessor: "departmentName",
    hidden: false,
  },
 
  
  // {
  //   Header: "Modified By",
  //   accessor: "modby",
  //   hidden: true,
  // },
  // {
  //   Header: "User Id",
  //   accessor: "userId",
  //   hidden: true,
  // },
  // {
  //   Header: "Order Id",
  //   accessor: "orderId",
  //   hidden: true,
  // },
  // {
  //   Header: "Company Id",
  //   accessor: "companyId",
  //   hidden: true,
  // },
  // {
  //   Header: "Created By",
  //   accessor: "createdBy",
  //   hidden: true,
  // },
  // {
  //   Header: "Created Date",
  //   accessor: "createdDate",
  //   hidden: true,
  // },
  // {
  //   Header: "Modified Description ",
  //   accessor: "modByDesc",
  //   hidden: true,
  // },
  // {
  //   Header: "Created Date Description",
  //   accessor: "createdDateDesc",
  //   hidden: true,
  // },
];

const mapStatetoProps = (state) => {
  const { DepartmentMasterList } = state.DepartmentReducer;
  return { DepartmentMasterList };
};

export default withRouter(
  connect(mapStatetoProps, {
    departmentMasterList,
  })(Index)
);
