import {
  ROLE_MANAGEMENT,
  ROLE_MANAGEMENT_SUCCESS,
  ADD_ROLES,
  ADD_ROLES_SUCCESS,
  ROLE_TYPES_LIST,
  ROLE_TYPES_LIST_SUCCESS,
  USER_GROUPS,
  USER_GROUPS_SUCCESS,
  USER_GROUP_ROW,
  USER_GROUP_ROW_SUCCESS,
  USERS_LIST,
  USERS_LIST_SUCCESS,
  GET_PORTAL_LIST,
  GET_PORTAL_LIST_SUCCESS,
  GET_APP_ROLE_LIST,
  GET_APP_ROLE_SUCCESS,
  GET_COMPANY_LIST,
  GET_COMPANY_LIST_SUCCESS,
  GET_BRANCH_LIST,
  GET_BRANCH_LIST_SUCCESS,
  CLIENT_LIST,
  CLIENT_LIST_SUCCESS,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  ADD_APPROVAL_HIERARCY,
  ADD_APPROVAL_HIERARCY_SUCCESS,
  UPDATE_ROLES,
  UPDATE_ROLES_SUCCESS,
  USER_DETAILS_BYID,
  USER_DETAILS_BYID_SUCCESS,
  USER_MANAGEMENT_LINEITMS
} from "./actionTypes";

export const getportallist = (url) => {
  return {
    type: GET_PORTAL_LIST,
    payload: { url },
  };
};

export const portalListsuccess = (data) => {
  return {
    type: GET_PORTAL_LIST_SUCCESS,
    payload: data,
  };
};

export const getclientlist = (url) => {
  return {
    type: CLIENT_LIST,
    payload: { url },
  };
};

export const clientListsuccess = (data) => {
  return {
    type: CLIENT_LIST_SUCCESS,
    payload: data,
  };
};

export const getapplist = (url) => {
  return {
    type: GET_APP_ROLE_LIST,
    payload: { url },
  };
};

export const appListsuccess = (data) => {
  return {
    type: GET_APP_ROLE_SUCCESS,
    payload: data,
  };
};

export const getbranchlist = (url, data, history,type) => {
  return {
    type: GET_BRANCH_LIST,
    payload: { url, data, history,type },
  };
};

export const branchListsuccess = (data) => {
  return {
    type: GET_BRANCH_LIST_SUCCESS,
    payload: data,
  };
};

export const getcompanylist = (url) => {
  return {
    type: GET_COMPANY_LIST,
    payload: { url },
  };
};

export const companyListsuccess = (data) => {
  return {
    type: GET_COMPANY_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserGroups = (url,data, history) => {
  return {
    type: USER_GROUPS,
    payload: { url,data, history, },
  };
};

export const usergroupsuccess = (data) => {
  return {
    type: USER_GROUPS_SUCCESS,
    payload: data,
  };
};

export const getUsersList = (url, data, history) => {
  return {
    type: USERS_LIST,
    payload: { url, data, history },
  };
};

export const userslistsuccess = (data) => {
  return {
    type: USERS_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserGroupRow = (url, history, onNameChange) => {
  return {
    type: USER_GROUP_ROW,
    payload: { url, history },
  };
};

export const getUserGroupRowsuccess = (data) => {
  return {
    type: USER_GROUP_ROW_SUCCESS,
    payload: data,
  };
};

// export const setConfiguration = (url, data, msg, history) => {
//   return {
//     type: CONFIGURATION_UPDATE,
//     payload: { url, data, msg, history },
//   };
// };

export const getRoleTyps = (url, history, companyId) => {
  return {
    type: ROLE_TYPES_LIST,
    payload: { url, history, companyId },
  };
};

export const roletypesuccess = (data) => {
  return {
    type: ROLE_TYPES_LIST_SUCCESS,
    payload: data,
  };
};

export const addRoletypes = (url, data, history) => {
  return {
    type: ADD_ROLES,
    payload: { url, data, history },
  };
};

export const addRoleTypsSuccess = (data) => {
  return {
    type: ADD_ROLES_SUCCESS,
    payload: data,
  };
};


export const updateRoletypes = (url, data, history) => {
  return {
    type: UPDATE_ROLES,
    payload: { url, data, history },
  };
};

export const updateRoleTypsSuccess = (data) => {
  return {
    type: UPDATE_ROLES_SUCCESS,
    payload: data,
  };
};



export const addApproval = (url, data, history,company,branch) => {
  return {
    type: ADD_APPROVAL_HIERARCY,
    payload: { url, data, history, company,branch},
  };
};

export const addApprovalSuccess = (data) => {
  return {
    type: ADD_APPROVAL_HIERARCY_SUCCESS,
    payload: data,
  };
};

export const userCreate = (url, data, history) => {
  return {
    type: CREATE_USER,
    payload: {url, data, history}
  }
}

export const userCreateSuccess = (response) => {
  return  {
    type: CREATE_USER_SUCCESS,
    payload: response
  }
}

export const userUpdate = (url, data, history) => {
  return {
    type: UPDATE_USER,
    payload: {url, data, history}
  }
}

export const userUpdateSuccess = (response) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: response
  }
}

export const userDetailsById = (url) => {
  console.log(url)
  return {
    type: USER_DETAILS_BYID,
    payload: {url}
  }
}

export const userDetailsByIdSuccess = (response) => {
  console.log(response)
  return {
    type: USER_DETAILS_BYID_SUCCESS,
    payload: response
  }
}

export const userManagementLineItems = (data) => {
  return {
    type: USER_MANAGEMENT_LINEITMS,
    payload: data,
  };
};
export const clearUserManagementLineItems = (data) => {
  return {
    type: USER_MANAGEMENT_LINEITMS,
    payload: data,
  };
};
