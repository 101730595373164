import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
import Link from "@mui/material/Link";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";
import RadioButton from "../../components/RadioButton/RadioButton";

class ModifyInvno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      modiyes: false,
      modino: false,
      modiinv: 0,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
    };
  }
  onClickRadioButton = (selectedValue) => {
    var modiinv = 0;
    var modiyes = false,
      modino = false;
    if (selectedValue === "Yes") {
      modiinv = 1;
      modiyes = true;
    } else if (selectedValue === "No") {
      modiinv = 2;
      modino = true;
    }

    this.setState({
      selectedValue: selectedValue,
      modiinv: modiinv,
      modiyes: modiyes,
      modino: modino,
    });
  };

  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      });
    }
    return id;
  };

  handleConfig = () => {
    var printstyle = this.state.selectedValue;
    if (!printstyle) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Modify Invoice",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (this.state.companyId == 0) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: this.state.companyId,
        configList: [
          {
            autoId: this.getAutoIdFromList(configNames.INVOICE_NO_MODIFY),
            configParameter: configNames.INVOICE_NO_MODIFY,
            isActive: 1,
            configValue: this.state.modiinv,
          },
        ],
      };
      if (this.state.companyId) {
        var msg = "Modify Invoice No Updated Successfully.";

        this.props.setConfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };

  componentDidMount=()=> {
    var modiinv = 0;
    var modiyes = false,
      modino = false;
    //var configdata = cons.CONFIG_DATA;//JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.INVOICE_NO_MODIFY) {
          if (prop.configValue === "1") {
            modiinv = 1;  
            modiyes = true;
          } else if (prop.configValue === "2") {
            modiinv = 2;
            modino = true;
          }
          this.setState({
            modiinv: modiinv,
            modiyes: modiyes,
            modino: modino,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  }

  componentWillReceiveProps = (props) => {
    var modiinv = 0;
    var modiyes = false,
      modino = false;
    //var configdata = cons.CONFIG_DATA;//JSON.parse(localStorage.getItem("configdata"));
   // var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
   var configdata = props.configdata;
    if (configdata) {
      configdata.forEach((prop) => {
        if (prop.configParameter === configNames.INVOICE_NO_MODIFY) {
          if (prop.configValue === "1") {
            modiinv = 1;  
            modiyes = true;
          } else if (prop.configValue === "2") {
            modiinv = 2;
            modino = true;
          }
          this.setState({
            modiinv: modiinv,
            modiyes: modiyes,
            modino: modino,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  }
  // componentWillMount = () => {
  //   var modiinv = 0;
  //   var modiyes = false,
  //     modino = false;
  //   var configdata = JSON.parse(localStorage.getItem("configdata"));
  //   if (configdata) {
  //     configdata.forEach((prop) => {
  //       if (prop.configParameter === configNames.INVOICE_NO_MODIFY) {
  //         console.log("Magesj rpw Mr in Print Invoice", prop);
  //         if (prop.configValue === "1") {
  //           modiinv = 1;  
  //           modiyes = true;
  //         } else if (prop.configValue === "2") {
  //           modiinv = 2;
  //           modino = true;
  //         }
  //         this.setState({
  //           modiinv: modiinv,
  //           modiyes: modiyes,
  //           modino: modino,
  //           configdata: configdata,
  //           companyId: localStorage.getItem("companyIdconfig"),
  //         });
  //       }
  //     });
  //   }
  // };

  render() {
    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <div className="consoleFormContainer">
          <h5>Modify Invoice No</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="modiinvoice"
                        checkednew={this.state.modiyes}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="modiinvoice"
                        checkednew={this.state.modino}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          <Link href="/CompanyList">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default connect(mapStatetoProps, {
  setConfiguration,
})(ModifyInvno);
