import validatorvals from 'validator';

const regex = {
    email: new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ),
    number: new RegExp('^[0-9]+$'),
    spec: new RegExp('^[a-zA-Z0-9!@#]'),   //'^[a-zA-z]*[a-zA-Zd-_@&$%#s]{1,50}$'),^[a-zA-z]*[a-zA-Zd-_@&$%#s]{1,50}$

};

export class Validators {
    static email(value, message, event) {
        if (value) {
            const result = regex.email.test(value);
            if (!result) return { error: true, message };
        }
        // return false;
    }

    

    static number(value, message, event) {
        const length = value ? value.toString().length : 0;
        if (length > 0) {
            const result = regex.number.test(value);
            if (!result) {                
                return { error: true, message };  
            }            
        }

        return false;
    }

    static length(value, message, event) {
        const length = value ? value.toString().length : 0;
        if (length > 0) {
            if(length > 10){
                return { error: true, message };     
            }
            
        }

        return false;
    }
    
    static special(value, message, event) {
        const length = value ? value.toString().length : 0;
        if (length > 0) {
           if(!validatorvals.isAlphanumeric(value)){
            return { error: true, message };    
           }
           
        }

        return false;
    }

    
}

export const validateInput = (validators, value, event) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message, event);
            if (error) {
                return error;
            }           
        }
    }
    return false;
};

export class Validatorslenght { 

    static length(value, message, len) {
        const length = value ? value.toString().length : 0;
        if (length > 0) {
            if(length > Number(len)){
                return { error: true, message };     
            }
            
        }

        return false;
    }      
}

export const validatorLenght = (Validatorslenght, value, event) => {
    if (Validatorslenght && Validatorslenght.length) {
        for (let i = 0; i < Validatorslenght.length; i++) {
            const lenerror = Validatorslenght[i].length(value, Validatorslenght[i].message, event);
            if (lenerror) {
                return lenerror;
            }
        }
    }
    return false;
};


export class Validatorsrequired { 

    static required(value, message) {
        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    } 
    
}

export const validatorRequired = (Validatorsrequired, value) => {
    if (Validatorsrequired && Validatorsrequired.length) {
        for (let i = 0; i < Validatorsrequired.length; i++) {
            const reqerror = Validatorsrequired[i].check(value, Validatorsrequired[i].message);
            if (reqerror) {
                return reqerror;
            }
        }
    }
    return false;
};



