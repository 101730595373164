import React, { Suspense } from "react";
export const ApH = React.lazy(()=>import("./ApprovelHierarcy"))

const ApprovalLazyLoading = () => {

    return(<>
        <apH/>

        <Suspense fallback={<div>Loading....</div>}>
        <ApH/>
        </Suspense>
    </>)

}

export default ApprovalLazyLoading;