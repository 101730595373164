export const GET_LEAVE_POLICY_LIST = "get_leave_policy_list";
export const GET_LEAVE_POLICY_LIST_SUCCESSFULL = "get_leave_policy_list_successfull";
export const GET_LEAVE_SELECTION_LIST = "get_leave_selection_list";
export const GET_LEAVE_SELECTION_LIST_SUCCESSFULL = "get_leave_selection_list_successfull";
export const CREATE_LEAVE_POLICY = "create_leave_policy";
export const CREATE_LEAVE_POLICY_SUCCESSFULL = "create_leave_policy_successfull";
export const LEAVE_POLICY_VIEW_BY_ID_LIST = "leave_policy_view_by_id_list";
export const LEAVE_POLICY_VIEW_BY_ID_LIST_SUCCESSFULL = "leave_policy_view_by_id_list_successfull";



