import {
  // EDUCATIONAL_DETAILS,
  // EDUCATIONAL_DETAILS_SUCCESSFULL,
  DEPARTMENT_LIST,
  DEPARTMENT_LIST_SUCCESSFULL,
  CATEGORY_LIST,
  CATEGORY_LIST_SUCCESSFULL,
  EMP_LEVEL,
  EMP_LEVEL_SUCCESSFULL,
  REPORTING_MANAGER_LIST,
  REPORTING_MANAGER_LIST_SUCCESSFULL,
  CONTRACTOR,
  CONTRACTOR_SUCCESSFULL,
  WORK_LOCATION,
  WORK_LOCATION_SUCCESSFULL,
  DESIGNATION,
  DESIGNATION_SUCCESSFULL,
  LEAVE_POLICY_LIST,
  LEAVE_POLICY_LIST_SUCCESSFULL,
  LEAVE_LIST,
  LEAVE_LIST_SUCCESSFULL,
  PAY_SCHEME,
  PAY_SCHEME_SUCCESSFULL,
  
  PAY_SCHEME_BY_ID,
  PAY_SCHEME_BY_ID_SUCCESSFULL,
  CHECK_PAY_SCHEME,
  CHECK_PAY_SCHEME_SUCCESSFULL,
  LEAVE_POLICY_MAPPING,
  LEAVE_POLICY_MAPPING_SUCCESSFULL,
  GET_ALL_FILES,
  GET_ALL_FILES_SUCCESSFULL,
  ALL_FILES_LIST_SUCCESSFULL,
  DOWNLOAD_FILES,
  DOWNLOAD_FILES_SUCCESSFULL,
  UPDATE_FILE,
  UPDATE_FILE_SUCCESSFULL,
  OFFER_LETTER_EMAIL_SERVICE,
  OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL,
  EXPERIENCE_DETAILS_VIEW_BY_ID,
  EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_,
  GET_PAYSCHEME_LIST,
  GET_PAYSCHEME_LIST_SUCCESSFULL,
  ADD_SALARY,
  ADD_SALARY_SUCCESSFULLY,
  DISPLAY_EMAIL_TEMPLATE,
  DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY,
  PAY_WAGES_MODES,
  PAY_WAGES_MODES_SUCCESSFULL,
  PAY_COMPONENTS,
  PAY_COMPONENTS_SUCCESSFULL,
  ADD_PAY_SCHEME,
  ADD_PAY_SCHEME_SUCCESSFULL,
  UPDATE_PAY_SCHEME,
  UPDATE_PAY_SCHEME_SUCCESSFULL,
  GET_PROFILE_PICTURE,
  GET_PROFILE_PICTURE_SUCCESSFULLY,
  GET_PROFILE_PICTURE_FILE,
  GET_PROFILE_PICTURE_FILE_SUCCESSFULLY,
  UPDATE_SALARY,
  UPDATE_SALARY_SUCCESSFULLY,
  HRMS_DASHBOARD,
  HRMS_DASHBOARD_SUCCESSFULLY,
  PAYSCHEME_BY_ID,
  PAYSCHEME_BY_ID_SUCCESSFULL,
  APPROVAL_HIERARCHY,
  APPROVAL_HIERARCHY_SUCCESSFULLY,
  VIEW_BY_ID_APPROVAL,
  VIEW_BY_ID_APPROVAL_SUCCESSFULLY,
  UPDATE_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY_SUCCESSFULL,
  WORKER_DETAILS_PROGRESS,
  WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_WORKER_DETAILS_PROGRESS,
  GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY,
  GET_LIST_ROW_DATA,
  DELETE_WORKER_EDUCATIONAL_DETAILS,
  DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY,

    // PAY SCHEME ORDERING ACTION TYPES
    GET_ORDERED_PAYSCHEME_COMPONENT,
    GET_ORDERED_PAYSCHEME_COMPONENT_SUCCESSFULL
} from "./actionTypes";

const initialState = {
  error: "sorry no data found",
  message: null,
  loading: false,
  page: "",
  
  employeeListColumns: [],
  employeeListItemsList: [],
  educationalDetails: [],
  
  departmentList: [],
  categoryList: [],
  empLevel: [],
  reporting_Manager_List: [],
  contractor: [],
  workLocation: [],
  designation: [],
  leavePolicy_List: [],
  leave_List: [],
  payScheme_List: [],
  EmployeeviewById: [],
  experienceDetails: [],
  paySchemeById: [],
  paySchemeByIdList:[],
  checkPayScheme: [],
  pay_policy_mapping: [],
  allFiles: [],
  Download_File: [],
  Update_file: [],
  assetForm: [],
  EmailOfferLetter: [],
  experienceDetailsViewById: [],
  // getPayschemeList: [],
  checkScheme: [],
  addingsalary: [],
  DisplayEmailTemplate: [],
  payWagesModes_list: [],
  payComponents_list: [],
  addPayscheme: [],
  updatePayScheme: [],
  profilePicUrl: [],
  profilePicFile: [],
  HrmsDashboardData: [],
  approval_hierarchy:[],
  approval_hierarchy_view_byId:[],
  Update_leave_policy:[],
  worker_details_progress:[],
  Get_worker_progress:[],
  pagereder:1,
  delete_educational_details:[],
  files_response:[],
};

const EmployeeReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case EXPERIENCE_DETAILS_VIEW_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EXPERIENCE_DETAILS_VIEW_BY_ID_SUCCESSFULL_:
      state = {
        ...state,
        experienceDetailsViewById: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    // Work Details
    case DEPARTMENT_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DEPARTMENT_LIST_SUCCESSFULL:
      state = {
        ...state,
        departmentList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case CATEGORY_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case CATEGORY_LIST_SUCCESSFULL:
      state = {
        ...state,
        categoryList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case EMP_LEVEL:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case EMP_LEVEL_SUCCESSFULL:
      state = {
        ...state,
        empLevel: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case REPORTING_MANAGER_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case REPORTING_MANAGER_LIST_SUCCESSFULL:
      state = {
        ...state,
        reporting_Manager_List: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case CONTRACTOR:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case CONTRACTOR_SUCCESSFULL:
      state = {
        ...state,
        contractor: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case WORK_LOCATION:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case WORK_LOCATION_SUCCESSFULL:
      state = {
        ...state,
        workLocation: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case DESIGNATION:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DESIGNATION_SUCCESSFULL:
      state = {
        ...state,
        designation: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_LIST_SUCCESSFULL:
      state = {
        ...state,
        leavePolicy_List: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case LEAVE_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_LIST_SUCCESSFULL:
      state = {
        ...state,
        leave_List: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        payScheme_List: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case PAY_SCHEME_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_SCHEME_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        paySchemeById: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case PAYSCHEME_BY_ID:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAYSCHEME_BY_ID_SUCCESSFULL:
      state = {
        ...state,
        paySchemeByIdList: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case CHECK_PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case CHECK_PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        checkPayScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_MAPPING:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case LEAVE_POLICY_MAPPING_SUCCESSFULL:
      state = {
        ...state,
        pay_policy_mapping: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      case UPDATE_LEAVE_POLICY:
        state = {
          ...state,
          loading: true,
          page: "EmployeeReducer",
        };
        break;
      case UPDATE_LEAVE_POLICY_SUCCESSFULL:
        state = {
          ...state,
          Update_leave_policy: action.payload.response,
          loading: false,
          page: "EmployeeReducer",
        };
      break;
    case GET_ALL_FILES:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case ALL_FILES_LIST_SUCCESSFULL:
      console.log("All FilesSuccessFull",action.payload.response.data.data)
      state = {
        ...state,
        files_response: action.payload.response.data.data,
      }
    case GET_ALL_FILES_SUCCESSFULL:      
      state = {
        ...state,
        allFiles: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case DOWNLOAD_FILES:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DOWNLOAD_FILES_SUCCESSFULL:
      state = {
        ...state,
        Download_File: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_FILE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_FILE_SUCCESSFULL:
      state = {
        ...state,
        Update_file: action.payload.response.data,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case OFFER_LETTER_EMAIL_SERVICE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case OFFER_LETTER_EMAIL_SERVICE_SUCCESSFULL:
      state = {
        ...state,
        EmailOfferLetter: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_PAYSCHEME_LIST:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case GET_PAYSCHEME_LIST_SUCCESSFULL:
      state = {
        ...state,
        checkScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case ADD_PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case ADD_PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        addPayscheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case UPDATE_PAY_SCHEME:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_PAY_SCHEME_SUCCESSFULL:
      state = {
        ...state,
        updatePayScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_ORDERED_PAYSCHEME_COMPONENT:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case GET_ORDERED_PAYSCHEME_COMPONENT_SUCCESSFULL:
      state = {
        ...state,
        orderedPayScheme: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case ADD_SALARY:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case ADD_SALARY_SUCCESSFULLY:
      state = {
        ...state,
        addingsalary: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_SALARY:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case UPDATE_SALARY_SUCCESSFULLY:
      state = {
        ...state,
        addingsalary: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case DISPLAY_EMAIL_TEMPLATE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case DISPLAY_EMAIL_TEMPLATE_SUCCESSFULLY:
      state = {
        ...state,
        DisplayEmailTemplate: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PAY_WAGES_MODES:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_WAGES_MODES_SUCCESSFULL:
      state = {
        ...state,
        payWagesModes_list: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case PAY_COMPONENTS:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case PAY_COMPONENTS_SUCCESSFULL:
      state = {
        ...state,
        payComponents_list: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_PROFILE_PICTURE_FILE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case GET_PROFILE_PICTURE_FILE_SUCCESSFULLY:
      console.log(action.payload.response)
      state = {
        ...state,
        profilePicFile: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;
    case GET_PROFILE_PICTURE:
      state = {
        ...state,
        loading: true,
        page: "EmployeeReducer",
      };
      break;
    case GET_PROFILE_PICTURE_SUCCESSFULLY:
      state = {
        ...state,
        profilePicUrl: action.payload.response,
        loading: false,
        page: "EmployeeReducer",
      };
      break;

    case HRMS_DASHBOARD:
      state = {
        ...state,
        loading: true,
          page: "EmployeeReducer"
      }
      break;
    case HRMS_DASHBOARD_SUCCESSFULLY:
      state = {
        ...state,
        HrmsDashboardData: action.payload.response,
        loading: false,
        page: "EmployeeReducer"
      }
      break;
      case APPROVAL_HIERARCHY:
        state = {
          ...state,
          loading: true,
        };
        break;
      case APPROVAL_HIERARCHY_SUCCESSFULLY:
        state = {
          ...state,
          approval_hierarchy: action.payload.response,
          loading: true,
        };
        break;
        case VIEW_BY_ID_APPROVAL:
          state = {
            ...state,
            loading: true,
          };
          break;
        case VIEW_BY_ID_APPROVAL_SUCCESSFULLY:
          state = {
            ...state,
            approval_hierarchy_view_byId: action.payload.response,
            loading: true,
          };
          break;
          case WORKER_DETAILS_PROGRESS:
            state = {
              ...state,
              loading: true,
            };
            break;
          case WORKER_DETAILS_PROGRESS_SUCCESSFULLY:
            state = {
              ...state,
            worker_details_progress: action.payload.response,
              loading: true,
            };
            break;
            case GET_WORKER_DETAILS_PROGRESS:
              state = {
                ...state,
                loading: true,
              };
              break;
            case GET_WORKER_DETAILS_PROGRESS_SUCCESSFULLY:
              state = {
                ...state,
              Get_worker_progress: action.payload.response,
                loading: true,
              };
              break;
              case DELETE_WORKER_EDUCATIONAL_DETAILS:
                state = {
                  ...state,
                  loading: true,
                };
                break;
              case DELETE_WORKER_EDUCATIONAL_DETAILS_SUCCESSFULLY:
                state = {
                  ...state,
                delete_educational_details: action.payload.response,
                  loading: true,
                };

                break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EmployeeReducer;
