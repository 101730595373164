import { CHECK_LOGIN, CHECK_TOKEN, LOGIN_USER_SUCCESSFUL, API_ERROR, LOGOUT_USER, LOGOUT_USER_SUCCESS, RESET_PASSWORD, CHECK_TOKEN_SUCCESSFUL, FIRST_COMPANY, FIRST_COMPANY_SUCCESSFULL } from './actionTypes';

const initialState = {
    loginError: "", message: null, loading: false, resp: [], firstCompanyList:[]
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_LOGIN:
            state = {
                ...state,
                loading: true
            }
            break;
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false
            }
            break;
        case CHECK_TOKEN_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                resp: action.payload,
            }
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;

        case LOGOUT_USER_SUCCESS:
            state = { ...state };
            break;
        case CHECK_TOKEN:
            state = { ...state };
            break;    
        case RESET_PASSWORD:
            state = {
                ...state,
                loading: true
            }
            break;
        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginError: action.payload
            }
            break;
            case FIRST_COMPANY:
                state = {
                    ...state,
                    loading: true
                }
                break;
            case FIRST_COMPANY_SUCCESSFULL:
                state = {
                    ...state,
                    loading: false,
                    firstCompanyList: action.payload
                }
                break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;