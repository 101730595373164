import React, {Component} from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

class DynamicSelectSimple extends Component{
    constructor(props){
        super(props);
        this.setState={}
    }

    //On the change event for the select box pass the selected value back to the parent
    handleChange = (event) =>
    {
        let selectedValue = event.target.value;        
        let strUser = event.target.options[event.target.selectedIndex].text;
        this.props.onSelectChange(selectedValue, strUser);
        
    }

    render(){
        let arrayOfData = this.props.arrayOfData;        
        let options = arrayOfData.map((data,key) =>     
           {
            return( <option key={key} value={data.value} selected={data.label === this.props.selected ? this.props.selected : ""}>{data.name.split('^')[0]}</option>  ) 
           }
        );

            
        

            return (                
                <FormControl sx={{ width: "100%" }}  className="customDropdown">
                    {this.props.lable && <div><label htmlFor="app-input-field">{this.props.lable}</label></div> }
                    <select name="customSearch"
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name" onChange={this.handleChange} classNamePrefix="react-select">
                        {options}
                    </select>
                </FormControl>
            )
    }
}

export default DynamicSelectSimple;