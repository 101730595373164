export const GET_SUPPLIER_TYPE_MASTER_LIST="getSupplierTypeMasterList";
export const GET_SUPPLIER_TYPE_MASTER_LIST_SUCCESSFULL="getSupplierTypeMasterListSuccessfull";

export const CREATE_SUPPLIER_TYPES="createSupplierTypes";
export const CREATE_SUPPLIER_TYPES_SUCCESSFULL="createSupplierTypesSuccessfull";

export const UPDATE_SUPPLIER_TYPES="updateSupplierTypes";
export const UPDATE_SUPPLIER_TYPES_SUCCESSFULL="updateSupplierTypesSuccessfull";

export const API_ERRORS='apiErrors';


