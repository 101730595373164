import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import {
  LEAVETYPELIST,
  CREATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE,
} from "./actionTypes";
import {
  LeaveTypeListSuccessfull,
  createLeaveTypeSuccessfull,
  updateLeaveTypeSuccessfull,
} from "./actions";
import { getList, getListurl, postAdd } from "../../../helpers/Server_Helper";
import swal from "sweetalert";

function* getLeaveTypeList({ payload: { url,data, history } }) {
  try {
    const response = yield call(postAdd, url,data);
    yield put(LeaveTypeListSuccessfull({ response }));
    console.log("LeaveType.....", response);
  } catch (error) {
    console.log(error);
  }
}

function* CreateLeaveType({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
   if(response.data.status === true) {
    yield put(createLeaveTypeSuccessfull({ response }));
    swal(response.data.message,{icon: "success"})
    history.push('/leaveTypes')
   }else{
    swal(response.data.message,{icon: "error"})

   }
  } catch (error) {
    console.log(error);
  }
}
function* UpdateLeaveType({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    if(response.data.status === true) {
      yield put(updateLeaveTypeSuccessfull({ response }));
      swal(response.data.message,{icon: "success"})
      history.push('/leaveTypes')
    }else{
      swal(response.data.message,{icon: "error"})
    }
  } catch (error) {
    console.log(error);
  }
}

export function* watchLeaveTypeList() {
  yield takeEvery(LEAVETYPELIST, getLeaveTypeList);
}
export function* watchCreateLeaveTypeMaster() {
  yield takeEvery(CREATE_LEAVE_TYPE, CreateLeaveType);
}
export function* watchUpdateLeaveTypeMaster() {
  yield takeEvery(UPDATE_LEAVE_TYPE, UpdateLeaveType);
}

function* LeaveTypeListSaga() {
  yield all([
    fork(watchLeaveTypeList),
    fork(watchCreateLeaveTypeMaster),
    fork(watchUpdateLeaveTypeMaster),
  ]);
}

export default LeaveTypeListSaga;
