import {
  OFFICIAL_API_ERRORS,
  OFFICIALDETAILS_ADD,
  OFFICIALDETAILS_ADD_SUCCESSFUL,
  GET_ACCTYPES_LIST,
  ACCTYPES_LIST_SUCCESS,
  CLEAR_FORM,
  COMPANY_DIT,
  COMPANY_DIT_SUCCESS,
  BASIC_DETAILS_DATA,
  FILE_UPLOAD_LIST,
  FILE_UPLOAD_LIST_SECCESSFULL,
  UPLOADTANFILE,
  UPLOADTANFILE_SUCCESSFULL,
  UPLOADTANFILESLIST,
  UPLOADTANFILESLIST_SUCCESSFULL,
  UPLOADCINFILE,
  UPLOADCINFILESLIST_SUCCESSFULL,
  UPLOADCINFILE_SUCCESSFULL,
  UPLOADCINFILESLIST,
} from "./actionTypes";




export const officialdetailsAdd = (url, data, id, history) => {
  console.log(url);
  return {
    type: OFFICIALDETAILS_ADD,
    payload: { url, data, id, history },
  };
};

export const officialdetailsAddSuccessful = (response) => {
  console.log(response);
  return {
    type: OFFICIALDETAILS_ADD_SUCCESSFUL,
    payload: response,
  };
};


export const officialapiErrors = (error) => {
  return {
    type: OFFICIAL_API_ERRORS,
    payload: error,
  };
};

export const getMasterAccountTypes = () => {
  return {
      type: GET_ACCTYPES_LIST       
  }
}

export const accounttypeslist = (acctypes) => {
  return {
      type: ACCTYPES_LIST_SUCCESS,
      payload: acctypes
  }
}

export const getCompanyDits = (url, data, history) => {
  return {
      type: COMPANY_DIT,
      payload: { url, data, history },    
  }
}

export const companyditssuccess = (resp) => {
  return {
      type: COMPANY_DIT_SUCCESS,
      payload: resp
  }
}

export const clearForm = () => {  
  return {
      type: CLEAR_FORM
  }
  
}

export const basicDetailsData = (data) => {  
  return {

      type: BASIC_DETAILS_DATA,
      payload: data
  }
  
}
export const getUploadedFilesList = (url, data, history) => {
  return {
      type: FILE_UPLOAD_LIST,
      payload: { url, history },    
  }
}

export const getUploadedFilesListSuccessfull = (resp) => {
  return {
      type: FILE_UPLOAD_LIST_SECCESSFULL,
      payload: resp
  }
}
//tan file 
export const getTanUploadedFilesList = (url, data, history) => {
  return {
      type:UPLOADTANFILESLIST,
      payload: { url, history },    
  }
}

export const getTanUploadedFilesListSuccessfull = (resp) => {
  return {
      type: UPLOADTANFILESLIST_SUCCESSFULL,
      payload: resp
  }
}

export const postUploadTanFileList = (url,data,history) =>{
  return {
      type:UPLOADTANFILE,
      payload:{url,data,history}
  }
}

export const UploadTanFileListSuccessfull = (data)=>{
  return {
      type:UPLOADTANFILE_SUCCESSFULL,
      payload:data
  }
}
//cin file

export const getCinUploadedFilesList = (url, data, history) => {
  return {
      type:UPLOADCINFILESLIST,
      payload: { url, history },    
  }
}

export const getCinUploadedFilesListSuccessfull = (resp) => {
  return {
      type: UPLOADCINFILESLIST_SUCCESSFULL,
      payload: resp
  }
}

export const postUploadCinFileList = (url,data,history) =>{
  return {
      type:UPLOADCINFILE,
      payload:{url,data,history}
  }
}

export const UploadCinFileListSuccessfull = (data)=>{
  return {
      type:UPLOADCINFILE_SUCCESSFULL,
      payload:data
  }
}