import{
    API_ERRORS,
    BANK_ACCOUNT_LIST_API,
    BANK_ACCOUNT_LIST_API_SUCCESSFULL,
    BRANCH_SELECTION_LIST,
    BRANCH_SELECTION_LIST_SUCCESSFULL,
    CREATE_BANK_ACCOUNT,
    CREATE_BANK_ACCOUNT_SUCCESSFULL,
    UPDATE_BANK_ACCOUNT,
    UPDATE_BANK_ACCOUNT_SUCCESSFULL,
}from './actionTypes'

export const bank_account_list_api=(url,data,history)=>{
    return{
        type:BANK_ACCOUNT_LIST_API,
        payload:{url,data,history},
    };
}
export const bank_account_list_api_successfull=(data)=>{
    return{
        type:BANK_ACCOUNT_LIST_API_SUCCESSFULL,
        payload:data,
    };
}

export const create_bank_account=(url,data,history)=>{
    return{
        type:CREATE_BANK_ACCOUNT,
        payload:{url,data,history}
    }
}

export const create_bank_account_successfull =(data)=>{
    return{
        type:CREATE_BANK_ACCOUNT_SUCCESSFULL,
        payload:data
    }
}

export const update_bank_account=(url,data,history)=>{
    return {
        type:UPDATE_BANK_ACCOUNT,
        payload:{url,data,history}
    }
}

export const update_bank_account_successfull=(data)=>{
    return {
        type:UPDATE_BANK_ACCOUNT_SUCCESSFULL,
        payload:data
    }
}

export const BranchSelectionList=(url,history)=>{
    return{
        type:BRANCH_SELECTION_LIST,
        payload:{url,history}
    }
}

export const BranchSelectionListSuccessfull =(data)=>{
    return{
        type:BRANCH_SELECTION_LIST_SUCCESSFULL,
        payload:data
    }
}

export const apiErrors=(error)=>{
    return {
        type: API_ERRORS,
        payload:error,
    };
};
