import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Grid, Box } from "@mui/material";
// import DynamicSelect from "../../../components/Dropdown/DynamicSelect";
import DynamicSelect from "./DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import CustomButton from "../../../components/Buttons/Buttons";
import Link from "@mui/material/Link";
// import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { serverApi } from "../../../helpers/Consts";
import {
  createLeaveType,
  updateLeaveType,
} from "../../../store/Master/LeaveTypeMaster/actions";
// import { createLeaveTypeMaster } from '../../../store/Master/ItemMaster/actions';

import moment from "moment";
import swal from "sweetalert";

class CreateLeaveTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyId"),
      payable: "",
      payableValue: "",
      leaveTypeCode: "",
      leaveTypeDescription: "",
      createdBy: null,
      createdOn: "",
      id: "",
    };
  }
  /* Calling Create and Update Api's to Actions and validation part */
  onClickCreateUpdate = () => {
    const { leaveTypeCode, leaveTypeDescription } = this.state;
    if (this.props.location.rowdata) {
      const data = {
        leaveTypeId: this.props.location.rowdata.leaveTypeId,
        leaveTypeCode: this.state.leaveTypeCode,
        leaveTypeDescription: this.state.leaveTypeDescription,
        payable: this.state.payable,
        isActive: 1,
        updatedBy: this.state.userDit.userId,
        companyId: localStorage.getItem("companyIdconfig"),
      };
      // if (
      //   this.state.leaveTypeCode === "" ||
      //   this.state.leaveTypeDescription === "" ||
      //   this.state.payable === ""
      // ) {
      //   swal("Please Enter the mandatory fields !!!", { icon: "error" });
      // }
      if (
        this.state.leaveTypeCode === "" 
      ) {
        swal("Please Enter the Leave Type Code !!!");
        return false
      } 
      if (
        this.state.leaveTypeDescription === "" 
      ) {
        swal("Please Enter the Leave Type Description !!!");
        return false
      } 
      if(this.state.payable===""){
        swal("Please Enter the Payable !!!");
        return false
      }
       else {
        if (
          localStorage.getItem("companyIdconfig") !== undefined &&
          localStorage.getItem("companyIdconfig") !== "undefined" &&
          localStorage.getItem("companyIdconfig") !== null
        ) {
          this.props.updateLeaveType(
            serverApi.UPDATE_LEAVE_TYPE,
            data,
            this.props.history
          );
        }else{
          swal("Please select the company from dropdown", {icon: "error"});
        }
       
      }
    } else {
      
      const data = {
        companyId: localStorage.getItem("companyIdconfig"),
        leaveTypeCode: this.state.leaveTypeCode,
        leaveTypeDescription: this.state.leaveTypeDescription,
        createdBy: this.state.userDit.userId,
        payable: this.state.payable,
      };
      if (
        this.state.leaveTypeCode === "" 
      ) {
        swal("Please Enter the Leave Type Code !!!");
        return false
      } 
      if (
        this.state.leaveTypeDescription === "" 
      ) {
        swal("Please Enter the Leave Type Description !!!");
        return false
      } 
      if(this.state.payable===""){
        swal("Please Enter the Payable !!!");
        return false
      }
      else {
        // swal("created successfully");
        if (
          localStorage.getItem("companyIdconfig") !== undefined &&
          localStorage.getItem("companyIdconfig") !== "undefined" &&
          localStorage.getItem("companyIdconfig") !== null
        ) {
          this.props.createLeaveType(
            serverApi.CREATE_LEAVE_TYPE,
            data,
            this.props.history
          ); // calling create leaveTypes master api
        }else{
          swal("Company is not selected", {icon: "error"})
        }
      
      }
      console.log(
        this.state.leaveTypeCode,
        this.state.leaveTypeDescription,
        this.state.payable
      );
    }
  };
  onClickCancel = () => {
    this.props.history.push("/leaveTypes");
  };
  onhandlechangeValue = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "leaveTypeCode") {
      const leavecode = event.replace(/[^a-zA-Z0-9\s]+/g, '');
      this.setState({
        leaveTypeCode: leavecode,
      });
    }
    if (key === "leaveTypeDescription") {
      this.setState({
        leaveTypeDescription: event,
      });
    }
  };

  onclickPopUP() {
    this.setState({
      confirm: false,
    });
  }
  handlePropSelectChange = (selectedValue, selectedName, name) => {
    if (name === "payable") {
      this.setState({
        payable: selectedValue,
        payableValue: selectedValue,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.props.location.rowdata) {
      this.setState({
        leaveTypeCode: this.props.location.rowdata.leaveTypeCode,
        leaveTypeDescription: this.props.location.rowdata.leaveTypeDescription,
        createdBy: this.props.location.rowdata.createdBy,
        payable: this.props.location.rowdata.payable,
        leaveTypeId: this.props.location.rowdata.leaveTypeId,
      });
      const dateEntered = this.props.location.rowdata.createDateTime;
      const dateIsValid = moment(dateEntered).format("LLLL");
      this.setState({
        createdOn: dateIsValid,
      });
    }
  }

    handleMenuOpen=()=>{
    this.setState({
      isDropdownOpen:false
    })
  }

  handleScroll = (event) => {
    const { scrollLeft, scrollTop } = event.target;
    this.setState({
      isDropdownOpen:true
    })
  };

  handleScroll = () => {
    // Add your custom logic for handling the scroll event here.
    console.log('Scrolling...');
    this.setState({
      isDropdownOpen:true
    })
  };

  render() {
    console.log("getting the updated data", this.state.leaveTypeCode);
    return (
      <div>
        <div className="consoleFormContainer">
          <h5>Fill Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Leave Type Code"
                        value={this.state.leaveTypeCode}
                        caps="OFF"
                        onChange={this.onhandlechangeValue("leaveTypeCode")}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <TextFieldReq
                        label="Leave Type Description"
                        value={this.state.leaveTypeDescription}
                        onChange={this.onhandlechangeValue(
                          "leaveTypeDescription"
                        )}
                        required
                      />
                    </div>
                  </Box>
                  <Box gridColumn="span 3">
                    <div className="consoleTextFieldBlock">
                      <DynamicSelect
                        arrayOfData={payable}
                        className="dropdownBlockContainer"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        onSelectChange={this.handlePropSelectChange}
                        isDropdownOpen={this.state.isDropdownOpen}
                        handleMenuOpen={this.handleMenuOpen}
                        selected={this.state.payable}
                        update={this.props.location.state ? 1 : 0}
                        name="payable"
                        label="Payable"
                        required
                      />
                    </div>
                  </Box>
                  {this.props.location.rowdata === undefined ? (
                    ""
                  ) : (
                    <Box gridColumn="span 6">
                      <div className="consoleUpdateTextBlock">
                        <div>
                          <span className="taxLabel">
                            Created By : <b>{this.state.createdBy}</b>
                          </span>
                        </div>
                        <div>
                          <span className="taxLabel">
                            Created On :<b> {this.state.createdOn}</b>
                          </span>
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link>
            <CustomButton
              label={"Cancel"}
              className="greenBorderButton"
              handleClick={this.onClickCancel}
            />
          </Link>
          {this.props.location.rowdata !== undefined ? (
            <CustomButton
              label={"Update"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          ) : (
            <CustomButton
              label={"Create"}
              className="greenButton"
              handleClick={this.onClickCreateUpdate}
              type="sumbit"
            />
          )}
        </div>
      </div>
    );
  }
}

const payable = [
  {
    value: "Y",
    label: "Yes",
    name: "Yes",
  },
  {
    value: "N",
    label: "No",
    name: "No",
  },
];
const mapStatetoProps = (state) => {
  const { Create_Leave_Type, Update_Leave_Type } = state.LeaveTypeListReducer;
  return { Create_Leave_Type, Update_Leave_Type };
};

export default withRouter(
  connect(mapStatetoProps, {
    createLeaveType,
    updateLeaveType,
    // createLeaveTypeMaster
  })(CreateLeaveTypes)
);
