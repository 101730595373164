/****
 * Created By Nagesh Medisetty 01-02-2022
 * Normal TextField
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

const TextFieldSample = ({
  value,
  label,
  caps,
  placeholder,
  type,
  onChange,
}) => {
  const [error, setError] = useState(false);

  const handleChange = (event,props) => {
    const { value } = event.target;
    if(caps==='ON'){
      onChange(value.toUpperCase()); 
    }else{
      onChange(value); 
    }     
  };

  return (
    <div className="TextFieldBlock">
      {label && <label htmlFor="app-input-field">{label}</label>}
      {type === "textarea" ? (
        <textarea
          className= {error ? "TextField errorTextField" : "TextField" } 
          placeholder={placeholder}
          value={value}
          defaultValue={value}
          onChange={handleChange}
        />
      ) : (
        <input
          type={type}
          value={value}
          className= {error ? "TextField errorTextField" : "TextField" } 
          placeholder={placeholder}
          onChange={handleChange}
        />
      )}
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

TextFieldSample.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

TextFieldSample.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
};

export default TextFieldSample;
