import React, { useState, useEffect } from 'react'
import LeftSidebar from "../../Sidebar/LeftSidebar";
import { Grid, Box, Paper } from "@mui/material";
import DashboardHeader from "../Dashboard/DashboardHeader";
import CompanyIcon from "../../assets/images/company_unactive.png";
import UserIcon from "../../assets/images/profile_unactive.png";
import ModuleIcon from "../../assets/images/module_unactive.png";
import NextDueIcon from "../../assets/images/invoiceNextDueIcon.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LocationIcon from "../../assets/images/location.png";
import FacialIcon from "../../assets/images/facial.png";
import WhatsAppIcon from "../../assets/images/whatsaap.png";
import TallyIcon from "../../assets/images/tally.png";
import BiometricIcon from "../../assets/images/biometric.png";
import PaymentIcon from "../../assets/images/payment.png";



// import FacialIcon from "../../assets/images/facialIcon.png";

import Button from "@mui/material/Button";
import { GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import DashboardCard from "./DashboardCard";
import LineChartComponent from "../../components/LineChart/LineChartComponent";
import Profile from "../../assets/images/profileIcon.png";
import MailIcon from "../../assets/images/MailIconGreen.png";
import Tally from "../../assets/images/TallyIconGreen.png";
import DataIcon from "../../assets/images/DataIcon.png";
import { styled } from "@mui/material/styles";
import axios from 'axios';

import cons, { serverApi, serverVars } from "../../helpers/Consts";



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  padding: "11px",
  boxShadow: "none",
  border: "1px solid #e5e5e5",
  borderRadius: "10px",
}));
function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

const rows1 = [
  createRow("Modules (1)", 100, 1.15),
  createRow("No. of Users (400)", 10, 45.99),
  createRow("No. of Companies (8)", 2, 17.99),
  createRow("Additions (2)", 2, 17.99),
  createRow("Total Amount", 2, 17, 99),
];


const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  {
    field: "age",
    headerName: "Age",
    type: "number",
    width: 90,
  },
  {
    field: "fullName",
    headerName: "Full name",
    description: "This column has a value getter and is not sortable.",
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, "firstName") || ""} ${
        params.getValue(params.id, "lastName") || ""
      }`,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        const api: GridApi = params.api;
        const thisRow: Record<string, GridCellValue> = {};

        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );

        return alert(JSON.stringify(thisRow, null, 4));
      };

      return <Button onClick={onClick}>Click</Button>;
    },
  },
];
const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];
const pdata = [
  {
    name: "01",
    student: 11,
  },
  {
    name: "02",
    student: 15,
  },
  {
    name: "03",
    student: 5,
  },
  {
    name: "04",
    student: 10,
  },
  {
    name: "05",
    student: 9,
  },
  {
    name: "06",
    student: 10,
  },
];
const facialRecData = [
  {
    name: "01",
    student: 34,
  },
  {
    name: "02",
    student: 65,
  },
  {
    name: "03",
    student: 45,
  },
  {
    name: "04",
    student: 10,
  },
  {
    name: "05",
    student: 9,
  },
  {
    name: "06",
    student: 67,
  },
];
export default function Dashboard() {  
  const [dashboard, setDashboard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  var userDit= JSON.parse(localStorage.getItem("authUser"));
  useEffect(() =>{
    const loadDashBoard = async () =>{   
      const response = await axios.get(serverApi.GET_ORG_DASHBOARD_DATA+userDit.orgId);
      if(response){
          if(response.data){
            if(response.data.status){
              if(response.data.result){
                setDashboard(response.data.result);
              }else{
                setDashboard(null);
              }
            }else{
              setDashboard(null);
            }
          }else{
            setDashboard(null);
          }
      }else{
        setDashboard(null);
      }
      
    }
    loadDashBoard();
  },[]);
  if(dashboard){
    localStorage.setItem("companys_purchased",dashboard.companys);
    localStorage.setItem("companys_created",dashboard.usedcompanys);
    localStorage.setItem("users_purchased",dashboard.users);
    localStorage.setItem("users_created",dashboard.usedusers);
  }
  return (
    <>
      <Grid container className="mainContainer">
        <LeftSidebar />
        <Grid className="mainContent dashboardContent" style={{width:"95%",display:"block"}}>
          <div className="consoleContainer">
            <DashboardHeader  />
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <DashboardCard
                      dashboardCardBg="dashboardCompanyContent"
                      iconClass="dashboardCardCompanyIcon"
                      CardIcon={CompanyIcon}
                      cardType="Company Added"
                      cardCount={dashboard ? dashboard.usedcompanys+"/"+dashboard.companys : "0/10"}
                    />
                  </Box>
                  <Box gridColumn="span 4">
                    <DashboardCard
                      dashboardCardBg="dashboardUserContent"
                      iconClass="dashboardCardUserIcon"
                      CardIcon={UserIcon}
                      cardType="Users Created"
                      cardCount={dashboard ? dashboard.usedusers+"/"+ dashboard.users : "0/250"}
                    />
                  </Box>
                  <Box gridColumn="span 4">
                    <DashboardCard
                      dashboardCardBg="dashboardCompanyContent"
                      iconClass="dashboardCardCompanyIcon"
                      CardIcon={ModuleIcon}
                      cardType="Module Selected"
                      cardCount={dashboard ? dashboard.modules + "/9" : "0/9"}
                    />
                  </Box>
                </Box>
                <div className="dashboardGraphBlock" style={{display:"none"}}>
                  <h1>Weekly Usage Status</h1>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <LineChartComponent
                        statusOf="Whatsapp"
                        statusMobile="(+91 9988989989)"
                        fromDate="24th Dec'22"
                        toDate="30th Dec'22"
                        actualCount="2389"
                        totalCount="msgs"
                        lastUpdateMsg="Last updated on 1st Jan'22"
                        strokeColor="#560ef0"
                        data={pdata}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <LineChartComponent
                        statusOf="Facial Recognition"
                        fromDate="24th Dec'22"
                        toDate="30th Dec'22"
                        actualCount="68"
                        totalCount="recognitions"
                        lastUpdateMsg="Last updated on 1st Jan'22"
                        strokeColor="#ff7f5d"
                        data={facialRecData}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div className="dashboardDisplayCardsContainer" style={{display:"none"}}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className="dashboardDisplayCards"
                      maxWidth="1200px"
                    >
                      {/* <Grid item xs={6}>
                        <Item className="employeeDetails">
                          <h1>68 Users added to this Organisation</h1>
                          <div className="employees">
                            <div className="userprofilepic">
                              <img
                                src={Profile}
                                alt="playstore"
                                className="profilePic"
                              />
                              <img
                                src={Profile}
                                alt="playstore"
                                className="profilePic"
                              />
                              <img
                                src={Profile}
                                alt="playstore"
                                className="profilePic"
                              />
                              <img
                                src={Profile}
                                alt="playstore"
                                className="profilePic"
                              />
                              <img
                                src={Profile}
                                alt="playstore"
                                className="profilePic"
                              />
                            </div>
                            <div className="buttonBlock">
                              <button type="button">+62</button>
                            </div>
                          </div>
                          <a href="#">+Add Users</a>
                        </Item>
                      </Grid> */}
                      <Grid item xs={6}>
                        <Item className="profileInfo">
                          <div className="mailBlock">
                            <img
                              src={MailIcon}
                              alt="playstore"
                              className="mailpic"
                            />
                            <h1>Email Sync</h1>
                          </div>
                          <div className="border">
                            <div className="borderLeft"></div>
                            <div className="addressBlock">
                              <h2>Swarna Technology</h2>
                              <p>Swarnatech@gmail.com</p>
                            </div>
                          </div>
                        </Item>
                      </Grid>
                      <Grid item xs={6}>
                        <Item className="tally">
                          <div className="tallyDetails">
                            <img
                              src={Tally}
                              alt="playstore"
                              className="tallyIcon"
                            />
                            <h1>Tally Sync</h1>
                          </div>
                          <div className="dataBlock">
                            <img
                              src={DataIcon}
                              alt="playstore"
                              className="dataIcon"
                            />
                            <p>No info yet</p>
                          </div>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  className="invoiceSection"
                  // sx={{
                  //   boxShadow: 3,
                  // }}
                >
                  {/* <Box className="invoiceSectionHeader">
                    <h1>
                      Invoice No. {dashboard ? dashboard.invno : ""}<span style={{fontWeight:"bold"}}>( {dashboard ? dashboard.plan : ""} )</span>
                    </h1>
                    <span>Issued On: 23rd Jan'22{dashboard ? dashboard.invdate : ""}</span>
                  </Box> */}
                  <TableContainer>
                    <h5>Current Plan Details</h5>
                    <Table sx={{ minWidth: 100 }} aria-label="spanning table">
                      <TableBody>
                        {/* {rows1.map((row) => (
                          <TableRow key={row.desc}>
                            <TableCell>{row.desc}</TableCell>
                            <TableCell align="right">
                              {ccyFormat(row.price)}
                            </TableCell>
                          </TableRow>
                        ))} */}
                          <TableRow key="1">
                            <TableCell>No of Companys</TableCell>
                            <TableCell align="right">
                              {dashboard ? dashboard.companys : "0"}
                            </TableCell>
                          </TableRow>
                          <TableRow key="1">
                            <TableCell>No of Users</TableCell>
                            <TableCell align="right">
                              {dashboard ? dashboard.users : "0"}
                            </TableCell>
                          </TableRow>
                          <TableRow key="1">
                            <TableCell>Modules ({dashboard ? Number(dashboard.modules) : 0}) </TableCell>
                            <TableCell align="right">
                              {"₹ " +ccyFormat(dashboard ? Number(dashboard.modules_amt) : 0)}
                            </TableCell>
                          </TableRow>
                          <TableRow key="1">
                              {dashboard ? dashboard.module_addons ? dashboard.module_addons.map((mod)=>(
                                <div><TableCell style={{fontSize:"10px"}}>&nbsp;&nbsp;<sapn  style={{color:"#c5c5c5"}}>{mod ? mod.name : ""}</sapn></TableCell></div>                                   
                              )):"": ""}
                            
                                                 
                          </TableRow>
                          <TableRow key="1">
                            <TableCell>Addons({dashboard ? dashboard.addons : 0})</TableCell>
                            <TableCell align="right">
                              {"₹ " +ccyFormat(dashboard ? Number(dashboard.addons_amt) : 0)}
                            </TableCell>
                          </TableRow>
                          <TableRow key="1">
                              {dashboard ? dashboard.purchased_addons ? dashboard.purchased_addons.map((add)=>(
                                <div><TableCell style={{fontSize:"11px"}}>&nbsp;&nbsp;<sapn  style={{color:"#c5c5c5"}}>{add ? add.name : ""}</sapn></TableCell></div>  
                                                                
                              )):"": ""}
                            
                                                  
                          </TableRow>
                          <TableRow key="1">
                            <TableCell>Total Amount</TableCell>
                            <TableCell align="right">
                              {"₹ " +ccyFormat(dashboard ? Number(dashboard.total_amt) : 0)}
                            </TableCell>
                          </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* <Box className="invoiceSectionFooter">
                    <Box className="invoiceFooterContent">
                      <h5>Payment Details</h5>
                      <Box className="invoicePaidInfo">
                        <span>Payment Status</span>
                        <span>{dashboard ? dashboard.Payment_Status : "Invoice Not Generated"}</span>
                      </Box>
                    </Box>
                    <Box className="invoiceFooter">
                      <h1>
                        <img src={NextDueIcon} alt="" className="invoiceNextDueIcon" />
                        Next payment due on {dashboard ? dashboard.next_due_on : ""}
                      </h1>
                    </Box>
                  </Box> */}
                </Box>
                <Box
                  className="addonSection"
                  sx={{
                    //boxShadow: 3,
                    borderRadius: 3,
                    mt: 3,
                  }}
                >
                  <List
                    sx={{
                      width: "100%",
                      // maxWidth: 360,
                      p: 0,
                      m: 0,
                      fontSize: "20px",
                    }}
                  >
                    <h1 className="headingText">Addons</h1>                    
                      {dashboard ? dashboard.purchased_addons.map((row, key) =>(<>
                        <ListItem>
                      <ListItemAvatar sx={{ display: "flex", justifyContent: "center",  flex: "0", }} >
                        {row.icon==='LocationIcon' ? (<img src={LocationIcon} alt="" className="iconSize" />):(<></>)}
                        {row.icon==='FacialIcon' ? (<img src={FacialIcon} alt="" className="iconSize" />):(<></>)}
                        {row.icon==='WhatsAppIcon' ? (<img src={WhatsAppIcon} alt="" className="iconSize" />):(<></>)}
                        {row.icon==='TallyIcon' ? (<img src={TallyIcon} alt="" className="iconSize" />):(<></>)}
                        {row.icon==='BiometricIcon' ? (<img src={BiometricIcon} alt="" className="iconSize" />):(<></>)}
                        {row.icon==='PaymentIcon' ? (<img src={PaymentIcon} alt="" className="iconSize" />):(<></>)}
                        {/* <img src={row.icon} alt="" className="iconSize" /> */}
                      </ListItemAvatar>
                      <ListItemText primary={row.name} sx={{ color: "#004b73", flex: 2, pl: 2, }} />
                      <ListItemText primary="Purchased" className="statusGreen" sx={{ flex: 1 }} />
                    </ListItem>
                      </>)):(<></>)}
                    
                  </List>
                  <Link
                    href="/ModuleSelection"
                    underline="always"
                    sx={{
                      p: 2,
                      mb: 3,
                      display: "flex",
                      color: "#94cd00",
                      textDecorationColor: "#94cd00",
                      fontWeight: "bold",
                    }}
                  >
                    {"+ Add more"}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
