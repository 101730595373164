import React, { Component } from "react";
import { Grid, Box } from "@mui/material";
import DynamicSelect from "../LeaveTypes/DynamicSelect";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import TextFieldAlfaNum from "../../../components/TextField/TextFieldAlfaNum";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import { withRouter } from "react-router-dom";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { connect } from "react-redux";
import { serverApi } from "../../../helpers/Consts";
import moment from "moment";
import { getLeaveSelectionList } from "../../../store/Master/LeavePolicy/actions";
import swal from "sweetalert";

class LeavePolicyLineItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 1,
      leaveSelectionList: [],
      description: this.props.header.description,
      fundsOut: this.props.header.fundsOut,
      delete: false,
      products: this.props.products,
      totalAmount: 0,
      length: 0,
      selectedRow: [],
      selectedValue: "",
      lastRow: [],
      lastVal: "",
      userDit: JSON.parse(localStorage.getItem("authUser")),
    };
  }

  componentDidMount() {
    this.props.getLeaveSelectionList(
      serverApi.GET_LEAVE_TYPE_SELECTION +
        localStorage.getItem("companyIdconfig"),
      this.props.history
    );
  }

  componentWillReceiveProps(props) {
    // if (props.leaveSelectionList) {
    //   this.setState({
    //     leaveSelectionList: props.leaveSelectionList,
    //   });
    // }

    if(props.leaveSelectionList){
      var list=[]
      list=props.leaveSelectionList.filter((item)=>{
        if(item.value !==0){
        return item;
      }
     })
     this.setState({
      leaveSelectionList:list,
     })
    }


  }

  handleChange = (data) => (event) => {
    const { key, row } = data;
    const { value } = event.target;
    console.log("hellooooooo", value);
    console.log(key);
    this.setState({ [key]: event });
    let mname = key.split("_");
    console.log("printing mname", mname[0]);

    if (mname[0] === "leavesPerYear") {
      if (/^[0-9]+$/.test(value) || value===""){
      row.leavesPerYear = value;
      }
    }

    if (mname[0] === "maxConsecutiveCount") {
      if (/^[0-9]+$/.test(value) || value===""){
      row.maxConsecutiveCount = value;
      }
    }

    if (mname[0] === "maxLeavesPerYear") {
      if (/^[0-9]+$/.test(value) || value===""){
      row.maxLeavesPerYear = value;
      }
    }
  };

  handleChangedropdown = (selectedValue, selectedName, name, selectedRow) => {
    console.log("selected row", name);
    let lastVal = selectedRow.id;
    this.setState({
      rowCount: this.state.rowCount + 1,
    });
    let lastRow = this.state.products[this.state.products.length - 1];
    if (name === "carryFrwStatus") {
      console.log("getting anmeeee", name);
      selectedRow.carryFrwStatus = selectedValue;
    }

    if (name === "encashmentStatus") {
      selectedRow.encashmentStatus = selectedValue;
    }

    if (name === "leaveTypeId") {
      selectedRow.leaveTypeId = selectedValue;
    }

    if (selectedRow === lastRow && selectedValue !== "0" && lastVal === "0") {
      this.handleAddEvent();
    }
  };

  handleAddEvent(evt) {
    var userId = JSON.parse(localStorage.getItem("authUser"));
    let rowId = this.state.rowCount;
    let product = {
      id: "0",
      no: rowId,
      carryFrwStatus: "",
      encashmentStatus: "",
      leaveTypeId: 0,
      leavesPerYear: "",
      maxConsecutiveCount: "",
      maxLeavesPerYear: "",
      yearEndDate: null,
      yearStartDate: null,
    };
    this.setState({ rowCount: rowId + 1 });
    this.state.products.push(product);
  }

  onDel() {
    this.setState({
      delete: false,
    });
  }

  onDelEvent = (data) => (event) => {
    const { key, row } = data;
    this.setState({ [key]: event });
    if (this.props.location.rowdata) {
      // const filterData = this.props.products;
      // console.log("getting the deleted item list inside if condition", filterData)
      console.log("getting the row in update page", row);
      const filterData = this.props.products.filter(
        (item) => item.leavePolicyDtlId !== row.leavePolicyDtlId
      );
      console.log("getting the deleted item list", filterData);
      //!
      if (filterData.length === 0) {
        console.log("Cannot delete the only record.",filterData.length);
        swal("last record cannot be deleted",{ icon: "error" })
        return;
      }
      // !
      this.setState({
        products: filterData,
      });
      this.props.delete(filterData);
    } else {
      // const filterData = this.props.products;
      // console.log("getting the deleted item list inside else condition", filterData)
      const filterData = this.props.products.filter(
        (item) => item.no !== row.no
      );
      console.log("getting the deleted item list", filterData.length);
      if (filterData.length === 1) {
        console.log("Cannot delete the only  record",filterData.length);
        return;
      }
      this.setState({
        products: filterData,
      });
      this.props.delete(filterData);
    }
  };

  handleSelectDate = (e, name, row) => {
    if (name === "yearEndDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      // row.yearEndDate = e.getTime()
      row.yearEndDate = Date;

      this.setState({
        // yearEndDate: e.getItem()
        yearEndDate: Date,
      });
    }

    if (name === "yearStartDate") {
      var date = moment(e).format();
      var Date = date.split("T", 1).toString();
      // var startDate = e.getItem()
      console.log("getting name of the date", row.yearStartDate);

      row.yearStartDate = Date;
      this.setState({
        yearStartDate: Date,
      });
    }
  };

  render() {
    console.log("getting data of plineItem", this.props.header);
    console.log("getting data of the products", this.state.products);

    return (
      <div className="editableTableContainer">
        {/* console.log() */}

        {console.log("updating product addItem", this.state.products)}
        <div className="gridContainer">
          <div className="gridComponent">
            <table cellSpacing="0px" cellPadding="0px">
              <thead>
                <tr className="tableRowBlock">
                  {this.props.tableHeaders.map((column) => (
                    <>
                      {column.header === "Actions" ? (
                        <th>{column.header}</th>
                      ) : (
                        <th>
                          {column.header}
                          <span style={{ color: "red" }}>*</span>
                        </th>
                      )}
                    </>
                  ))}
                </tr>
              </thead>
              <tbody>
                {console.log("getting data from render", this.state.products)}
                {this.state.products.map((row, rowIndex) => (
                  <tr>
                    {console.log("getting row data", row)}
                    <>
                      {this.props.RowData &&
                        this.props.RowData.map((col) =>
                          col.component === "Inputadorment" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {console.log(
                                      "getting the row datasssssss",
                                      col
                                    )}

                                    <input
                                      type="text"
                                      name={col.type + "_" + rowIndex}
                                      id={col.no}
                                      value={
                                        col.type === "leavesPerYear"
                                          ? row.leavesPerYear
                                          : col.type === "maxConsecutiveCount"
                                          ? row.maxConsecutiveCount
                                          : col.type === "maxLeavesPerYear"
                                          ? row.maxLeavesPerYear
                                          : ""
                                      }
                                      onChange={this.handleChange({
                                        key: col.type + "_" + rowIndex,
                                        row,
                                      })}
                                      className="inputBlockContainer"
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : col.component === "DynamicSelect" ? (
                            <td>
                              <div className="quantityDropdownBlock">
                                <Grid className="selectAndTextfield">
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    className="dynamicSelectBlock"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <DynamicSelect
                                      arrayOfData={
                                        col.type === "carryFrwStatus"
                                          ? carryForwardStatus
                                          : col.type === "encashmentStatus"
                                          ? EncashmentStatus
                                          : col.type === "leaveTypeId"
                                          ? this.state.leaveSelectionList
                                          : []
                                      }
                                      className="dropdownBlockContainer"
                                      onSelectChange={this.handleChangedropdown}
                                      selected={
                                        col.type === "carryFrwStatus"
                                          ? row.carryFrwStatus
                                          : col.type === "encashmentStatus"
                                          ? row.encashmentStatus
                                          : col.type === "leaveTypeId"
                                          ? row.leaveTypeId
                                          : ""
                                      }
                                      name={
                                        col.name === "carryFrwStatus"
                                          ? "carryFrwStatus"
                                          : col.name === "encashmentStatus"
                                          ? "encashmentStatus"
                                          : col.name === "leaveTypeId"
                                          ? "leaveTypeId"
                                          : ""
                                      }
                                      row={row}
                                      value={
                                        col.type === "carryFrwStatus"
                                          ? row.carryFrwStatus
                                          : col.type === "encashmentStatus"
                                          ? row.encashmentStatus
                                          : col.type === "leaveTypeId"
                                          ? row.leaveTypeId
                                          : ""
                                      }
                                      update={this.props.location.state ? 1 : 0}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                            </td>
                          ) : (
                            <td>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                {console.log(
                                  "getting the row data",
                                  row.yearEndDate,
                                  col
                                )}
                                <DesktopDatePicker
                                  inputFormat="dd-MM-yyyy"
                                  name={
                                    col.type === "yearEndDate"
                                      ? "yearEndDate"
                                      : col.type === "yearStartDate"
                                      ? "yearStartDate"
                                      : ""
                                  }
                                 minDate={row.yearStartDate}
                                  value={
                                    col.type === "yearEndDate"
                                      ? row.yearEndDate
                                      : col.type === "yearStartDate"
                                      ? row.yearStartDate
                                      : ""
                                  }
                                  onChange={(e) =>
                                    this.handleSelectDate(
                                      e,
                                      col.type === "yearEndDate"
                                        ? "yearEndDate"
                                        : col.type === "yearStartDate"
                                        ? "yearStartDate"
                                        : "",
                                      row
                                    )
                                  }
                                  fullWidth
                                  renderInput={({
                                    inputRef,
                                    inputProps,
                                    InputProps,
                                  }) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      className="consoleDatePicker"
                                    >
                                      <input
                                        ref={inputRef}
                                        {...inputProps}
                                        readOnly={true}
                                      />
                                      {InputProps?.endAdornment}
                                    </Box>
                                  )}
                                />
                              </LocalizationProvider>
                            </td>
                          )
                        )}
                    </>
                    <td className="">
                      <input
                        type="button"
                        onClick={this.onDelEvent({ key: row.no, row })}
                        value="X"
                        className="deleteDisplayButton"
                      />
                      {this.state.delete && (
                        <CommonPopup>
                          <div className="delAddItem">
                            <div>Are you sure you want to delete?</div>
                            <div className="delAddItemBtns">
                              <input
                                type="button"
                                onClick={this.onDel.bind(this)}
                                value="Yes"
                                className="delYesBtn"
                              />
                              <input
                                type="button"
                                onClick={this.onDel.bind(this)}
                                value="No"
                                className="delNoBtn"
                              />
                            </div>
                          </div>
                        </CommonPopup>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const carryForwardStatus = [
  // {
  //   name: "Select...",
  //   label: "Select...",
  //   value: "",
  // },
  {
    name: "YES",
    label: "YES",
    value: "Y",
  },
  {
    name: "NO",
    label: "NO",
    value: "N",
  },
];

const EncashmentStatus = [
  // {
  //   name: "Select...",
  //   label: "Select...",
  //   value: "",
  // },
  {
    name: "YES",
    label: "YES",
    value: "Y",
  },
  {
    name: "NO",
    label: "NO",
    value: "N",
  },
];

const mapStatetoProps = (state) => {
  const { leaveSelectionList } = state.LeavePolicyReducer;
  return { leaveSelectionList };
  //   const { pendingStockPoandReceipt } = state.DropDownListReducer;
  // const { BillNoList } = state.PaymentReducer
  // const {taxList} = state.taxList
  // return { BillNoList };
};

export default withRouter(
  connect(mapStatetoProps, {
    // getCostList,
    getLeaveSelectionList,
  })(LeavePolicyLineItem)
);
