import { MODULE_LIST, MODULE_LIST_SUCCESSFUL, ADD_MODULE, ADD_MODULE_SUCCESS, ADDON_LIST, ADDON_LIST_SUCCESSFULL,MODULES_UPDATION, MODULES_UPDATION_SUCCESSFULL } from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  modulelist: [], 
  addRole: [],
  addonlist: [],
  subscriptionId:0,
};

const ModuleSelection = (state = initialState, action) => {
  switch (action.type) {
    case MODULE_LIST:
      state = {
        ...state, 
        loading: true,
      };
      break;
    case MODULE_LIST_SUCCESSFUL:
      state = {
        ...state,
        modulelist: action.payload.response,
        loading: false,
      };
      break;

     case ADDON_LIST: 
      state = {
        ...state,
        loading: true
      } ;
      break;

      case ADDON_LIST_SUCCESSFULL: 
      state= {
        ...state,
        addonlist: action.payload.response,
        loading: false
      }
      break;

      case ADD_MODULE:        
      state = {
        ...state,
        loading: true,
      };
      break;

    case ADD_MODULE_SUCCESS:
      state = {
        ...state,
        addRole: action.payload.response.data,
        loading: false,
      };
      break;


      case MODULES_UPDATION:        
      state = {
        ...state,
        loading: true,
      };
      break;

    case MODULES_UPDATION_SUCCESSFULL:
      state = {
        ...state,
        addRole: action.payload.response.result,
        loading: false,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ModuleSelection;
