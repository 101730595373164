export const BASICDETAILS = 'basicdetails';
export const BASICDETAILS_ADD = 'basicdetails_add';
export const BASICDETAILS_ADD_SUCCESSFUL = 'basicdetails_add_successfull';
export const COMPANYS = 'locatons';
export const COMPANYS_LIST_SUCCESSFUL = 'locaton_list_successfull';

export const API_ERRORS = 'api_failed';
export const IMAGE_CMP_UPLOAD = 'image_cmp_upload';
export const COMPANY_DIT = "company_dits";
export const COMPANY_DIT_SUCCESS = "company_dits_success";
export const CLIENT_LIST = "client_list";
export const CLIENT_LIST_SUCCESS = "client_list_success";
export const CLEAR_CLIENT_LIST = "clear_client_list";

export const COMPANY_PROPS = "company_props";

export const COMPANY_TABLE = "company_table";
export const COMPANY_TABLE_SUCCESS = "company_table_success";
