import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  BASICDETAILS_ADD,
  COMPANYS,
  IMAGE_CMP_UPLOAD,
  COMPANY_DIT,
  CLIENT_LIST,
  COMPANY_TABLE,
} from "./actionTypes";
import {
  apiErrors,
  getCompanys,
  basicdetailsAddSuccessful,
  basicdetailsAddSuccessfulWithoutUpload,
  companyListSuccessful,
  companyditsuccess,
  clientListSuccess,
  getCompanyTableSuccessful,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  postAddmultipart,
  postEdit,
  getList,
  postUploadImage,
  getPostRowData,
  getListurl,
} from "../../../helpers/Server_Helper";
import swal from "sweetalert";
import { postAddPhp } from "../../../helpers/Server_Helper_Php"

import cons, { serverApi } from "../../../helpers/Consts";

function* getCompanysList({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    console.log("List Response", response);
    // if(response){
    //   if(response.data){
    //     localStorage.setItem("companyIdconfig", response.data[0].companyId);
    //     localStorage.setItem("companyupdateId", response.data[0].companyId);
    //   }
    // }
    yield put(companyListSuccessful({ response }));
  } catch (error) {
    // console.log(error);
    yield put(apiErrors(error));
  }
}


//If user is login then dispatch redux action's are directly from here.
function* AddBasicDetails({ payload: { url, data, id, updateView, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    // console.log("nagesh image Date", imgdata);
    console.log("nagesh resp Date", response);
    if (response.data.status == true) {
      // if (imgdata) {
      //   yield put(basicdetailsAddSuccessful(response.data, imgdata, history));
      // } else {
      console.log("nagesh resp Date", response.data);
      if (response.status) {
        if (updateView === false) {
          var values = {
            company_id: response.data.id,
            config_parameter: 'tasksList',
            config_value: [{ "taskName": "Employee Database", "taskId": 15 }, { "taskName": "Employee Attendance", "taskId": 16 }, { "taskName": "Employee Leave Request", "taskId": 18 }, { "taskName": "Expense Booking", "taskId": 19 }, { "taskName": "Salary Adjustment", "taskId": 20 }, { "taskName": "Advance Request", "taskId": 21 }],
            is_active: 1
          }
          console.log("values", values)
          yield call(postAddPhp, serverApi.CREATE_CONFIGURATION_MASTER, values);
          history.push("/CompanyList")
        }
      }
      yield put(
        basicdetailsAddSuccessfulWithoutUpload(
          response.data,
          updateView,
          history
        )
      );
      swal(response.data.message, {
        icon: "success",
      });

      if (response.status) {
        if (updateView === true) {
          history.push("/officialDetails");
        }
      }
    } else {
      swal("Sorry! Unable to add Basic Details. Please try again!", {
        icon: "error",
      });
    }
  } catch (error) {
    console.log(error);
    swal("Sorry! Permission Denied!", {
      icon: "error",
    });
    //yield put(apiError(error));
  }
}

//If user is login then dispatch redux action's are directly from here.
function* UploadImage(resp) {
  var imgdata = resp.payload.imgdata;
  var history = resp.payload.history;
  var res = resp.payload.response;

  try {
    if (resp) {
      let userDit = JSON.parse(localStorage.getItem("authUser"));
      let userId = userDit.userId;
      const formData = new FormData();
      formData.append("fileName", imgdata.fileName);
      formData.append("fileUpload", imgdata.file);
      formData.append("fileExtension", imgdata.fileType);
      formData.append("sourceMenu", 42);
      formData.append("displayName", imgdata.fileName);
      formData.append("taskUniqueId", 185);
      formData.append("createdBy", userId);
      formData.append("fileCompanyId", 185);

      const response = yield call(
        postUploadImage,
        serverApi.FILE_UPLOAD,
        formData
      );
      if (response) {
        console.log("Nagesh Image Upload Response XXXXXXXXXXXX", response);
        swal("Your Basic Details Added Successfully.!", {
          icon: "success",
        });

        if (response.status) {
          history.push("/CompanyList");
        } else {
          history.push("/CompanyList");
        }
      } else {
        swal("Sorry! Unable to ad Basic Details. Please try again!", {
          icon: "error",
        });
      }
    }
  } catch (error) {
    console.log(error);
    swal("Sorry! Permission Denied!", {
      icon: "error",
    });
  }
}

function* getCompanyDits({ payload: { url, data, history } }) {
  try {
    const response = yield call(getPostRowData, url, data);
    yield put(companyditsuccess({ response }));
  } catch (error) {
    // yield put(officialapiErrors(error));
  }
}

function* getCompanyTableData({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAddPhp, url, data);
    if (response.status) {
      yield put(getCompanyTableSuccessful({ response }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getClient({ payload: { url, data, history } }) {
  try {
    const response = yield call(getList, url, data);
    yield put(clientListSuccess({ response }));
  } catch (error) {
    // yield put(officialapiErrors(error));
  }
}

export function* watchgetCompanys() {
  console.log("THis is Saga");
  yield takeEvery(COMPANYS, getCompanysList);
}

export function* watchgetClient() {
  console.log("THis is Saga");
  yield takeEvery(CLIENT_LIST, getClient);
  yield takeEvery(COMPANY_TABLE, getCompanyTableData)
}

export function* watchBasicDetailsAdd() {
  yield takeEvery(BASICDETAILS_ADD, AddBasicDetails);
}

export function* watchUploadImage() {
  yield takeEvery(IMAGE_CMP_UPLOAD, UploadImage);
}

export function* watchCompanyDit() {
  yield takeEvery(COMPANY_DIT, getCompanyDits);
}

function* BasicDetailsSaga() {
  yield all([
    fork(watchBasicDetailsAdd),
    fork(watchgetCompanys),
    fork(watchUploadImage),
    fork(watchCompanyDit),
    fork(watchgetClient),
  ]);
}

export default BasicDetailsSaga;
