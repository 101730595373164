import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUsersList } from "../../store/UserManagement/actions";
import cons, { serverApi } from "../../helpers/Consts";
import { Grid } from "@mui/material";

import PlaceHolderuser from "../../components/PlaceHolders/PlaceHolderComponentUser";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExportIcon from "../../assets/images/exportIcon.png";
import CustomButton from "../../components/Buttons/Buttons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import ToggleButton from "../../components/ToggleButton/ToggleButton";
// import GridView from "../../components/Grid/GridView";
import GridView from "../../components/Grid/GridView";

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyId: localStorage.getItem("companyIdconfig"),
      row: [],
      mdata: [],
      mcolumns: [],
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleValidSubmit(event, values) {
    // this.props.basicdetailsAdd(values, this.props.history);
  }

  componentDidMount = () => {
    // var orgId = this.state.userDit.orgId;
    // var data = {
    //   comId: JSON.parse(localStorage.getItem("authUser")).orgId,
    //   cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
    // };
    // this.props.getUsersList(
    //   serverApi.GET_ALL_USERS_LIST,
    //   data,
    //   this.props.history
    // );
  };

  editRow = (row) => {
    this.props.history.push({ pathname: "/create_user", rowdata: row });
  };
  deleteRow(name, value) {
    var status = "";
    if (value === true) {
      name = "Active";
    } else {
      name = "InActive";
    }
  }
  componentWillReceiveProps(props) {
    if (props.userslist) {
      this.setState({
        mdata: props.userslist,
      });
    }
    if (props.userslistcolumns) {
      this.setState({
        mcolumns: props.userslistcolumns,
      });
    }
  }
  render() {
    // const mdata = this.props.userslist;
    // const mcolumns = this.props.userslistcolumns;
    return (
      <>
        <GridView 
        mdata={this.state.mdata} 
        mcolumns={mcolumns} 
        pathname="/create_user" />
      </>
    );
  }
}
const mcolumns=[
  {
    Header:"User Name",
    accessor: "userName",
    hidden:false,
  },
  
  {
    Header:"Email Id",
    accessor: "email",
    hidden:false,
  },
  {
    Header:"Contact Number",
        accessor: "mobile",
        hidden:false,
  },
  {
    Header:"App Role",
    accessor: "appRole",
    hidden:false,
  },
  {
    Header:"Portal Role",
    accessor: "portalRole",
    hidden:false,
  },
  {
    Header:"Status",
    accessor: "status",
    hidden:false,
  },
  {
    Header:"Company Name",
    accessor: "companyName",
    hidden:false,
  },
]

const mapStatetoProps = (state) => {
  const { userslist, userslistcolumns } = state.UserManagement;
  return { userslist, userslistcolumns };
};

export default withRouter(
  connect(mapStatetoProps, {
    getUsersList,
  })(UsersList)
);
