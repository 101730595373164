import React, {Component} from "react";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import InnerNavigation from "../../components/InnerNavigation/InnerNavigation";
import { Grid } from "@mui/material";
import Header from "../../components/Header/Header";
import Invoice from "../../Pages/Configuration/Invoice";
import ModuleSelect from "./ModuleSelect";


class ModuleSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Module Selection" ,      
      createbranchbtn: 0,
      module:4
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (name, page, btn) => {

    this.setState({
      parentName: name,
      companypage: page,
      createbranchbtn:btn
    });

    
  }

  render() {
    
    return (
      <>
      <Grid container className="mainContainer">
        <LeftSidebar  />
        <Grid className="mainContent">
          <div className="consoleContainer">
            <Header childName={this.state.parentName} buttonbranch={this.state.createbranchbtn} onNameChange={this.onChange} />
            <ModuleSelect />   
          </div>
        </Grid>
      </Grid>
    </>
    );
  }

  
}

export default ModuleSelection;
