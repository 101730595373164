import React, { Component } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VowLogo from "../../assets/images/vow_logo.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// actions
import { checkLogin, apiError, checkToken } from "../../store/actions";

import { serverApi } from "../../helpers/Consts";

// availity-reactstrap-validation
// import {AvForm, AvField} from 'availity-reactstrap-validation';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "su", password: "123456" };
    this.state = { hide: "", passwordExist: "", passwordVerify: "" };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputOnChange = this.handleInputOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onHandleLogin = this.onHandleLogin.bind(this);
  }
  // http://devapi.vowerp.com/security-api/oauth/token

  onHandleLogin = (event) => {
    event.preventDefault();

    let username = event.target.email.value;
    let password = event.target.password.value;
    let reqSource = 0;

    const data = {
      username,
      password,
      reqSource,
    };

    /* Authentication Token generations */
    this.props.checkToken(serverApi.OAUTH_LOGIN, data, this.props.history);
    /* Login  */
    this.props.checkLogin(serverApi.LOGIN, data, this.props.history);
  };

  componentDidMount() {
    this.props.apiError("");
    // document.body.classList.add('auth-body-bg');
  }

  componentWillUnmount() {
    // document.body.classList.remove('auth-body-bg');
  }
  handleInputOnChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }
  handleInputOnChangeShowPassword(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      // [target.name]: target.value,
      passwordExist: !this.state.passwordExist,
      // hide: !this.state.hide,
    });
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    
    if (this.state.passwordExist === this.state.passwordVerify) {
      alert("passwords are same");
    } else {
      alert("password must be same");
    }
  }

  render() {
    const { passwordExist } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="loginPatternOne"
        >
          <CssBaseline />
          <Grid item xs={false} sm={4} md={6} className="loginBackground">
            <Box className="loginLeftContent">
              <h2>Nice to meet you</h2>
              <h1>WELCOME</h1>
              <p>
                We have customized this product according to your needs and
                can't wait to see you using this product. This will simplify
                working of your business
              </p>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={6}
            component={Paper}
            elevation={6}
            square
            className="loginContainer"
          >
            {/* <form onValidSubmit={this.handleSubmit}> */}
            <Box
              className="loginBlock"
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={VowLogo} alt="" />
              <Typography component="h1">Reset Password</Typography>
              <Typography component="p">
                Reset Password and proceed with the company login process
              </Typography>
              <form onSubmit={this.handleSubmit}>
                <Box
                  // component="form"
                  noValidate
                  // onSubmit={this.handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passwordExist"
                    value={this.state.passwordExist}
                    onChange={this.handleInputOnChange}
                    
                    autoFocus
                    className="loginInput"
                    placeholder="New Password"
                    type={this.state.passwordExist ? "password" : "text"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={this.handleInputOnChangeShowPassword}
                        >
                          {this.state.passwordExist ? (
                            <VisibilityRoundedIcon />
                          ) : (
                            <VisibilityOffRoundedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="passwordVerify"
                    // label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.passwordVerify}
                    onChange={this.handleInputChange}
                    className="loginInput"
                    placeholder="Re-type password"
                  />
                  <Button color="primary" className="loginButton" type="sumbit">
                    Reset Password
                  </Button>
                </Box>
              </form>
            </Box>
            {/* </form> */}
          </Grid>
        </Grid>
      </ThemeProvider>
    );
  }
}
const theme = createTheme();
const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, apiError, checkToken })(ResetPassword)
);
