export const ROLE_MANAGEMENT = "role_management";
export const ROLE_MANAGEMENT_SUCCESS = "role_management_success";
export const ADD_ROLES = "add_roles";
export const ADD_ROLES_SUCCESS = "add_roles_success";

export const UPDATE_ROLES = "update_roles";
export const UPDATE_ROLES_SUCCESS = "update_roles_success";

export const ADD_APPROVAL_HIERARCY = "add_approval_hierarcy";
export const ADD_APPROVAL_HIERARCY_SUCCESS = "add_approval_hierarcy_success";

export const USER_DETAILS_BYID = "user_details_byid";
export const USER_DETAILS_BYID_SUCCESS = "user_details_byid_success"
export const USER_MANAGEMENT_LINEITMS ="user_Management_LineItems"
export const CLEAR_USER_MANAGEMENT_LINEITMS ="clear_user_Management_LineItems"



export const ROLE_TYPES_LIST = "role_types_list";
export const ROLE_TYPES_LIST_SUCCESS = "role_types_list_success";
export const USER_GROUPS = "user_groups";
export const USER_GROUPS_SUCCESS = "user_groups_success";
export const USER_GROUP_ROW = "user_group_row";
export const USER_GROUP_ROW_SUCCESS = "user_group_row_success";
export const USERS_LIST = "users_list";
export const USERS_LIST_SUCCESS = "users_list_success";
export const GET_PORTAL_LIST = "get_portal_list";
export const GET_PORTAL_LIST_SUCCESS = "get_portal_list_success";
export const GET_APP_ROLE_LIST = "get_app_role_list";
export const GET_APP_ROLE_SUCCESS = "get_app_role_list_success";
export const GET_COMPANY_LIST = "get_company_list";
export const GET_COMPANY_LIST_SUCCESS = "get_company_list_success";
export const GET_BRANCH_LIST = "get_branch_list";
export const GET_BRANCH_LIST_SUCCESS = "get_branch_list_success";
export const CLIENT_LIST = "get_client_list";
export const CLIENT_LIST_SUCCESS = "get_client_list_success";
export const CREATE_USER = "create_user";
export const CREATE_USER_SUCCESS = "create_user_success";
export const UPDATE_USER = "update_user";
export const UPDATE_USER_SUCCESS = "update_user_success";

