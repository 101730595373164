import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
// import Checkbox from "../../components/CheckBox/Checkbox";
import Checkbox from '@mui/material/Checkbox';
import { Multiselect } from "multiselect-react-dropdown";


export default class AutoMultiselect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    
  }
  onSelect = (selectedList, selectedItem) => {
    this.props.getOpt(selectedList, this.props.name,this.props.row);
  };
  onRemove = (selectedList, removedItem) => {
    this.props.getOpt(selectedList, this.props.name,this.props.row);
  };

  render() {
    return (
      <div className="autoCompleteDropdownContainer" style={{ width: '100%' }}>
        <label>{this.props.label}<span>{this.props.mandatory}</span></label>
          <Multiselect
              options={this.props.options} // Options to display in the dropdown
              displayValue="label" // Property name to display in the dropdown options
              showCheckbox={true}
              selectedValues={this.props.selectedValues} // Preselected value to persist in dropdown
              onSelect={this.onSelect} // Function will trigger on select event
              onRemove={this.onRemove} // Function will trigger on remove event
          />
        
        
      </div>
    )
  }
}

