import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import  { serverApi } from "../../../helpers/Consts";
// import {createBankAccount} from "../../../store/Master/"
import GridView from "../../../components/Grid/GridView";
import {bank_account_list_api} from "../../../store/Master/BankAccountMaster/actions"
import GridViewSearch from "../../../components/Grid/GridViewSerch";

class Index extends Component{
    constructor(props){
        super(props);
        this.state={companyId:0,userDit: JSON.parse(localStorage.getItem("authUser")),
        mdata:[],
        bankcolumns:[],
    };

    }
    componentDidMount(){
        const data={
            cipher:this.state.userDit.cipher,
            companyId:localStorage.getItem("companyIdconfig"),

        }
        // this.props.createBankAccount(serverApi.CREATE_BANK_ACCOUNT, data, this.props.history); // calling create bank account api
        this.props.bank_account_list_api(serverApi.BANK_ACCOUNT_LIST_API,data,this.props.history);
    }
    render(){
        const mdata = this.props.BankAccountList ? this.props.BankAccountList.data : "";
    const mcolumns = this.props.BankAccountList ? this.props.BankAccountList.column : "";
    return (
        // <>
        //     <h1>Bank Accounts</h1>
        
        // </>
      <GridViewSearch mdata={mdata} mcolumns={bankcolumns} pathname="/create_bank_account_master"/>
    )
    }
}

const bankcolumns=[
    {
        Header:"Bank Name",
        dataField:"bankName",
        hidden:false,
    },
    {
        Header:"Account Holder Name",
        dataField:"accountantName",
        hidden:false,
    },
    {
        Header:"Account No",
        dataField:"accountNo",
        hidden:false,
    },
    {
        Header:"IFSC Code",
        dataField:"ifscCode",
        hidden:false,
    },
    {
        Header:"MICR Code",
        dataField:"micrCode",
        hidden:false,
    },
    {
        Header:"Type",
        dataField:"accountType",
        hidden:false,
    }
]
const mapStatetoProps=(state)=>{
    const {BankAccountList}=state.BankAccountReducer;
    return {BankAccountList};
};
export default withRouter(
    connect(mapStatetoProps,{
        bank_account_list_api,
        // createBankAccount,
    })(Index)
);