import React, {Component} from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";


class Test extends Component {

  constructor(props) {
    super(props);
    this.state = { values: [] };
  }

  handleChange(i, e, name) {
    console.log("Target", e.target)
    this.setState({
      values: { ...this.state.values, [i]: e.target.value }
    });
  }

  render() {
      var fieldsArray = [];

      console.log("Nagesh Feilds Values", this.state.values);

    for (var i = 0; i <= 2; i++) {
      fieldsArray.push(
        <div>
            <label>
                <div className="label">{i}</div> 
                <input 
                  type='text' 
                  value={this.state.values["name"+i]} 
                  name={this.state.values["name"+i]} 
                  onChange={this.handleChange.bind(this, "name"+i)} />
                  <input 
                  type='text' 
                  value={this.state.values["age"+i]} 
                  name={this.state.values["age"+i]} 
                  onChange={this.handleChange.bind(this, "age"+i)} />
            </label>
        </div>
      );
    }

    return (
      <div className = 'inputs'>
        {fieldsArray}
      </div>
    );
  }

  
}
const mapStatetoProps = (state) => {
};
// export default CompanyCreation;
export default withRouter(connect(mapStatetoProps, {
})(Test));

