/** Created On Nagesh Medisetty On 02-02-2022 */
import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import ConsoleUpload from "../../components/PictureUpload/PictureUpload";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { connect } from "react-redux";
import TextFieldSimple from "../../components/TextField/TextFieldSimple";
import TextFieldReq from "../../components/TextField/TextFieldReq";
import TextFieldEmailReq from "../../components/TextField/TextFieldEmailReq";
import TextFieldAlfaNumReq from "../../components/TextField/TextFieldAlfaNumReq";
import TextFieldMobileReq from "../../components/TextField/TextFieldMobileReq";
import swal from "sweetalert";
import dateFormat from 'dateformat';
import moment from "moment"
//import Link from "@mui/material/Link";
import {
  basicdetailsAdd,
  getCompanyDit,
  companyprops,

} from "../../store/Company/BasicDetails/actions";
import { postUploadFileList, deleteFile } from "../../store/UploadFile/actions"
import { withRouter, Link } from "react-router-dom";
import { basicDetailsData } from "../../store/Company/OfficialDetails/actions"
import cons, { serverApi, serverConfig } from "../../helpers/Consts";
import { getFileDownload, getProfileFileDownload } from "../../helpers/Server_Helper"
import EditLogoIcon from "../../assets/images/picEditIcon.png";
import { getProfilePicFile, getProfilePicFileSetNull } from "../../store/Master/PaySchemecreation/actions"


class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHedder: "Basic Details",
      errors: false,
      file: "",
      imagePreview_Url: "",
      imagePreviewUrl: "",
      imagePreviewId: null,
      fileUpload: "",
      imagePreview: "none",
      fileName: "",
      fileType: "",
      rowdata: null,
      headerData: null,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      alertMailHost: null,
      alertMailId: null,
      alertMailName: null,
      alertMailPassword: null,
      bucketName: "",
      cinNo: null,
      companyAddress1: null,
      companyAddress2: null,
      companyCode: "",
      companyId: 0,
      companyName: "",
      contactNo: "",
      contactPerson: "",
      createdBy: null,
      emailId: "",
      financialDate: "",
      folderName: "",
      gstNo: "",
      gstNo2: null,
      juteMrBccMail: null,
      jutePoBccMail: null,
      orgId: localStorage.getItem("companyupdateId"),
      panNO: "",
      portNo: null,
      stateCode1: null,
      stateCode2: null,
      stateName1: null,
      stateName2: null,
      storeMrBccMail: null,
      storePoBccMail: null,
      tallySync: null,
      yourUrl: "",
      financialYear: moment().year()
    };

    // this.handleValidSubmit = this.handleValidSubmit.bind(this);
    // this.handleImageChange = this.handleImageChange.bind(this);
    // this.handleDeleteFile = this.handleDeleteFile.bind(this);
  }

  componentDidMount() {
    // new code for default selection of financial year.....
   if(this.props.location.rowdata === undefined){
    const currentYear = new Date().getFullYear();
    const financialYearStart = new Date(currentYear, 3, 1); 
     var date = moment(financialYearStart).format();
      let formatedDate = date.split("T", 1).toString();

    if (localStorage.getItem("financialDate")) {
      //old code.......
      // let financialDate = moment(localStorage.getItem("financialDate")).format("DD-MM-YYYY")

      this.setState({
        financialYear: formatedDate,
      })
    }
   }
    if (localStorage.getItem("yourUrl")) {
      this.setState({
        yourUrl: localStorage.getItem("yourUrl")
      })
    }
    if (localStorage.getItem("companyupdateId")) {
      const data = {
        cipher: "",
        companyId: localStorage.getItem("companyupdateId"),
      };
      if (this.props.location) {
        if (this.props.location.rowdata) {
          var rowd = this.props.location.rowdata;
          this.setState({
            companyId: rowd.companyId,
            headerData: rowd,
          });
          this.props.companyprops(rowd);
          this.props.getProfilePicFile(serverApi.GET_ALL_FILES + "1/" + rowd.companyId + "/" + rowd.companyId, this.props.history, 1); // 1 for profile pic
        }
        if (this.props.headerData) {
          this.setState({
            alertMailHost: this.props.headerData.alertMailHost,
            alertMailId: this.props.headerData.alertMailId,
            alertMailName: this.props.headerData.alertMailName,
            alertMailPassword: this.props.headerData.alertMailPassword,
            bucketName: this.props.headerData.bucketName,
            cinNo: this.props.headerData.cinNo,
            companyAddress1: this.props.headerData.companyAddress1,
            companyAddress2: this.props.headerData.companyAddress2,
            companyCode: this.props.headerData.companyCode,
            companyId: this.props.headerData.companyId,
            companyName: this.props.headerData.companyName,
            contactNo: this.props.headerData.contactNo,
            contactPerson: this.props.headerData.contactPerson,
            createdBy: this.props.headerData.createdBy,
            emailId: this.props.headerData.emailId,
            financialDate: this.props.headerData.financialDate,
            folderName: this.props.headerData.folderName,
            gstNo: this.props.headerData.gstNo,
            gstNo2: this.props.headerData.gstNo2,
            juteMrBccMail: this.props.headerData.juteMrBccMail,
            jutePoBccMail: this.props.headerData.jutePoBccMail,
            orgId: this.props.headerData.orgId,
            panNO: this.props.headerData.panNO,
            portNo: this.props.headerData.portNo,
            stateCode1: this.props.headerData.stateCode1,
            stateCode2: this.props.headerData.stateCode2,
            stateName1: this.props.headerData.stateName1,
            stateName2: this.props.headerData.stateName2,
            storeMrBccMail: this.props.headerData.storeMrBccMail,
            storePoBccMail: this.props.headerData.storePoBccMail,
            tallySync: this.props.headerData.tallySync,
            yourUrl: this.props.headerData.yourUrl,
            financialYear:this.props.headerData.financialDate
            
          });
          if (rowd === undefined) {
            this.props.getProfilePicFile(serverApi.GET_ALL_FILES + "1/" + this.props.headerData.companyId + "/" + this.props.headerData.companyId, this.props.history, 1); // 1 for profile pic
          }
        }
        
      }
    }
  }

  componentWillReceiveProps(props) {
    var profilepicurl = "";
    var fileName = "";
    var fileExtension = "";
    var imagePreviewId = null
    if (props) {
      if (props.profilePicFile) {
        if (props.profilePicFile.data) {
          if(props.profilePicFile.data.data){
            let profilePicFiles = props.profilePicFile.data.data
            if (profilePicFiles.length !== 0) {
              profilePicFiles.map((row) => {
                profilepicurl = row.retrievalPath;
                fileName = row.fileName;
                fileExtension = row.fileExtension;
                imagePreviewId = row.fileUploadId
              });
            } else {
              profilepicurl = "";
              fileName = "";
              fileExtension = "";
              imagePreviewId = null
            }
            if (profilepicurl) {
              // console.log("download path", serverConfig.SERVER_URL + profilepicurl, fileName, fileExtension);
              getProfileFileDownload(serverConfig.SERVER_URL + profilepicurl, fileName, fileExtension, this.handleDownloadedFile);
              this.setState({
                imagePreviewId: imagePreviewId
              })
            } else {
              this.setState({
                imagePreviewId: null,
                imagePreviewUrl: ""
              })
            }
          }
         
        }
      }

      if (this.props.headerData) {
        this.setState({
          alertMailHost: this.props.headerData.alertMailHost,
          alertMailId: this.props.headerData.alertMailId,
          alertMailName: this.props.headerData.alertMailName,
          alertMailPassword: this.props.headerData.alertMailPassword,
          bucketName: this.props.headerData.bucketName,
          cinNo: this.props.headerData.cinNo,
          companyAddress1: this.props.headerData.companyAddress1,
          companyAddress2: this.props.headerData.companyAddress2,
          companyCode: this.props.headerData.companyCode,
          companyId: this.props.headerData.companyId,
          companyName: this.props.headerData.companyName,
          contactNo: this.props.headerData.contactNo,
          contactPerson: this.props.headerData.contactPerson,
          createdBy: this.props.headerData.createdBy,
          emailId: this.props.headerData.emailId,
          financialDate: this.props.headerData.financialDate,
          folderName: this.props.headerData.folderName,
          gstNo: this.props.headerData.gstNo,
          gstNo2: this.props.headerData.gstNo2,
          juteMrBccMail: this.props.headerData.juteMrBccMail,
          jutePoBccMail: this.props.headerData.jutePoBccMail,
          orgId: this.props.headerData.orgId,
          panNO: this.props.headerData.panNO,
          portNo: this.props.headerData.portNo,
          stateCode1: this.props.headerData.stateCode1,
          stateCode2: this.props.headerData.stateCode2,
          stateName1: this.props.headerData.stateName1,
          stateName2: this.props.headerData.stateName2,
          storeMrBccMail: this.props.headerData.storeMrBccMail,
          storePoBccMail: this.props.headerData.storePoBccMail,
          tallySync: this.props.headerData.tallySync,
          yourUrl: this.props.headerData.yourUrl,
          financialYear: props.headerData.financialDate
        });
      }
    }
  }
  handleDownloadedFile = (url) => {
    this.setState({
      imagePreviewUrl: url,
      fileUpload: "none",
      imagePreview: "block",
    })
  };

  handleChange = (key) => (event) => {
    this.setState({ [key]: event });
    this.props.headerData[key] = event;
  };

  handleImageChange = (e) => {
    var companyId = this.state.companyId;
    let reader = new FileReader();
    let file = e.target.files[0];
    let fileExt = file.name.split(".").pop();
    const formData = new FormData();
    formData.append("fileName", file.name);
    formData.append("fileUpload", file);
    formData.append("fileExtension", fileExt);
    formData.append("sourceMenu", 1);
    formData.append("displayName", file.name);
    formData.append("taskUniqueId", companyId);
    formData.append("createdBy", this.state.userDit.userId);
    formData.append("fileCompanyId", companyId);
    this.setState({
      fileName: file.name,
      fileExtension: file.name.split(".").pop(),
    });

    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        fileUpload: "none",
        imagePreview: "block",
      });
    };
    reader.readAsDataURL(file);

    this.props.postUploadFileList(
      serverApi.UPLOADFILE,
      formData,
      this.props.history
    );
  }

  // handleImageRemove(e) {
  //   e.preventDefault()
  //   this.setState({
  //     fileUpload: 'block',
  //     imagePreview: 'none',
  //     file: '',
  //     imagePreviewUrl: '',
  //   })
  // }
  handleDeleteFile = (key, fileUploadId) => (e) => {
    swal({
      title: "Are you sure?",
      text: "Do you want to delete",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (key === "profile") {
          this.setState({
            fileUpload: 'block',
            imagePreview: 'none',
            file: '',
            imagePreviewUrl: '',
          })
          this.props.deleteFile(serverApi.DELETEFILE + fileUploadId,this.props.history,"companyCreation")
        }
      } else {
        swal("Your action is canceled!");
      }
    });
  };

  handleValidSubmit = (event) => {
    event.preventDefault();
    var id = this.state.companyId;
    var phone = this.state.contactNo;
    var year = dateFormat(this.state.financialDate, "dd-mm-yyyy");
    var txturl = this.state.url;
    let userDit = JSON.parse(localStorage.getItem("authUser"));
    var imgdata = null;

    const length = phone ? phone.toString().length : 0;
    if (length > 0) {
      if (length < 10) {
        swal("Sorry! Please Enter Correct Phone Number");
        return false;
      }
    }
    // if (this.state.companyName && this.state.companyCode && this.state.contactNo && this.state.contactPerson && this.state.yourUrl && length > 0) {
      if(this.state.contactNo===""){
        swal("Please Enter the Phone Number !!!")
      }
      if(this.state.contactPerson===""){
        swal("Please Enter the Contact Person !!!")
      }
      if(this.state.emailId===""){
        swal("Please Enter the Email Address !!!")
      }
      if(this.state.companyCode==="" || this.state.companyCode===null){
        swal("Please Enter the Company Abbreviation !!!")
      }
      if(this.state.companyName===""){
        swal("Please Enter the Company Name !!!");
      }
      if(this.state.yourUrl ===""){
        swal("Please Enter the URL !!!")
      }
      

     
      if (this.props.headerData) {
        var companyId = this.props.headerData.companyId;
        id = companyId;
        var rowd = this.props.headerData;
        let companyCode = this.state.companyCode.slice(0, 3).toUpperCase()
        var data = {
          company: {
            companyId: companyId,
            companyName: this.state.companyName,
            companyCode: this.state.companyCode,
            companyAddress1: rowd.companyAddress1,
            companyAddress2: rowd.companyAddress2,
            gstNo: rowd.gstNo,
            stateCode1: rowd.stateCode1,
            stateCode2: rowd.stateCode2,
            gstNo2: rowd.gstNo2,
            createdBy: userDit.userId,
            orgId: userDit.orgId,
            tallySync: rowd.tallySync,
            contactPerson: this.state.contactPerson,
            stateName1: rowd.stateName1,
            stateName2: rowd.stateName2,
            contactNo: this.state.contactNo,
            emailId: this.state.emailId,
            panNO: rowd.panNO,
            yourUrl: this.state.yourUrl,
            // financialDate: this.state.financialYear.toString(),
            financialDate: moment(this.state.financialYear).format("DD-MM-YYYY"),
            bucketName: "vowcloud",
            folderName: companyId + "_" + companyCode,
            portNo:587, 
            alertMailHost:'smtp.gmail.com', 
            alertMailId: 'alert@vowerp.com', 
            alertMailPassword: 'VowErp@321', 
            alertMailName: this.props.headerData.companyCode === undefined? this.state.companyCode : this.props.headerData.companyCode
          },
          // financialDate: year,
          postAuthenticationDto: {
            cipher: this.state.userDit.cipher,
          },
          // "financialYear": this.state.financialYear.toString()
          "financialYear": moment(this.state.financialYear).format("DD-MM-YYYY")

        };

        if (!data.company.emailId) {

        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.company.emailId)) {
          swal('Invalid email address', { icon: "error" });
          return false;
        }
        let updateView = true
        this.props.basicdetailsAdd(
          serverApi.COMPANYMASTER_UPDATE,
          data,
          id,
          updateView,
          this.props.history
        );
        // swal("updated.....")
      } else {
        let companyCode = this.state.companyCode.slice(0, 3).toUpperCase()
        // console.log("sharath",this.props.headerData.companyCode)
        var data = {
          company: {
            companyName: this.state.companyName,
            companyCode: companyCode,
            companyAddress1: "",
            companyAddress2: "",
            gstNo: "",
            stateCode1: "",
            stateCode2: "",
            gstNo2: "",
            createdBy: userDit.userId,
            orgId: userDit.orgId,
            tallySync: "",
            contactPerson: this.state.contactPerson,
            stateName1: "",
            stateName2: "",
            contactNo: this.state.contactNo,
            emailId: this.state.emailId,
            panNO: "",
            yourUrl: this.state.yourUrl,
            // financialDate: this.state.financialYear.toString(),
            financialDate: moment(this.state.financialYear).format("DD-MM-YYYY"),
            bucketName: "vowcloud",
            portNo:587, 
            alertMailHost:'smtp.gmail.com', 
            alertMailId: 'alert@vowerp.com', 
            alertMailPassword: 'VowErp@321', 
            // alertMailName: this.props.headerData.companyCode
            alertMailName: companyCode
          },
          // financialDate: year,
          postAuthenticationDto: {

            cipher: this.state.userDit.cipher,
          },
          // "financialYear": this.state.financialYear.toString()
          "financialYear": moment(this.state.financialYear).format("DD-MM-YYYY")

          
        };
        // console.log("Nagesh Basicdetails data", data);

        this.props.basicDetailsData(data)
        if (!data.company.emailId) {

        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.company.emailId)) {
          swal('Invalid email address', { icon: "error" });
          return false;
        }
        if (localStorage.getItem('companys_purchased') > localStorage.getItem('companys_created')) {
          let updateView = false
          this.props.basicdetailsAdd(
            serverApi.COMPANYMASTER_ADD,
            data,
            id,
            updateView,
            this.props.history
          );
          // this.props.history.push("/CompanyList")

        } else {
          swal("Sorry! your company's subscription has expired");
        }
      }
    // } else {
    //   swal("Sorry! Please Enter all mandatory fields", { icon: "error" });
    // }
  };

  backToList = () => {
    var data = [];
    this.props.basicDetailsData(data);
    // this.props.history("/CompanyList")
  };

  handleChangeDate =(event,key) => {

    if(key === "financialYear"){
      var date = moment(event).format();
      let formatedDate = date.split("T", 1).toString();

      this.setState({
        financialYear: formatedDate
      })
    }

  }

  render() {
    return (
      <>
        {/* <form onSubmit={this.handleValidSubmit}> */}
        <div className="consoleFormContainer">
          <h5>Company Info</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={2}>
                {/* <ConsoleUpload
                    handleImageChange={this.handleImageChange}
                    handleImageRemove={this.handleImageRemove}
                    imagePreview={this.state.imagePreview}
                    imagePreview_Url={this.state.imagePreview_Url}
                    fileUpload={this.state.fileUpload}
                  /> */}
                <div className="uploadBlock">
                  {/* { this.props.imagePreviewUrl} */}
                  <div style={{ display: this.state.fileUpload }}>
                    <Button component="label" className="uploadBtn">
                      <div className="uploadInfo">
                        <h1>+</h1>
                        <h3>Upload Photo</h3>
                        <p>(Format: jpeg, png)</p>
                      </div>
                      <input
                        type="file"
                        hidden
                        onChange={this.handleImageChange}
                        accept="image/jpeg, image/png"
                      />
                    </Button>
                  </div>
                  <div
                    className="imagePreview"
                    style={{
                      display: this.state.imagePreview,
                    }}
                  >
                    <img src={this.state.imagePreviewUrl} alt="" />
                    <button onClick={
                      this.handleDeleteFile("profile", this.state.imagePreviewId)
                    }
                      className="editIconStyle">
                      <img src={EditLogoIcon} alt="" />
                    </button>
                  </div>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldReq
                          value={this.state.companyName}
                          type="text"
                          label="Company Name"
                          caps="OFF"
                          placeholder="Enter Company Name"
                          onChange={this.handleChange("companyName")}
                        // onChange={(e) => {this.setState({companyname: e.target.value})}}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldReq
                          value={this.state.companyCode}
                          label="Company Abbreviation"
                          placeholder="Enter text here"
                          onChange={this.handleChange("companyCode")}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldEmailReq
                          value={this.state.emailId}
                          type="text"
                          label="Email Address"
                          caps="OFF"
                          placeholder="Enter text here"
                          onChange={this.handleChange("emailId")}
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <h5>Contact Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldReq
                          value={this.state.contactPerson}
                          type="text"
                          label="Contact Person"
                          caps="OFF"
                          placeholder="Enter Contact Person"
                          onChange={this.handleChange("contactPerson")}
                        />
                      </div>
                    </div>
                  </Box>
                  <Box gridColumn="span 4">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldMobileReq
                          value={this.state.contactNo}
                          type="text"
                          label="Phone Number"
                          placeholder="Enter text here"
                          onChange={this.handleChange("contactNo")}
                        />
                      </div>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <h5>Other Details</h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div className="TextFieldBlock">
                        <label>Financial Year begins from</label>
                      </div>
                      {/* <ConsoleDatePicker /> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat="dd-MM-yyyy"
                          name="financial_year"
                          id="financial_year"
                          value={this.state.financialYear}
                          fullWidth
                          // onChange={(newValue) => {
                          //   // setValue(newValue);
                          //   this.setState({ financialDate: newValue });
                          // }}
                          onChange={(e) =>this.handleChangeDate(e,"financialYear")}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Box
                              sx={{ display: "flex", alignItems: "center" }}
                              className="consoleDatePicker"
                            >
                              <input ref={inputRef} {...inputProps} readOnly={true} />
                              {InputProps?.endAdornment}
                            </Box>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  {/* <Box gridColumn="span 6">
                    <div className="consoleTextFieldBlock">
                      <div>
                        <TextFieldReq
                          value={this.state.yourUrl}
                          type="text"
                          label="Your URL"
                          placeholder="Enter Your URL"
                          caps="OFF"
                          onChange={this.handleChange("yourUrl")}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </Box> */}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormButtonBlock">
          <Link to="/CompanyList">
            <Button variant="contained" className="cancelButton" onClick={this.backToList}>
              Cancel
            </Button>
          </Link>
          <Button variant="contained" type="sumbit" className="saveButton" onClick={this.handleValidSubmit}>
            {this.props.headerData === null ? "Create Company" : "Update and Next"}
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companyresponse, rowdata, headerData } = state.BasicDetails;
  const { basicDetails } = state.OffcialDetails
  const { profilePicFile } = state.PaySchemeReducer
  return { companyresponse, rowdata, headerData, basicDetails, profilePicFile };
};

export default withRouter(
  connect(mapStatetoProps, {
    basicdetailsAdd,
    getCompanyDit,
    companyprops,
    basicDetailsData,
    postUploadFileList,
    getProfilePicFile,
    getFileDownload,
    getProfileFileDownload,
    deleteFile,
    getProfilePicFileSetNull
  })(BasicDetails)
);
