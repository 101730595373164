import React, {Component} from "react";
import LeftSidebar from "../../Sidebar/LeftSidebar";
import InnerNavigation from "../../components/InnerNavigation/InnerNavigation";
import { Grid } from "@mui/material";
import Header from "../../components/Header/Header";
import BillingDetails from "../../Pages/Billing/BillingDetails";


class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "Billing Details" ,
      companypage: "",
      createbranchbtn: 0,
      module:4
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange = (name, page, btn) => {

    this.setState({
      parentName: name,
      companypage: page,
      createbranchbtn:btn,
      buttonbranch: 100
    });

    
  }

  render() {
    
    return (
      <>
      
      <BillingDetails />
    </>
    );
  }

  
}

export default Billing;
