import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { ADD_MODULE, MODULE_LIST, ADDON_LIST, MODULES_UPDATION} from "./actionTypes";
import { moduleListSuccessful, addModuleSuccess, getAddonslistsuccessfull, updateModulesSuccess } from "./actions";

// AUTH related methods 
import { getModuleList } from "../../helpers/fackBackend_Helper";

import {getListurl, getList} from "../../helpers/Server_Helper"
import {getListurlPhp, postAddPhp} from "../../helpers/Server_Helper_Php"
import swal from "sweetalert";
// import swal from "sweetalert";

function* getModuleData({payload: {url}}) {
  try {
    const response = yield call(getListurlPhp, url, {});
    yield put(moduleListSuccessful({ response }));
  } catch (error) {
    console.log(error);
    
  }
}

function* getAddonData() {
  try {
    const response = yield call(getModuleList, {});
    yield put(getAddonslistsuccessfull({response}))

  }catch(error) {
    console.log(error)
  }
}

function* addModuleType({ payload: { url, data, history } }) {
  console.log("Nagesh",data);
  try {
    const response = yield call(getList, url, data);
    yield put(addModuleSuccess({ response }));
    swal("Modules Updated Successfully", { icon: "success" });
    history.push("/ModuleSelection");
  } catch (error) {
    console.log(error);
  }
}

function* updateModulesPhp({ payload: { url, data, history } }) {
  try {
    const response = yield call(postAddPhp, url, data);
    if(response.status){
      
      yield put(updateModulesSuccess({ response }));
    }
    


   
    // swal("Modules Updated Successfully", { icon: "success" });
    // history.push("/ModuleSelection");
  } catch (error) {
    console.log(error);
  }
}


export function* watchgetModules() {
  yield takeEvery(MODULE_LIST, getModuleData);
}

export function* watchgetAddons() {
  yield takeEvery(ADDON_LIST, getAddonData)
}


export function* watchaddModule() {
  yield takeEvery(ADD_MODULE, addModuleType);
}

export function* watchupdateModule(){
  yield takeEvery(MODULES_UPDATION, updateModulesPhp)
}


function* ModuleSaga() {
  yield all([
    fork(watchgetModules), 
    fork(watchaddModule),
    fork(watchgetAddons),
    fork(watchupdateModule)
  ]);
}

export default ModuleSaga;
