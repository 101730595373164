import React, { Component } from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
//import Link from "@mui/material/Link";

import RadioButton from "../../components/RadioButton/RadioButton";
import BlankVideoImage from "../../assets/images/videoGif/BlankVideoImage.png";
import Alerts from "../../components/notificationAlerts/Alerts";
import cons, { serverApi, configNames } from "../../helpers/Consts";
import { setConfiguration } from "../../store/Configuration/actions";
import IndentWithMultipleDept from "../../assets/images/videoGif/IntendMultipleDept.gif";
import IndentWithSingleDept from "../../assets/images/videoGif/IndentSingleDept.gif";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "../../assets/images/CloseIcon.png";
import {Link} from "react-router-dom"

class IndentwithMutipleDepts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: "",
      notmsg: "",
      severity: "error",
      alert: "none",
      indent: 0,
      inyes: false,
      inno: false,
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),
    };
  }
  getAutoIdFromList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach((prop) => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      });
    }
    return id;
  };
  onClickRadioButton = (selectedValue) => {
    var indent = 0;
    var inyes = false,
      inno = false;
    if (selectedValue === "Yes") {
      indent = 1;
      inyes = true;
    } else if (selectedValue === "No") {
      indent = 2;
      inno = true;
    }
    this.setState({
      selectedValue: selectedValue,
      indent: indent,
      inyes: inyes,
      inno: inno,
    });
  };

  handleConfig = () => {
    var printstyle = this.state.selectedValue;
    if (!printstyle) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Indent with Mutiple Depts",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else if (!this.state.companyId) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } else {
      var data = {
        companyId: this.state.companyId,
        configList: [
          {
            autoId: this.getAutoIdFromList(
              configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
            ),
            configParameter:
              configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS,
            isActive: 1,
            configValue: this.state.indent,
          },
        ],
      };
      if (this.state.companyId) {
        var msg = "Indent with Mutiple Depts Updated Successfully.";

        this.props.setConfiguration(
          serverApi.UPDATE_CONFIGURATION_NEW,
          data,
          msg,
          this.props.history
        );
      } else {
        this.setState({
          alert: "block",
          severity: "error",
          notmsg: "Sorrry! Please Select Company",
        });
        setTimeout(() => {
          this.setState({ alert: "none" });
        }, 3000);
      }
    }
  };

  componentDidMount = () => {
    var indent = 0;
    var inyes = false,
      inno = false;
    //var configdata = cons.CONFIG_DATA;//JSON.parse(localStorage.getItem("configdata"));
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      configdata.forEach((prop) => {
        if (
          prop.configParameter ===
          configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
        ) {
          if (prop.configValue === "1") {
            indent = 1;
            inyes = true;
          } else if (prop.configValue === "2") {
            indent = 2;
            inno = true;
          }
          this.setState({
            indent: indent,
            inyes: inyes,
            inno: inno,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  };

  componentWillReceiveProps = (props) => {
    var indent = 0;
    var inyes = false,
      inno = false;
    //var configdata = cons.CONFIG_DATA;//JSON.parse(localStorage.getItem("configdata"));
    //var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    var configdata = props.configdata

    if (configdata) {
      configdata.forEach((prop) => {
        if (
          prop.configParameter ===
          configNames.INDENT_WITH_MULTIPLE_DEPARTMENTS_ITEMGROUPS
        ) {
          if (prop.configValue === "1") {
            indent = 1;
            inyes = true;
          } else if (prop.configValue === "2") {
            indent = 2;
            inno = true;
          }
          this.setState({
            indent: indent,
            inyes: inyes,
            inno: inno,
            configdata: configdata,
            companyId: localStorage.getItem("companyIdconfig"),
          });
        }
      });
    }
  }

  render() {

    return (
      <>
        <Alerts
          alert={this.state.alert}
          severity={this.state.severity}
          notmsg={this.state.notmsg}
        />
        <div className="consoleFormContainer">
          <h5>Indent with Mutiple Depts</h5>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="consoleFormBlock">
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="indent"
                        checkednew={this.state.inyes}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="indent"
                        checkednew={this.state.inno}
                        onChange={this.onClickRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6}>
              {/* <div className="VideoGifContainer">
                <img src={IndentWithMultipleDept} alt="" />
              </div> */}
              <div className="VideoGifContainer">
                {/* <Popup
                    imageButton={IndentWithMultipleDept}
                    popupImage={IndentWithMultipleDept}
                  /> */}
                <img
                  src={IndentWithSingleDept}
                  alt="indent"
                  onClick={() => this.setState({ open: true })}
                />
              </div>
              <Dialog open={this.state.open} maxWidth="md">
                <img
                  src={CloseIcon}
                  className="closeButton"
                  alt=""
                  onClick={() => this.setState({ open: false })}
                />
                <img
                  src={IndentWithSingleDept}
                  alt="indent"
                  sx={{ borderRadius: "12px" }}
                />
              </Dialog>
            </Grid>
          </Grid>
        </div>
        <div className="consoleFormButtonBlock">
          <Link to="/invoice">
            <Button variant="contained" className="cancelButton">
              Cancel
            </Button>
          </Link>
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {companysList, companyscoulmns} = state.BasicDetails;
  const {configdatalist, configdata} = state.Configuration;
  return {companysList, companyscoulmns, configdatalist, configdata};
};

export default connect(mapStatetoProps, {
  setConfiguration,
})(IndentwithMutipleDepts);
