import React, { Component } from "react";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import RadioButton from "../../components/RadioButton/RadioButton";


const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  color: #6491a9;
  font-family: "poppins_regular";
  cursor: pointer;
  background-color: transparent;
  padding: 0 0 5px;
  margin-right: 40px;
  border: none;
  border-radius: 5px;
  display: flex;
  //   justify-content: center;
  &:focus {
    color: #fff;
    border-radius: 3px;
  }

  &.${tabUnstyledClasses.selected} {
    color: #004b73;
    font-family: "poppins_bold";
    border-bottom: 3px solid #94cd00;
    border-radius: 0;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: "poppins_regular";
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 320px;
  //   background-color: ${blue[500]};
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
`;


class TabsNavigationPurchaseSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};  

  }

  render() {

    return (
      <>
        <TabsUnstyled defaultValue={0}>
       <TabsList>
         <Tab>Indent with Mutiple Depts</Tab>
         <Tab>PO Print</Tab>
         <Tab>Inward With Po</Tab>
         <Tab>Purchase Currency Configuration</Tab>
         <Tab>Open Indent</Tab>
       </TabsList>
       <TabPanel value={0}>{this.props.printstyles}</TabPanel>
      <TabPanel value={1}>{this.props.mrininvoice}</TabPanel>   
      <TabPanel value={2}>{this.props.inward}</TabPanel>   
      <TabPanel value={3}>{this.props.purchseCurrencyConfig}</TabPanel> 
      <TabPanel value={4}>{this.props.openIndent}</TabPanel>      


     </TabsUnstyled>
      </>
    );
  }
}




export default TabsNavigationPurchaseSettings;

