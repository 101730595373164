import axios from "axios";






const getListurlPhp = (url, data) => {
  // const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  return axios
    .get(url, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

const postAddPhp = (url, data) => {
  // const mtoken = localStorage.getItem("token_type") + localStorage.getItem("access_token");
  return axios
    .post(url, data, { headers: { 'Content-Type': 'application/json' } })
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.messge;
      if(response.status){
        return response;
      }
      
    })
    .catch((err) => {
      throw err[1];
    });
};





export {
  
  getListurlPhp,
  postAddPhp
};
