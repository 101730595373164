import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  BRANCHDETAILS_ADD,
  GET_STATES_LIST,
  STATES_LIST_SUCCESS,
  CLEAR_FORM,
  GST_VERIFICATION,
} from "./actionTypes";
import {
  branchapiErrors,
  branchCreationAddSuccessful,
  stateslist,
  gstVerificationSuccessful
} from "./actions";
import cons, { serverApi } from "../../../helpers/Consts";

// AUTH related methods
import {
  postAdd, postEdit, getList, getListurl
} from "../../../helpers/Server_Helper";
import { postAddPhp } from "../../../helpers/Server_Helper_Php";
import swal from "sweetalert";


//If user is login then dispatch redux action's are directly from here.
function* AddBranchDetails({ payload: { url, data, updateView, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    console.log("getting response", response);
    if (response.data.status == true) {

      swal(response.data.message, {
        icon: "success",
      });
      if(updateView === false){
      var values = { 
        company_id: data.postAuthenticationDto.companyId, 
        created_by: data.branch.createdBy, 
        task_desc: 'approved_master', 
        branch_id: response.data.id
      }
      console.log("values_CREATE_APPROVER_MASTER",values)
      // yield call(postAddPhp, serverApi.CREATE_APPROVER_MASTER, values);
      }
      yield put(branchCreationAddSuccessful(response.data, data));
      history.push('/branchlist');
    } else {
      console.log("resposneeeeeee", response, response.data);
      swal(response.data.message, {
        icon: "error",
      });
    }

  } catch (error) {
    console.log(error);
    swal("Sorry! Permission Denied!", {
      icon: "error",
    });
    //yield put(apiError(error));
  }
}

function* getMasterStatesList({ payload: { url } }) {
  try {
    const response = yield call(getListurl, url, {});
        // response.data.sort((a, b) => (a.suppId < b.suppId) ? 1 : -1)

    yield put(stateslist({ response }));

  } catch (error) {
    yield put(branchapiErrors(error));
  }
}

function* getGstVerification({ payload: { url, history } }) {
  try {
    const response = yield call(getListurl, url);
    if(response){
      if(response.data.status) {
        yield put(gstVerificationSuccessful({ response }));
        swal("GST Verified Successfully!", { icon: "success" });
      } else {
        yield put(gstVerificationSuccessful({ response }));
        swal("GST Verification Failed", { icon: "error" });
      }
    }
  } catch (error) {
    swal("GST Verification Failed", { icon: "error" });
  }
}


export function* watchBranchDetailsAdd() {
  yield takeEvery(BRANCHDETAILS_ADD, AddBranchDetails);
  yield takeEvery(GST_VERIFICATION,getGstVerification)
}

export function* watchMasterStatesListData() {
  yield takeEvery(GET_STATES_LIST, getMasterStatesList)
}

function* BranchDetailsSaga() {
  yield all([
    fork(watchMasterStatesListData),
    fork(watchBranchDetailsAdd),
  ]);
}

export default BranchDetailsSaga;
