/****
 * Created By Nagesh Medisetty 01-02-2022
 * TextField with alfa numeric text with required
 */


 import React, { Component } from 'react';
 import TextField from './TextField';
 
 
 
 
 
 class TextFieldMobile extends Component {
     constructor(props) {
         super(props);
     }
 
     render() {
       const Humbug = {
         value: this.props.value,
         label: this.props.lable,
         placeholder: this.props.placeholder,
         type:this.props.type,
         onChange:this.props.onChange,
         caps:this.props.caps
       }
         return(
           <div className="TextFieldBlock">
             {this.props.label && <label htmlFor="app-input-field">{this.props.label} </label>}
                 <TextField number={true} {...Humbug}></TextField>
             </div>
         )
     }
 }
 
 export default TextFieldMobile;

 