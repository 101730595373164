import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import { Component } from "react";
import TextFieldReq from "../../../components/TextField/TextFieldReq";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import DynamicSelect from "../LeaveTypes/DynamicSelect";
import CustomButton from "../../../components/Buttons/Buttons";
import swal from "sweetalert";
import { serverApi } from "../../../helpers/Consts";
import { createSupplierTypes,updateSupplierTypes } from "../../../store/Master/SupplierTypeMaster/actions"

class createisActive extends Component {
    constructor() {
        super();
        this.state = {
            confirm: false,
            userDit: JSON.parse(localStorage.getItem("authUser")),
            companyId: localStorage.getItem('companyId'),
            suppTypeDesc: "",
            suppTypeCode: "",
            isActive: "",
            supplierValue: "",
            createdBy: null,
            createdOn: "",
            suppTypeId: '',
            startIndex: "",
            lastIndex: ""

        };
    }
    componentDidMount() {

        if (this.props.location) {
            if (this.props.location.rowdata) {
                console.log("data.....!", this.props.location.rowdata);
                this.setState({
                    suppTypeCode: this.props.location.rowdata.suppTypeCode,
                    suppTypeDesc: this.props.location.rowdata.suppTypeDesc,
                    createdBy: this.props.location.rowdata.createdBy,
                    companyId: localStorage.getItem('companyId'),
                })
            }

        }
    }

    onhandlechangeValue = (key) => (event) => {
        if (key === "suppTypeCode") {
          
          if (/^[a-zA-Z]{1}$/.test(event) || event === "") {
        //    if (/^[a-zA-Z0-9]{1}$/.test(event) || event === ""){


            this.setState({
                suppTypeCode: event.toUpperCase()
            })
        }
        }
         if (key === "suppTypeDesc") {
            this.setState({
                suppTypeDesc: event
            })
        }
    }
    onClickCreateUpdate = () => {
        if (this.props.location.rowdata === undefined) {
            // if (this.state.suppTypeCode === "" || this.state.suppTypeDesc === "") {
            //     swal("Please Enter the mandatory fields ", { icon: "error" });

            // }
            if(this.state.suppTypeCode === ""){
                swal("Please Enter the Supplier Type Code !!!");
                return false
            }
            
            if(this.state.suppTypeDesc === ""){
                swal("Please Enter the Supplier Type Description !!!");
                return false
            }
            
            else {
                const data = {
                    suppTypeCode: this.state.suppTypeCode,
                    suppTypeDesc: this.state.suppTypeDesc,
                    createdBy: this.state.userDit.userId,
                    companyId: localStorage.getItem("companyIdconfig"),
                    isActive: 1
                }
                console.log("created successfully", data);
                //calling create api
                this.props.createSupplierTypes(serverApi.CREATE_SUPPLIER_TYPES, data, this.props.history);
            }

        } else {
            // if (this.state.suppTypeCode === "" || this.state.suppTypeDesc === "") {
            //     swal("Please Enter the mandatory fields", { icon: "error" });
            //     console.log("mandatory fields", this.props.location.rowdata);
            // }
            if(this.state.suppTypeCode === ""){
                swal("Please Enter the Supplier Type Code !!!");
                return false
            }
            
            if(this.state.suppTypeDesc === ""){
                swal("Please Enter the Supplier Type Description !!!");
                return false
            }
            else {
                const data = {
                    suppTypeId:this.props.location.rowdata.suppTypeId,
                    suppTypeCode: this.state.suppTypeCode,
                    suppTypeDesc: this.state.suppTypeDesc,
                    createdBy: this.state.userDit.userId,
                    companyId: localStorage.getItem("companyIdconfig"),
                    isActive: 1
                }
                
                console.log("Update Successfully", data)
                //calling update api
                this.props.updateSupplierTypes(serverApi.UPDATE_SUPPLIER_TYPES, data, this.props.history)
            }
        }
    }

    onClickCancel = () => {
        this.props.history.push("/supplier_types")
    }

    handlePropsSelectChange = (selectedValue, selectedName, name) => {
        if (name === "isActive") {
            this.setState({
                isActive: selectedValue,
                supplierValue: selectedValue,
            })
        }
    }

    render() {
        return (
            <div>
                <div className="consoleFormContainer">
                    <h5>Fill Details</h5>
                    <div className="consoleFormBlock">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(12, 1fr)"
                                    gap={2}
                                >

                                    <Box gridColumn="span 3">
                                        <div className="consoleTextFieldBlock">
                                            <TextFieldReq
                                                label="Supplier Type Code"
                                                value={this.state.suppTypeCode}
                                                caps="OFF"
                                                onChange={this.onhandlechangeValue('suppTypeCode')}
                                            />
                                        </div>
                                    </Box>
                                    <Box gridColumn="span 3">
                                        <div className="consoleTextFieldBlock">
                                            <TextFieldReq
                                                label="Supplier Type Description"
                                                value={this.state.suppTypeDesc}
                                                caps="OFF"
                                                onChange={this.onhandlechangeValue('suppTypeDesc')}
                                            />
                                        </div>
                                    </Box>
                                    {/* <Box gridColumn="span 3">
                                        <div className="consoleTextFieldBlock">
                                            <label>isActive</label>
                                            <DynamicSelect
                                                arrayOfData={isActive}
                                                className="dropdownBlockContainer"
                                                menuPosition="fixed"
                                                menuPlacement="auto"
                                                onSelectChange={this.handlePropsSelectChange}
                                                selected={this.state.isActive}
                                                update={this.props.location.state ? 1 : 0}
                                                name="isActive"
                                            />

                                        </div>
                                    </Box> */}

                                    {this.props.location.state === undefined ? (
                                        ""
                                    ) : (
                                        <Box gridColumn="span 6">
                                            <div className="consoleUpdateTextBlock">
                                                <div>
                                                    <span className="taxLabel">Created By : <b>{this.state.createdBy}</b></span>
                                                </div>
                                                <div>
                                                    <span className="taxLabel">Created On :<b> {this.state.createdDate}</b></span>
                                                </div>
                                            </div>
                                        </Box>
                                    )}

                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="consoleFormButtonBlock">
                    <Link>
                        <CustomButton
                            label={"Cancel"}
                            className="greenBorderButton"
                            handleClick={this.onClickCancel}
                        />
                    </Link>
                    {this.props.location.rowdata !== undefined ? (
                        <CustomButton
                            label={"Update"}
                            className="greenButton"
                            handleClick={this.onClickCreateUpdate}
                            type="sumbit"
                        />
                    ) : (
                        <CustomButton
                            label={"Create"}
                            className="greenButton"
                            handleClick={this.onClickCreateUpdate}
                            type="sumbit"
                        />
                    )}
                    {/* common popup for validation */}
                    {/* {this.state.confirm && (
            <CommonPopup>
              <div className="delAddItem">
                <div className="mandatoryFieldText">Please select all the mandatory fields</div>
                <div className="delAddItemBtns">
                  <input
                    type="button"
                    onClick={this.onclickPopUP.bind(this)}
                    value="Ok"
                    className="delYesBtn"
                  />
                </div>
              </div>
            </CommonPopup>
          )} */}
                </div>
            </div>
        )
    }
}
const isActive = [
    {
        value: "Y",
        label: "Yes",
        name: "Yes",
    },
    {
        value: "N",
        label: "No",
        name: "No",
    },
];


const mapStatetoProps = (state) => {

};

export default withRouter(
    connect(mapStatetoProps, {
        createSupplierTypes,
        updateSupplierTypes
    })(createisActive)
);
