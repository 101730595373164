/* Created By Nagesh Medisetty on 19-04-2022 */
import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  headerData,
  changeLayout,
} from "../../store/layout/actions";

import LeftSidebar from "../../Sidebar/LeftSidebar";
import InnerNavigation from "../../components/InnerNavigation/InnerNavigation";
import { Grid } from "@mui/material";
import Header from "../../components/Header/Header";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      userDit: JSON.parse(localStorage.getItem("authUser")),
    };
  }

componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {      
    }
}
  componentDidMount() {
    // console.log("Nagesh this.props.location",this.props.location);
    this.props.headerData(this.props.location);
  }   
  componentWillReceiveProps(props){
    // console.log("Nagesh componentWillReceiveProps ",this.props.location);
    this.setState({
      rowdata: this.props.location.rowdata
    })
  }
 
  render() {
    const headdata = this.props.header_data;
    var width= ""
    var mainWidth = ""
    if (headdata.isInnerNavigation === true){
      width = "20%"
      mainWidth="80%"
    }else{
      width = "0%"
      mainWidth="98%"
    }


    
    return (
      <React.Fragment>
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <i className="ri-loader-line spin-icon"></i>
                </div>
            </div>
        </div>


        <Grid container className="mainContainer">
        <LeftSidebar  />
        <Grid className="mainContent">
          {
            (headdata.isInnerNavigation ? <InnerNavigation childName={headdata.parentName} buttonbranch={headdata.createbranchbtn} module= {this.state.module} onNameChange={this.onChange} myCompanyId = {this.state.userDit.orgId} menuList={headdata.menulist} headerName={headdata.headerName} locationState={this.props.location} headdata= {headdata}style={{width:width}}/> : "")
            
          }
          
          <div className="consoleContainer" style={{width:mainWidth}}>
            <Header childName={headdata.parentName} buttonbranch={headdata.createbranchbtn} onNameChange={this.onChange} myCompanyId = {this.state.userDit.orgId}/>
            {this.props.children}
          </div>
        </Grid>
      </Grid>
        
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.Layout
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  headerData,
})(withRouter(Layout));

