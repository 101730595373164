import React, { Component } from "react";
import { Grid, Box, Button } from "@mui/material";
import { connect } from "react-redux";
import RadioButton from "../../components/RadioButton/RadioButton";
import cons, { serverApi, configNames, serverVars } from "../../helpers/Consts";
import { setConfiguration, getConfigurationData } from "../../store/Configuration/actions";
import Checkbox from "../../components/CheckBox/Checkbox";
import swal from "sweetalert";

class SalesSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configdata: [],
      companyId: localStorage.getItem("companyIdconfig"),

      saleOrdQYes: false,
      saleOrdQNo: true,
      saleOrdautoID: "",

      delOrdQYes: false,
      delOrdQNo: true,
      delOrdSoYes: true,
      delOrdSoNo: false,
      delOrdAutoId: "",

      invOrdQYes: false,
      invOrdQNo: true,
      invOrdSoYes: false,
      invOrdSoNo: true,
      invOrdDoYes: false,
      invOrdDoNo: true,
      invOrdAutoId: "",

      regInvoice: true,
      juteGovt: false,
      juteYarn: false,
      juteHessian: false,
      InvTypeAutoId: "",

     e_wbill_e_InvoiceYes:true,
     e_wbill_e_InvoiceNo:false,
     e_wbill_e_InvoiceautoID: "",



    };
  }

  onClickSalOrdRadioButton = (selectedVal) => {
    if (selectedVal === "Yes") {
      this.setState({
        saleOrdQYes: true,
        saleOrdQNo: false 
      })
    } else if(this.state.saleOrdQYes === true) {
      swal('sales is mandatory')
      this.setState({
        saleOrdQYes: false,
        saleOrdQNo: true
      })
    }
  }
  onClickDelQuatRadioButton = (selectedVal) => {
    if (selectedVal === "Yes") {
      this.setState({
        delOrdQYes: true,
        delOrdQNo: false
      })
    } else {
      this.setState({
        delOrdQYes: false,
        delOrdQNo: true
      })
    }
  }
  onClickDelSoRadioButton = (selectedVal) => {
    if (selectedVal === "Yes") {
      this.setState({
        delOrdSoYes: true,
        delOrdSoNo: false
      })
    } else {
      this.setState({
        delOrdSoYes: false,
        delOrdSoNo: true
      })
    }
  }
  onClickInvOrdQRadioButton = (selectedVal) => {
    if (selectedVal === "Yes") {
      this.setState({
        invOrdQYes: true,
        invOrdQNo: false
      })
    } else {
      this.setState({
        invOrdQYes: false,
        invOrdQNo: true
      })
    }
  }
  onClickInvOrdSoRadioButton = (selectedVal) => {
    if (selectedVal === "Yes") {
      this.setState({
        invOrdSoYes: true,
        invOrdSoNo: false
      })
    } else {
      this.setState({
        invOrdSoYes: false,
        invOrdSoNo: true
      })
    }
  }
  onClickInvOrdDoRadioButton = (selectedVal) => {
    if (selectedVal === "Yes") {
      this.setState({
        invOrdDoYes: true,
        invOrdDoNo: false
      })
    } else {
      this.setState({
        invOrdDoYes: false,
        invOrdDoNo: true
      })
    }
  }

  onClickE_wbil_e_InvoiceRadioButton = (selectedVal) => {
    if(selectedVal === "Yes") {
      this.setState({
        e_wbill_e_InvoiceYes:true,
        e_wbill_e_InvoiceNo:false,

      })
    }else{
      this.setState({
        e_wbill_e_InvoiceYes:false,
        e_wbill_e_InvoiceNo:true,
      });
    }
  }
  handleCheckBox = (name, value) => {
    this.setState(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  }
  componentDidMount = () => {
    var configdata = cons.CONFIG_DATA ? cons.CONFIG_DATA : "";
    if (configdata) {
      if (configdata.length !== 0) {
        configdata.forEach((prop) => {
          if (prop.configParameter === 'quetationMandatory') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              saleOrdautoID: prop.autoId,
              saleOrdQYes: configValue[0].quatation === 1 ? true : false,
              saleOrdQNo: configValue[0].quatation === 2 ? true : false
            });
          }
          if (prop.configParameter)
            if (prop.configParameter === 'deliveryorderMandatory') {
              var configValue = JSON.parse(prop.configValue);
              this.setState({
                delOrdAutoId: prop.autoId,
                delOrdQYes: configValue[0].quatation === 1 ? true : false,
                delOrdQNo: configValue[0].quatation === 2 ? true : false,
                delOrdSoYes: configValue[0].salesorder === 1 ? true : false,
                delOrdSoNo: configValue[0].salesorder === 2 ? true : false,
              });
            }
          if (prop.configParameter === '"invoiceMandatory"') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              invOrdAutoId: prop.autoId,
              invOrdQYes: configValue[0].quatation == 1 ? true : false,
              invOrdQNo: configValue[0].quatation == 2 ? true : false,
              invOrdSoYes: configValue[0].salesorder == 1 ? true : false,
              invOrdSoNo: configValue[0].salesorder == 2 ? true : false,
              invOrdDoYes: configValue[0].deliveryorder == 1 ? true : false,
              invOrdDoNo: configValue[0].deliveryorder == 2 ? true : false,
            });
          }

          if(prop.configParameter ==="eway_bill_einvoice"){
          var configValue = JSON.parse(prop.configValue);
          this.setState({
            e_wbill_e_InvoiceautoID:prop.autoId,
            e_wbill_e_InvoiceYes:configValue[0].quatation===1?true:false,
            e_wbill_e_InvoiceNo:configValue[0].quatation===2?true:false
          }); 
          }

          if (prop.configParameter === 'invoiceType') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              InvTypeAutoId:prop.autoId,
              juteGovt:configValue[0].juteGovt  ==1?true:false,
              juteHessian:configValue[0].juteHessian  ==1?true:false,
              juteYarn:configValue[0].juteYarn  ==1?true:false,
              regInvoice:configValue[0].regInvoice ==1?true:false
            })
          }else{
            this.setState({
              InvTypeAutoId:"",
              juteGovt:false,
              juteHessian:false,
              juteYarn:false,
              regInvoice:true
             
            })
          }
        });
      } 
    }
  };

  componentWillReceiveProps = (props) => {
    if (props.configdata) {
      if (props.configdata.length !== 0) {
        props.configdata.forEach((prop) => {
          if (prop.configParameter === 'quetationMandatory') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({ saleOrdautoID: prop.autoId, saleOrdQYes: configValue[0].quatation === 1 ? true : false, saleOrdQNo: configValue[0].quatation === 2 ? true : false });
          }
          if (prop.configParameter === 'deliveryorderMandatory') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              delOrdAutoId: prop.autoId,
              delOrdQYes: configValue[0].quatation === 1 ? true : false,
              delOrdQNo: configValue[0].quatation === 2 ? true : false,
              delOrdSoYes: configValue[0].salesorder === 1 ? true : false,
              delOrdSoNo: configValue[0].salesorder === 2 ? true : false,
            });
          }
          if (prop.configParameter === 'invoiceMandatory') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              invOrdAutoId: prop.autoId,
              invOrdQYes: configValue[0].quatation == 1 ? true : false,
              invOrdQNo: configValue[0].quatation == 2 ? true : false,
              invOrdSoYes: configValue[0].salesorder == 1 ? true : false,
              invOrdSoNo: configValue[0].salesorder == 2 ? true : false,
              invOrdDoYes: configValue[0].deliveryorder == 1 ? true : false,
              invOrdDoNo: configValue[0].deliveryorder == 2 ? true : false,
            });
          }
          if(prop.configParameter ==="eway_bill_einvoice" ){
          
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              e_wbill_e_InvoiceautoID:prop.autoId,
              e_wbill_e_InvoiceYes:configValue[0].quatation===1?true:false,
              e_wbill_e_InvoiceNo:configValue[0].quatation===2?true:false
            }); 
          }

          if (prop.configParameter === 'invoiceType') {
            var configValue = JSON.parse(prop.configValue);
            this.setState({
              InvTypeAutoId:prop.autoId,
              juteGovt:configValue[0].juteGovt ==1?true:false,
              juteHessian:configValue[0].juteHessian  ==1?true:false,
              juteYarn:configValue[0].juteYarn  ==1?true:false,
              regInvoice:configValue[0].regInvoice ==1?true:false
            })
          }else{
            this.setState({   
              InvTypeAutoId:"", 
              juteGovt:false,
              juteHessian:false,
              juteYarn:false,
              regInvoice:true
            })
          }
        });
      }
    }

  };

  getAutoIdFormList = (configParameter) => {
    var list = this.state.configdata;
    var id = undefined;
    if (list.length > 0) {
      list.forEach(prop => {
        if (prop.configParameter === configParameter) {
          id = prop.autoId;
        }
      })
    }
  }

  handleConfig = () => {
    var data = {
      companyId: localStorage.getItem("companyIdconfig"),
      configList: [
        {
          autoId: this.state.saleOrdautoID,
          configParameter: configNames.QUETATION_MANDATORY,
          isActive: 1,
          configValue: JSON.stringify([{ "taskName": "salesorder", "quatation": this.state.saleOrdQYes === true ? 1 : 2 }])
        },
        {
          autoId:this.state.e_wbill_e_InvoiceautoID,
          configParameter:configNames.EWAY_BILL_EINVOICE,
          isActive:this.state.e_wbill_e_InvoiceYes ? 1 : 0,
          configValue:JSON.stringify([{"taskName": "e_bill_e_invoice", "quatation": this.state.e_wbill_e_InvoiceYes===true?1:2}])

        },
        {
          autoId: this.state.delOrdAutoId,
          configParameter: configNames.DELIVERYORDER_MANDATORY,
          isActive: 1,
          configValue: JSON.stringify([{ "taskName": "salesorder", "quatation": this.state.delOrdQYes === true ? 1 : 2, "salesorder": this.state.delOrdSoYes === true ? 1 : 2 }])
        },
        {
          autoId: this.state.invOrdAutoId,
          configParameter: configNames.INVOICE_MANDATORY,
          isActive: 1,
          configValue: JSON.stringify([{ "taskName": "invoice", "quatation": this.state.invOrdQYes === true ? 1 : 2, "salesorder": this.state.invOrdSoYes === true ? 1 : 2, "deliveryorder": this.state.invOrdDoYes === true ? 1 : 2 }])
        },
        {
          autoId: this.state.InvTypeAutoId,
          configParameter: configNames.INVOICE_TYPE,
          isActive: 1,
          configValue: JSON.stringify([{
            regInvoice: this.state.regInvoice == true ? 1 : 0,
            juteGovt: this.state.juteGovt == true ? 1 : 0,
            juteYarn: this.state.juteYarn == true ? 1 : 0,
            juteHessian: this.state.juteHessian == true ? 1 : 0,
          }])
        },
      ],
    };

    if (this.state.companyId) {
      var msg = "Updated Successfully.";
      this.props.setConfiguration(
        serverApi.UPDATE_CONFIGURATION_NEW,
        data,
        msg,
        this.props.history
      );
    } else if (this.state.selectedInvValue) {
      this.setState({
        alert: "block",
        severity: "error",
        notmsg: "Sorrry!!! Please Select Company",
      });
      setTimeout(() => {
        this.setState({ alert: "none" });
      }, 3000);
    } 
  };

  render() {
    return (
      <>
       <div className="consoleFormContainer">
          <h5>Invoice Type </h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              {/* <Grid item xs={4}><h5><i class="fa fa-check"></i>&nbsp;Quotation is Mandatory </h5></Grid> */}
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid container spacing={2} style={{ marginTop: "10px" }}>
                    <Grid item xs={4}>
                      <div className="consoleTextFieldBlock" style={{ margin: "6px", color: "#004b73", display: "flex" }}>
                        <div >
                          <Checkbox label="Regular Invoice" name="regInvoice" selected={this.state.regInvoice} onChange={this.handleCheckBox}

                          ></Checkbox>
                        </div>

                        <div >
                          <Checkbox label="Jute Goverment" name="juteGovt" selected={this.state.juteGovt} onChange={this.handleCheckBox}></Checkbox>
                        </div>

                        <div>
                          <Checkbox label="Jute Yarn" name="juteYarn" selected={this.state.juteYarn} onChange={this.handleCheckBox}></Checkbox>
                        </div>

                        <div style={{ position: "relative", right: "50px" }} >
                          <Checkbox label="Jute Hessian" name="juteHessian" selected={this.state.juteHessian} onChange={this.handleCheckBox}></Checkbox>
                        </div>

                      </div>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={4}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="saleOrd"
                        checkednew={this.state.saleOrdQYes}
                        onChange={this.onClickSalOrdRadioButton}
                      />
                    </div>
                  </Grid> */}
                  {/* <Grid item xs={4}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="saleOrd"
                        checkednew={this.state.saleOrdQNo}
                        onChange={this.onClickSalOrdRadioButton}
                      />
                    </div>
                  </Grid> */}
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <h5>Sales Order </h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={4}><h5><i class="fa fa-check"></i>&nbsp;Quotation is Mandatory </h5></Grid>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >

                  <Grid item xs={4}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="saleOrd"
                        checkednew={this.state.saleOrdQYes}
                        onChange={this.onClickSalOrdRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="saleOrd"
                        checkednew={this.state.saleOrdQNo}
                        onChange={this.onClickSalOrdRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="consoleFormContainer">
          <h5>Delivery Order </h5>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="consoleFormBlock">
                <Grid item xs={12}><h5><i class="fa fa-check"></i>&nbsp;Quotation is Mandatory </h5></Grid>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="indentQut"
                        checkednew={this.state.delOrdQYes}
                        onChange={this.onClickDelQuatRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="indentQut"
                        checkednew={this.state.delOrdQNo}
                        onChange={this.onClickDelQuatRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="consoleFormBlock">
                <Grid item xs={12}><h5><i class="fa fa-check"></i>&nbsp;Sales Order is Mandatory </h5></Grid>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="indentSo"
                        checkednew={this.state.delOrdSoYes}
                        onChange={this.onClickDelSoRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="indentSo"
                        checkednew={this.state.delOrdSoNo}
                        onChange={this.onClickDelSoRadioButton}
                        disabled={true}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="consoleFormContainer">
          <h5>Invoice </h5>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <Grid item xs={12}><h5><i class="fa fa-check"></i>&nbsp;Quotation is Mandatory </h5></Grid>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                    
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="InvQgroup"
                        checkednew={this.state.invOrdQYes}
                        onChange={this.onClickInvOrdQRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="InvQgroup"
                        checkednew={this.state.invOrdQNo}
                        onChange={this.onClickInvOrdQRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <Grid item xs={12}><h5><i class="fa fa-check"></i>&nbsp;Sales Order is Mandatory </h5></Grid>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="InvSogroup"
                        checkednew={this.state.invOrdSoYes}
                        onChange={this.onClickInvOrdSoRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="InvSogroup"
                        checkednew={this.state.invOrdSoNo}
                        onChange={this.onClickInvOrdSoRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="consoleFormBlock">
                <Grid item xs={12}><h5><i class="fa fa-check"></i>&nbsp;Delivery Order is Mandatory </h5></Grid>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="InvDogroup"
                        checkednew={this.state.invOrdDoYes}
                        onChange={this.onClickInvOrdDoRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="InvDogroup"
                        checkednew={this.state.invOrdDoNo}
                        onChange={this.onClickInvOrdDoRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="consoleFormContainer">
          <h5>Eway bill / E-invoice </h5>
          <div className="consoleFormBlock">
            <Grid container spacing={2}>
              <Grid item xs={4}><h5><i class="fa fa-check"></i>&nbsp;Eway bill / E-invoice </h5></Grid>
              <Grid item xs={12}>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(12, 1fr)"
                  gap={2}
                >

                  <Grid item xs={4}>
                    <div className="">
                      <RadioButton
                        labelText="Yes"
                        value="yes"
                        groupname="e_bill_e_invoice"
                        checkednew={this.state.e_wbill_e_InvoiceYes}
                        onChange={this.onClickE_wbil_e_InvoiceRadioButton}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="">
                      <RadioButton
                        labelText="No"
                        value="no"
                        groupname="e_bill_e_invoice"
                        checkednew={this.state.e_wbill_e_InvoiceNo}
                        onChange={this.onClickE_wbil_e_InvoiceRadioButton}
                      />
                    </div>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="consoleFormButtonBlock">
          {/* <Link to="/date_allowable"> */}
          {/* <Link to=""> */}

          <Button variant="contained" className="cancelButton">
            Cancel
          </Button>
          {/* </Link> */}
          <Button
            variant="contained"
            type="button"
            className="saveButton"
            onClick={this.handleConfig}
          >
            Save
          </Button>
        </div>
        {/* </form> */}
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companysList, companyscoulmns } = state.BasicDetails;
  const { configdatalist, configdata } = state.Configuration;
  return { companysList, companyscoulmns, configdatalist, configdata };
};

export default connect(mapStatetoProps, {
  setConfiguration,
  getConfigurationData
})(SalesSettings);
