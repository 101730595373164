import React, { Component } from "react";
import Form from "../../components/Form/Form";
class UserCreateNew extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {    
    return (
      <>
        <Form />
      </>
    );
  }
}
export default UserCreateNew;
