/* 
  Nagesh Medisetty On 01-01-2022 
*/
import React, { Component } from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "../assets/images/dashboard_inactive.png";
import CompanyIcon from "../assets/images/company_unactive.png";
import ConfigurationIcon from "../assets/images/configuration_unactive.png";
import ModuleIcon from "../assets/images/module_unactive.png";
import ProfileMenuIcon from "../assets/images/profile_unactive.png";
import InvoiceIcon from "../assets/images/Invoice_unactive.png";
import MasterData from "../assets/images/masterData.png"
import NavLogo from "../assets/images/MainLogo.png";
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';





// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class LeftSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleDrawerOpen = () => {
    this.setState({open:true});
  };

  handleDrawerClose = () => {
    this.setState({open:false});
  };

  render() {
    const handleLinkActive = (value) => () => {
      if (value == 1) {
        localStorage.setItem("dashboard", 1);
        localStorage.setItem("company", 0);
        localStorage.setItem("config", 0);
        localStorage.setItem("module", 0);
        localStorage.setItem("usermange", 0);
        localStorage.setItem("billing", 0);
        localStorage.setItem("masterData", 0);
  
        this.props.history.push("/dashboard");
      }
      if (value == 2) {
        localStorage.setItem("dashboard", 0);
        localStorage.setItem("company", 1);
        localStorage.setItem("config", 0);
        localStorage.setItem("module", 0);
        localStorage.setItem("usermange", 0);
        localStorage.setItem("billing", 0);
        localStorage.setItem("masterData", 0);
  
        this.props.history.push("/CompanyList");
      }
      
      if (value == 3) {
        localStorage.setItem("dashboard", 0);
        localStorage.setItem("company", 0);
        localStorage.setItem("config", 1);
        localStorage.setItem("module", 0);
        localStorage.setItem("usermange", 0);
        localStorage.setItem("billing", 0);
        localStorage.setItem("masterData", 0);
  
        this.props.history.push("/invoice");
      }
      if (value == 4) {
        localStorage.setItem("dashboard", 0);
        localStorage.setItem("company", 0);
        localStorage.setItem("config", 0);
        localStorage.setItem("module", 1);
        localStorage.setItem("usermange", 0);
        localStorage.setItem("billing", 0);
        localStorage.setItem("masterData", 0);
  
        this.props.history.push("/ModuleSelection");
      }
      if (value == 5) {
        localStorage.setItem("dashboard", 0);
        localStorage.setItem("company", 0);
        localStorage.setItem("config", 0);
        localStorage.setItem("module", 0);
        localStorage.setItem("usermange", 1);
        localStorage.setItem("billing", 0);
        localStorage.setItem("masterData", 0);
  
        this.props.history.push("/UserManagement");
      }
      if (value == 6) {
        localStorage.setItem("dashboard", 0);
        localStorage.setItem("company", 0);
        localStorage.setItem("config", 0);
        localStorage.setItem("module", 0);
        localStorage.setItem("usermange", 0);
        localStorage.setItem("billing", 1);
        localStorage.setItem("masterData", 0);
        this.props.history.push("/Billing");
      }
      if (value == 7) {
        localStorage.setItem("dashboard", 0);
        localStorage.setItem("company", 0);
        localStorage.setItem("config", 0);
        localStorage.setItem("module", 0);
        localStorage.setItem("usermange", 0);
        localStorage.setItem("billing", 0);
        localStorage.setItem("masterData", 1);
        this.props.history.push("/leaveTypes");
      }
  
      
    };
    return (<>
<div>
        <IconButton onClick={()=>this.handleDrawerClose}>
          <ChevronRightIcon
            style={{
              color: "#ffffff",
              position: "fixed",
              bottom: "35px",
              left: "-15px",
              background: "#004b73",
              borderRadius: "20px",
              padding: "8px",
            }}
          />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={()=>this.handleDrawerOpen}
          edge="start"
          sx={{
            ...(this.state.open && { display: "none" }),
          }}
        >
          <ChevronLeftIcon style={{ color: "#ffffff" }} />
        </IconButton>

        <Drawer variant="permanent" open={this.state.open}>
          <div className="NavLogoBlock">
            <img src={NavLogo} alt="" />
            {/* {localStorage.getItem("company")} */}
          </div>
          <div className="leftSideBarContainer">
            <Link onClick={handleLinkActive(1)}>
            <Tooltip title="DashBoard" placement="right">
              <img
                src={DashboardIcon}
                alt=""
                className={
                  localStorage.getItem("dashboard") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
            
            <Link onClick={handleLinkActive(2)}>
            <Tooltip title="Company Creation" placement="right">
              <img
                src={CompanyIcon}
                alt=""
                className={
                  localStorage.getItem("company") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
            <Link to="/date_allowable" onClick={handleLinkActive(3)}>
            <Tooltip title="Configuration" placement="right">
              <img
                src={ConfigurationIcon}
                alt=""
                className={
                  localStorage.getItem("config") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
            <Link to="/leaveTypes" onClick={handleLinkActive(7)}>
            <Tooltip title="Master Data" placement="right">
              <img
                src={MasterData}
                alt=""
                className={
                  localStorage.getItem("masterData") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
            <Link to="/ModuleSelection" onClick={handleLinkActive(4)}>
            <Tooltip title="Module" placement="right">
              <img
                src={ModuleIcon}
                alt=""
                className={
                  localStorage.getItem("module") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
            <Link to="/role_management" onClick={handleLinkActive(5)}>
            <Tooltip title="User Management" placement="right">
                
              <img
                src={ProfileMenuIcon}
                alt=""
                className={
                  localStorage.getItem("usermange") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
            <Link to="/Billing" onClick={handleLinkActive(6)}>
            <Tooltip title="Billing" placement="right">
              
              <img
                src={InvoiceIcon}
                alt=""
                className={
                  localStorage.getItem("billing") == 1 ? "selected" : ""
                }
              />
              </Tooltip>
            </Link>
           
          </div>
        </Drawer>
      </div>
    </>);
  }
}

const mapStatetoProps = (state) => {
  const { loginError } = state.Login;
  return { loginError };
};

export default withRouter(connect(mapStatetoProps, {})(LeftSidebar));



const drawerWidth = 0;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(3)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

// const DrawerHeader = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "flex-end",
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

