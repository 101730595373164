import {  
  LOCATIONS,
  LOCATION_LIST_SUCCESSFUL,
} from "./actionTypes";

export const getLocations = () => {
  return {
    type: LOCATIONS,
  };
};

export const locationListSuccessful = (locations) => {
  return {
    type: LOCATION_LIST_SUCCESSFUL,
    payload: locations,
  };
};

