import React, { Component } from "react";
import { Grid, Step, Stepper, StepLabel } from "@mui/material";
import Link from "@mui/material/Link";
import CustomButton from "../../../components/Buttons/Buttons";
import LeavePolicyFillDetails from "./LeavePolicyFillDetails";
import LeavePolicyAddItems from "./LeavePolicyAddItems";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CommonPopup from "../../../components/CommonPopup/CommonPopup";
import {
  createLeavePolicy,
  leavePolicyViewById,
} from "../../../store/Master/LeavePolicy/actions";
import { serverApi } from "../../../helpers/Consts";
import swal from "sweetalert";

class CreateLeavePolicies extends Component {
  constructor() {
    super();
    this.state = {
      steps: [
        {
          title: "Fill Details",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled01.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive01.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
        {
          title: "Add Items",
          icon: "http://vowerp.com/MailImages/assets/indentDisabled02.png",
          activeIcon: "http://vowerp.com/MailImages/assets/indentActive02.png",
          onClick: (e) => {
            e.preventDefault();
          },
        },
      ],
      currentStep: 1,
      key: 0,
      date: null,
      status: 0,
      confirm: false,
      userDit: JSON.parse(localStorage.getItem("authUser")),
      viewByIdResponse: [],
      header: {
        leavePolicyDesc: "",
      },
      products: [
        {
          id: "0",
          no: 0,
          carryFrwStatus: "",
          encashmentStatus: "",
          leaveTypeId: 0,
          leavesPerYear: "",
          maxConsecutiveCount: "",
          maxLeavesPerYear: "",
          yearEndDate: null,
          yearStartDate: null,
        },
      ],
    };
  }

  // validationOfLineItem = () => {
  //   swal("Enter the mandatory field")
  // }

  onClickNext = () => {
    const { currentStep } = this.state;

    if (this.state.header.leavePolicyDesc !== "") {
      this.setState({
        currentStep: currentStep + 1,
      });
    } else {
      swal("Please enter the Leave Policies Description !!!");
    }
  };

  onClickBefore = () => {
    const { currentStep } = this.state;

    if (currentStep > 1) {
      this.setState({
        currentStep: currentStep - 1,
      });
      this.setState({
        branchId: this.state.header.branchId,
      });
    } else {
      this.props.history.push("/leavePolicies");
    }
  };

  QontoStepIcon = (iconProp, icon, activeIcon, title) => {
    const { completed } = iconProp;

    return (
      <div style={{ textAlign: "center" }}>
        {completed ? (
          <img src={activeIcon} alt="" />
        ) : (
          <img src={icon} alt="" />
        )}
        {completed ? (
          <p style={{ color: "#00abdc", margin: 0 }}>{title}</p>
        ) : (
          <p style={{ margin: 0 }}>{title}</p>
        )}
      </div>
    );
  };

  onclickOk() {
    this.setState({
      confirm: false,
    });
    // swal("want to delete addItem,are you sure!..");
  }
  handleSelectDate = (value, name) => {
    if (name === "transactionDate") {
      this.setState({
        transactionDate: value,
      });
    }
  };

  delete = (filterProducts) => {
    // console.log("getting the deleted item from lineItem", filterProducts)
    // let filterProducts = this.state.products.filter(item => item.no !== id)
    // this.props.products = filterProducts

    this.setState({
      products: filterProducts,
    });
  };

  componentDidMount() {
    if (this.props.location.rowdata) {
      // alert("hello world")
      const leavePolicyId = this.props.location.rowdata.leavePolicyId;
      // console.log("getting the id",this.props.location.rowdata.leavePolicyId)
      this.props.leavePolicyViewById(
        serverApi.LEAVE_POLICY_VIEW_BY_ID + leavePolicyId,
        this.props.history
      );
    }
  }

  componentWillReceiveProps(props) {
    if (props.viewByIdList) {
      this.setState({
        viewByIdResponse: props.viewByIdList,
      });
    }

    if (props.location.rowdata) {
      if (props.viewByIdList.dtlList) {
        let productsData = props.viewByIdList.dtlList;
        // productsData.push({
        //   id: "0",
        //   no: 0,
        //   carryFrwStatus: "",
        //   encashmentStatus: "",
        //   leaveTypeId: 0,
        //   leavesPerYear: "",
        //   maxConsecutiveCount: "",
        //   maxLeavesPerYear: "",
        //   yearEndDate: null,
        //   yearStartDate: null
        // })

        this.setState({
          products: productsData,
        });
      }
    }
  }

  onCreateLeavePolicy = () => {
    const filterData = this.state.products.filter((prop) => {
      // console.log("getttttttttttttt", prop)
      if (prop.leaveTypeId !== 0) {
        return prop;
      }
    });

    let lastRow = this.state.products[this.state.products.length - 1];
    // let firstRow = 0;
    var validate = false;
    if(this.state.products.length==1){
      this.state.products.map((prop)=>{
        if(prop.carryFrwStatus==""){
          swal("Please Select Carry Forward Status ")
          return false;
        }
       else if(prop.encashmentStatus==""){
          swal("Please Select Encashment Status")
          return false;
        }
        else if(prop.leaveTypeId==0){
          swal("Please Select Leave Type")
          return false;
        }
        else if(prop.leavesPerYear==""){
          swal("Please Enter Leave Per Year")
          return false;
        }
        else if(prop.maxConsecutiveCount==""){
          swal("Please Enter Max Consecutive Count")
          return false;
        }
        else if(prop.maxLeavesPerYear==""){
          swal("Please Enter Max Leave Per Year")
          return false;
        }
        else if(prop.yearStartDate==null){
          swal("Please Select Year Start Date")
          return false;

        }
        else if(prop.yearEndDate==null){
          swal("Please Select Year End Date")
          return false;
        }
        else {
          validate =true;
        }
      });
    }else{
      for(var i=0;i<=this.state.products.length-1;i++){
        if(this.state.products[i] !== lastRow){
          if(this.state.products[i].carryFrwStatus==""||this.state.products[i].carryFrwStatus==undefined){
            swal("Please Select Carry Forward Status");
            return false;
          }
        else  if(this.state.products[i].encashmentStatus==""){
            swal("Please Select Encashment Status")
            return false;
          }
        else  if(this.state.products[i].leaveTypeId==0){
            swal("Please Enter Leave Type");
            return false;
          }
        else  if(this.state.products[i].leavesPerYear==""){
            swal("Please Enter Leave Per Year")
            return false;
          }
        else  if(this.state.products[i].maxConsecutiveCount==""){
            swal("Please Enter Max Consecutive Count")
            return false;
          }
        else  if(this.state.products[i].maxLeavesPerYear==""){
            swal("Please Enter Max Leave Per Year")
            return false;
          }
        else  if(this.state.products[i].yearStartDate==null){
            swal("Please Select Year Start Date")
            return false;
          }
        else  if(this.state.products[i].yearEndDate==null){
          swal("Please Select Year End Date")
          return false;
        }
        else{
          validate=true;
        }
        }
      }
    }

    if (this.props.location.rowdata) {
      const leavePolicyId = this.props.location.rowdata.leavePolicyId;
      var filterItems = [];
      this.state.products.map((prop) => {
        filterItems.push({
          carryFrwStatus: prop.carryFrwStatus,
          encashmentStatus: prop.encashmentStatus,
          leaveTypeId: prop.leaveTypeId,
          leavesPerYear: prop.leavesPerYear,
          maxConsecutiveCount: prop.maxConsecutiveCount,
          maxLeavesPerYear: prop.maxLeavesPerYear,
          yearEndDate: prop.yearEndDate,
          yearStartDate: prop.yearStartDate,
          leavePolicyDtlId: prop.leavePolicyDtlId,
          leavePolicyId: prop.leavePolicyId,
          isActive: 1,
        });
      });
      const data = {
        hdr: {
          companyId: localStorage.getItem("companyIdconfig"),
          leavePolicyDesc: this.state.header.leavePolicyDesc,
          leavePolicyId: leavePolicyId,
          isActive: 1,
        },
        dtlList: filterItems,
      };

      // this.state.products.map((prop) => {
      //   console.log("prop.leavesPerYear", prop);
      //   if (
      //     prop.leavesPerYear !== "" &&
      //     prop.carryFrwStatus !== "" &&
      //     prop.encashmentStatus !== "" &&
      //     prop.maxConsecutiveCount !== "" &&
      //     prop.yearEndDate !== null &&
      //     prop.yearStartDate !== null &&
      //     prop.leaveTypeId !== 0 &&
      //     prop.maxLeavesPerYear !== ""
      //   ) {
      //     // swal("created successfully")
      //   } else {
      //     validate = true;
      //   }
      // });

      if(this.state.products.length==1){
        this.state.products.map((prop)=>{
          if(prop.carryFrwStatus==""){
            swal("Please Select Carry Forward Status ")
            return false;
          }
         else if(prop.encashmentStatus==""){
            swal("Please Select Encashment Status")
            return false;
          }
          else if(prop.leaveTypeId==0){
            swal("Please Select Leave Type")
            return false;
          }
          else if(prop.leavesPerYear==""){
            swal("Please Enter Leave Per Year")
            return false;
          }
          else if(prop.maxConsecutiveCount==""){
            swal("Please Enter Max Consecutive Count")
            return false;
          }
          else if(prop.maxLeavesPerYear==""){
            swal("Please Enter Max Leave Per Year")
            return false;
          }
          else if(prop.yearStartDate==null){
            swal("Please Select Year Start Date")
            return false;
  
          }
          else if(prop.yearEndDate==null){
            swal("Please Select Year End Date")
            return false;
          }
          else {
            validate =true;
          }
        });
      }

      if (validate === true) {
        if(localStorage.getItem("companyIdconfig") !== undefined && localStorage.getItem("companyIdconfig") !== "undefined"){

          this.props.createLeavePolicy(
            serverApi.UPDATE_LEAVE_POLICY,
            data,
            this.props.history
          );
        }else{
          swal("Please Select the company", {icon: "error"})
        }
        // swal("Please Enter the Mandatory Fieldsnnn", { icon: "error" });
      } 

    } else {
      const data = {
        hdr: {
          companyId: localStorage.getItem("companyupdateId"),
          leavePolicyDesc: this.state.header.leavePolicyDesc,
        },
        dtlList: filterData,
      };

      // this.props.createLeavePolicy(serverApi.CREATE_LEAVE_POLICY, data, this.props.history);
      this.state.products.map((item) => {
        // console.log("getting the data of the item", lastRow);
        if (item !== lastRow) {
          if (
            item.carryFrwStatus !== "" &&
            item.encashmentStatus !== "" &&
            item.leavesPerYear !== "" &&
            item.maxConsecutiveCount !== "" &&
            item.maxLeavesPerYear !== "" &&
            item.yearEndDate !== null &&
            item.yearStartDate !== null
          ) {
            
            validate = true;
          } else {
            validate = false;
          }
        }
      });
      if (validate === true) {
        this.props.createLeavePolicy(
              serverApi.CREATE_LEAVE_POLICY,
              data,
              this.props.history
            );
        // swal("Created successfully");
      } else {
        // swal("Please enter the mandatory fieldswww", { icon: "error" });
      }
      // swal("Creeeeaaatttteddd successfully", {icon: "success"});
      // console.log("Created successfully", data);
    }
  };

  render() {
    const { steps, currentStep } = this.state;
    return (
      <div>
        {/* {this.state.status} */}
        <Grid className="indentContainerBlock">
          <Grid sx={12} className="indentContainerMainBlock">
            <div className="iconsBlock">
              <Stepper alternativeLabel activeStep={currentStep}>
                {steps.map((prop) => (
                  <Step key={prop.title}>
                    <StepLabel
                      StepIconComponent={(Iconprops) => {
                        return this.QontoStepIcon(
                          Iconprops,
                          prop.icon,
                          prop.activeIcon,
                          prop.title
                        );
                      }}
                    >
                      {/* {prop.title} */}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {/* the steppper functionality */}
            {currentStep === 1 && (
              <>
                <LeavePolicyFillDetails
                  onSelectDep={this.handleSelectChange}
                  onhandlechangeValue={this.onhandlechangeValue}
                  handleSelectDate={this.handleSelectDate}
                  header={this.state.header}
                  viewByIdResponse={this.state.viewByIdResponse}
                />
                {this.props.location.state ? (
                  this.props.location.state.rowData.transactions !== null ? (
                    <div className="consoleFormButtonBlock">
                      <Link>
                        <CustomButton
                          label={"Back"}
                          className="greenBorderButton"
                          handleClick={this.onClickBefore}
                        />
                      </Link>
                      <CustomButton
                        label={"Next"}
                        className="greenButton"
                        handleClick={this.onClickNext}
                        type="sumbit"
                      />
                      {this.state.confirm && (
                        <CommonPopup>
                          <div className="delAddItem">
                            <div>Please Enter the mandatory Fields !</div>
                            <div className="delAddItemBtns">
                              <input
                                type="button"
                                onClick={this.onclickOk.bind(this)}
                                value="Ok!"
                                className="delYesBtn"
                              />
                            </div>
                          </div>
                        </CommonPopup>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <div className="consoleFormButtonBlock">
                    <Link>
                      <CustomButton
                        label={"Back"}
                        className="greenBorderButton"
                        handleClick={this.onClickBefore}
                      />
                    </Link>
                    <CustomButton
                      label={"Next"}
                      className="greenButton"
                      handleClick={this.onClickNext}
                      type="sumbit"
                    />
                    {this.state.confirm && (
                      <CommonPopup>
                        <div className="delAddItem">
                          <div>Please Enter the mandatory Fields !</div>
                          <div className="delAddItemBtns">
                            <input
                              type="button"
                              onClick={this.onclickOk.bind(this)}
                              value="Ok!"
                              className="delYesBtn"
                            />
                          </div>
                        </div>
                      </CommonPopup>
                    )}
                  </div>
                )}
              </>
            )}
            {currentStep === 2 && (
              <>
                <LeavePolicyAddItems
                  products={this.state.products}
                  header={this.state.header}
                  delete={this.delete}
                  viewByIdResponse={this.state.viewByIdResponse}
                />
                <div className="consoleFormButtonBlock">
                  <Link>
                    <CustomButton
                      label={"Back"}
                      className="greenBorderButton"
                      handleClick={this.onClickBefore}
                    />
                  </Link>
                  <CustomButton
                    label={this.props.location.rowdata ? "Update" : "Create"}
                    className="greenButton"
                    handleClick={this.onCreateLeavePolicy}
                    type="sumbit"
                  />
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { viewByIdList } = state.LeavePolicyReducer;
  return { viewByIdList };
};

export default withRouter(
  connect(mapStatetoProps, {
    //   getPaymentCreate
    createLeavePolicy,
    leavePolicyViewById,
  })(CreateLeavePolicies)
);
