import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  OFFICIALDETAILS_ADD,
  GET_ACCTYPES_LIST,
  ACCTYPES_LIST_SUCCESS,
  CLEAR_FORM,
  COMPANY_DIT,
  FILE_UPLOAD_LIST,
  UPLOADTANFILE,
  UPLOADTANFILESLIST,
  UPLOADCINFILE,
  UPLOADCINFILESLIST,
} from "./actionTypes";
import {
  officialapiErrors,
  officialdetailsAddSuccessful,
  locationListSuccessful,
  accounttypeslist,
  companyditssuccess,
  getUploadedFilesListSuccessfull,
  UploadTanFileListSuccessfull,
  getTanUploadedFilesListSuccessfull,
  UploadCinFileListSuccessfull,
  getCinUploadedFilesListSuccessfull,
} from "./actions";

// AUTH related methods
import {
  postAdd,
  postEdit,
  getList,
  getPostRowData,
  getListurl
} from "../../../helpers/Server_Helper";
import swal from "sweetalert";

//If user is login then dispatch redux action's are directly from here.
function* AddOfficialDetails({ payload: { url, data, id, history } }) {
  try {
    const response = yield call(postAdd, url, data);
    console.log(response.data);
    if (response.data.status == true) {
      swal("Your Official Details Updated Successfully.!", {
        icon: "success",
      });
      yield put(officialdetailsAddSuccessful(response.data, data));
      history.push("/branchlist");
    } else {
      swal("Sorry! Unable to ad Official Details. Please try again!", {
        icon: "error",
      });
    }
  } catch (error) {
    console.log(error);
    swal("Sorry! Permission Denied!", {
      icon: "error",
    });
    //yield put(apiError(error));
  }
}

function* getMasterAccountTypesList() {
  try {
    const response = yield call(
      getList,
      "/apimasters/mlocation/?format=datatables",
      {}
    );
    yield put(accounttypeslist({ response }));
  } catch (error) {
    yield put(officialapiErrors(error));
  }
}
function* getCompanyDits({ payload: { url, data, history } }) {
  try {
    const response = yield call(getPostRowData, url, data);
    yield put(companyditssuccess({ response }));
  } catch (error) {
    yield put(officialapiErrors(error));
  }
}

function* getUploadFilesList({ payload: { url, data, history } }) {
  try {
      const response = yield call(getListurl, url, data);
      console.log('Data', response)      
      yield put(getUploadedFilesListSuccessfull({ response }))
  } catch (error) {
      console.log(error)
  }
}
//for tan files
function* getUploadTanFileList({payload:{url,data,history}}) {
  try{
      const response = yield call(postAdd,url,data);
      yield put(UploadTanFileListSuccessfull({response}))
      swal("Uploaded Successfully", { icon: "success" })
      console.log('PrResponse.....uploadFile',response)
  }catch(error){
      console.log(error)
  }
}
function* getTanUploadFilesList({ payload: { url, data, history } }) {
  try {
      const response = yield call(getListurl, url, data);
      console.log('Data', response)      
      yield put(getTanUploadedFilesListSuccessfull({ response }))
  } catch (error) {
      console.log(error)
  }
}
//for cin files
function* getUploadCinFileList({payload:{url,data,history}}) {
  try{
      const response = yield call(postAdd,url,data);
      yield put(UploadCinFileListSuccessfull({response}))
      swal("Uploaded Successfully", { icon: "success" })
      console.log('PrResponse.....uploadFile',response)
  }catch(error){
      console.log(error)
  }
}
function* getCinUploadFilesList({ payload: { url, data, history } }) {
  try {
      const response = yield call(getListurl, url, data);
      console.log('Data', response)      
      yield put(getCinUploadedFilesListSuccessfull({ response }))
  } catch (error) {
      console.log(error)
  }
}

export function* watchOfficialDetailsAdd() {
  yield takeEvery(OFFICIALDETAILS_ADD, AddOfficialDetails);
}

export function* watchMasterAccountTypeListData() {
  yield takeEvery(GET_ACCTYPES_LIST, getMasterAccountTypesList);
}

export function* watchCompanyDit() {
  yield takeEvery(COMPANY_DIT, getCompanyDits);
}
export function* watchFileUploadList() {
  yield takeEvery(FILE_UPLOAD_LIST, getUploadFilesList);
  yield takeEvery(UPLOADTANFILE,getUploadTanFileList);
  yield takeEvery(UPLOADTANFILESLIST,getTanUploadFilesList)
  yield takeEvery(UPLOADCINFILE,getUploadCinFileList)
  yield takeEvery(UPLOADCINFILESLIST,getCinUploadFilesList)
}



function* OfficialDetailsSaga() {
  yield all([
    fork(watchMasterAccountTypeListData),
    fork(watchOfficialDetailsAdd),
    fork(watchCompanyDit),
    fork(watchFileUploadList),
  ]);
}

export default OfficialDetailsSaga;
