import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import cons, { serverApi } from "../../../helpers/Consts";
import {getLeaveTypeList} from "../../../store/Master/LeaveTypeMaster/actions"
import GridView from "../../../components/Grid/GridView";
import {getPayScheme} from "../../../store/Master/PaySchemecreation/actions"
import GridViewSearch from "../../../components/Grid/GridViewSerch";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { companyId: 0, userDit: JSON.parse(localStorage.getItem("authUser")) };
  }
  componentDidMount() {
    if(localStorage.getItem("companyIdconfig")){
      const data = {
        activeStatus: "",
        status: "",
        // orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
        orgId:"",
        companyId: localStorage.getItem("companyIdconfig")
      };
      this.props.getPayScheme(
        serverApi.EMPLOYEE_PAY_SCHEME,
        data,
        this.props.history
      );
    }else{
      const data = {
        activeStatus: "",
        status: "",
        orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
        companyId: localStorage.getItem("companyupdateId")
      };
      this.props.getPayScheme(
        serverApi.EMPLOYEE_PAY_SCHEME,
        data,
        this.props.history
      );
    }
   
    this.props.getLeaveTypeList(serverApi.GET_ALL_LEAVE_TYPE_LIST + localStorage.getItem("companyIdconfig"), this.props.history); // calling api for Leave type list
  }

  render() {
    console.log("getting the data man", this.props.payScheme_List)
    const mdata = this.props.payScheme_List ? this.props.payScheme_List : "";

    return (
      <GridViewSearch 
        mdata={mdata} 
        mcolumns={payschemeListColumn} 
        pathname="/create_payScheme_creation"
      />
    )
    
  }
}

const payschemeListColumn = [
  // {
  //   Header: "Pay Scheme Id",
  //   accessor: "id",
  //   hidden: false,
  // },
  {
    Header: "Pay Scheme Code",
    dataField: "code",
    // accessor: "code",
    hidden: false,
  },

  {
    Header: "Pay Scheme Name",
    dataField: "name",
    // accessor: "name",
    hidden: false,
  },

  {
    Header: "Wage Type",
    dataField:"wageDesc",
    // accessor: "wageDesc",
    hidden: false,
  },
  {
    Header: "Description",
    // accessor: "description",
    dataField:"description",
    hidden: false,
  },
  {
    Header: "Status",
    // accessor: "statusDesc",
    dataField:"statusDesc",
    hidden: false,
  },
  {
    Header: "Approver Status",
    // accessor: "approverStatus",
    dataField:"approverStatus",
    hidden: true,
  },
  // {
  //   Header: "Branch Id",
  //   accessor: "branchid",
  //   hidden: true,
  // },
  {
    Header: "Last Update",
    // accessor: "lupdate",
    dataField:"lupdate",
    hidden: true,
  },
  {
    Header: "Last update Description",
    // accessor: "lupdateDesc",
    dataField:"lupdateDesc",
    hidden: true,
  },
  {
    Header: "Status",
    // accessor: "status",
    dataField:"status",
    hidden: true,
  },
  {
    Header: "Active Status",
    // accessor: "activeStatus",
    dataField:"activeStatus",
    hidden: true,
  },
  // {
  //   Header: "Wage Id",
  //   accessor: "wageId",
  //   hidden: true,
  // },
  // {
  //   Header: "Business Unit Id",
  //   accessor: "businessunitId",
  //   hidden: true,
  // },

  {
    Header: "Created By",
    // accessor: "createdBy",
    dataField:"createdBy",
    hidden: true,
  },
  {
    Header: "Create Date",
    // accessor: "createDate",
    dataField:"createDate",
    hidden: true,
  },

  {
    Header: "Modified By",
    // accessor: "modifiedBy",
    dataField:"modifiedBy",
    hidden: true,
  },
  {
    Header: "Modified Date",
    // accessor: "modifiedDate",
    dataField:"modifiedDate",
    hidden: true,
  },

  {
    Header: "Designation",
    // accessor: "designation",
    dataField:"designation",
    hidden: true,
  },
];

const mapStatetoProps = (state) => {
  const { LeaveType_List } = state.LeaveTypeListReducer
  const { payScheme_List } = state.PaySchemeReducer
  // return { payScheme_List };
  return { LeaveType_List, payScheme_List };
};

export default withRouter(
  connect(mapStatetoProps, {
    getLeaveTypeList,
    getPayScheme
  })(Index)
);
