import {
  CONFIGURATION,
  CONFIGURATION_DATA,
  CONFIGURATION_DATA_SUCCESSFUL,
  CONFIGURATION_SUCCESSFUL,
  CONFIGURATION_UPDATE,
  CURRENCY_CONFIGURATION,
  CURRENCY_CONFIGURATION_SUCCESSFULL,
  INWARD_PO_CONFIGURATION,
  INWARD_PO_CONFIGURATION_SUCCESSFULL,
} from "./actionTypes";

const initialState = {
  error: "Sorry! No Data Found.",
  message: null,
  loading: false,
  configdatalist: [],
  configdata: [],
  configuration_data: [],
  currencyConfig:[],
  InwardConfig:[]
};

const configuration = (state = initialState, action) => {
  switch (action.type) {
    case CONFIGURATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CONFIGURATION_UPDATE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CONFIGURATION_SUCCESSFUL:
      state = {
        ...state,
        configdatalist: action.payload.response.data,
        configdata: action.payload.response.data.configList,
        loading: false,
      };

      break;
    // New Reducer for Date allowable
    case CONFIGURATION_DATA:
      state = {
        ...state,
        loading: false,
      };
      break;
    case CONFIGURATION_DATA_SUCCESSFUL:
      state = {
        ...state,
        configuration_data: action.payload.response,
        loading: false,
      };
      // currency configuration 
      case CURRENCY_CONFIGURATION:
        state = {
          ...state,
          loading: false,
        };
        break;
      case CURRENCY_CONFIGURATION_SUCCESSFULL:
        state = {
          ...state,
          currencyConfig: action.payload.response,
          loading: false,
        };
        // Inward PO configuration
        case INWARD_PO_CONFIGURATION:
          state = {
            ...state,
            loading: false,
          };
          break;
        case INWARD_PO_CONFIGURATION_SUCCESSFULL:
          state = {
            ...state,
            InwardConfig: action.payload.response,
            loading: false,
          };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default configuration;
