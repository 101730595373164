import {
  TAX_MASTER_LIST_API,
  TAX_MASTER_LIST_API_SUCCESSFULL,
  CREATE_TAX_MASTER,
  CREATE_TAX_MASTER_SUCCESSFULL,
} from "./actionTypes";

export const taxMasterList = (url, data, history) => {
  return {
    type: TAX_MASTER_LIST_API,
    payload: { url, data, history },
  };
};
export const taxMasterListSuccessfull = (data) => {
  return {
    type: TAX_MASTER_LIST_API_SUCCESSFULL,
    payload: data,
  };
};
export const createTaxMaster = (url, data, history) => {
  return {
    type: CREATE_TAX_MASTER,
    payload: { url, data, history },
  };
};
export const createTaxMasterSuccessfull = (data) => {
  return {
    type: CREATE_TAX_MASTER_SUCCESSFULL,
    payload: data,
  };
};

// export const apiErrors = (error) => {
//     return {
//         type: API_ERRORS,
//         payload: error,
//     };
// };
