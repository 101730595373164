import React, { Component } from "react";
import axios from "axios";
import { Grid, Box, Paper, Button, listClasses } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Checkbox from "../../components/CheckBox/Checkbox";
import cons, { serverApi } from "../../helpers/Consts";
import swal from "sweetalert";
import {
  getModules,
  getAddons,
  updateModules,
} from "../../store/ModuleSelection/actions";
import { addModule } from "../../store/ModuleSelection/actions";
import {
  branchCreationAdd,
  getMasterStates,
} from "../../store/Company/BranchCreation/actions";

import { Link } from "react-router-dom";
import InputRange from "../../components/InputRange/inputRange";
import TextFieldEmail from "../../components/TextField/TextFieldEmail";
import DynamicSelect from "../../components/Dropdown/DynamicSelect";

import TextFieldSample from "../../components/TextField/TextFieldSample";
import TextFieldMobile from "../../components/TextField/TextFieldMobile";

import CommonPopup from "../../components/CommonPopup/CommonPopup";
import CustomButton from "../../components/Buttons/Buttons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dateFormat from "dateformat";
import RadioButton from "../../components/RadioButton/RadioButton";

class ModuleSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageHedder: "Basic Details",
      companyname: "",
      setdate: "",
      dtvalue: "",
      companyId: localStorage.getItem("companyIdconfig"),
      userDit: JSON.parse(localStorage.getItem("authUser")),
      orgId: localStorage.getItem("companyupdateId"),
      errors: false,
      allSelected: false,
      subscription: "",
      updatedprice: "",
      modulelist: [],
      menu: "none",
      show: false,
      users: 0,
      companys: 1,
      open: false,
      subscription_months: 1,
      total_price: 0,
      subscriptionId: 0,
      //**********//
      customerId: 0,
      isChecked: true,
      shippingAddressBlock: false,
      selectedId: 0,
      billingState: "",
      shippingState: "",
      billingStateCode: "",
      shippingStateCode: "",
      statecode: "",
      billingPinCode: "",
      shippingPinCode: "",
      billingCity: "",
      shippingCity: "",
      billingGstNumber: "",
      shippingGstNumber: "",
      billingAddress: "",
      shippingAddress: "",
      billingEmailId: "",
      shippingEmailId: "",
      billingContactNo: "",
      shippingContactNo: "",
      billingCompanyName: "",
      shippingCompanyName: "",
      billingLastName: "",
      shippingLastName: "",
      billingFirstName: "",
      shippingFirstName: "",
    };
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  handleChangeText = (key) => (event) => {
    // this.setState({ [key]: event });
    // console.log("handleChangeText", key);
    // console.log("handleChangeText-->", event);

    let newMarkers = this.state.addonlist.map((el) =>
      el.check_name === key ? { ...el, value: event } : el
    );
    this.setState({ addonlist: newMarkers });
  };

  handleAllSelectChange = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    this.setState({ allSelected: checked });
  };

  handleValidSubmit = (event) => {
    // console.log("Nagesh", event);
    // console.log("subscription", this.state.subscription);
    // console.log("updatedprice", this.state.updatedprice);
    // console.log("modulelist", this.state.modulelist);
    // console.log("addonlist", this.state.addonlist);
    // console.log("users", this.state.users);
    // console.log("companys", this.state.companys);

    var data = {
      orgId: this.state.userDit.orgId,
      no_of_companies: this.state.companys,
      no_of_users: this.state.users,
      modules: this.state.modulelist,
      subscription: this.state.subscription,
      updatedprice: this.state.updatedprice,
      addonlist: this.state.addonlist,
      userId: this.state.userDit.userId,
      companyId: this.state.companyId,
      customerId: this.state.customerId,
      billingState: this.state.billingState,
      billingStateCode: this.state.billingStateCode,
      billingPinCode: this.state.billingPinCode,
      billingCity: this.state.billingCity,
      billingGstNumber: this.state.billingGstNumber,
      billingAddress: this.state.billingAddress,
      shippingState: this.state.shippingState,
      shippingStateCode: this.state.shippingStateCode,
      shippingPinCode: this.state.shippingPinCode,
      shippingGstNumber: this.state.shippingGstNumber,
      shippingAddress: this.state.shippingAddress,

      billingEmailId: this.state.billingEmailId,
      // shippingEmailId: "",
      billingContactNo: this.state.billingContactNo,
      // shippingContactNo: "",
      billingCompanyName: this.state.billingCompanyName,
      // shippingCompanyName: "",
      billingLastName: "",
      // shippingLastName: "",
      billingFirstName: this.state.billingFirstName,
    };

    // this.props.updateModules(serverApi.UPDATE_MODULES,data,this.props.history);

    var amount = this.state.updatedprice.total_price;
    const options = {
      key: "rzp_test_wtWlaTZv5YX1ph",
      amount: (amount * 100).toString(),
      // currency: currency,
      name: "VOW ERP SOLUTIONS PRIVATE LIMITED",
      description: "Payment",
      image: "https://vowerp.com/assets/images/vow_logo.png",
      order_id: "",
      // url: 'https://vowerp.com/razorpaymentconsole.php',
      // data: {orgId : this.state.userDit.orgId, subscription_id:this.state.subscriptionId},
      // callback_url: "https://vowerp.com/success.php?orgId="+this.state.userDit.orgId+"&subscription_id="+this.state.subscriptionId,
      // redirect: true,
      handler: function (response) {
        console.log("---------------------------------------->>>>>>", response);
        var values = {
          razorpay_order_id: options.order_id.data.result,
          // order_id : options.order_id.data.result,
          userId: data.userId,
          transactionid: response.razorpay_payment_id,
          orgId: data.orgId,
          amount: amount,
        };
        axios
          .post(serverApi.CREATE_MODULE_INVOCIE, values)
          .then((res) => {
            // alert("Success")
            swal(
              "Payment Success!",
              "You Transaction Id : " + response.razorpay_payment_id,
              "success"
            );
            this.props.history.push("/ModuleSelection");
          })
          .catch((e) => console.log(e));
      },
      prefill: {
        name: "Swapnil Pawar",
        email: "swapnil@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "None",
      },
      theme: {
        color: "#61dafb",
      },
    };

    axios
      .post(serverApi.UPDATE_MODULES, data)
      .then((res) => {
        options.order_id = res;
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
      })
      .catch((e) => console.log(e));

    // // const myJSON = JSON.stringify(data);

    // // //********************* */ PAYMENT GATEWAY INTIGRATION ************************************////
    // var amount = this.state.updatedprice.total_price;

    // const options = {
    //   key: "rzp_test_wtWlaTZv5YX1ph",
    //   amount: (amount * 100).toString(),
    //   // currency: currency,
    //   name: "VOW ERP SOLUTIONS PRIVATE LIMITED",
    //   description: "Payment",
    //   image: "https://vowerp.com/assets/images/vow_logo.png",
    //   // order_id: orderId,
    //   url: 'https://vowerp.com/razorpaymentconsole.php',
    //   data: {orgId : this.state.userDit.orgId, subscription_id:this.state.subscriptionId},
    //   callback_url: "https://vowerp.com/success.php?orgId="+this.state.userDit.orgId+"&subscription_id="+this.state.subscriptionId,
    //   redirect: true,
    //   //   prefill: {
    //   //     name: "Swapnil Pawar",
    //   //     email: "swapnil@example.com",
    //   //     contact: "9999999999",
    //   // },
    //   notes: {
    //     address: "None",
    //   },
    //   theme: {
    //     color: "#61dafb",
    //   },
    // };

    // const paymentObject = new window.Razorpay(options);
    // paymentObject.open();

    // event.preventDefault();
  };

  onClickRadioButtonFuturedays = (selectedFuturedayValue) => {
    if (selectedFuturedayValue === "Monthly") {
      this.state.updatedprice.subscription_months = 1;
      this.state.subscription.subscription_months = 1;
    }
    if (selectedFuturedayValue === "Quarterly") {
      this.state.updatedprice.subscription_months = 3;
      this.state.subscription.subscription_months = 3;
    }
    if (selectedFuturedayValue === "Semi Annually") {
      this.state.updatedprice.subscription_months = 6;
      this.state.subscription.subscription_months = 6;
    }
    if (selectedFuturedayValue === "Yearly") {
      this.state.updatedprice.subscription_months = 12;
      this.state.subscription.subscription_months = 12;
    }
    this.setState({ updatedprice: this.state.updatedprice });
    this.handleClickPricingPopup(2);
  };
  handleChangeTextField = (key) => (event) => {
    this.setState({ [key]: event });
    if (key === "billingFirstName") {
      this.setState({
        billingFirstName: event,
      });
    }
    if (key === "billingLastName") {
      this.setState({
        billingLastName: event,
      });
    }
    if (key === "billingCompanyName") {
      this.setState({
        billingCompanyName: event,
      });
    }
    if (key === "billingContactNo") {
      this.setState({
        billingContactNo: event,
      });
    }
    if (key === "billingEmailId") {
      this.setState({
        billingEmailId: event,
      });
    }
    if (key === "billingAddress") {
      this.setState({
        billingAddress: event,
        shippingAddress: event,
      });
    }
    if (key === "billingCity") {
      this.setState({
        billingCity: event,
        shippingCity: event,
      });
    }
    if (key === "billingPinCode") {
      this.setState({
        billingPinCode: event,
        shippingPinCode: event,
      });
    }
    if (key === "billingStateCode") {
      this.setState({
        billingStateCode: event,
        shippingStateCode: event,
      });
    }
    if (key === "billingGstNumber") {
      this.setState({
        billingGstNumber: event,
        shippingGstNumber: event,
      });
    }
  };

  handleChange = (name, value) => {
    // console.log("value--------->", value);

    var modules = this.state.modulelist.filter((modul) => {
      if (modul.menu === name) {
        modul.checked = value;
      }
      return modul;
    });

    this.setState({ modulelist: modules });
    console.log("modulelist", modules);
  };

  hide = (name, value) => {
    // console.log(
    //   "getting selected value ---> " +
    //     value +
    //     "<----- getting name ---> " +
    //     name
    // );
    var addOns = this.state.addonlist.filter((addon) => {
      if (addon.menu === name) {
        addon.checked = value;
      }
      return addon;
    });
    this.setState({ addonlist: addOns });
    console.log("addonlist", addOns);
  };

  componentDidMount = () => {
    // this.props.getModules(serverApi.GET_ALL_MENUS + "0/" + "0");
    // this.props.getModules(serverApi.GET_ALL_MENUS + "0/" + "1");
    // this.props.getAddons();
    this.props.getModules(
      serverApi.GET_ALL_MODULES_LIST + this.state.userDit.orgId
    );
    this.props.getMasterStates(serverApi.GET_ALL_STATES);
    // console.log(serverApi.GET_ALL_MODULES_LIST+this.state.userDit.orgId)
  };
  componentWillReceiveProps(props) {
    // console.log("Niranjan props", props)

    if (props.modulelist.result) {
      var subscription = "";
      var modulelist = [];
      var addonlist = [];

      // props.modulelist.result.filter((row) => {
      var row = props.modulelist.result;
      subscription = row.subscription;
      var users = 5;
      var companysle = 1;
      if (row.addonsdata) {
        row.addonsdata.filter((mrow) => {
          if (mrow.menu_type == 1) {
            modulelist.push(mrow);
          }
          if (mrow.menu_type == 2) {
            addonlist.push(mrow);
          }
          if (mrow.menu_type == 3) {
            if (mrow.selectedMenu) {
              companysle = mrow.selectedMenu.transactions_count;
            }
          }
          if (mrow.menu_type == 4) {
            if (mrow.selectedMenu) {
              users = mrow.selectedMenu.transactions_count;
            }
          }
        });
      }
      if (row.user_data) {
        var userdata = row.user_data;
        this.setState({
          billingFirstName: userdata.first_name,
          billingLastName: userdata.last_name,
          billingCompanyName: userdata.companyname,
          billingEmailId: userdata.email,
          billingContactNo: userdata.mobile,
        });
      }
      if (row.customer_data) {
        var custdata = row.customer_data;
        this.setState({
          // isChecked: true,
          customerId: userdata.id,
          billingCompanyName: userdata.last_name,
          // shippingAddressBlock: false,
          // selectedId: 0,
          billingState: custdata.state,
          shippingState: custdata.state2,
          billingStateCode: custdata.billing_state_code,
          shippingStateCode: custdata.shipping_state_code,
          // statecode: "",
          billingPinCode: custdata.pincode,
          shippingPinCode: custdata.pincode2,
          billingCity: custdata.city,
          shippingCity: custdata.city2,
          billingGstNumber: custdata.gst_no,
          shippingGstNumber: custdata.gst_no2,
          billingAddress: custdata.address,
          shippingAddress: custdata.shipping_address,
          billingEmailId: custdata.email_id,
          // shippingEmailId: "",
          billingContactNo: custdata.contact_no,
          // shippingContactNo: "",
          billingCompanyName: custdata.name,
          // shippingCompanyName: "",
          billingLastName: "",
          // shippingLastName: "",
          billingFirstName: custdata.contact_person,
          // shippingFirstName: "",
        });
      }

      // });
    }

    this.setState({
      subscription: subscription,
      modulelist: modulelist,
      addonlist: addonlist,
      users: users,
      companys: companysle,
      subscription_months: subscription ? subscription.subscription_months : 1,
      total_price: subscription ? subscription.total_price : 0,
      subscriptionId: props.subscriptionId,
    });
  }
  handleSelect = (selectedValue, selectedId, name) => {
    this.setState({
      selectedValue: selectedValue,
      selectedId: selectedId,
      name: name,
    });
    if (name === "billingState") {
      let stateslist = this.props.stateslist;
      var billingStateCode = 0;
      stateslist.map((state, key) => {
        if (state.value == selectedValue) {
          billingStateCode = state.name.split("^")[1];
          this.setState({
            billingStateCode: state.name.split("^")[1],
            billingGstNumber: state.name.split("^")[1],
            billingState: selectedValue,
          });
        }
      });
      if (this.state.isChecked === true) {
        this.setState({
          shippingState: selectedValue,
        });
      }
    }
    if (name === "shippingState") {
      let stateslist = this.props.stateslist;
      var shippingStateCode = 0;
      stateslist.map((state, key) => {
        if (state.value == selectedValue) {
          shippingStateCode = state.name.split("^")[1];
          this.setState({
            shippingStateCode: state.name.split("^")[1],
            shippingGstNumber: state.name.split("^")[1],
            shippingState: selectedValue,
          });
        }
      });
    }
  };
  copytoShippingAddress = (e) => {
    // debugger;
    let val = e.target.checked;
    //console.log("checkbox value************************", val);
    if (val) {
      this.setState({
        isChecked: true,
        shippingAddressBlock: false,
        shippingGstNumber: this.state.billingGstNumber,
        shippingAddress: this.state.billingAddress,
        shippingCity: this.state.billingCity,
        shippingPinCode: this.state.billingPinCode,
        shippingState: this.state.billingState,
        shippingStateCode: this.state.billingStateCode,
      });
    } else {
      this.setState({
        isChecked: false,
        shippingAddressBlock: true,
        shippingGstNumber: this.state.billingGstNumber,
        shippingAddress: this.state.billingAddress,
        shippingCity: this.state.billingCity,
        shippingPinCode: this.state.billingPinCode,
        shippingState: this.state.billingState,
        shippingStateCode: this.state.billingStateCode,
      });
    }
  };
  handleSelectChange = (selectedValue, lable) => {
    if (lable === "company") {
      this.setState({
        companys: selectedValue,
      });
    } else {
      this.setState({
        users: selectedValue,
      });
    }
  };
  handleClickPricingPopup = (type) => {
    var modulelist = this.state.modulelist;
    var addonlist = this.state.addonlist;
    var userCount = this.state.users;
    var companys = this.state.companys;
    let price = 0;
    var juteprice = [5350, 9600, 15750];
    var purchaseprice = [1350, 2300, 3250];
    var salesprice = [1000, 1700, 2500];
    var hrprice = [5000, 9000, 15000];
    var productionprice = [3000, 5000, 8000];
    var accountingprice = [3000, 5000, 8000];
    var storeprice = [3000, 5000, 8000];
    var callMngtPrice = [1000, 1700, 2500]; //Call Management
    var projectPrice = [700, 1200, 1500]; //Projects Price
    var subscription_months = this.state.subscription.subscription_months;

    // MODULE PRICE CALCULATION //
    modulelist.filter((module) => {
      if (module.checked && !module.selectedMenu) {
        console.log("Module List", module);
        if (module.menu === "Jute") {
          if (userCount <= 15) {
            price = Number(price) + Number(juteprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(juteprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(juteprice[2]);
          }
        }
        if (module.menu === "Purchase") {
          if (userCount <= 15) {
            price = Number(price) + Number(purchaseprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(purchaseprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(purchaseprice[2]);
          }
        }
        if (module.menu === "Store") {
          if (userCount <= 15) {
            price = Number(price) + Number(storeprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(storeprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(storeprice[2]);
          }
        }
        if (module.menu === "Jute Production") {
          if (userCount <= 15) {
            price = Number(price) + Number(productionprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(productionprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(productionprice[2]);
          }
        }
        if (module.menu === "Sales") {
          if (userCount <= 15) {
            price = Number(price) + Number(salesprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(salesprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(salesprice[2]);
          }
        }
        if (module.menu === "Accounting") {
          if (userCount <= 15) {
            price = Number(price) + Number(accountingprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(accountingprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(accountingprice[2]);
          }
        }
        if (module.menu === "HR Management") {
          if (userCount <= 15) {
            price = Number(price) + Number(hrprice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(hrprice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(hrprice[2]);
          }
        }
        if (module.menu === "Call Management") {
          if (userCount <= 15) {
            price = Number(price) + Number(callMngtPrice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(callMngtPrice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(callMngtPrice[2]);
          }
        }
        if (module.menu === "Projects") {
          if (userCount <= 15) {
            price = Number(price) + Number(projectPrice[0]);
          }
          if (userCount > 15 && userCount <= 30) {
            price = Number(price) + Number(projectPrice[1]);
          }
          if (userCount >= 35) {
            price = Number(price) + Number(projectPrice[2]);
          }
        }
      }
    });
    // ADDONS PRICE CALCULATION //
    var locTrval = 0.1;
    var fcilTrval = 0.1;
    var wtappTrval = 0.4;
    var tallyval = 300;
    var boival = 50;
    var payGate = 10000;
    var addonprice = 0;

    if (addonlist) {
      addonlist.filter((addon) => {
        let location = 50000;
        let facial = 10000;
        let whatsapp = 10000;
        if (addon.checked && !addon.selectedMenu) {
          if (addon.menu_id == 1) {
            addonprice =
              Number(addonprice) + Number(location) * Number(locTrval);
          }
          if (addon.menu_id == 2) {
            addonprice =
              Number(addonprice) + Number(facial) * Number(fcilTrval);
          }
          if (addon.menu_id == 3) {
            addonprice =
              Number(addonprice) + Number(whatsapp) * Number(wtappTrval);
          }
          if (addon.menu_id == 4) {
            addonprice =
              Number(addonprice) + Number(userCount) * Number(tallyval);
          }
          if (addon.menu_id == 5) {
            addonprice =
              Number(addonprice) + Number(userCount) * Number(boival);
          }
          if (addon.menu_id == 6) {
            addonprice = Number(addonprice) + Number(payGate);
          }
        }
      });
    }

    let moduleprice = 0;
    if (userCount <= 15) {
      let firstmoduleprice = ((Number(price) * 0.8) / 10) * Number(userCount);
      let secondmoduleprice = ((Number(price) * 0.2) / 2) * Number(companys);
      moduleprice = Number(firstmoduleprice) + Number(secondmoduleprice);
    }
    if (userCount >= 20 && userCount <= 30) {
      let firstmoduleprice = ((Number(price) * 0.8) / 20) * Number(userCount);
      let secondmoduleprice = ((Number(price) * 0.2) / 5) * Number(companys);
      moduleprice = Number(firstmoduleprice) + Number(secondmoduleprice);
    }
    if (userCount >= 35) {
      let firstmoduleprice = ((Number(price) * 0.8) / 50) * Number(userCount);
      let secondmoduleprice = ((Number(price) * 0.2) / 10) * Number(companys);
      moduleprice = Number(firstmoduleprice) + Number(secondmoduleprice);
    }

    moduleprice = Number(moduleprice) * Number(subscription_months);
    let totalPrice = moduleprice + addonprice;

    var obj = {
      addons_price:
        this.state.subscription.type_of_puchase === "1"
          ? Number(addonprice) + Number(this.state.subscription.addons_price)
          : Number(addonprice),
      companys: companys,
      created_by: this.state.userDit.userId,
      created_date: dateFormat(this.state.dtvalue, "yyyy-mm-dd h:i:s"),
      is_active: "1",
      module_price:
        this.state.subscription.type_of_puchase === "1"
          ? Number(moduleprice) + Number(this.state.subscription.module_price)
          : Number(moduleprice),
      org_id: this.state.orgId,
      payment_id: "0",
      status: "1",
      subscription_months: subscription_months,
      total_price:
        this.state.subscription.type_of_puchase === "1"
          ? Number(totalPrice) + Number(this.state.total_price)
          : Number(totalPrice),
      trail_days: 0,
      type_of_puchase: 2,
      users: userCount,
    };
    console.log("Object -----------<<<<<>>>>", obj);
    if (type == 1) {
      this.setState({ open: !this.state.open });
    }
    this.setState({ updatedprice: obj });
  };

  handleClosePayslip = () => {
    this.setState({ open: false });
  };
  render() {
    const { statecode } = this.state;
    // console.log("subscription", this.state.subscription);
    // console.log("modulelist", this.state.modulelist);
    // console.log("addonlist", this.state.addonlist);
    // console.log("users", this.state.users);
    // console.log("companys", this.state.companys);
    var submonths = "Monthly";
    var monthdisable = false;
    var quarterlydisable = false;
    var semidisable = false;
    var yeardisable = false;
    var upmonths = "Monthly";
    const subscription = this.state.subscription;

    if (this.state.subscription_months) {
      if (this.state.subscription_months == 1) {
        submonths = "Monthly";
        monthdisable = false;
        quarterlydisable = false;
        semidisable = false;
        yeardisable = false;
      }
      if (this.state.subscription_months == 3) {
        submonths = "Quarterly";
        monthdisable = true;
        quarterlydisable = false;
        semidisable = false;
        yeardisable = false;
      }
      if (this.state.subscription_months == 6) {
        submonths = "Semi-Annually";
        monthdisable = true;
        quarterlydisable = true;
        semidisable = false;
        yeardisable = false;
      }
      if (this.state.subscription_months == 12) {
        submonths = "Yearly";
        monthdisable = true;
        quarterlydisable = true;
        semidisable = true;
        yeardisable = false;
      }
    }
    const updatedprice = this.state.updatedprice;
    // console.log("Nagesh Subscription-------------->", this.state.subscription);
    // console.log("Nagesh updatedprice-------------->", this.state.updatedprice);
    return (
      <>
        {/* <Button
          variant="contained"
          bgcolor="primary"
          onClick={this.handleClickPayslip}
        >
          Payslip
        </Button> */}
        {this.state.open && (
          <>
            {/* <CommonPopup className="moduleSelectPopup"> */}
            <div className="customizeViewContainer">
              <div
                onClick={() => {
                  this.setState({
                    open: false,
                  });
                }}
                className="Invisible"
              ></div>
              <div className="modal">
                <Grid item xs={12} className="payslipPopupBlock">
                  <Grid item xs={4}>
                    <div className="popupHeader">
                      <div class="modal-header">
                        <lable class="radio">{submonths}</lable>
                        <h2>
                          <span class="TotalMonthlycost">
                            ₹ {this.state.total_price}
                          </span>
                          <span class="smallText">
                            / {this.state.subscription_months}{" "}
                            {this.state.subscription_months == 1
                              ? "month"
                              : "months"}
                          </span>
                        </h2>
                      </div>
                    </div>
                    <TableContainer>
                      <Table
                        aria-label="simple table"
                        className="actualPricing"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <strong>Current Price</strong>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>No. of Companies</TableCell>
                            <TableCell align="right">
                              {subscription.companys}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>No. of Users</TableCell>
                            <TableCell align="right">
                              {subscription.users}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Modules</TableCell>
                            <TableCell align="right">
                              ₹ {subscription.module_price}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Additions</TableCell>
                            <TableCell align="right">
                              ₹ {subscription.addons_price}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>

                      <div className="updatedPricing">
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex" }}
                          className="radioButtonSection"
                        >
                          <Grid item xs={3}>
                            <div
                              className=""
                              style={
                                monthdisable
                                  ? { pointerEvents: "none", opacity: "0.4" }
                                  : {}
                              }
                            >
                              <RadioButton
                                labelText="Monthly"
                                value="1"
                                groupname="fdates"
                                disabled={this.state.daysdesible}
                                checkednew={
                                  updatedprice.subscription_months == 1
                                    ? true
                                    : false
                                }
                                onChange={this.onClickRadioButtonFuturedays}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div
                              className=""
                              style={
                                quarterlydisable
                                  ? { pointerEvents: "none", opacity: "0.4" }
                                  : {}
                              }
                            >
                              <RadioButton
                                labelText="Quarterly"
                                value="3"
                                groupname="fdates"
                                disabled={this.state.daysdesible}
                                checkednew={
                                  updatedprice.subscription_months == 3
                                    ? true
                                    : false
                                }
                                onChange={this.onClickRadioButtonFuturedays}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div
                              className=""
                              style={
                                semidisable
                                  ? { pointerEvents: "none", opacity: "0.4" }
                                  : {}
                              }
                            >
                              <RadioButton
                                labelText="Semi Annually"
                                value="6"
                                groupname="fdates"
                                disabled={this.state.daysdesible}
                                checkednew={
                                  updatedprice.subscription_months == 6
                                    ? true
                                    : false
                                }
                                onChange={this.onClickRadioButtonFuturedays}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div
                              className=""
                              style={
                                yeardisable
                                  ? { pointerEvents: "none", opacity: "0.4" }
                                  : {}
                              }
                            >
                              <RadioButton
                                labelText="Yearly"
                                value="12"
                                groupname="fdates"
                                disabled={this.state.daysdesible}
                                checkednew={
                                  updatedprice.subscription_months == 12
                                    ? true
                                    : false
                                }
                                onChange={this.onClickRadioButtonFuturedays}
                              />
                            </div>
                          </Grid>
                        </Grid>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={2}>
                                <strong>Updated Price</strong>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>No. of Companies</TableCell>
                              <TableCell align="right">
                                {updatedprice.companys}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>No. of Users</TableCell>
                              <TableCell align="right">
                                {updatedprice.users}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell>Modules</TableCell>
                              <TableCell align="right">
                                ₹ {updatedprice.module_price}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Additions</TableCell>
                              <TableCell align="right">
                                ₹ {updatedprice.addons_price}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <div className="popupHeader">
                          <div class="modal-header">
                            <label class="radio">{submonths}</label>
                            <h2>
                              <span class="TotalMonthlycost">
                                ₹ {updatedprice.total_price}
                              </span>
                              <span class="smallText">
                                / {updatedprice.subscription_months}{" "}
                                {updatedprice.subscription_months == 1
                                  ? "month"
                                  : "months"}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={8}>
                    <div className="formBeforePayment">
                      <div className="consoleFormContainer">
                        <h5>Purchase Details</h5>
                        <h6>Drop in details and we will setup your product</h6>
                        <div className="consoleFormBlock">
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={2}
                              >
                                <Box gridColumn="span 6">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      value={this.state.billingFirstName}
                                      type="text"
                                      label="First Name"
                                      placeholder="Enter here"
                                      onChange={this.handleChangeTextField(
                                        "billingFirstName"
                                      )}
                                    />
                                  </div>
                                </Box>
                                <Box gridColumn="span 6">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      value={this.state.billingLastName}
                                      type="text"
                                      label="Last Name"
                                      placeholder="Enter here"
                                      onChange={this.handleChangeTextField(
                                        "billingLastName"
                                      )}
                                    />
                                  </div>
                                </Box>
                                {/* <Box gridColumn="span 4">
                              <div className="consoleTextFieldBlock">
                                <TextFieldSample
                                  value={this.state.billingCompanyName}
                                  type="text"
                                  label="Company Name"
                                  placeholder="Enter here"
                                  onChange={this.handleChangeTextField(
                                    "billingCompanyName"
                                  )}
                                />
                              </div>
                            </Box> */}
                                <Box gridColumn="span 6">
                                  <div className="consoleTextFieldBlock">
                                    <div>
                                      <TextFieldMobile
                                        value={this.state.billingContactNo}
                                        type="text"
                                        label="Phone Number"
                                        placeholder="Enter here"
                                        onChange={this.handleChangeTextField(
                                          "billingContactNo"
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Box>
                                <Box gridColumn="span 6">
                                  <div className="consoleTextFieldBlock">
                                    <div>
                                      <TextFieldEmail
                                        value={this.state.billingEmailId}
                                        type="text"
                                        label="Email Address"
                                        caps="OFF"
                                        placeholder="Enter here"
                                        onChange={this.handleChangeTextField(
                                          "billingEmailId"
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Box>
                              </Box>
                              <Box
                                display="grid"
                                gridTemplateColumns="repeat(12, 1fr)"
                                gap={2}
                                className="billingSection"
                              >
                                <Box gridColumn="span 12">
                                  <h5>Billing address</h5>
                                </Box>
                                <Box gridColumn="span 4">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      value={this.state.billingAddress}
                                      type="text"
                                      label="Address"
                                      placeholder="Enter here"
                                      onChange={this.handleChangeTextField(
                                        "billingAddress"
                                      )}
                                    />
                                  </div>
                                </Box>
                                <Box gridColumn="span 4">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      value={this.state.billingCity}
                                      type="text"
                                      label="City"
                                      placeholder="Enter here"
                                      onChange={this.handleChangeTextField(
                                        "billingCity"
                                      )}
                                    />
                                  </div>
                                </Box>
                                <Box gridColumn="span 4">
                                  <div className="consoleTextFieldBlock">
                                    <div className="consoleTextFieldBlock">
                                      <DynamicSelect
                                        arrayOfData={this.props.stateslist}
                                        selected={this.state.billingState}
                                        onSelectChange={this.handleSelect}
                                        lable="State"
                                        name="billingState"
                                      />
                                    </div>
                                  </div>
                                </Box>
                                <Box gridColumn="span 4">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      value={this.state.billingStateCode}
                                      type="text"
                                      label="State Code"
                                      placeholder="Enter here"
                                      onChange={this.handleChangeTextField(
                                        "billingStateCode"
                                      )}
                                    />
                                  </div>
                                </Box>
                                <Box gridColumn="span 4">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      placeholder="Enter here"
                                      type="number"
                                      value={this.state.billingPinCode}
                                      onChange={this.handleChangeTextField(
                                        "billingPinCode"
                                      )}
                                      label="Pin Code"
                                    />
                                  </div>
                                </Box>
                                <Box gridColumn="span 4">
                                  <div className="consoleTextFieldBlock">
                                    <TextFieldSample
                                      placeholder="Enter here"
                                      type="text"
                                      value={this.state.billingGstNumber}
                                      onChange={this.handleChangeTextField(
                                        "billingGstNumber"
                                      )}
                                      label="GST Number"
                                    />
                                  </div>
                                </Box>
                              </Box>
                              <div class="checkboxContain">
                                <input
                                  type="checkbox"
                                  onClick={(e) => {
                                    this.copytoShippingAddress(e);
                                  }}
                                  checked={this.state.isChecked}
                                />
                                <label for="shipping">
                                  Shipping address is same as Billing address
                                </label>
                              </div>
                              {this.state.shippingAddressBlock === true ? (
                                <Box
                                  display="grid"
                                  gridTemplateColumns="repeat(12, 1fr)"
                                  gap={2}
                                  className="shippingSection"
                                >
                                  <Box gridColumn="span 12">
                                    <h5>Shipping address</h5>
                                  </Box>
                                  <Box gridColumn="span 4">
                                    <div className="consoleTextFieldBlock">
                                      <TextFieldSample
                                        value={this.state.shippingAddress}
                                        type="text"
                                        label="Address"
                                        placeholder="Enter here"
                                        onChange={this.handleChangeTextField(
                                          "shippingAddress"
                                        )}
                                      />
                                    </div>
                                  </Box>
                                  <Box gridColumn="span 4">
                                    <div className="consoleTextFieldBlock">
                                      <TextFieldSample
                                        value={this.state.shippingCity}
                                        type="text"
                                        label="City"
                                        placeholder="Enter here"
                                        onChange={this.handleChangeTextField(
                                          "shippingCity"
                                        )}
                                      />
                                    </div>
                                  </Box>
                                  <Box gridColumn="span 4">
                                    <div className="consoleTextFieldBlock">
                                      <div className="consoleTextFieldBlock">
                                        <DynamicSelect
                                          arrayOfData={this.props.stateslist}
                                          selected={this.state.shippingState}
                                          onSelectChange={this.handleSelect}
                                          lable="State"
                                          name="shippingState"
                                        />
                                      </div>
                                    </div>
                                  </Box>
                                  <Box gridColumn="span 4">
                                    <div className="consoleTextFieldBlock">
                                      <TextFieldSample
                                        value={this.state.shippingStateCode}
                                        type="text"
                                        label="State Code"
                                        placeholder="Enter here"
                                        onChange={this.handleChangeTextField(
                                          "shippingStateCode"
                                        )}
                                      />
                                    </div>
                                  </Box>
                                  <Box gridColumn="span 4">
                                    <div className="consoleTextFieldBlock">
                                      <TextFieldSample
                                        placeholder="Enter here"
                                        type="number"
                                        value={this.state.shippingPinCode}
                                        onChange={this.handleChangeTextField(
                                          "shippingPinCode"
                                        )}
                                        label="Pin Code"
                                      />
                                    </div>
                                  </Box>
                                  <Box gridColumn="span 4">
                                    <div className="consoleTextFieldBlock">
                                      <TextFieldSample
                                        placeholder="Enter here"
                                        type="text"
                                        value={this.state.shippingGstNumber}
                                        onChange={this.handleChangeTextField(
                                          "shippingGstNumber"
                                        )}
                                        label="GST Number"
                                      />
                                    </div>
                                  </Box>
                                </Box>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                    <div className="consoleFormButtonBlock">
                      <CustomButton
                        label={"Close"}
                        className="greenBorderButton"
                        handleClick={this.handleClosePayslip}
                        type="sumbit"
                      />
                      <CustomButton
                        label={"Pay Now"}
                        className="greenButton"
                        handleClick={this.handleValidSubmit}
                        type="sumbit"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {/* </CommonPopup> */}
          </>
        )}
        <>
          <div className="moduleSelection">
            {/* <form onSubmit={this.handleValidSubmit}> */}
            <div className="consoleFormContainer">
              <div className="consoleFormBlock">
                <h3 style={{ color: "#004b73" }}>COMPANY </h3>
                <Grid spacing={2} container>
                  <Grid item xs={6}>
                    {/* <div className="" style={{border:"2px solid #dee7ec",borderRadius:"10px",padding:"10px 15px"}}> */}
                    <InputRange
                      name={"No. of Companies"}
                      number={10}
                      step={1}
                      lable="company"
                      value={this.state.companys}
                      onSelectChange={this.handleSelectChange}
                    />
                    {/* </div> */}
                  </Grid>

                  <Grid item xs={6}>
                    {/* <div className="" style={{border:"2px solid #dee7ec",borderRadius:"10px",padding:"10px 15px"}}> */}
                    <InputRange
                      name={"No. of Users"}
                      number={250}
                      step={5}
                      lable="users"
                      value={this.state.users}
                      onSelectChange={this.handleSelectChange}
                    />
                    {/* </div> */}
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="consoleFormContainer">
              <div className="consoleFormBlock">
                <h3 style={{ color: "#004b73" }}>MODULES</h3>
                <Grid spacing={2} container>
                  {this.state.modulelist
                    ? this.state.modulelist.map((prop, inx) => {
                        return (
                          <>
                            {prop.menu_type === 1 ? (
                              <Grid item xs={3}>
                                <div className="moduleCheckBoxBlock">
                                  <Checkbox
                                    label={prop.menu}
                                    image={prop.menu_icon_name}
                                    iconSizeClass="iconSize"
                                    selected={prop.selectedMenu}
                                    value={prop.menu}
                                    name={prop.menu}
                                    onChange={this.handleChange}
                                    disabled={prop.selectedMenu}
                                  />
                                </div>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                </Grid>
              </div>
            </div>

            <div className="consoleFormContainer">
              <div className="consoleFormBlock">
                <Grid container spacing={6}>
                  <Grid item sx={3} style={{ display: "flex" }}>
                    <h3 style={{ color: "#004b73" }}>ADDITIONAL</h3>
                  </Grid>
                </Grid>
                <Grid spacing={2} container>
                  {this.state.addonlist
                    ? this.state.addonlist.map((prop, inx) => {
                        return (
                          <>
                            {prop.menu_type === 2 ? (
                              <Grid item xs={4}>
                                <div
                                  className="moduleCheckBoxBlock"
                                  style={
                                    prop.menu === "Tally" ||
                                    prop.menu === "Payment Gateway"
                                      ? {
                                          pointerEvents: "none",
                                          opacity: "0.4",
                                        }
                                      : {}
                                  }
                                >
                                  {/* <div className="moduleCheckBoxBlock"> */}
                                  <Checkbox
                                    label={prop.menu}
                                    image={prop.menu_icon_name}
                                    iconSizeClass="iconSize"
                                    selected={prop.selectedMenu}
                                    value={prop.menu}
                                    name={prop.menu}
                                    onChange={this.hide}
                                    disabled={prop.selectedMenu}
                                  />
                                </div>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      })
                    : ""}
                </Grid>
              </div>
            </div>
            {/* <UserManagementPopup /> */}
            <div className="consoleFormButtonBlock">
              <Link to="/dashboard">
                <Button variant="contained" className="cancelButton">
                  Cancel
                </Button>
              </Link>
              <Button
                variant="contained"
                type="button"
                className="saveButton"
                onClick={() => this.handleClickPricingPopup(1)}
              >
                Save
              </Button>
            </div>
            {/* </form> */}
          </div>
        </>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const { modulelist, addonlist, subscriptionId } = state.ModuleSelection;
  const { stateslist } = state.BranchCreation;
  return { modulelist, addonlist, subscriptionId, stateslist };
};

export default withRouter(
  connect(mapStatetoProps, {
    getModules,
    branchCreationAdd,
    addModule,
    getAddons,
    updateModules,
    getMasterStates,
  })(ModuleSelect)
);
