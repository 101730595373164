import React, { Component } from "react";
import cons, { serverApi } from "../../helpers/Consts";
import DynamicSelect from "../Dropdown/DynamicSelect";
import { getCompanys } from "../../store/Company/BasicDetails/actions";
import { getConfiguration } from "../../store/Configuration/actions";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getLeaveTypeList } from "../../store/Master/LeaveTypeMaster/actions";
import { departmentMasterList } from "../../store/Master/DepartmentMaster/actions";
import { payschemeParametersList } from "../../store/Master/PayschemeParameters/actions";
import { getLeavePolicyListAll } from "../../store/Master/LeavePolicy/actions";
import { getPayScheme } from "../../store/Master/PaySchemecreation/actions";
import { taxMasterList } from "../../store/Master/TaxMaster/actions";
import { bank_account_list_api } from "../../store/Master/BankAccountMaster/actions";
import {
  getUserGroups,
  getUsersList,
} from "../../store/UserManagement/actions";
import { getSupplierTypeMasterList } from "../../store/Master/SupplierTypeMaster/actions";

class InnerNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDit: JSON.parse(localStorage.getItem("authUser")),
      companyIdconfig: localStorage.getItem("companyIdconfig"),
      companyList: [],
    };
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
    const data = {
      cipher: "",
      orgId: this.state.userDit.orgId,
    };

    this.props.getCompanys(
      serverApi.COMPANYMASTER_LIST_BY_ORG_ID,
      data,
      this.props.history
    );
    this.props.getConfiguration(
      serverApi.GET_CONFIGURATION_BY_COMPANY + this.state.companyIdconfig,
      this.props.history,
      this.state.companyIdconfig
    );
    if (localStorage.getItem("companyIdconfig")) {
      let dataPayload = {
        comId: localStorage.getItem("companyIdconfig"),
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      };
      if (this.props.location.pathname === "/role_management") {
        this.props.getUserGroups(
          serverApi.GET_ALL_USER_GROUP +
            localStorage.getItem("companyIdconfig") +
            "/" +
            this.state.userDit.cipher,
          dataPayload,
          this.props.history
        );
      }
      if (this.props.location.pathname === "/UserManagement") {
        this.props.getUsersList(
          serverApi.GET_ALL_USERS_LIST,
          dataPayload,
          this.props.history
        );
      }
    }
    // this.props.payschemeParametersList(serverApi.GET_ALL_PAYSCHEME_PARAMETERS_LIST, this.props.history); // calling api for department list
    // this.props.getUserGroups(
    //   serverApi.GET_ALL_USER_GROUP +
    //   localStorage.getItem("companyIdconfig") +
    //     "/"+this.state.userDit.cipher,
    //   this.props.history
    // );
  };

  handleSelectChange = (selectedValue, selectedId) => {
    localStorage.removeItem("configdata");
    this.setState({
      selectedValue: selectedValue,
      selectedId: selectedId,
    });

    // localStorage.removeItem("companyIdconfig");
    // localStorage.removeItem("configdata");

    this.props.getConfiguration(
      serverApi.GET_CONFIGURATION_BY_COMPANY + selectedValue,
      this.props.history,
      selectedValue
    );

    this.setState({
      companyIdconfig: selectedValue,
    });

    localStorage.setItem("companyIdconfig", selectedValue);
    localStorage.setItem("companyupdateId", selectedValue);
    localStorage.setItem("companyName", selectedId);
    const SupplierTypeData = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyIdconfig"),
      startIndex: "",
      lastIndex: "",
    };
    this.props.getSupplierTypeMasterList(
      serverApi.SUPPLIER_TYPES_LIST_API,
      SupplierTypeData,
      this.props.history
    );
    const LeaveTypeListData = {
      cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      companyId: localStorage.getItem("companyIdconfig"),
      leaveTypeCode: null,
      leaveDesc: null,
      payable: null,
    };
    this.props.getLeaveTypeList(
      serverApi.GET_ALL_LEAVE_TYPE_LIST,
      LeaveTypeListData,
      this.props.history
    );
    

    const data = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyIdconfig"),
    };
    if (localStorage.getItem("companyIdconfig")) {
      const data = {
        activeStatus: "",
        status: "",
        // orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
        orgId: "",
        companyId: selectedValue,
      };
      this.props.getPayScheme(
        serverApi.EMPLOYEE_PAY_SCHEME,
        data,
        this.props.history
      );
      let dataPayload = {
        comId: selectedValue,
        cipher: JSON.parse(localStorage.getItem("authUser")).cipher,
      };
      if (this.props.location.pathname === "/role_management") {
        this.props.getUserGroups(
          serverApi.GET_ALL_USER_GROUP +
            localStorage.getItem("companyIdconfig") +
            "/" +
            this.state.userDit.cipher,
          dataPayload,
          this.props.history
        );
      }
      if (this.props.location.pathname === "/UserManagement") {
        this.props.getUsersList(
          serverApi.GET_ALL_USERS_LIST,
          dataPayload,
          this.props.history
        );
      }
      if (this.props.location.pathname === "/create_user") {
        this.props.history.push("/UserManagement")
      }

    } else {
      const data = {
        activeStatus: "",
        status: "",
        orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
        companyId: selectedValue,
      };
      this.props.getPayScheme(
        serverApi.EMPLOYEE_PAY_SCHEME,
        data,
        this.props.history
      );
    }
    this.props.departmentMasterList(
      serverApi.GET_ALL_DEPARTMENT_MASTER_LIST,
      data,
      this.props.history
    ); // calling api for department list
    // calling api for Leave type list
    // this.props.payschemeParametersList(serverApi.GET_ALL_PAYSCHEME_PARAMETERS_LIST, this.props.history); // calling api for department list
    this.props.payschemeParametersList(
      serverApi.GET_ALL_PAYSCHEME_PARAMETERS_LIST +
        "/companyId/" +
        selectedValue +
        "/orgId/0",
      this.props.history
    );
    this.props.getLeavePolicyListAll(
      serverApi.GET_LEAVE_POLICY + localStorage.getItem("companyIdconfig"),
      this.props.history
    );
    const taxdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyIdconfig"),
      taxname: "",
      taxper: "",
    };
    this.props.taxMasterList(
      serverApi.TAX_MASTER_LIST,
      taxdata,
      this.props.history
    );
    // bankAccount api call
    const BankAccountdata = {
      cipher: this.state.userDit.cipher,
      companyId: localStorage.getItem("companyIdconfig"),
    };
    this.props.bank_account_list_api(
      serverApi.BANK_ACCOUNT_LIST_API,
      BankAccountdata,
      this.props.history
    );

    // if(localStorage.getItem("companyIdconfig")){

    // }else{
    //   const data = {
    //     activeStatus: "",
    //     status: "",
    //     orgId: JSON.parse(localStorage.getItem("authUser")).orgId,
    //     companyId: localStorage.getItem("companyupdateId")
    //   };
    //   this.props.getPayScheme(
    //     serverApi.EMPLOYEE_PAY_SCHEME,
    //     data,
    //     this.props.history
    //   );
    // }
  };

  componentWillReceiveProps(props) {
    if (props.configdata) {
      localStorage.setItem("configdata", JSON.stringify(props.configdata));
      cons.CONFIG_DATA = props.configdata;
    }
    var arrayOfData = [];
    var selectedlable = "";
    let selectedItem = "";
    if (props.companyslist) {
      var obj1 = { label: "Select....", value: "", name: "Select Company" };
      arrayOfData.push(obj1);
      props.companyslist.forEach((prop) => {
        var obj = {
          label: prop.companyCode,
          value: prop.companyId,
          name: prop.companyCode,
        };
        if (this.state.companyIdconfig == prop.companyId) {
          selectedlable = prop.companyCode;
        }
        arrayOfData.push(obj);
      });
      this.setState({
        companyList: arrayOfData,
      });
      selectedItem = {
        value: this.state.companyIdconfig,
        label: selectedlable,
      };
    }
  }
  handleMenuOpen = () => {
    this.setState({
      isDropdownOpen: false,
    });
  };

  handleScroll = (event) => {
    this.setState({
      isDropdownOpen: true,
    });
  };

  render() {
    // var arrayOfData = [];
    // var selectedlable = "";
    // let selectedItem = "";
    // if (this.props.companyslist) {
    //   var obj1 = { label: "Select....", value: "", name: "Select Company" };
    //   arrayOfData.push(obj1);
    //   this.props.companyslist.forEach((prop) => {
    //     var obj = {
    //       label: prop.companyName,
    //       value: prop.companyId,
    //       name: prop.companyName,
    //     };
    //     if (this.state.companyIdconfig == prop.companyId) {
    //       selectedlable = prop.companyName;
    //     }
    //     arrayOfData.push(obj);
    //   });
    //   selectedItem = {
    //     value: this.state.companyIdconfig,
    //     label: selectedlable,
    //   };
    // }

    return (
      <div className="innerNavContainer">
        <div>
          <h4>{this.props.headerName}</h4>
          {this.props.headerName === "Configuration Details" ||
          this.props.headerName === "Master Data" ||
          this.props.headerName === "User Management" ? (
            <div className="consoleTextFieldBlock">
              <DynamicSelect
                arrayOfData={this.state.companyList}
                onSelectChange={this.handleSelectChange}
                isDropdownOpen={this.state.isDropdownOpen}
                handleMenuOpen={this.handleMenuOpen}
                // selected={selectedItem ? selectedItem : { label: "Select....", value: "", name: "Select Company" }}
                selected={this.state.companyIdconfig}
              />
            </div>
          ) : (
            ""
          )}
          <ul>
            {this.props.menuList ? (
              <>
                {this.props.menuList.map((item, key) => {
                  if (
                    item.name === "Official Details" ||
                    item.name === "Branch Creation"
                  ) {
                    return (
                      <li
                        className="pointer"
                        style={{
                          pointerEvents:
                            this.props.headerData === null ? "none" : "auto",
                        }}
                      >
                        <Link to={item.link} className={item.active}>
                          {item.name}
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li className="pointer">
                        <Link to={item.link} className={item.active}>
                          {item.name}
                        </Link>
                      </li>
                    );
                  }
                })}
              </>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { companyslist, companyscoulmns, headerData } = state.BasicDetails;
  const { configdatalist, configdata } = state.Configuration;
  return {
    companyslist,
    companyscoulmns,
    configdatalist,
    configdata,
    headerData,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    getCompanys,
    getConfiguration,
    getLeaveTypeList,
    departmentMasterList,
    payschemeParametersList,
    getLeavePolicyListAll,
    getPayScheme,
    taxMasterList,
    bank_account_list_api,
    getUserGroups,
    getUsersList,
    getSupplierTypeMasterList
    // basicdetailsAdd,
  })(InnerNavigation)
);
