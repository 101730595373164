import { API_ERRORS, UPDATE_SUPPLIER_TYPES_SUCCESSFULL } from "./actionsTypes";
import { GET_SUPPLIER_TYPE_MASTER_LIST, 
    GET_SUPPLIER_TYPE_MASTER_LIST_SUCCESSFULL,
    CREATE_SUPPLIER_TYPES_SUCCESSFULL,
    CREATE_SUPPLIER_TYPES,
    UPDATE_SUPPLIER_TYPES } from "./actionsTypes"

export const getSupplierTypeMasterList=(url,data,history)=>{
    return{
        type:GET_SUPPLIER_TYPE_MASTER_LIST,
        payload:{url,data,history},

    };
}

export const getSupplierTypeMasterListSuccessfull=(data)=>{
    return{
        type:GET_SUPPLIER_TYPE_MASTER_LIST_SUCCESSFULL,
        payload:data,
    }
}

export const createSupplierTypes=(url,data,history)=>{
    return{
        type:CREATE_SUPPLIER_TYPES,
        payload:{url,data,history},
}
}
export const createSupplierTypesSuccessfull=(data)=>{
    return{
        type:CREATE_SUPPLIER_TYPES_SUCCESSFULL,
        payload:data,
    }
}

export const updateSupplierTypes=(url,data,history)=>{
    return{
        type:UPDATE_SUPPLIER_TYPES,
        payload:{url,data,history},

    }
}
export const updateSupplierTypesSuccessfull=(data)=>{
    return{
        type:UPDATE_SUPPLIER_TYPES_SUCCESSFULL,
        payload:data,

    }
}

export const apiErrors=(error)=>{
    return{
        type:API_ERRORS,
        payload:error,

    };
};
